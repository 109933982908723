import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileItem } from './FileItem';
import { RenameFilePopup } from '../popups/RenameFilePopup';
import { StatusMessages } from '../common/StatusMessages';
import { Pagination } from '../common/Pagination';

export const FileList = ({ 
  files = [], 
  folders = [],
  loadMoreFiles,
  onNavigate,
  onDeleteFile,
  onRenameFile,
  onMoveFile,
  totalFiles = 0,
  pageSize = 10
}) => {
  const [currentFiles, setCurrentFiles] = useState(files);
  const [openRenameFilePopup, setOpenRenameFilePopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  
  // Calculate total pages
  const totalPages = Math.ceil(totalFiles / pageSize);
  
  // Update files when prop changes
  useEffect(() => {
    setCurrentFiles(files);
    setIsLoading(false);
  }, [files]);
  
  // Handle file clicks
  const handleFileClick = (file) => {
    onNavigate(`/file_transcript?id=${file.id}`, {
      state: { file },
    });
  };
  
  // Handle file rename
  const handleOpenRename = (file) => {
    setSelectedFile(file);
    setOpenRenameFilePopup(true);
  };
  
  const handleRenameSubmit = async (newName) => {
    setOpenRenameFilePopup(false);
    
    try {
      await onRenameFile(selectedFile.id, newName);
      
      // Update local state
      setCurrentFiles(prevFiles => 
        prevFiles.map(file => 
          file.id === selectedFile.id ? { ...file, name: newName } : file
        )
      );
    } catch (err) {
      setError(err.message || 'Failed to rename file');
    }
  };
  
  const handleCloseRenamePopup = (e) => {
    if (e) e.stopPropagation();
    setOpenRenameFilePopup(false);
  };

  // Handle file delete
  const handleDeleteFile = async (file) => {
    try {
      await onDeleteFile(file.id, file.name);
      setSuccess('File deleted successfully');
      
      // Update local state
      setCurrentFiles(prevFiles => prevFiles.filter(f => f.id !== file.id));
    } catch (err) {
      setError(err.message || 'Failed to delete file');
    }
  };
  
  // Handle file move
  const handleMoveFile = async (fileId, folderId) => {
    try {
      await onMoveFile(fileId, folderId);
      setSuccess('File moved successfully');
      
      // Update local state
      setCurrentFiles(prevFiles => prevFiles.filter(f => f.id !== fileId));
    } catch (err) {
      setError(err.message || 'Failed to move file');
    }
  };
  
  // Pagination handlers
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages && page !== currentPage) {
      setIsLoading(true);
      setCurrentPage(page);
      loadMoreFiles(page, true); // true means replace instead of append
    }
  };
  
  // Container variants for animations
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.05 
      } 
    }
  };

  return (
    <div className="flex flex-col text-sm text-updated-color-grey3">
      <StatusMessages 
        error={error} 
        success={success} 
        clearError={() => setError(null)} 
        clearSuccess={() => setSuccess(null)} 
      />
      
      <AnimatePresence>
        {openRenameFilePopup && selectedFile && (
          <RenameFilePopup
            name={selectedFile.name}
            onSubmit={handleRenameSubmit}
            onClose={handleCloseRenamePopup}
          />
        )}
      </AnimatePresence>
      
      <div className="relative">
        {isLoading && (
          <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center z-10">
            <div className="loader-spinner"></div>
          </div>
        )}
        
        <motion.div 
          className="flex flex-col w-full py-[5px] px-0 relative gap-[10px] h-[400px] overflow-y-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {currentFiles.length === 0 ? (
            <motion.div 
              className="text-center py-8 text-gray-500"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {isLoading ? 'Loading...' : 'No files found'}
            </motion.div>
          ) : (
            currentFiles.map((file, index) => (
              <motion.div
                key={file.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ 
                  duration: 0.3,
                  delay: index * 0.05,
                }}
              >
                <FileItem
                  file={file}
                  folders={folders}
                  onClick={handleFileClick}
                  onRename={handleOpenRename}
                  onDelete={handleDeleteFile}
                  onMove={handleMoveFile}
                />
              </motion.div>
            ))
          )}
        </motion.div>
      </div>
      
      {/* Pagination Controls */}
      <Pagination 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        className="mt-6"
      />
    </div>
  );
};