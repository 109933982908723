// src/components/popups/PopupForm.jsx
import React, { useState } from 'react';
import { BasePopup } from './BasePopup';
import { Button } from '../common/Button'; // Assuming Button is in this location

export const PopupForm = ({ 
  title, 
  initialValue = "", 
  onSubmit, 
  onClose, 
  label = "Name",
  submitText = "Submit",
  placeholder = "Enter name..." 
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!value.trim()) {
      setError(`${label} cannot be empty`);
      return;
    }
    
    onSubmit(value.trim());
  };

  return (
    <BasePopup title={title} onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name-input" className="block text-sm font-medium text-gray-700 mb-1">
            {label}:
          </label>
          <input
            id="name-input"
            type="text"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              if (error) setError("");
            }}
            placeholder={placeholder}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            autoFocus
          />
          {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
        </div>
        
        <div className="flex justify-end space-x-3">
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            {submitText}
          </Button>
        </div>
      </form>
    </BasePopup>
  );
};