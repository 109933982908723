import React, { memo, useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NoteFormatPopup } from './NoteFormatPopup';
import { downloadNotesAsPDF } from '../helpers/downloadNotes';

// FormatTag component for displaying active format chips
const FormatTag = memo(({ format, onRemove }) => (
  <div className='flex items-center px-3 py-1 bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] text-white rounded-full whitespace-nowrap'>
    <span className='mr-2 font-normal text-sm truncate' title={format.title}>
      {format.title}
    </span>
    <button
      onClick={() => onRemove(format.id)}
      className='text-sm bg-white rounded-full text-black hover:opacity-75'
      aria-label={`Remove ${format.title} format`}
    >
      <img src='/cancel.svg' className='w-3 h-3 pt-[0.2rem]' alt='Remove' />
    </button>
  </div>
));

FormatTag.propTypes = {
  format: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
};

// NoteEnhancer component for the text input and improvement functionality
const NoteEnhancer = memo(
  ({
    noteEnhancementPrompt,
    setNoteEnhancementPrompt,
    outlineContent,
    setOutlineContent,
    outlineContentRef,
    setIsEditingNotes,
    autoApplyNoteEnhancement,
    executeChatbotMethod,
    isChatbotInitialized,
    isTranscribing,
  }) => {
    // Add loading state for enhancement
    const [isEnhancing, setIsEnhancing] = useState(false);

    // Add timeout ref to prevent hanging enhancement
    const enhancementTimeoutRef = useRef(null);

    const enhanceNotes = useCallback(async () => {
      if (!noteEnhancementPrompt || isEnhancing || !isChatbotInitialized) {
        if (!isChatbotInitialized) {
          console.warn('Chatbot not initialized, please wait...');
        }
        return;
      }

      setIsEnhancing(true);
      setIsEditingNotes(true);

      // Set a timeout to prevent hanging forever
      enhancementTimeoutRef.current = setTimeout(() => {
        console.error('Note enhancement timed out');
        setIsEnhancing(false);
        setIsEditingNotes(false);
      }, 30000); // 30 second timeout

      try {
        // If we're actively transcribing, get latest context first
        if (isTranscribing) {
          await executeChatbotMethod('updateOutline', false, false);
        }

        const currentContent =
          (await executeChatbotMethod('getOutlineContent')) || outlineContent;
        const prompt = `Based on these notes:\n${currentContent}\n\nUser request: ${noteEnhancementPrompt}`;
        const response = await executeChatbotMethod(
          'handleNoteModification',
          prompt,
          true, // Force this to bypass throttling
        );

        // Clear the timeout since we got a response
        if (enhancementTimeoutRef.current) {
          clearTimeout(enhancementTimeoutRef.current);
          enhancementTimeoutRef.current = null;
        }

        if (response) {
          setOutlineContent(response);
          outlineContentRef.current = response;
          await executeChatbotMethod('setOutlineContent', response);
        } else {
          console.error('Empty response from note enhancement');
        }
      } catch (error) {
        console.error('Error enhancing notes:', error);
      } finally {
        setIsEnhancing(false);
        setIsEditingNotes(false);

        // Clear timeout if it's still active
        if (enhancementTimeoutRef.current) {
          clearTimeout(enhancementTimeoutRef.current);
          enhancementTimeoutRef.current = null;
        }

        if (!autoApplyNoteEnhancement) {
          setNoteEnhancementPrompt('');
        }
      }
    }, [
      noteEnhancementPrompt,
      isEnhancing,
      isChatbotInitialized,
      outlineContent,
      isTranscribing,
      executeChatbotMethod,
      setIsEditingNotes,
      setOutlineContent,
      outlineContentRef,
      autoApplyNoteEnhancement,
      setNoteEnhancementPrompt,
    ]);

    // Clean up timeout on unmount
    useEffect(() => {
      return () => {
        if (enhancementTimeoutRef.current) {
          clearTimeout(enhancementTimeoutRef.current);
          enhancementTimeoutRef.current = null;
        }
      };
    }, []);

    return (
      <div className='flex-1 relative flex items-end'>
        <textarea
          placeholder='Improve or modify notes...'
          className='flex-1 w-full p-[0.4rem] pl-3 pr-12 text-[#8C8C8C] border rounded-lg text-sm border-solid border-[#d9d9d9] resize-none h-12'
          value={noteEnhancementPrompt}
          onChange={(e) => setNoteEnhancementPrompt(e.target.value)}
          disabled={isEnhancing}
          aria-label='Note enhancement prompt'
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              enhanceNotes();
            }
          }}
        />
        <button
          className={`absolute bottom-0 p-2 transition-transform duration-300 rounded-full right-2 hover:bg-gray-100 ${
            isEnhancing ? 'opacity-50' : ''
          }`}
          onClick={enhanceNotes}
          disabled={isEnhancing || !noteEnhancementPrompt}
          aria-label='Apply note enhancement'
        >
          {isEnhancing ? (
            <svg
              className='animate-spin h-5 w-5 text-blue-500'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='2'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          ) : (
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='w-5 h-5 hover:w-6 text-blue-500 transition-all duration-300 hover:h-6'
            >
              <g clipPath='url(#clip0_2054_11417)'>
                <path
                  d='M9 3.75L9 14.25'
                  stroke='#007EF3'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M14.25 9L9 3.75L3.75 9'
                  stroke='#007EF3'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_2054_11417'>
                  <rect width='18' height='18' fill='white' />
                </clipPath>
              </defs>
            </svg>
          )}
        </button>
      </div>
    );
  },
);

NoteEnhancer.propTypes = {
  noteEnhancementPrompt: PropTypes.string.isRequired,
  setNoteEnhancementPrompt: PropTypes.func.isRequired,
  splitPosition: PropTypes.number.isRequired,
  chatbotRef: PropTypes.object.isRequired,
  outlineContent: PropTypes.string.isRequired,
  setOutlineContent: PropTypes.func.isRequired,
  outlineContentRef: PropTypes.object.isRequired,
  setIsEditingNotes: PropTypes.func.isRequired,
  autoApplyNoteEnhancement: PropTypes.bool.isRequired,
  executeChatbotMethod: PropTypes.func.isRequired,
  isChatbotInitialized: PropTypes.bool.isRequired,
  isTranscribing: PropTypes.bool.isRequired,
};

// ActionButtons component for templates and download buttons
const ActionButtons = memo(
  ({ setIsFormatPopupOpen, outlineContent, splitPosition }) => (
    <div className='flex flex-col gap-1'>
      <button
        onClick={() => setIsFormatPopupOpen(true)}
        className='flex ml-2 text-md gap-1 px-2 py-[0.05rem] border-solid bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] text-white rounded-md hover:opacity-75 transition-all duration-200 group'
        aria-label='Open templates'
      >
        <img
          src='/template.svg'
          className='w-6 h-6 pt-[0.2rem]'
          alt='Templates icon'
        />
        <div
          className={`pt-[0.4rem] font-normal text-[14px] ${splitPosition > 57 ? 'hidden pl-20' : 'block'}`}
        >
          Templates
        </div>
      </button>

      <button
        onClick={() => downloadNotesAsPDF(outlineContent)}
        className='flex ml-2 text-md gap-2 px-2 py-[0.1rem] border-solid border-[#d9d9d9] text-black hover:bg-blue-500 rounded-md hover:text-white transition-all duration-200 group'
        aria-label='Download notes as PDF'
      >
        <img
          src='/download1.svg'
          className='h-6 w-6 group-hover:brightness-0 group-hover:invert transition-all duration-200'
          alt='Download icon'
        />
        <div
          className={`pt-[0.3rem] ${splitPosition > 57 ? 'hidden' : 'block'}`}
        >
          Download
        </div>
      </button>
    </div>
  ),
);

ActionButtons.propTypes = {
  setIsFormatPopupOpen: PropTypes.func.isRequired,
  outlineContent: PropTypes.string.isRequired,
  splitPosition: PropTypes.number.isRequired,
};

// FormatDropdown component for selecting existing formats
const FormatDropdown = memo(
  ({
    showFormatDropdown,
    formats,
    activeFormats,
    handleApplyFormat,
    setShowFormatDropdown,
  }) => {
    if (!showFormatDropdown) return null;

    return (
      <div className='absolute bottom-full mb-24 left-0 w-48 bg-slate-200 rounded-md shadow-lg p-2 z-50 space-y-1'>
        {formats.map((fmt) => {
          const isActive = activeFormats.some((af) => af.id === fmt.id);
          return (
            <button
              key={fmt.id}
              onClick={() => {
                if (!isActive) {
                  handleApplyFormat(fmt);
                }
                setShowFormatDropdown(false);
              }}
              disabled={isActive}
              className={`block w-full text-left rounded-md px-3 py-2 border-solid border-updated-color-blue bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] transition-all ease-in-out duration-700 hover:bg-gradient-to-r hover:from-[#a2ace7] hover:to-[#75b1f4] ${
                isActive ? 'opacity-50 cursor-not-allowed' : 'text-white'
              }`}
              aria-label={`Apply ${fmt.title} format`}
            >
              {fmt.title}
            </button>
          );
        })}
      </div>
    );
  },
);

FormatDropdown.propTypes = {
  showFormatDropdown: PropTypes.bool.isRequired,
  formats: PropTypes.array.isRequired,
  activeFormats: PropTypes.array.isRequired,
  handleApplyFormat: PropTypes.func.isRequired,
  setShowFormatDropdown: PropTypes.func.isRequired,
};

// Main OutlineContent component
export const OutlineContent = memo(
  ({
    activeFormats,
    handleDiscontinueFormat,
    setNoteEnhancementPrompt,
    noteEnhancementPrompt,
    chatbotRef,
    outlineContent,
    outlineContentRef,
    setOutlineContent,
    splitPosition,
    setIsEditingNotes,
    autoApplyNoteEnhancement,
    setIsFormatPopupOpen,
    showFormatDropdown,
    setShowFormatDropdown,
    handleApplyFormat,
    isFormatPopupOpen,
    formatToEdit,
    setFormatToEdit,
    handleCreate,
    formats,
    executeChatbotMethod,
    isChatbotInitialized,
  }) => {
    return (
      <div className='p-2 flex flex-col border-t mt-auto'>
        {/* Active formats display */}
        <div className='flex flex-row items-center gap-1 ml-1 relative'>
          <div className='flex flex-wrap gap-1'>
            {activeFormats.map((fmt) => (
              <FormatTag
                key={fmt.id}
                format={fmt}
                onRemove={handleDiscontinueFormat}
              />
            ))}
          </div>
        </div>

        {/* Note enhancement input */}
        <div className='flex items-center justify-between mt-2'>
          <NoteEnhancer
            noteEnhancementPrompt={noteEnhancementPrompt}
            setNoteEnhancementPrompt={setNoteEnhancementPrompt}
            chatbotRef={chatbotRef}
            outlineContent={outlineContent}
            setOutlineContent={setOutlineContent}
            outlineContentRef={outlineContentRef}
            setIsEditingNotes={setIsEditingNotes}
            autoApplyNoteEnhancement={autoApplyNoteEnhancement}
            executeChatbotMethod={executeChatbotMethod}
            isChatbotInitialized={isChatbotInitialized}
          />

          <ActionButtons
            setIsFormatPopupOpen={setIsFormatPopupOpen}
            outlineContent={outlineContent}
            splitPosition={splitPosition}
          />
        </div>

        {/* Format dropdown */}
        <div className='flex flex-wrap items-center gap-2 ml-3 relative'>
          <FormatDropdown
            showFormatDropdown={showFormatDropdown}
            formats={formats}
            activeFormats={activeFormats}
            handleApplyFormat={handleApplyFormat}
            setShowFormatDropdown={setShowFormatDropdown}
          />

          {/* Format popup modal */}
          <NoteFormatPopup
            isOpen={isFormatPopupOpen}
            onClose={() => {
              setIsFormatPopupOpen(false);
              setFormatToEdit(null);
            }}
            formats={formats}
            formatToEdit={formatToEdit}
            onCreateFormat={handleCreate}
            onApplyFormat={handleApplyFormat}
            activeFormats={activeFormats}
          />
        </div>
      </div>
    );
  },
);

OutlineContent.propTypes = {
  activeFormats: PropTypes.array.isRequired,
  handleDiscontinueFormat: PropTypes.func.isRequired,
  setNoteEnhancementPrompt: PropTypes.func.isRequired,
  noteEnhancementPrompt: PropTypes.string.isRequired,
  chatbotRef: PropTypes.object.isRequired,
  outlineContent: PropTypes.string.isRequired,
  outlineContentRef: PropTypes.object.isRequired,
  setOutlineContent: PropTypes.func.isRequired,
  splitPosition: PropTypes.number.isRequired,
  setIsEditingNotes: PropTypes.func.isRequired,
  autoApplyNoteEnhancement: PropTypes.bool.isRequired,
  setIsFormatPopupOpen: PropTypes.func.isRequired,
  showFormatDropdown: PropTypes.bool.isRequired,
  setShowFormatDropdown: PropTypes.func.isRequired,
  handleApplyFormat: PropTypes.func.isRequired,
  isFormatPopupOpen: PropTypes.bool.isRequired,
  formatToEdit: PropTypes.object,
  setFormatToEdit: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  formats: PropTypes.array.isRequired,
  executeChatbotMethod: PropTypes.func.isRequired,
  isChatbotInitialized: PropTypes.bool.isRequired,
};
