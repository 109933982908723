import { useState, useCallback, useEffect, lazy, Suspense } from 'react';
import { userInfoRef, updateAccountInfo, jwtTokenRef } from '../../httpContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { motion } from 'framer-motion';

// Lazy load components that aren't needed immediately
const StartjoinARoomOverlay = lazy(() => import('../../components/mobile/StartjoinARoomOverlay'));
const PortalPopup = lazy(() => import('../../components/mobile/PortalPopup'));
const Profile = lazy(() => import('../../components/mobile/Profile'));

// Loading fallback component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-full">
    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

export const Home = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isStartjoinARoomOverlayOpen, setStartjoinARoomOverlayOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [userInfo, setUserInfo] = useState(null);

  const onFrameContainerClick = useCallback(() => {
    navigate('/transcribe');
  }, [navigate]);

  const openStartjoinARoomOverlay = useCallback(() => {
    setStartjoinARoomOverlayOpen(true);
  }, []);

  const closeStartjoinARoomOverlay = useCallback(() => {
    setStartjoinARoomOverlayOpen(false);
  }, []);

  const openProfile = useCallback(() => {
    setProfileOpen(true);
  }, []);

  const closeProfile = useCallback(() => {
    setProfileOpen(false);
  }, []);

  const refreshEmail = useCallback(async () => {
    try {
      await updateAccountInfo(jwtTokenRef, userInfoRef);
      if (userInfoRef.current) {
        setEmail(userInfoRef.current.email);
        setUserInfo(userInfoRef.current);
      }
    } catch (error) {
      console.error("Error updating account info:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Check if we already have user info to avoid unnecessary loading
    if (userInfoRef.current) {
      setEmail(userInfoRef.current.email);
      setUserInfo(userInfoRef.current);
      setIsLoading(false);
    } else {
      refreshEmail();
    }
  }, [refreshEmail]);

  // Check if we need to open subscriptions based on navigation state
  useEffect(() => {
    if (location.state?.openSubscriptions) {
      // Open profile first since subscriptions are accessed through it
      setProfileOpen(true);
      // This will be handled by the Profile component
      // Clear the state to prevent reopening on subsequent renders
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  // Memoized animation variants with smoother transitions
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1, // Slightly increased for smoother animation
        duration: 0.3 // Increased for smoother animation
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 }, // Increased y distance for smoother animation
    visible: {
      y: 0,
      opacity: 1,
      transition: { 
        type: "spring", 
        stiffness: 300, // Reduced stiffness for smoother animation
        damping: 20, 
        duration: 0.4 // Increased duration for smoother animation
      }
    }
  };

  // Header is not part of staggered animation to ensure it loads immediately
  const headerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.3 } // Increased for smoother animation
    }
  };

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <DotLottieReact
          src="https://lottie.host/e54ef364-4fbd-4d27-af7c-dbce99bf43a5/AyZ2WNA90E.json"
          background="transparent"
          speed="1"
          loop
          autoplay
          className="w-32 h-32"
        />
      </div>
    );
  }

  return (
    <motion.div 
      className='h-full overflow-y-scroll pb-20'
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className='flex min-h-full flex-col gap-4 px-5 pt-16 text-left sm:text-base text-xs text-updated-color-new-black font-montserrat'>
        {/* Header with logo and profile - not part of staggered animation */}
        <motion.div 
          className='flex items-center justify-between sticky top-0 z-10'
          variants={headerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className='flex flex-row items-start justify-start'>
            <img
              className='w-[52px] relative h-[52px] overflow-hidden shrink-0'
              alt=''
              src='/1024px-x-1024px-round-w-text.svg'
              loading="eager"
              fetchPriority="high"
            />
          </div>
          <div className='flex flex-row items-center justify-start gap-[6px]'>
            <div className='relative leading-[22px] font-semibold text-base'>
              Hello,{' '}
            </div>
            <motion.img
              className='w-[52px] relative h-[52px] object-cover cursor-pointer rounded-full'
              alt=''
              src={userInfo?.profile_picture || '/no-picture.svg'}
              onClick={openProfile}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              loading="eager"
              fetchPriority="high"
            />
          </div>
        </motion.div>
        <motion.h1 
          className='text-base sm:text-lg font-semibold'
          variants={itemVariants}
        >
          Welcome to Interpret!
        </motion.h1>
        <div className='flex flex-col sm:gap-[15px] gap-[8px]'>
          <motion.div
            className='rounded-xl bg-[linear-gradient(110.83deg,_rgba(0,_126,_243,_0.09),_rgba(121,_119,_209,_0.09))] shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] flex flex-col items-start justify-start py-4 sm:py-5 px-4 sm:px-2.5 cursor-pointer'
            onClick={onFrameContainerClick}
            variants={itemVariants}
            whileHover={{ scale: 1.02, boxShadow: "0px 5px 10px rgba(140, 140, 140, 0.3)" }}
            whileTap={{ scale: 0.98 }}
          >
            <div className='flex flex-col items-start justify-start gap-[10px] w-full'>
              <div className='w-full flex flex-row items-center py-0 box-border'>
                <div className='relative leading-[22px] font-medium text-nowrap text-base w-full'>
                  AI-powered Transcriber
                </div>
              </div>
              <div className='w-full flex items-center justify-center'>
                <DotLottieReact
                  src='https://lottie.host/e54ef364-4fbd-4d27-af7c-dbce99bf43a5/AyZ2WNA90E.json'
                  background='transparent'
                  speed='1'
                  loop
                  autoplay
                  className='w-64 h-64'
                />
              </div>
            </div>
            <div className='w-full flex items-end flex-wrap justify-between py-0 mt-[-17px] text-2xl'>
              <div className='relative font-semibold z-[0]'>
                Start a<br /> Conversation Now!
              </div>
              <motion.img
                className='w-6 h-6'
                alt=''
                src='/arrow--arrow-top-right-1.svg'
                animate={{ x: [0, 5, 0] }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 2, 
                  ease: "easeInOut" 
                }}
                loading="eager"
              />
            </div>
          </motion.div>
          <motion.div
            className='rounded-xl [background:linear-gradient(93.12deg,_rgba(121,_119,_209,_0.56),_rgba(24,_125,_236,_0.24)_80%,_rgba(0,_126,_243,_0.72))] shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] flex flex-col items-start justify-start py-2.5 px-4 gap-[15px] cursor-pointer'
            onClick={openStartjoinARoomOverlay}
            variants={itemVariants}
            whileHover={{ scale: 1.02, boxShadow: "0px 5px 10px rgba(140, 140, 140, 0.3)" }}
            whileTap={{ scale: 0.98 }}
          >
            <div className='flex flex-row items-start justify-start py-0'>
              <img
                className='w-10 relative h-10 object-cover opacity-[0.62]'
                alt=''
                src='/broadcast-headphones.png'
                loading="eager"
              />
            </div>
            <div className='flex flex-row items-center justify-between w-full py-0'>
              <div className='relative'>
                <p className='m-0 font-medium text-base'>Broadcast</p>
                <p className='m-0 text-xs text-nowrap'>share or join a room</p>
              </div>
              <motion.img
                className='w-6 h-6'
                alt=''
                src='/arrow--arrow-top-right-1.svg'
                animate={{ x: [0, 5, 0] }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 2, 
                  ease: "easeInOut" 
                }}
                loading="eager"
              />
            </div>
          </motion.div>
        </div>
      </div>
      {isStartjoinARoomOverlayOpen && (
        <Suspense fallback={<LoadingSpinner />}>
          <PortalPopup
            overlayColor='rgba(113, 113, 113, 0.3)'
            placement='Centered'
            onOutsideClick={closeStartjoinARoomOverlay}
          >
            <StartjoinARoomOverlay
              onOutsideClickFunc={closeStartjoinARoomOverlay}
            />
          </PortalPopup>
        </Suspense>
      )}
      {isProfileOpen && (
        <Suspense fallback={<LoadingSpinner />}>
          <PortalPopup
            overlayColor='rgba(113, 113, 113, 0.3)'
            placement='Centered'
            onOutsideClick={closeProfile}
          >
            <Profile
              setIsLoggedIn={setIsLoggedIn}
              email={email}
              closeFunc={closeProfile}
              openSubscriptions={location.state?.openSubscriptions}
            />
          </PortalPopup>
        </Suspense>
      )}
    </motion.div>
  );
};
