import { useEffect, useRef, useState } from 'react';
import { MobileFile } from './MobileFile';
import { MobileFolder } from './MobileFolder';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { MobilePagination } from './MobilePagination';

export const Files = ({
  title,
  recentFiles,
  setRecentFiles,
  refreshFiles,
  queryRef,
  currentFolderId,
  setCurrentFolderId,
  setCurrentFolderName,
  showFolders = false,
  totalCount = 0,
  forceRefresh = 0, // Add this prop
}) => {
  const navigate = useNavigate();
  const scrollingContainerRef = useRef(null);
  const scrollEndRef = useRef(null);
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  
  // Calculate total pages
  const totalPages = Math.max(1, Math.ceil(totalCount / pageSize));

  // Update the folder fetching function to be callable from outside
  const fetchFolders = async () => {
    try {
      console.log("Requesting folders from API...");
      setIsLoading(true);
      
      const response = await axios.get(`${serverURL}/list_folders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      
      const retrievedFolders = response.data.folders.map((folder) => ({
        name: folder.name,
        id: folder.id,
        path: folder.path,
      }));
      
      console.log("Folders retrieved:", retrievedFolders.length);
      setFolders(retrievedFolders);
      return retrievedFolders;
    } catch (error) {
      console.error('Error fetching folders:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch folders if needed
  useEffect(() => {
    if (!showFolders) return;
    
    // Add a console log to help debug
    console.log("Fetching folders...", { showFolders, currentFolderId, forceRefresh });
    
    fetchFolders();
  }, [showFolders, currentFolderId, forceRefresh]); // Re-fetch when folder ID changes too

  // Reset page when folder changes or query changes
  useEffect(() => {
    setCurrentPage(1);
  }, [currentFolderId, queryRef.current]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages || page === currentPage) return;
    setCurrentPage(page);
    refreshFiles(page); // Call the parent's refreshFiles with the new page number
  };

  const handleFileRemove = async (fileToRemove) => {
    try {
      await fetch(
        `${serverURL}/delete_transcript?transcript_id=${fileToRemove.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      
      // If it's the last item on the page and not the first page, go to previous page
      if (recentFiles.length === 1 && currentPage > 1) {
        handlePageChange(currentPage - 1);
      } else {
        // Otherwise just refresh current page
        refreshFiles(currentPage);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  // Fix to properly pass the handleFileRename function to MobileDoc
  const handleFileRenameWrapper = async (fileId, newName) => {
    try {
      await fetch(
        `${serverURL}/rename_transcript?transcript_id=${fileId}&new_name=${newName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        }
      );
      
      // Update file name in local state
      setRecentFiles(prevFiles => 
        prevFiles.map(file => 
          file.id === fileId ? { ...file, name: newName } : file
        )
      );
      
      return true;
    } catch (error) {
      console.error('Error renaming file:', error);
      return false;
    }
  };

  const handleFileOpen = (file) => {
    navigate('/file_transcript?id=' + file.id);
  };

  const handleFolderClick = (folder) => {
    setCurrentFolderId(folder.id);
    setCurrentFolderName(folder.name);
  };

  // Fix folder rename to properly update UI and make the API call correctly
  const handleFolderRenameWrapper = async (folderId, newName) => {
    try {
      console.log(`Renaming folder ${folderId} to ${newName}`);
      
      // Properly encode the URL parameters
      const encodedName = encodeURIComponent(newName);
      
      const response = await axios.post(
        `${serverURL}/rename_folder`,
        {}, // empty body
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          params: {
            folder_id: folderId,
            new_name: newName
          }
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error(`Failed to rename folder: ${response.statusText}`);
      }
      
      // Update folder name in state
      setFolders(prevFolders => 
        prevFolders.map(folder => 
          folder.id === folderId ? { ...folder, name: newName } : folder
        )
      );
      
      // If this is the current folder, update the title
      if (folderId === currentFolderId) {
        setCurrentFolderName(newName);
      }
      
      console.log(`Successfully renamed folder ${folderId} to ${newName}`);
      return true;
    } catch (error) {
      console.error('Error renaming folder:', error);
      return false;
    }
  };

  const handleFolderDelete = async (folderId) => {
    try {
      await fetch(`${serverURL}/delete_folder?folder_id=${folderId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      
      // Remove folder from state
      setFolders(prevFolders => 
        prevFolders.filter(folder => folder.id !== folderId)
      );
      
      // If this is the current folder, go back to all files
      if (folderId === currentFolderId) {
        setCurrentFolderId(null);
        setCurrentFolderName('');
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.08,
        when: "beforeChildren" 
      } 
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  // Scroll to top when page changes
  useEffect(() => {
    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.scrollTop = 0;
    }
  }, [currentPage]);

  // Add a key-based approach to ensure render updates
  const filesKey = useRef(0);
  
  // Update key when recentFiles changes to force re-render
  useEffect(() => {
    filesKey.current += 1;
  }, [recentFiles]);
  
  // Make sure folders are displayed properly after mount
  useEffect(() => {
    if (showFolders && !currentFolderId) {
      console.log("Initial folders fetch on mount");
      fetchFolders();
    }
  }, []); // Empty dependency array = only on mount
  
  // Add debug for better visibility
  useEffect(() => {
    console.log(`Files component updated: ${recentFiles.length} files, ${folders.length} folders`);
  }, [recentFiles, folders]);

  return (
    <div
      className='flex-grow flex flex-col gap-[0.75rem] text-left font-montserrat'
      ref={scrollingContainerRef}
    >
      
      {/* Folders Section */}
      {showFolders && !currentFolderId && folders.length > 0 && (
        <motion.div 
          className='mt-1 mb-3'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className='text-lg font-medium text-gray-700'>Folders</h2>
            <motion.span 
              className='text-sm text-blue-500 font-medium'
              whileHover={{ x: 2 }}
              whileTap={{ scale: 0.95 }}
            >
              {folders.length} {folders.length === 1 ? 'folder' : 'folders'}
            </motion.span>
          </div>
          <motion.div 
            className='flex flex-col gap-3 px-1'
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <AnimatePresence>
              {folders.map(folder => (
                <motion.div
                  key={`folder-${folder.id}`}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  transition={{ duration: 0.3 }}
                >
                  <MobileFolder
                    folder={folder}
                    handleFolderClick={handleFolderClick}
                    handleFolderRename={handleFolderRenameWrapper}
                    handleFolderDelete={handleFolderDelete}
                    refreshFolders={refreshFiles}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      )}
      
      {/* Files Section Heading */}
      {(!currentFolderId || recentFiles.length > 0) && (
        <motion.div
          className="flex items-center justify-between mt-2 mb-1"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <h2 className='text-lg font-medium text-gray-700'>{currentFolderId ? 'Files' : 'Recently Saved'}</h2>
          {recentFiles.length > 0 && (
            <motion.span 
              className='text-sm text-blue-500 font-medium'
              whileHover={{ x: 2 }}
              whileTap={{ scale: 0.95 }}
            >
              {totalCount} {totalCount === 1 ? 'file' : 'files'}
            </motion.span>
          )}
        </motion.div>
      )}
      
      {/* Files List */}
      <div key={filesKey.current} className='flex-grow flex flex-col gap-3 pb-4'>
        <AnimatePresence mode="wait">
          {isLoading ? (
            <motion.div
              key="loading"
              className="flex-grow flex items-center justify-center py-16"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </motion.div>
          ) : recentFiles.length > 0 ? (
            <motion.div
              key="file-list"
              className="flex flex-col gap-3 w-full"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {recentFiles.map((file, index) => (
                <motion.div
                  key={`file-${file.id}`}
                  variants={itemVariants}
                  transition={{ 
                    duration: 0.3,
                    delay: index * 0.05,
                  }}
                >
                  <MobileFile
                    file={file}
                    handleFileOpen={handleFileOpen}
                    handleFileRemove={handleFileRemove}
                    handleFileRename={handleFileRenameWrapper}
                    refreshFiles={() => refreshFiles(currentPage)}
                  />
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <motion.div 
              key="empty-state"
              className='flex flex-col items-center justify-center py-10 text-gray-500'
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <p className='text-base'>{currentFolderId ? 'This folder is empty' : 'No files found'}</p>
            </motion.div>
          )}
        </AnimatePresence>
        {/* Pagination */}
        {totalPages > 1 && (
          <motion.div 
            className="py-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <MobilePagination 
              currentPage={currentPage} 
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </motion.div>
        )}
        
        <div ref={scrollEndRef} className='h-10'></div>
      </div>
    </div>
  );
};
