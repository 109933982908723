// src/components/chatbot/ChatbotWidget.js
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { top100WorldLanguages } from '../../languages';
import { ChatbotProvider, useChatbot } from './ChatbotContext';
import {
  translateMessages,
  sendMessage,
  updateOutline,
  updateSuggestedPrompts,
  modifyNotes,
} from './ChatbotServices';
import {
  ChatHeader,
  ChatInput,
  SuggestedPrompts,
  OutlineContent,
  ChatMessages,
} from './ChatbotComponents';
import { userInfoRef } from '../../httpContext';

// Main component rendering
const ChatbotWidget = forwardRef(
  (
    {
      className,
      onClose,
      contextRef,
      isChatbotOpenRef,
      roomId,
      roomPassword,
      isToggled,
      isTranscribingRef,
    },
    ref,
  ) => {
    return (
      <ChatbotProvider
        contextRef={contextRef}
        isChatbotOpenRef={isChatbotOpenRef}
        roomId={roomId}
        roomPassword={roomPassword}
      >
        <ChatbotWidgetInner
          className={className}
          onClose={onClose}
          isToggled={isToggled}
          isTranscribingRef={isTranscribingRef}
          ref={ref}
        />
      </ChatbotProvider>
    );
  },
);

ChatbotWidget.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  contextRef: PropTypes.object.isRequired,
  isChatbotOpenRef: PropTypes.object.isRequired,
  roomId: PropTypes.string,
  roomPassword: PropTypes.string,
  isToggled: PropTypes.bool,
  isTranscribingRef: PropTypes.object,
};

// Inner component with access to context
const ChatbotWidgetInner = forwardRef(
  ({ className, onClose, isToggled, isTranscribingRef }, ref) => {
    const {
      // State
      messages,
      setMessages,
      input,
      setInput,
      selectedTab,
      setSelectedTab,
      outlineContent,
      setOutlineContent,
      isDetailedMode,
      setIsDetailedMode,
      selectedLanguage,
      setSelectedLanguage,
      languageDropdownOpen,
      setLanguageDropdownOpen,
      languageSearch,
      setLanguageSearch,
      suggestedPrompts,
      setSuggestedPrompts,
      setSections,

      // Refs
      messagesEndRef,
      outlineContentRef,
      currentModeRef,
      lastContextRefSnapshotRef,
      lastContextRefSnapshotSuggestedPromptsRef,

      // Context
      contextRef,
      isChatbotOpenRef,
      roomId,
      roomPassword,
    } = useChatbot();

    const languageDropdownRef = useRef(null);
    const [slideIn, setSlideIn] = useState(false);
    const [loadingOutline, setLoadingOutline] = useState(false);

    // Close language dropdown when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          languageDropdownRef.current &&
          !languageDropdownRef.current.contains(event.target)
        ) {
          setLanguageDropdownOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [setLanguageDropdownOpen]);

    // Trigger entrance animation immediately when mounted
    useEffect(() => {
      setSlideIn(true);
    }, []);

    // Update isChatbotOpenRef when mounted
    useEffect(() => {
      if (isChatbotOpenRef) {
        isChatbotOpenRef.current = true;
      }
    }, [isChatbotOpenRef]);

    // Update outline periodically when toggled
    useEffect(() => {
      let interval;
      if (isToggled && contextRef.current) {
        interval = setInterval(() => {
          const currentContext = contextRef.current || '';
          const lastSnapshot = lastContextRefSnapshotRef.current || '';

          if (currentContext !== lastSnapshot && isTranscribingRef?.current) {
            lastContextRefSnapshotRef.current = currentContext;
            updateOutlineInternal(currentModeRef.current);
          }
        }, 30000);
      }
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [
      isToggled,
      contextRef,
      lastContextRefSnapshotRef,
      currentModeRef,
      isTranscribingRef,
    ]);

    // Update outline when tab or toggle changes
    useEffect(() => {
      if (selectedTab === 'Live Outline' || isToggled) {
        updateOutlineInternal(isDetailedMode);
      }
    }, [selectedTab, isToggled, isDetailedMode]);

    // Update suggested prompts periodically
    useEffect(() => {
      let interval;
      const checkAndUpdatePrompts = async () => {
        while (!contextRef.current || contextRef.current.trim() === '') {
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        await updateSuggestedPromptsInternal();
      };

      if (selectedTab === 'AI Chat') {
        checkAndUpdatePrompts();
        interval = setInterval(checkAndUpdatePrompts, 30000);
      }

      return () => {
        if (interval) clearInterval(interval);
      };
    }, [selectedTab, contextRef]);

    // Internal function wrappers
    const updateSuggestedPromptsInternal = async () => {
      const suggestions = await updateSuggestedPrompts(
        isChatbotOpenRef,
        contextRef,
        lastContextRefSnapshotSuggestedPromptsRef,
        selectedLanguage,
        roomId,
        roomPassword,
      );

      if (suggestions) {
        setSuggestedPrompts((prev) => [...prev.slice(0, 2), ...suggestions]);
      }
    };

    // Enhanced outline fetching with loading state
    const updateOutlineInternal = async (isDetailed) => {
      setLoadingOutline(true);
      const newOutline = await updateOutline(
        isDetailed,
        contextRef,
        selectedLanguage,
      );

      if (newOutline) {
        setOutlineContent(newOutline);
        outlineContentRef.current = newOutline;
      }
      setLoadingOutline(false);
    };

    const handleSend = (messageContent) => {
      const content = messageContent || input;
      sendMessage(
        content,
        messages,
        setMessages,
        input,
        setInput,
        contextRef,
        selectedLanguage,
        roomId,
        roomPassword,
      );
    };

    const handleLanguageSelect = (lang) => {
      setSelectedLanguage(lang);
      setLanguageDropdownOpen(false);
      setLanguageSearch('');
      translateMessages(
        messages,
        setMessages,
        outlineContent,
        setOutlineContent,
        suggestedPrompts,
        setSuggestedPrompts,
        lang,
      );
    };

    // Function to add a section
    const addSection = async (
      title,
      instructions,
      position,
      currentSectionTitle,
    ) => {
      setSections((prev) => {
        const newSections = [...prev];
        const currentIndex = newSections.findIndex(
          ([secTitle]) => secTitle === currentSectionTitle,
        );

        if (position === 'top') {
          newSections.splice(currentIndex, 0, [title, instructions]);
        } else {
          newSections.splice(currentIndex + 1, 0, [title, instructions]);
        }

        // Update outline with new sections
        updateOutlineInternal(isDetailedMode);

        return newSections;
      });
    };

    // Function to remove a section
    const removeSection = (title) => {
      setSections((prev) =>
        prev.filter(([sectionTitle]) => sectionTitle !== title),
      );
      // Update outline
      updateOutlineInternal(isDetailedMode);
    };

    // Filtered languages for dropdown
    const filteredLanguages = [
      'Select Language',
      ...top100WorldLanguages.filter((lang) =>
        lang.toLowerCase().includes(languageSearch.toLowerCase()),
      ),
    ];

    // Expose methods via ref
    useImperativeHandle(
      ref,
      () => ({
        updateOutline: (isDetailed) => {
          setIsDetailedMode(isDetailed);
          return updateOutlineInternal(isDetailed);
        },
        getOutlineContent: () => outlineContentRef.current,
        setOutlineContent: (content) => {
          setOutlineContent(content);
          outlineContentRef.current = content;
        },
        handleNoteModification: (prompt) =>
          modifyNotes(prompt, isDetailedMode, selectedLanguage),
        addSection,
        removeSection,
      }),
      [isDetailedMode, selectedLanguage],
    );

    // Handle closing with animation
    const handleClose = () => {
      // First start the animation
      setSlideIn(false);

      // After animation completes, update the ref and call onClose
      setTimeout(() => {
        if (isChatbotOpenRef) {
          isChatbotOpenRef.current = false;
        }
        onClose();
      }, 300);
    };

    return (
      <div
        className={`fixed bottom-4 h-[85%] z-20 right-4 w-[500px] bg-gradient-to-r from-[#e6f2fd] to-[#f1f2fb]  rounded-xl 
                shadow-lg flex flex-col overflow-hidden transform transition-all 
                duration-300 ease-in-out ${slideIn ? 'translate-x-0 opacity-100' : 'translate-x-16 opacity-0'} 
                ${className}`}
      >
        {/* Header with fixed close button */}
        <ChatHeader
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          selectedLanguage={selectedLanguage}
          languageDropdownOpen={languageDropdownOpen}
          setLanguageDropdownOpen={setLanguageDropdownOpen}
          languageDropdownRef={languageDropdownRef}
          languageSearch={languageSearch}
          setLanguageSearch={setLanguageSearch}
          filteredLanguages={filteredLanguages}
          onSelectLanguage={handleLanguageSelect}
          onClose={handleClose}
        />

        {/* Content with loading indicator */}
        {selectedTab === 'Live Outline' ? (
          <>
            <OutlineContent content={outlineContent} />
            {loadingOutline && (
              <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-80'>
                <div className='loader'>Loading...</div>
              </div>
            )}
          </>
        ) : (
          <ChatMessages messages={messages} messagesEndRef={messagesEndRef} />
        )}

        {/* Chat input and suggested prompts - only shown on AI Chat tab */}
        {selectedTab === 'AI Chat' && (
          <>
            <ChatInput
              input={input}
              setInput={setInput}
              handleSend={handleSend}
            />
            <SuggestedPrompts
              prompts={suggestedPrompts}
              onPromptClick={handleSend}
            />
          </>
        )}
      </div>
    );
  },
);

ChatbotWidgetInner.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isToggled: PropTypes.bool,
  isTranscribingRef: PropTypes.object,
};

export default ChatbotWidget;
