import React from 'react';

const ConfirmationModal = ({
  title,
  message,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  confirmButtonClass = 'bg-blue-500 text-white rounded hover:bg-blue-600'
}) => {
  return (
    <div className='fixed inset-0 bg-black/50 flex items-center justify-center z-[30]'>
      <div className='bg-white rounded-lg p-6'>
        <h3 className='text-lg font-semibold mb-4'>{title}</h3>
        <p className='mb-6'>{message}</p>
        <div className='flex justify-end gap-4'>
          <button
            className='px-4 py-2 text-gray-600'
            onClick={onCancel}
          >
            {cancelText}
          </button>
          <button
            className={`px-4 py-2 ${confirmButtonClass}`}
            onClick={onConfirm}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;