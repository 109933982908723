 // utils/messageHelpers.js - Extract message-related functions  
 export const copyMessagesToClipboard = (messagesMerged, language) => {
   let text = messagesMerged.map(msg => {
     if (!language) {
       return msg.transcript || '';
     } else {
       return msg.corrected_transcripts[language] || msg.transcript || '';
     }
   }).join('\n');
   
   return navigator.clipboard.writeText(text);
 };