import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { socket } from '../../socketContext';
import { top100WorldLanguages } from '../../languages';
import { jwtTokenRef, translateTexts, userInfoRef } from '../../httpContext';
import RoomLinkAndQRCode from '../../components/mobile/RoomLinkAndQRCode';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { format } from 'date-fns';
import { serverURL } from '../../httpContext';
import axios from 'axios';
import ChatbotWidget from '../../components/chatbot/ChatbotWidget';
import { Switch } from '../../components/Switch';
import { playNextAudio, computeBlob } from '../../utils';

const formatDate = (date) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

const BroadcastRoom = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const [fontSize, setFontSize] = useState('medium');
  const [fontSizeDropdownVisible, setFontSizeDropdownVisible] = useState(false);
  const [showLinkQRCode, setShowLinkQRCode] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesMerged, setMessagesMerged] = useState([]);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState('');
  const [inputLanguageDisplay, setInputLanguageDisplay] = useState('Translate');
  const [showBackTooltip, setShowBackTooltip] = useState(false);
  const [showShareTooltip, setShowShareTooltip] = useState(false);
const [audioStatus, setAudioStatus] = useState('idle'); // 'idle', 'loading', 'playing'

  const linkAndQRCodeRef = useRef(null);
  const messagesRef = useRef(messages);
  const messagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const usingInputLanguagePopupRef = useRef(false);
  const inputLanguage = useRef(null);
  const inputLanguageRef = useRef(null);
  const inputLanguageDropdownRef = useRef(null);
  var startOfMessagesToKeepRef = useRef(null);
  const [lottieRef, setLottieRef] = useState(null);
  const roomIdRef = useRef(null);
  const passwordRef = useRef(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const isChatbotOpenRef = useRef(isChatbotOpen);
  const chatbotContextRef = useRef(null);
  const playingAudioRef = useRef(false);
  const liveAudioEnabledRef = useRef(false);
  const [liveAudioEnabled, setLiveAudioEnabled] = useState(false);

  var blankSrc =
    'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  const audioContextRef = useRef(new Audio(blankSrc));
  audioContextRef.current.autoplay = true;
  const audioQueueRef = useRef([]);
  const startProcessingIndexRef = useRef(messagesRef.current.length);

  const date = new Date();
  const formattedDate = formatDate(date);

  useEffect(() => {
    isChatbotOpenRef.current = isChatbotOpen;
  }, [isChatbotOpen]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  // Add fullscreen toggle function
  const toggleFullScreen = () => {
    const container = messagesContainerRef.current.parentElement;
    
    if (!document.fullscreenElement) {
      container.requestFullscreen().then(() => {
        setIsFullScreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullScreen(false);
      });
    }
  };

  const toggleLiveAudio = () => {
    try {
      if (!liveAudioEnabled) {
        
        audioContextRef.current.play()
          .then(() => {
            setLiveAudioEnabled(true);
       
            
            var lastFinalMessage = 0;
            for (let i = messages.length - 1; i >= 0; i--) {
              if (messages[i]['is_final']) {
                lastFinalMessage = i + 1;
                break;
              }
            }
            startProcessingIndexRef.current = lastFinalMessage;
          })
          .catch(err => {
            console.error("Error playing audio:", err);
            setAudioStatus('idle');
            document.addEventListener('click', function audioPlayHandler() {
              audioContextRef.current.play();
              document.removeEventListener('click', audioPlayHandler);
            }, { once: true });
          });
      } else {
        setLiveAudioEnabled(false);

        audioQueueRef.current = [];
        playingAudioRef.current = false;
        audioContextRef.current.pause();
      }
    } catch (error) {
      console.error("Error toggling live audio:", error);
 
      alert("There was an issue with audio playback. Please try again.");
    }
  };

  useEffect(() => {
    liveAudioEnabledRef.current = liveAudioEnabled;
  }, [liveAudioEnabled]);

  var tasks = [];

  useEffect(() => {
    console.log('Live audio enabled:', liveAudioEnabled);
    console.log('Messages length:', messages.length);
    console.log('Start processing index:', startProcessingIndexRef.current);

    if (!liveAudioEnabledRef.current) {
      return;
    }

    if (tasks.length > 0) {
      return;
    }
    setAudioStatus('loading');

    var textToAdd = '';
    for (let i = startProcessingIndexRef.current; i < messages.length; i++) {
      const message = messages[i];
      console.log('Processing message:', message);
      if (message['is_final']) {
        let text = message['transcript'];
        if (
          inputLanguage.current !== 'Translate' &&
          message['corrected_transcripts'][inputLanguage.current] !== undefined
        ) {
          text = message['corrected_transcripts'][inputLanguage.current];
        }
        textToAdd = textToAdd + ' ' + text;
        console.log('Text to add:', textToAdd);
        // var blob = computeBlob(text)
        // tasks.push(blob)
      }
    }
    if (textToAdd != '') {
      var blob = computeBlob(textToAdd, roomIdRef);
      tasks.push(blob);
    }

    var lastFinalMessage = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i]['is_final']) {
        lastFinalMessage = i + 1;
        break;
      }
    }
    startProcessingIndexRef.current = lastFinalMessage;

    // wait for all the blobs to be computed
    Promise.all(tasks).then((blobs) => {
      // const mergedBlob = new Blob(blobs); // Merge the blobs into one
      for (let i = 0; i < blobs.length; i++) {
        audioQueueRef.current.push(blobs[i]);
      }
      tasks = [];
      setAudioStatus('playing');

      playNextAudio(
        playingAudioRef,
        audioQueueRef,
        audioContextRef,
        liveAudioEnabledRef,
      );
    });
  }, [messages, liveAudioEnabled]);

  const updateMessagesMerged = (messagesUpdated) => {
    // copy the messagesUpdated array to avoid reference issues
    var messagesUpdatedCopy = JSON.parse(JSON.stringify(messagesUpdated));
    var messagesMergedUpdated = [];
    var startOfMessagesToKeep = -1;
    for (var i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesMergedUpdated.length > 0 &&
          lastMergedMessage['messages_merged'] < 4 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 60 // Check if the time gap is less than or equal to 7 seconds
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (var key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          if (messagesUpdatedCopy.length > 10 && startOfMessagesToKeep == -1) {
            startOfMessagesToKeep = i;
          }
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }

    if (messagesMergedUpdated.length > 0) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      lastMessageMerged['unconfirmed_transcript'] = '';
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
    }

    if (
      messagesUpdatedCopy.length > 0 &&
      !messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['is_final']
    ) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      var lengthOfLastMessage =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript']
          .length;
      lastMessageMerged['transcript'] = messagesMergedUpdated[
        messagesMergedUpdated.length - 1
      ]['transcript'].slice(0, -lengthOfLastMessage);
      lastMessageMerged['unconfirmed_transcript'] =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript'];
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
      for (var key in lastMessageMerged['corrected_transcripts']) {
        lengthOfLastMessage =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key].length;
        lastMessageMerged['corrected_transcripts'][key] = lastMessageMerged[
          'corrected_transcripts'
        ][key].slice(0, -lengthOfLastMessage);
        lastMessageMerged['unconfirmed_corrected_transcripts'][key] =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key];
      }
    }

    // ensure only last 30 messages are stored
    if (messagesMergedUpdated.length > 15) {
      messagesMergedUpdated = messagesMergedUpdated.slice(
        messagesMergedUpdated.length - 15,
      );
      console.log('startOfMessagesToKeep: ' + startOfMessagesToKeep);

      setMessages(messagesUpdatedCopy.slice(startOfMessagesToKeep));
      messagesRef.current = messagesUpdatedCopy.slice(startOfMessagesToKeep);
    } else {
      setMessages(messagesUpdatedCopy);
      messagesRef.current = messagesUpdatedCopy;
    }

    console.log(messagesMergedUpdated);
    console.log(
      'messagesMergedUpdated: ' + JSON.stringify(messagesMergedUpdated),
    );
    setMessagesMerged(messagesMergedUpdated);
  };
  

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchRoomDetails = async () => {
    try {
      // Don't attempt fetch if roomId isn't set
      if (!roomIdRef.current) {
        console.log('Room ID not set, skipping fetch');
        return;
      }
  
      const response = await axios.get(
        `${serverURL}/room/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`,
        {
          headers: { Authorization: `Bearer ${jwtTokenRef.current}` },
        },
      );
      const roomData = response.data;
  
      passwordRef.current = roomData.password;
  
      // Parse messages safely
      let messagesList = [];
      try {
        messagesList = roomData.messages ? JSON.parse(roomData.messages) : [];
      } catch (error) {
        console.error('Error parsing messages:', error);
        messagesList = [];
      }
        
      // Ensure all messages have necessary properties
      messagesList = messagesList.map(msg => ({
        ...msg,
        transcript_id: msg.transcript_id || `msg-${msg.timestamp || Math.floor(Date.now() / 1000)}-${Math.random().toString(36).substring(2, 9)}`,
        corrected_transcripts: msg.corrected_transcripts || {},
        is_final: msg.is_final !== undefined ? msg.is_final : true,
        timestamp: msg.timestamp || Math.floor(Date.now() / 1000)
      }));
  
      // Collect transcript for chatbot context
      let roomTranscript = '';
      for (let i = 0; i < messagesList.length; i++) {
        if (messagesList[i].transcript) {
          roomTranscript += messagesList[i].transcript + ' ';
        }
      }
      chatbotContextRef.current = roomTranscript;
  
      // Get current messages safely
      const currentMessages = messagesRef.current || [];
      
      // Always treat the server response as the source of truth
      // This simplifies our approach and ensures we never miss updates
      let hasNewMessages = true;
      
      // Log the update reason
      console.log('Updating messages from server (polling update)');
  
      if (hasNewMessages) {
        // Create a map of existing messages for reference
        const existingMessagesMap = new Map();
        
        if (Array.isArray(currentMessages)) {
          currentMessages.forEach(message => {
            if (message && message.transcript_id) {
              existingMessagesMap.set(message.transcript_id, message);
            }
          });
        }
  
        // Process new messages, retaining existing data only when server data is null/undefined
        for (let i = 0; i < messagesList.length; i++) {
          const newMsg = messagesList[i];
          const existingMsg = existingMessagesMap.get(newMsg.transcript_id);
          
          // If we have an existing message with the same ID
          if (existingMsg) {
            // Always initialize corrected_transcripts
            if (!newMsg.corrected_transcripts) {
              newMsg.corrected_transcripts = {};
            }
            
            // For transcripts: use new value unless it's null/undefined, then fall back to existing
            if (newMsg.transcript === null || newMsg.transcript === undefined) {
              newMsg.transcript = existingMsg.transcript || '';
            }
            
            // For corrected transcripts: merge them, prioritizing new values unless they're null/undefined
            if (existingMsg.corrected_transcripts) {
              // Go through each language in existingMsg
              Object.keys(existingMsg.corrected_transcripts).forEach(lang => {
                // If new message doesn't have this language translation, or it's null/undefined,
                // use the existing translation
                if (!newMsg.corrected_transcripts[lang]) {
                  newMsg.corrected_transcripts[lang] = existingMsg.corrected_transcripts[lang];
                }
              });
            }
          }
        }
  
        // Update the messages - use a copy for both state updates
        const messagesListCopy = JSON.parse(JSON.stringify(messagesList));
        
        // Update ref first to ensure it's available for any triggered effects
        messagesRef.current = messagesListCopy;
        
        // Update state
        setMessages(messagesListCopy);
        updateMessagesMerged(messagesListCopy);

        // Always scroll for new messages
        shouldScroll.current = true;
      }
    } catch (error) {
      console.error('Error fetching room details:', error);
      // Don't reset room state on error, just log it
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const roomId = pathname.split('/').pop();
    const password = params.get('password');
    
    // Clear previous room data first
    roomIdRef.current = null;
    passwordRef.current = null;
    
    // Only set room details if valid
    if (roomId) {
      console.log(`Joining room: ${roomId}`);
      roomIdRef.current = roomId;
      passwordRef.current = password;
      
      // Ensure we're connected to socket before fetching room details
      if (!socket.connected) {
        socket.connect();
        socket.on('connect', () => {
          console.log('Socket connected, fetching room details');
          fetchRoomDetails();
        });
      } else {
        fetchRoomDetails();
      }
      
      const intervalId = setInterval(fetchRoomDetails, 1000);
      return () => {
        clearInterval(intervalId);
        console.log(`Leaving room: ${roomId}`);
      };
    }
  }, [pathname]);

  useEffect(() => {
    const correctedMessagesContainer = messagesContainerRef.current;
    if (correctedMessagesContainer) {
      let lastScrollTop = correctedMessagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = correctedMessagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          shouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (correctedMessagesContainer) {
        correctedMessagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (correctedMessagesContainer) {
          correctedMessagesContainer.removeEventListener(
            'scroll',
            handleScroll,
          );
        }
      };
    }
  }, []);

  const correctedScrollToBottom = () => {
    setShouldShowScrollButton(false);
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    // Force scroll to bottom whenever messages change
    if (messagesMerged.length > 0 && messagesContainerRef.current) {
      correctedScrollToBottom();
    }
  }, [messagesMerged]);

  const addLanguage = async (language, passwordRef) => {
    const roomId = roomIdRef.current; // Assuming roomIdRef is defined and holds the current room ID
    try {
      const response = await axios.post(
        `${serverURL}/room/${roomId}/add_language`,
        {
          language: language,
          password: passwordRef.current,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      if (response.status === 200) {
        console.log('Language added successfully:', response.data);
      } else {
        console.error('Failed to add language:', response.status);
      }
    } catch (error) {
      console.error('Error adding language:', error);
    }
  };

  const handleInputLanguageClick = (language, passwordRef, messagesRef) => {
    if (!language) {
      console.error("No language selected");
      return;
    }
    
    setLanguageDropdownVisible(false);
    
    addLanguage(language, passwordRef, messagesRef)
      .catch(err => console.error("Error adding language:", err));
      
    if (language !== inputLanguage.current) {
      let textsToTranslate = [];
      let messagesAlreadyTranslated = [];
      
      if (!Array.isArray(messagesRef.current)) {
        console.error("messagesRef.current is not an array:", messagesRef.current);
        return;
      }
      
      for (let i = 0; i < messagesRef.current.length; i++) {
        let message = messagesRef.current[i];
        if (!message) continue;
        
        if (message.corrected_transcripts && 
            message.corrected_transcripts[language] !== undefined) {
          messagesAlreadyTranslated.push(i);
          continue;
        }
        textsToTranslate.push(message.transcript || "");
      }
      
      if (textsToTranslate.length > 0) {
        translateTexts(textsToTranslate, language)
          .then((translations) => {
            if (!Array.isArray(translations)) {
              console.error("Received non-array translations:", translations);
              return;
            }
            
            let translationIndex = 0;
            let updatedMessages = [];
            
            for (let i = 0; i < messagesRef.current.length; i++) {
              let message = messagesRef.current[i];
              if (!message) continue;
              
              if (messagesAlreadyTranslated.includes(i)) {
                updatedMessages.push(message);
                continue;
              }
              
              if (!message.corrected_transcripts) {
                message.corrected_transcripts = {};
              }
              
              if (translationIndex < translations.length) {
                message.corrected_transcripts[language] = 
                  translations[translationIndex] + ' ';
                translationIndex++;
              }
              
              updatedMessages.push(message);
            }
            
            var updatedMessagesCopy = JSON.parse(JSON.stringify(updatedMessages));
            setMessages(updatedMessages);
            updateMessagesMerged(updatedMessagesCopy);
          })
          .catch(err => {
            console.error("Translation error:", err);
          });
      }
    }
    
    inputLanguage.current = language;
    setInputLanguageDisplay(language);
  };

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        inputLanguage.current &&
        inputLanguage.current !== 'Detect Language' &&
        inputLanguage.current !== 'Translate'
      ) {
        addLanguage(inputLanguage.current, passwordRef, messagesRef);
      }
    }, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Removed dependencies to prevent creating new intervals on changes

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    let lastScrollTop = messagesContainer.scrollTop;

    const handleScroll = () => {
      const currentScrollTop = messagesContainer.scrollTop;
      if (currentScrollTop < lastScrollTop) {
        // User is scrolling up
        shouldScroll.current = false;
      }
      lastScrollTop = currentScrollTop;
    };

    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        linkAndQRCodeRef.current &&
        !linkAndQRCodeRef.current.contains(event.target)
      ) {
        setShowLinkQRCode(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className='flex bg-gradient-to-br from-slate-50 to-white w-full h-full'>
    {showLinkQRCode && (
      <div className='fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm bg-black/30 transition-all duration-300'>
        <RoomLinkAndQRCode
          link={`https://interpretapp.ai/broadcast/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`}
          onOutsideClickFunc={() => setShowLinkQRCode(false)}
          divRef={linkAndQRCodeRef}
          isWeb={true}
        />
      </div>
    )}
       <div className='h-screen w-32 shadow-lg flex flex-col items-center pt-8 relative transition-all duration-300 hover:w-40'
      style={{
        background: 'linear-gradient(135deg, rgba(59, 130, 246, 0.08) 0%, rgba(147, 51, 234, 0.08) 100%)',
        borderRight: '1px solid rgba(226, 232, 240, 0.8)'
      }}>


        <div className='w-full px-6'>
        <img src='/logo.svg' alt='logo' 
          className='w-10 h-10 mb-16 pl-7 transform hover:scale-110 transition-transform duration-300 cursor-pointer' />
      </div>
      <div className='flex flex-col gap-8 items-center w-full'>
        <button
          onClick={() => {
            if (userInfoRef.current) navigate('/transcribe');
            else navigate('/signup');
          }}
          className='relative group w-full px-6'
          onMouseOver={() => setShowBackTooltip(true)}
          onMouseOut={() => setShowBackTooltip(false)}
        >
          <div className='flex items-center justify-center w-12 h-12 rounded-xl bg-white shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300'>
            <img src='/broadcastback.svg' alt='back' className='w-6 h-6' />
          </div>
          {showBackTooltip && (
            <div className='absolute top-2  ml-16 px-3 py-2 bg-black text-white text-sm rounded-lg  group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap'>
              Back
            </div>
          )}
        </button>
        <button
          className='relative group w-full px-6'
          onClick={() => setShowLinkQRCode(true)}
          onMouseOver={() => setShowShareTooltip(true)}
          onMouseOut={() => setShowShareTooltip(false)}
        >
           <div className='flex items-center justify-center w-12 h-12 rounded-xl bg-white shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300'>
            <img src='/broadcastshare.svg' alt='share' className='w-6 h-6' />
          </div>
          {showShareTooltip && (
            <div className='absolute top-2 ml-16 px-3 py-2 bg-black text-white text-sm rounded-lg  group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap'>
              Share
            </div>
          )}
        </button>
      </div>
      </div>





      
      <div
        className={`flex ${isChatbotOpen ? 'mr-[500px]' : ''} flex-col gap-6 justify-center w-full h-screen px-14`}
      >
        <div className='flex justify-between items-center relative'>


        <div className="flex items-center w-full mr-6 gap-3"> 
        <div className="bg-blue-50 px-4 py-2 rounded-xl shadow-sm">
    <span className="font-montserrat text-blue-800 font-medium">Room Created</span>
  </div>
  <div className="flex items-center">
    <span className="font-montserrat text-gray-600 text-lg tracking-wide">
      {formattedDate}
    </span>
  </div>
  <div className="h-2 w-2 rounded-full bg-green-500 animate-pulse"></div>
          

  </div>


          
  <div className="flex items-center gap-2 mr-6">
  <button
    
    onClick={(e) => {
      e.preventDefault(); // Prevent any default button behavior
      e.stopPropagation(); // Stop event bubbling
      toggleLiveAudio();
      
    }}

  
    className={`relative flex items-center px-4 py-1 rounded-full transition-all duration-300 ${
      liveAudioEnabled 
      ? 'bg-gradient-to-r from-blue-200 to-purple-200 shadow-lg shadow-blue-200'
      : 'bg-gray-200 shadow-sm'
    }`}
  >
    {/* Animated circle */}
    <div 
      className={`absolute w-6 h-6 rounded-full transition-all duration-300 ${
        liveAudioEnabled 
        ? 'left-2 bg-white' 
        : 'left-2 bg-[#4b5563]'
      }`}
    >
      {/* Audio wave animation when active */}


      {liveAudioEnabled && audioStatus === 'loading' && (
  <div className="flex items-center justify-center h-full gap-[2px]">
    <div className="w-3 h-3 border-2 border-solid border-blue-400 border-t-transparent rounded-full animate-[spin_0.8s_linear_infinite]"></div>
  </div>
)}




{liveAudioEnabled && audioStatus === 'playing'  && (
  <div className="flex items-center justify-center h-full gap-[2px]">
    <div className="w-[2px] h-2 bg-blue-400 animate-[wave_1s_ease-in-out_infinite]"></div>
    <div className="w-[2px] h-3 bg-blue-500 animate-[wave_1s_ease-in-out_infinite_0.2s]"></div>
    <div className="w-[2px] h-2 bg-blue-400 animate-[wave_1s_ease-in-out_infinite_0.4s]"></div>
  </div>
)}


    </div>

    {/* Text */}
    <span 
      className={`ml-8 font-medium transition-all duration-300 ${
        liveAudioEnabled 
        ? 'text-white' 
        : 'text-gray-600'
      }`}
    >
      Live Audio
    </span>
  </button>
</div>

{/* Add this keyframe animation to your existing styles */}
<style jsx>{`
  @keyframes wave {
    0%, 100% { transform: scaleY(1); }
    50% { transform: scaleY(0.5); }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


`}</style>

          <div className="px-4">

          <div
  className="group w-fit min-w-[100px] rounded-xl flex items-center justify-between gap-4 py-3 px-5 border border-blue-200 hover:border-blue-400 bg-white hover:bg-blue-50 cursor-pointer transition-all duration-200 shadow-sm hover:shadow-md"
  onClick={() => setFontSizeDropdownVisible(!fontSizeDropdownVisible)}
>
  <div className="flex items-center gap-3">
    <span className="font-semibold text-gray-700 whitespace-nowrap">Font Size: </span>
    <span className="text-blue-600 font-medium">{fontSize}</span>
  </div>
  <img
    className="w-5 h-5 group-hover:rotate-180 transition-transform duration-200"
    alt="toggle font size"
    src='/select-more-language.svg'
  />
</div>

  </div>
  {fontSizeDropdownVisible && (
    <div className='absolute top-12 right-52 bg-white border border-solid border-gray-300 rounded-md shadow-lg z-10 font-poppins'>
      <div 
        className='px-4 py-2 hover:bg-slate-300 cursor-pointer rounded-md font-poppins'
        onClick={() => {
          setFontSize('Medium');
          setFontSizeDropdownVisible(false);
        }}
      >
        Medium (16px)
      </div>
      <div 
        className='px-4 py-2 hover:bg-slate-300 cursor-pointer rounded-md'
        onClick={() => {
          setFontSize('Large');
          setFontSizeDropdownVisible(false);
        }}
      >
        Large (20px)
      </div>
      <div 
        className='px-4 py-2 hover:bg-slate-300 cursor-pointer rounded-md'
        onClick={() => {
          setFontSize('Extra_Large');
          setFontSizeDropdownVisible(false);
        }}
      >
        Extra-Large (24px)
      </div>
    </div>
  )}

          
<div
  ref={inputLanguageRef}
  className="w-fit rounded-xl hover:shadow-xl transition-shadow duration-200 h-fit flex py-2 px-6 border border-blue-200 cursor-pointer bg-white hover:bg-blue-50"
  onClick={() => {
    setLanguageDropdownVisible(!languageDropdownVisible);
    usingInputLanguagePopupRef.current = !usingInputLanguagePopupRef.current;
  }}
>
            <div className='flex flex-col py-1.5 px-2.5'>
              <div className='flex   gap-[10px] cursor-pointer'>
                <b className='relative'>{inputLanguageDisplay}</b>
                <img
                  className='w-5 relative h-5'
                  alt=''
                  src='/select-more-language.svg'
                />
              </div>
            </div>
          </div>
          {languageDropdownVisible && (
            <div
              ref={inputLanguageDropdownRef}
              className='grid grid-cols-4 gap-8 px-10 bg-white border absolute top-12 right-0 border-solid border-gray-300 rounded-md shadow-lg z-10 max-h-96 overflow-y-auto w-full p-4'
            >
              <div className='col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full p-1 border-none outline-none text-lg font-montserrat'
                  value={searchBarInput}
                  onChange={(e) => setSearchBarInput(e.target.value)}
                />
                <img
                  className='w-5 h-5 ml-2'
                  alt=''
                  src='/general--search.svg'
                />
              </div>
              {top100WorldLanguages
                .filter((language) =>
                  language
                    .toLowerCase()
                    .includes(searchBarInput?.toLowerCase() || ''),
                )
                .map((language) => (
                  <div
                    key={language}
                    className=' hover:bg-gray-200 cursor-pointer w-full h-fit'
                    onClick={() =>
                      handleInputLanguageClick(
                        language,
                        passwordRef,
                        messagesRef,
                      )
                    }
                  >
                    {language}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div
          className='flex relative flex-col w-full text-xs text-updated-color-new-black font-montserrat h-2/3 bg-white rounded-lg border-solid border-px border-updated-color-grey1'
          onTransitionEnd={() => {
            if (shouldScroll.current) {
              // check if at the bottom
              const messagesContainer = messagesContainerRef.current;
              if (messagesContainer) {
                const { scrollTop, clientHeight, scrollHeight } =
                  messagesContainer;
                const atBottom =
                  Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
                if (atBottom) {
                  shouldScroll.current = false;
                }
              }
            }
          }}
        >
          <div
            className={`flex flex-col text-left max-h-[80%] w-12/12 overflow-y-auto font-inter text-black font-light ${
              fontSize === 'Extra_Large' ? 'text-[24px]' : 
              fontSize === 'Large' ? 'text-[20px]' : 
              'text-[16px]'
            }`}
            ref={messagesContainerRef}
>
            {messagesMerged.map((message, index) => {
              const localTime = format(
                new Date(message.timestamp * 1000),
                'hh:mm:ss a',
              );
              return (
                <div key={index} className="flex m-4 p-3 hover:bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg transition-all duration-300 group shadow-sm hover:shadow-lg border-l-4 border-transparent hover:border-l-blue-400">
                  
                  <span className="relative group mr-4 w-24 whitespace-nowrap text-ellipsis pt-1  pr-[4rem] flex items-center">

                  
                  <span className="text-gray-600 group-hover:text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 transition-all duration-300 font-medium">{localTime}</span>
                  </span>
                  {message.speaker && (
                    <span className='mr-4 font-bold'>{message.speaker}: </span>
                  )}
                  <div>
                    {inputLanguage.current === 'Translate' ||
                    inputLanguage.current === 'Detect Language'
                      ? message.transcript
                      : message.corrected_transcripts[inputLanguage.current]}
                    {message.unconfirmed_corrected_transcripts &&
                    inputLanguage.current in
                      message.unconfirmed_corrected_transcripts &&
                    message.unconfirmed_corrected_transcripts[
                      inputLanguage.current
                    ] ? (
                      <span style={{ color: 'grey' }}>
                        {
                          message.unconfirmed_corrected_transcripts[
                            inputLanguage.current
                          ]
                        }
                      </span>
                    ) : message.unconfirmed_transcript ? (
                      <span style={{ color: 'grey' }}>
                        {message.unconfirmed_transcript}
                      </span>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>


          <div className='flex items-center absolute z-10 left-4 bottom-1 '>


          <button 
    onClick={toggleFullScreen}
    className=' hover:bg-gray-100 '
  >
    <img
      src={isFullScreen ? '/exit.svg' : '/enter.svg'}
      alt='Toggle fullscreen'
      className='w-8 h-8 hover:w-9 hover:h-9 transition-all duration-300  rounded-md '
    />
  </button>
          </div>
          <div
            className={`w-fit absolute z-10 bottom-16 mx-auto left-0 right-0 cursor-pointer`}
          >
            {shouldShowScrollButton && (
              <img
                className='w-full h-full overflow-hidden'
                loading='lazy'
                alt=''
                src='/arrow--arrow-down-6-circle.svg'
                onClick={() => {
                  correctedScrollToBottom();
                }}
              />
            )}
          </div>
          <div className='absolute bg-white bottom-0 w-full pt-2 pb-2 rounded-b-lg'>
            <div className='flex justify-center mx-auto items-center w-11/12 gap-2'>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
              <div className='flex items-center justify-center'>
                <DotLottieReact
                  src='https://lottie.host/a425c32d-9541-45f1-95e0-2f3a44e31cb7/GWtgIB7950.json'
                  background='transparent'
                  speed='1'
                  loop
                  dotLottieRefCallback={(ref) => setLottieRef(ref)}
                  className='w-48'
                />
              </div>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
            </div>
          </div>
        </div>
      </div>
      {isChatbotOpen && (
        <ChatbotWidget
          isChatbotOpenRef={isChatbotOpenRef}
          onClose={() => setIsChatbotOpen(false)}
          contextRef={chatbotContextRef}
          roomId={roomIdRef.current}
          roomPassword={passwordRef.current}
        />
      )}
      {!isChatbotOpen && (
        <img
          className='cursor-pointer fixed bottom-5 right-5'
          src='/chai2.svg'
          alt='Chatbot'
          onClick={() => setIsChatbotOpen(true)}
        />
      )}
    </div>
  );
};

export default BroadcastRoom;
