// utils/api.js - Extract API functions
import axios from 'axios';
import { serverURL } from '../../../../httpContext';
import { updateMessagesMerged } from '../../../../helpers/updateMessages';

export const fetchRoomDetails = async (roomId, jwtToken, setMessages, messagesRef, setMessagesMerged) => {
  try {
    const response = await axios.get(
      `${serverURL}/room/${roomId}`,
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      },
    );
    const roomData = response.data;
    const password = roomData.password;
    
    const messagesList = roomData.messages
      ? JSON.parse(roomData.messages)
      : [];

    setMessages(messagesList);
    updateMessagesMerged(messagesList || [], setMessages, messagesRef, setMessagesMerged);
    
    return { messagesList, password };
  } catch (error) {
    console.error('Error fetching room details:', error);
    throw error;
  }
};

export const textToSpeech = async (text, jwtToken) => {
  try {
    const response = await axios.post(
      `${serverURL}/text_to_speech?jwt_token=${jwtToken}`,
      { text }, 
      { responseType: 'blob' }
    );
    
    if (response.status === 200) {
      return URL.createObjectURL(response.data);
    } else {
      throw new Error(`Received status code ${response.status}`);
    }
  } catch (error) {
    console.error('Error converting text to speech:', error);
    throw error;
  }
};

export const clearMessagesFromRoom = async (roomId, jwtToken) => {
  try {
    await axios.post(
      `${serverURL}/room/${roomId}/delete_messages`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      },
    );
    return true;
  } catch (error) {
    console.error('Error clearing messages:', error);
    throw error;
  }
};