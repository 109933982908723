import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import { FileActionButtons } from './FileActionButtons';

export const FileItem = ({ 
  file, 
  folders,
  onClick, 
  onRename, 
  onDelete, 
  onMove 
}) => {
  const [isHovered, setIsHovered] = useState(false);
  
  const handleClick = () => {
    onClick(file);
  };

  // Format the file type label based on transcript_type
  const getFileTypeLabel = (type) => {
    if (type === 'gpu' || type === 'gemini') {
      return 'Audio/Video Transcript';
    } else if (type === 'image') {
      return 'Image Transcription';
    } else if (type === 'file') {
      return 'Document';
    } else {
      return '';
    }
  };

  // Get file icon based on type
  const getFileIcon = (type) => {
    if (type === 'gpu' || type === 'gemini') {
      return '/icons/audio-video-file.svg';
    } else if (type === 'image') {
      return '/icons/image-file.svg';
    } else if (type === 'file') {
      return '/icons/document-file.svg';
    } else {
      return '/icons/default-file.svg';
    }
  };

  // Stop propagation of click events from action buttons
  const handleActionContainerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <motion.div
      className="relative w-full cursor-pointer"
      onClick={handleClick}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.98 }}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
    >
      <motion.div 
        className={`rounded-md grid grid-cols-4 items-center py-3 pl-3 pr-2 text-base hover:bg-blue-50 transition-all duration-300 border ${isHovered ? 'border-blue-200 shadow-sm' : 'border-transparent'}`}
        animate={{ 
          backgroundColor: isHovered ? 'rgba(239, 246, 255, 0.7)' : 'rgba(249, 250, 251, 0.7)'
        }}
      >
        {/* File name */}
        <div className="relative leading-[22px] font-medium truncate flex items-center gap-2">
          <img 
            src={getFileIcon(file.transcript_type)} 
            alt="file icon" 
            className="w-5 h-5 opacity-70"
          />
          <span className={`truncate ${isHovered ? 'text-blue-700' : 'text-gray-800'}`}>
            {file.name || 'Untitled'}
          </span>
        </div>
        
        {/* File type */}
        <div className="leading-[22px] font-medium text-updated-color-grey3">
          {getFileTypeLabel(file.transcript_type)}
        </div>
        
        {/* Creation date */}
        <div className="leading-[22px] font-medium whitespace-nowrap text-updated-color-grey3">
          {file.created_at ? format(parseISO(file.created_at), 'MMM dd, yyyy') : 'N/A'}
        </div>
        
        {/* Actions */}
        <motion.div 
          className="flex justify-end pr-2" 
          onClick={handleActionContainerClick}
          initial={{ opacity: isHovered ? 1 : 0 }}
          animate={{ opacity: isHovered ? 1 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <FileActionButtons
            file={file}
            folders={folders}
            onRename={onRename}
            onDelete={onDelete}
            onMove={onMove}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};