import React from 'react';

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const TimerDisplay = ({ elapsedTime }) => {
  return (
    <div className='flex items-center text-xs w-[95%]'>
      
      <img className='w-full ' alt='' src='/vector-367.svg' />
      <div className='w-fit relative flex items-center px-2 justify-center'>
        {formatTime(elapsedTime)}
      </div>
      <img className='w-full relative' alt='' src='/vector-367.svg' />

    </div>
  );
};

export default TimerDisplay;
