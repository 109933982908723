import React from 'react';

const ResizablePanel = ({ splitPosition, setSplitPosition }) => {
  const handleMouseDown = (e) => {
    const container = e.target.parentElement;
    const containerRect = container.getBoundingClientRect();

    const startDrag = (e) => {
      document.body.classList.add('no-select');
      // Calculate the new position relative to the container
      const offsetX = e.clientX - containerRect.left;
      const containerWidth = containerRect.width;
      const newPosition = (offsetX / containerWidth) * 100;

      // Handle snapping logic
      if (splitPosition < 32 && newPosition > 1) {
        setSplitPosition(50);
        return;
      }
      if (splitPosition > 70 && newPosition < 99) {
        setSplitPosition(50);
        return;
      }

      // Constrain the position between 20% and 80%
      setSplitPosition(Math.min(Math.max(newPosition, 20), 80));
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', startDrag);
      document.removeEventListener('mouseup', stopDrag);
      document.body.classList.remove('no-select');
    };

    document.addEventListener('mousemove', startDrag);
    document.addEventListener('mouseup', stopDrag);
  };

  return (
    <div
    className="w-px cursor-col-resize mt-4 bg-[#E7E7E7] h-[95%] hover:bg-blue-400 active:bg-blue-600 transition-colors relative 
    before:content-[''] before:absolute before:top-1/2 before:-translate-y-1/2 before:left-1/2 before:-translate-x-1/2 
    before:w-4 before:h-16 before:bg-[#E7E7E7] before:rounded-[25px] before:hover:bg-blue-400 before:active:bg-blue-600 
    before:transition-colors before:flex before:items-center before:justify-center
    after:content-[''] after:absolute after:top-1/2 after:-translate-y-1/2 after:left-1/2 after:-translate-x-1/2 
    after:w-2.5 after:h-12 after:flex after:items-center after:justify-center after:pointer-events-none
    after:[background:linear-gradient(to_right,#c5c5c5_3px,transparent_0.5px,transparent_7px,#c5c5c5_7px)] 
    hover:after:[background:linear-gradient(to_right,white_3px,transparent_0.5px,transparent_7px,white_7px)]
    after:bg-repeat-x after:bg-[length:8px_100%]"
    style={{
      position: 'absolute',
      left: splitPosition < 32 ? '0%' : splitPosition > 70 ? '100%' : `${splitPosition}%`,
      zIndex: 20,
    }}
    onMouseDown={handleMouseDown}
  />
  );
};

export default ResizablePanel;
