import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { socket } from '../socketContext';
import RoomLinkAndQRCode from './mobile/RoomLinkAndQRCode';
import { jwtTokenRef, userInfoRef, serverURL } from '../httpContext';

const SelectTypesOfRoomToStart = ({ onOutsideClickFunc }) => {
  const selectRoomRef = useRef(null);

  const [isTemporaryRoomWindowOpen, setIsTemporaryRoomWindowOpen] =
    useState(false);
  const isTemporaryRoomWindowOpenRef = useRef(isTemporaryRoomWindowOpen);

  useEffect(() => {
    isTemporaryRoomWindowOpenRef.current = isTemporaryRoomWindowOpen;
  }, [isTemporaryRoomWindowOpen]);

  const [isCustomRoomWindowOpen, setIsCustomRoomWindowOpen] = useState(false);
  const [isLinkAndQRCodeVisible, setIsLinkAndQRCodeVisible] = useState(false);
  const isCustomRoomRef = useRef(isCustomRoomWindowOpen);
  const roomIdRef = useRef(null);
  const passwordRef = useRef(null);
  const linkAndQRCodeRef = useRef(null);

  useEffect(() => {
    isCustomRoomRef.current = isCustomRoomWindowOpen;
  }, [isCustomRoomWindowOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRoomRef.current &&
        !selectRoomRef.current.contains(event.target) &&
        !isTemporaryRoomWindowOpenRef.current &&
        linkAndQRCodeRef.current &&
        !linkAndQRCodeRef.current.contains(event.target)
      ) {
        onOutsideClickFunc();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onOutsideClickFunc]);

  return (
    <div className='w-full h-full flex items-center justify-center text-left text-lg text-updated-color-new-black font-montserrat relative'>
      {isCustomRoomWindowOpen && (
        <CustomRoom
          onOutsideClickFunc={onOutsideClickFunc}
          isCustomRoomRef={isCustomRoomRef}
          roomIdRef={roomIdRef}
          passwordRef={passwordRef}
          setIsCustomRoomWindowOpen={setIsCustomRoomWindowOpen}
        />
      )}
      {isLinkAndQRCodeVisible && (
        <div
          className='flex justify-center items-center'
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <RoomLinkAndQRCode
            divRef={linkAndQRCodeRef}
            link={`https://interpretapp.ai/broadcast/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`}
            navigateUrl={`/broadcast-host/${roomIdRef.current}`}
            onOutsideClickFunc={() => {
              setIsLinkAndQRCodeVisible(false);
            }}
          />
        </div>
      )}
      {!isTemporaryRoomWindowOpen &&
        !isCustomRoomWindowOpen &&
        !isLinkAndQRCodeVisible && (
          <div
            ref={selectRoomRef}
            className='p-10 cursor-pointer rounded-xl bg-gray-100 flex flex-col items-center justify-center box-border gap-[25px] relative'
          >
            <img
              className='w-6 h-6 absolute top-4 right-4 cursor-pointer'
              alt='close'
              src='/interface--close-circle-1.svg'
              onClick={onOutsideClickFunc}
            />
            <div
              className='flex flex-col pt-0 pb-6 w-full text-center'
              onClick={() => {
                fetch(`${serverURL}/create_room?expiry_days=1`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwtTokenRef.current}`,
                  },
                  body: JSON.stringify({
                    name: 'One-Time Use Room',
                    description: 'General discussion room',
                    languages: null,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.room_id) {
                      roomIdRef.current = data.room_id;
                      passwordRef.current = data.password;
                      setIsLinkAndQRCodeVisible(true);
                    }
                  })
                  .catch((error) => {
                    console.error('Error creating room:', error);
                  });
              }}
            >
              <div className='flex flex-col items-center justify-center gap-[5px]'>
                <div className='m-0 relative text-inherit font-medium font-inherit'>
                  One-Time Use Room
                </div>
                <div className='relative text-xs'>Create a Temporary Room.</div>
              </div>
            </div>
            <div
              className='w-full flex flex-col items-center justify-center pb-6 gap-[5px] text-center'
              onClick={() => {
                setIsCustomRoomWindowOpen(true);
              }}
            >
              <h3 className='m-0 relative text-inherit font-medium font-inherit'>
                Reusable Room
              </h3>
              <div className='self-stretch relative text-xs'>
                Create a Reusable Room for ongoing access. Name your space and
                we’ll provide a link that’s durable for continuous use.
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

const CustomRoom = ({
  onOutsideClickFunc,
  isCustomRoomRef,
  setIsCustomRoomWindowOpen,
  roomIdRef,
  passwordRef,
}) => {
  const [customRoomName, setCustomRoomName] = useState('');
  const inputRef = useRef(null);
  const [isLinkAndQRCodeVisible, setIsLinkAndQRCodeVisible] = useState(false);
  const isLinkAndQRCodeVisibleRef = useRef(isLinkAndQRCodeVisible);
  const customRoomInputWindowRef = useRef(null);
  const linkAndQRCodeRef = useRef(null);

  useEffect(() => {
    isLinkAndQRCodeVisibleRef.current = isLinkAndQRCodeVisible;
  }, [isLinkAndQRCodeVisible]);

  const handleInputChange = (e) => {
    setCustomRoomName(e.target.value);
  };

  const handleInputClick = () => {
    inputRef.current.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isLinkAndQRCodeVisibleRef.current &&
        !linkAndQRCodeRef.current.contains(event.target) &&
        linkAndQRCodeRef.current
      ) {
        setIsLinkAndQRCodeVisible(false);
      }
      if (
        customRoomInputWindowRef.current &&
        !customRoomInputWindowRef.current.contains(event.target) &&
        isCustomRoomRef.current
      ) {
        setIsCustomRoomWindowOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onOutsideClickFunc]);

  return (
    <div className='h-full flex flex-col justify-center items-center z-10'>
      {isLinkAndQRCodeVisible && (
        <RoomLinkAndQRCode
          divRef={linkAndQRCodeRef}
          link={`https://interpretapp.ai/broadcast/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`}
          navigateUrl={`/broadcast-host/${roomIdRef.current}`}
          onOutsideClickFunc={() => {
            setIsLinkAndQRCodeVisible(false);
          }}
        />
      )}
      {!isLinkAndQRCodeVisible && (
        <div
          ref={customRoomInputWindowRef}
          className='relative flex flex-col justify-center items-center bg-gray-100 px-[35px] py-[45px] pb-[20px] rounded-xl z-10'
        >
          <img
            className='w-6 h-6 absolute top-4 right-4'
            loading='lazy'
            alt='close'
            src='/interface--close-circle-1.svg'
            onClick={() => {
              setIsCustomRoomWindowOpen(false);
            }}
          />
          <div className='font-semibold inline-block mb-[20px] text-center'>
            Name your room
          </div>
          <input
            ref={inputRef}
            className='rounded-3xs box-border flex flex-col items-center justify-center p-[19px] min-w-[46px] max-w-full border-[1px] border-solid border-updated-color-new-black text-center'
            type='text'
            inputMode='text'
            value={customRoomName}
            onChange={handleInputChange}
            onClick={handleInputClick}
          />
          <button
            onClick={() => {
              fetch(`${serverURL}/create_room`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${jwtTokenRef.current}`,
                },
                body: JSON.stringify({
                  name: customRoomName,
                  description: 'General discussion room',
                  languages: null,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.room_id) {
                    roomIdRef.current = data.room_id;
                    passwordRef.current = data.password;
                    setIsLinkAndQRCodeVisible(true);
                  }
                })
                .catch((error) => {
                  console.error('Error creating room:', error);
                });
            }}
            className={`mt-4 cursor-pointer [border:none] py-2.5 px-[75.5px] rounded-[10px] ${
              customRoomName.trim() !== ''
                ? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)]'
                : 'bg-updated-color-grey2'
            } flex items-center justify-center whitespace-nowrap`}
          >
            <div className='relative text-base font-medium font-montserrat text-white text-center inline-block min-w-[119px]'>
              Create
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectTypesOfRoomToStart;
