import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtTokenRef, serverURL, translateTexts } from '../../httpContext';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import RecentsSidebar from './RecentsSidebar';
import { top100WorldLanguages } from '../../languages';
import { Switch } from '../../components/Switch';
// import { extractMeetingId } from '../../utils';
import { Editor } from '../../components/Editor';
import { UndoRedo } from '../../components/UndoRedo';
import { HighlightOutlined } from '@ant-design/icons';
import ChatbotWidget from '../../components/chatbot/ChatbotWidget';
import LinkInput from '../../components/web/LinkInput';

const FileTranscript = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fileContent, setFileContent] = useState('');
  const [newFileContent, setNewFileContent] = useState('');
  const [file, setFile] = useState(null);
  const [summary, setSummary] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const showSummaryRef = useRef(null);
  const [outputLanguage, setOutputLanguage] = useState('Translate');
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [isDownloadDropdownOpen, setDownloadDropdownOpen] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState('');
  const downloadDropdownRef = useRef(null);
  const languageDropdownRef = useRef(null);
  const [shouldShowDownloadButton, setShouldShowDownloadButton] =
    useState(false);
  const [includeTimestamps, setIncludeTimestamps] = useState(true);
  const [isRemovingTimestamps, setIsRemovingTimestamps] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [saveText, setSaveText] = useState('Save');
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [copiedText, setCopiedText] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(true);
  const isChatbotOpenRef = useRef(null);
  const chatbotContextRef = useRef(null);

  useEffect(() => {
    chatbotContextRef.current = fileContent;
  }, [fileContent]);

  useEffect(() => {
    if (localStorage.getItem('chatbotClosed') === 'true') {
      setIsChatbotOpen(false);
    }
  }, []);

  const editorRef = useRef(null);

  const handleUndo = () => {
    if (editorRef.current) {
      editorRef.current.undo();
    }
  };

  const handleRedo = () => {
    if (editorRef.current) {
      editorRef.current.redo();
    }
  };

  const handleHighlight = () => {
    if (editorRef.current) {
      editorRef.current.highlight();
    }
  };

  const handleTimestampToggle = () => {
    setIncludeTimestamps(() => !includeTimestamps);

    // if it was true, we are removing timestamps
    if (includeTimestamps) {
      setIsRemovingTimestamps(true);
      axios
        .post(serverURL + '/summarize_transcript_custom_prompt', null, {
          params: {
            transcript_id: file.id,
            custom_prompt:
              'Output transcript split by paragraphs without timestamps and speaker names.',
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setIsRemovingTimestamps(false);
            return response.data;
          }
          throw new Error('Network response was not ok.');
        })
        .then((data) => {
          setFileContent(data.output);
        })
        .catch((error) => {
          console.error('Error generating summary:', error);
        });
    } else {
      setFileContent(file.transcript);
    }
  };

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setIsLanguageDropdownOpen(false);
      }
      if (
        downloadDropdownRef.current &&
        !downloadDropdownRef.current.contains(event.target)
      ) {
        setDownloadDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  let interval;

  useEffect(() => {
    let isMounted = true; // flag to check if the component is mounted

    const fetchData = async () => {
      let retrievedFile;
      let isFileTranscript = false;
      do {
        try {
          const response = await axios.get(`${serverURL}/get_transcript`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtTokenRef.current}`,
            },
            params: {
              transcript_id: new URLSearchParams(location.search).get('id'),
            },
            onDownloadProgress: (progressEvent) => {
              // console.log('Progress event:', progressEvent);
              // const { loaded, total } = progressEvent;
              // if (total) {
              // 	const percentCompleted = Math.round((loaded * 100) / total);
              // 	console.log('Progress:', percentCompleted);
              // 	setFileContent(`Transcribing... ${percentCompleted}%`);
              // }
              if (!isFileTranscript) {
                setFileContent('Transcribing.');
                setTimeout(() => {
                  if (!retrievedFile) {
                    setFileContent('Transcribing..');
                  }
                }, 500);
                setTimeout(() => {
                  if (!retrievedFile) {
                    setFileContent('Transcribing...');
                  }
                }, 1000);
              }
            },
          });

          retrievedFile = response.data;

          console.log('response:', response);

          if (retrievedFile && isMounted) {
            // Only update state if the component is still mounted
            let rawTranscriptJson = JSON.parse(retrievedFile.raw_transcript);

            // setShowSummary(false);
            // setSummary(null);

            if (
              retrievedFile.transcript_type === 'gpu' ||
              retrievedFile.transcript_type === 'zoom' ||
              retrievedFile.transcript_type === 'gemini'
            ) {
              console.log('setting file content', retrievedFile.transcript);
              const raw_transcript = retrievedFile.raw_transcript;

              if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'converting' &&
                JSON.parse(raw_transcript)['youtube_url'] != null
              ) {
                setFileContent(
                  'Transcribing URL: ' +
                    JSON.parse(raw_transcript)['youtube_url'],
                );
              } else {
                setFileContent(retrievedFile.transcript);
              }
              // setEditableContent(retrievedFile.transcript);
            } else if (retrievedFile.transcript_type === 'image') {
              setFileContent(rawTranscriptJson.transcription);
            } else if (retrievedFile.transcript_type === 'file') {
              const raw_transcript = retrievedFile.raw_transcript;
              if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'transcribing'
              ) {
                isFileTranscript = true;
                setFileContent('Translating');
                if (!interval) {
                  interval = setInterval(() => {
                    setFileContent((prev) => {
                      if (prev.endsWith('...')) {
                        return prev.slice(0, -3);
                      } else {
                        return prev + '.';
                      }
                    }); // Add a dot to the end of the text
                  }, 1000);
                }
              } else if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'computing_credits'
              ) {
                isFileTranscript = true;
                setFileContent('Computing required credits...');
                if (!interval) {
                  interval = setInterval(() => {
                    setFileContent((prev) => {
                      if (prev.endsWith('...')) {
                        return prev.slice(0, -3);
                      } else {
                        return prev + '.';
                      }
                    });
                  }, 1000);
                }
              } else if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'translating'
              ) {
                isFileTranscript = true;
                const parsedTranscript = JSON.parse(raw_transcript);
                const progress = parsedTranscript['progress'] ? ` (${parsedTranscript['progress']}%)` : '';
                setFileContent(`Translating document${progress}...`);
                if (!interval) {
                  interval = setInterval(() => {
                    setFileContent((prev) => {
                      if (prev.endsWith('...')) {
                        return prev.slice(0, -3);
                      } else {
                        return prev + '.';
                      }
                    });
                  }, 1000);
                }
              } else if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'insufficient_credits'
              ) {
                if (interval) clearInterval(interval);
                const error = JSON.parse(raw_transcript)['error'] || 'Insufficient credits to process this document.';
                setFileContent(`Error: ${error}`);
              } else if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'failed'
              ) {
                if (interval) clearInterval(interval);
                const error = JSON.parse(raw_transcript)['error'] || 'Translation failed.';
                setFileContent(`Error: ${error}`);
              } else if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'transcribed'
              ) {
                if (interval) clearInterval(interval);
                if (
                  retrievedFile.transcript != null &&
                  retrievedFile.transcript != ''
                ) {
                  setFileContent(retrievedFile.transcript);
                } else {
                  setFileContent('Transcription is ready to download.');
                }
              } else if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'queued'
              ) {
                isFileTranscript = true;
                setFileContent('Translation queued, waiting to process...');
                if (!interval) {
                  interval = setInterval(() => {
                    setFileContent((prev) => {
                      if (prev.endsWith('...')) {
                        return prev.slice(0, -3);
                      } else {
                        return prev + '.';
                      }
                    });
                  }, 1000);
                }
              }
            }
            console.log('checking download button');

            setFile(retrievedFile);

            if (
              (retrievedFile != null &&
                retrievedFile.transcript_type == 'gpu' &&
                (!retrievedFile.raw_transcript ||
                  JSON.parse(retrievedFile.raw_transcript)['status'] !==
                    'converting')) ||
              (retrievedFile.transcript_type === 'file' &&
                retrievedFile.raw_transcript != null &&
                JSON.parse(retrievedFile.raw_transcript)['status'] !==
                  'transcribing' &&
                JSON.parse(retrievedFile.raw_transcript)['status'] !==
                  'computing_credits' &&
                JSON.parse(retrievedFile.raw_transcript)['status'] !==
                  'translating' &&
                JSON.parse(retrievedFile.raw_transcript)['status'] !==
                  'queued' &&
                JSON.parse(retrievedFile.raw_transcript)['status'] !==
                  'converting')
            ) {
              setShouldShowDownloadButton(true);
            } else {
              setShouldShowDownloadButton(false);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.log('File not found, retrying...');
            // You can also add additional logic here if needed, such as a counter for retries or a timeout
          } else {
            console.error('An error occurred:', error);
            // Handle other types of errors (e.g., network issues)
          }
        }

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      } while (
        isMounted &&
        (!retrievedFile ||
          (!retrievedFile.transcript &&
            retrievedFile.transcript_type != 'file') ||
          (retrievedFile &&
            JSON.parse(retrievedFile.raw_transcript).status ===
              'transcribing') ||
          (retrievedFile &&
            JSON.parse(retrievedFile.raw_transcript).status ===
              'computing_credits') ||
          (retrievedFile &&
            JSON.parse(retrievedFile.raw_transcript).status ===
              'translating') ||
          (retrievedFile &&
            JSON.parse(retrievedFile.raw_transcript).status ===
              'queued') ||
          JSON.parse(retrievedFile.raw_transcript).status === 'converting')
      );
    };

    fetchData();

    return () => {
      isMounted = false; // Set the flag to false to exit the loop
    };
  }, [
    location.state?.file,
    location.search,
    new URLSearchParams(location.search).get('id'),
  ]);

  const updateTranscript = async (newTranscript) => {
    const payload = {
      transcript_id: file.id,
      transcript: newTranscript,
    };

    try {
      const response = await fetch(serverURL + '/update_transcript', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Transcript updated:', data);
        setSaveText('Saved');
        setTimeout(() => {
          setSaveText('Save');
        }, 2000);
      } else {
        console.error('Error updating transcript:', response);
      }
    } catch (error) {
      console.error('Error updating transcript:', error);
    }
  };

  const useDebouncedCallback = (callback, delay) => {
    const timeoutRef = useRef(null);

    const debouncedCallback = useCallback(
      (...args) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback(...args);
        }, delay);
      },
      [callback, delay],
    );

    return debouncedCallback;
  };

  const debouncedUpdateTranscript = useDebouncedCallback((newText) => {
    const formattedText = newText
      .replace(/<br>/g, '\n')
      .replace(/<\/div><div>/g, '\n')
      .replace(/<\/?div>/g, ''); // This removes any remaining <div> tags
    updateTranscript(formattedText);
  }, 1000);

  // const getSummaryFromFile = (file) => {
  //   let raw_transcript = JSON.parse(file.raw_transcript);
  //   const description = raw_transcript.description;
  //   if (description) {
  //     return description;
  //   }
  //   const summaries = raw_transcript.summaries;
  //   if (summaries) {
  //     const firstSummary = Object.values(summaries)[0];
  //     if (firstSummary && firstSummary.summary) {
  //       if (firstSummary.summary.summary) {
  //         return firstSummary.summary.summary;
  //       }
  //       return firstSummary.summary;
  //     }
  //   }
  //   return '';
  // };

  // const handleSummarize = async () => {
  //   if (showSummaryRef.current) {
  //     setShowSummary(false);
  //     return;
  //   }
  //   if (getSummaryFromFile(file) !== '') {
  //     console.log('Summary already exists', getSummaryFromFile(file));
  //     setSummary(getSummaryFromFile(file));
  //   } else {
  //     setSummary('Summarizing...');
  //     try {
  //       const response = await axios.post(
  //         `${serverURL}/summarize_transcript?transcript_id=${file.id}&target_language=en`,
  //         {},
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: `Bearer ${jwtTokenRef.current}`,
  //           },
  //         },
  //       );
  //       if (response.status !== 200) {
  //         throw new Error('Network response was not ok.');
  //       }
  //     } catch (error) {
  //       console.error('Error summarizing:', error);
  //       return;
  //     }

  //     const getSummary = async (retryCount = 0, maxRetries = 200) => {
  //       try {
  //         const response = await axios.get(`${serverURL}/get_raw_transcript`, {
  //           params: { transcript_id: file.id },
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: `Bearer ${jwtTokenRef.current}`,
  //           },
  //         });
  //         if (response.status !== 200) {
  //           throw new Error('Network response was not ok.');
  //         }

  //         const data = response.data;
  //         if (
  //           data.summaries &&
  //           data.summaries.en &&
  //           data.summaries.en.summary &&
  //           data.summaries.en.summary.summary
  //         ) {
  //           setSummary(data.summaries.en.summary.summary);
  //         } else if (retryCount < maxRetries) {
  //           setTimeout(() => getSummary(retryCount + 1, maxRetries), 2000);
  //         } else {
  //           console.error('Maximum retries reached, no summary available.');
  //         }
  //       } catch (error) {
  //         console.error('Error generating summary:', error);
  //       }
  //     };
  //     getSummary();
  //   }
  //   setShowSummary(true);
  // };

  useEffect(() => {
    showSummaryRef.current = showSummary;
  }, [showSummary]);

  const translateFileContent = async (targetLanguage, text, setFunc) => {
    setIsTranslating(true);
    console.log('Translating to:', targetLanguage);
    console.log('Text:', text);

    // Split the text at \n\n to get the paragraphs
    const paragraphs = text.split('\n\n');

    // filter out empty paragraphs
    const nonEmptyParagraphs = paragraphs.filter(
      (paragraph) => paragraph.trim() !== '',
    );

    // Concatenate paragraphs with \n\n while the total length is less than 1000 characters
    const newParagraphs = [];
    let currentParagraph = '';
    for (let i = 0; i < nonEmptyParagraphs.length; i++) {
      const paragraph = nonEmptyParagraphs[i];
      if (currentParagraph.length + paragraph.length < 1000) {
        currentParagraph += paragraph + '\n\n';
      } else {
        newParagraphs.push(currentParagraph.trim());
        currentParagraph = paragraph + '\n\n';
      }
    }
    if (currentParagraph) {
      newParagraphs.push(currentParagraph.trim());
    }

    const translatedTexts = await translateTexts(newParagraphs, targetLanguage);

    setFunc(translatedTexts.join('\n\n'));
    setIsTranslating(false);
  };

  return (
    <div className='max-w-[1512px] w-full mx-auto relative text-left text-xl text-updated-color-new-black font-montserrat'>
      <div className='flex justify-center min-h-screen gap-10 py-10 pt-16 px-10 h-full items-center'>
        <div className='flex flex-col gap-4 w-full self-start'>
          <img
            className='w-fit cursor-pointer'
            alt=''
            src='/group-1707478204.svg'
            onClick={() => {
              navigate('/docs');
            }}
          />
          <div className='[filter:drop-shadow(1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset)] rounded-xl border-[0.5px] border-solid border-updated-color-grey1'>
            <div className='flex justify-between items-end'>
              <div className='flex p-4 justify-between w-full'>
                <div className='flex flex-col pt-0 gap-[5px] text-base font-montserrat'>
                  <div className='relative font-semibold'>{file?.name}</div>
                  {file?.created_at && (
                    <div className='relative text-sm'>
                      Created{' '}
                      {format(parseISO(file.created_at), 'MMM dd, yyyy')}
                    </div>
                  )}
                </div>
                <div className='flex items-center gap-2'>
                  {isTranslating && (
                    <div className='w-8 h-8 flex items-center justify-center'>
                      <svg
                        className='animate-spin h-7 w-7 text-updated-color-blue'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-75'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                        ></circle>
                        <path
                          className=''
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        ></path>
                      </svg>
                    </div>
                  )}
                  {file != null && file.transcript_type !== 'file' && (
                    <div
                      className='relative rounded-md w-full flex items-center justify-center p-2.5 border border-gray-300 cursor-pointer'
                      onClick={() =>
                        setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
                      }
                    >
                      <div className='relative font-medium'>
                        {outputLanguage}
                      </div>
                      <img
                        className='w-[18px] relative h-[18px] overflow-hidden shrink-0 ml-2'
                        alt=''
                        src='/arrow--arrow-down-2.svg'
                      />
                      {isLanguageDropdownOpen && (
                        <div
                          ref={languageDropdownRef}
                          className='absolute top-12 right-0 w-[32rem] px-10 grid grid-cols-4 gap-6 z-10 bg-white border border-gray-300 rounded-md shadow-lg max-h-96 overflow-y-auto'
                        >
                          <div className='flex col-span-4 items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                            <input
                              type='text'
                              placeholder='Search...'
                              className='w-full p-1 border-none outline-none text-lg'
                              value={searchBarInput}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onChange={(e) => {
                                setSearchBarInput(e.target.value);
                              }}
                            />
                            <img
                              className='w-5 h-5 ml-2'
                              alt=''
                              src='/general--search.svg'
                            />
                          </div>
                          <div
                            key={'Original'}
                            className='p-2 hover:bg-gray-200 cursor-pointer'
                            onClick={() => {
                              setOutputLanguage('Original');
                              setIsLanguageDropdownOpen(false);
                              setFileContent(file.transcript);
                            }}
                          >
                            {'Original'}
                          </div>
                          {top100WorldLanguages
                            .filter((language) =>
                              language
                                .toLowerCase()
                                .includes(searchBarInput?.toLowerCase() || ''),
                            )
                            .map((language) => (
                              <div
                                key={language}
                                className='p-2 hover:bg-gray-200 cursor-pointer w-full'
                                onClick={() => {
                                  setOutputLanguage(language);
                                  setIsLanguageDropdownOpen(false);
                                  translateFileContent(
                                    language,
                                    file.transcript,
                                    setFileContent,
                                  );
                                  console.log('summary', summary);
                                  if (summary != '' && summary != null) {
                                    translateFileContent(
                                      language,
                                      summary,
                                      setSummary,
                                    );
                                  }
                                }}
                              >
                                {language}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}
                  {shouldShowDownloadButton && (
                    <div
                      ref={downloadDropdownRef}
                      className='relative rounded-8xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 text-white cursor-pointer z-20'
                      onClick={() => {
                        setDownloadDropdownOpen(!isDownloadDropdownOpen);
                      }}
                    >
                      <div className='relative leading-[112.4%] font-medium'>
                        Download
                      </div>
                      <img
                        className='w-[18px] relative h-[18px] overflow-hidden shrink-0'
                        alt=''
                        src='/arrow--arrow-down-2.svg'
                      />
                      {isDownloadDropdownOpen && (
                        <div className='absolute z-10 text-not-black top-12 w-fit flex flex-col gap-2 rounded-8xs bg-white shadow-lg p-2'>
                          {file != null && file.transcript_type !== 'file' && (
                            <div
                              className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                              onClick={() => {
                                const element = document.createElement('a');
                                const file = new Blob([fileContent], {
                                  type: 'text/plain',
                                });
                                element.href = URL.createObjectURL(file);
                                element.download = 'transcript.txt';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                              }}
                            >
                              <div className='relative leading-[112.4%] font-medium'>
                                Download as TXT
                              </div>
                            </div>
                          )}
                          {file != null && file.transcript_type == 'file' && (
                            <div
                              className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                              onClick={async () => {
                                try {
                                  const response = await axios.get(
                                    `${serverURL}/download_file?transcript_id=${file.id}`,
                                    {
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${jwtTokenRef.current}`,
                                      },
                                      responseType: 'blob', // Ensure the response is a blob (file)
                                    },
                                  );

                                  var raw_transcript = JSON.parse(
                                    file.raw_transcript,
                                  );
                                  const name =
                                    raw_transcript['original_file_name'];

                                  if (response.status === 200) {
                                    const blob = new Blob([response.data]);
                                    const element = document.createElement('a');
                                    element.href = URL.createObjectURL(blob);
                                    element.download = name; // Use a generic name for the downloaded file
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                  } else {
                                    console.error('Failed to download file.');
                                  }
                                } catch (error) {
                                  console.error(
                                    'Error occurred while downloading PDF file:',
                                    error,
                                  );
                                }
                              }}
                            >
                              <div className='relative leading-[112.4%] font-medium'>
                                Download Translation
                              </div>
                            </div>
                          )}
                          {file.transcript_type == 'gpu' && (
                            <div
                              className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                              onClick={async () => {
                                try {
                                  var params = {
                                    transcript_id: file.id,
                                    language: outputLanguage,
                                  };

                                  if (
                                    outputLanguage === 'Original' ||
                                    outputLanguage === 'Translate'
                                  ) {
                                    params = { transcript_id: file.id };
                                  }
                                  const response = await axios.get(
                                    `${serverURL}/get_srt_from_transcript`,
                                    {
                                      params: params,
                                      responseType: 'blob', // Ensure the response is a blob (file)
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${jwtTokenRef.current}`,
                                      },
                                    },
                                  );

                                  if (response.status === 200) {
                                    const file = new Blob([response.data], {
                                      type: 'application/x-subrip',
                                    });
                                    const element = document.createElement('a');
                                    element.href = URL.createObjectURL(file);
                                    element.download = 'transcript.srt';
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                  } else {
                                    console.error(
                                      'Failed to download SRT file.',
                                    );
                                  }
                                } catch (error) {
                                  console.error(
                                    'Error occurred while downloading SRT file:',
                                    error,
                                  );
                                }
                              }}
                            >
                              <div className='relative leading-[112.4%] font-medium'>
                                Download as SRT
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className='border-solid border-t-[0.5px] border-x-0 border-b-0 border-updated-color-grey1 mx-auto w-[99%]' />
            <div className='p-10 pt-2 flex flex-col text-base font-montserrat gap-8'>
              <div
                className={`flex items-center gap-4 w-full ${showSummary ? 'justify-end' : 'justify-between'}`}
              >
                {file?.transcript_type === 'gpu' && !showSummary && (
                  <div className='flex w-fit items-center gap-4'>
                    <UndoRedo
                      isUndo={true}
                      isEnabled={canUndo}
                      undoHandler={handleUndo}
                      width={22}
                      height={22}
                    />
                    <UndoRedo
                      isUndo={false}
                      isEnabled={canRedo}
                      redoHandler={handleRedo}
                      width={22}
                      height={22}
                    />
                    <button
                      className='bg-black/60 text-white p-3 py-2 flex items-center justify-center rounded-full font-montserrat'
                      onClick={() => {
                        navigator.clipboard.writeText(fileContent);
                        setCopiedText(true);
                        setTimeout(() => setCopiedText(false), 2000);
                      }}
                    >
                      <div className='flex justify-center items-center gap-2'>
                        <img
                          className='w-6 h-6 overflow-hidden'
                          alt='Copy button'
                          src='/whitecopy.svg'
                        />
                        <div className='text-white'>
                          {copiedText ? 'Copied!' : 'Copy All'}
                        </div>
                      </div>
                    </button>
                    <HighlightOutlined
                      className='text-3xl mt-2'
                      onClick={handleHighlight}
                    />
                    <button
                      onClick={() => {
                        setSaveText('Saving...');
                        debouncedUpdateTranscript(newFileContent);
                      }}
                      className='font-montserrat font-bold text-base text-white bg-updated-color-blue rounded-full py-2 px-6 flex items-center gap-2 transition duration-300 ease-in-out transform hover:scale-105'
                    >
                      {saveText}
                    </button>
                  </div>
                )}
                <div className='w-fit justify-self-end flex items-center gap-4'>
                  {file?.transcript_type === 'gpu' &&
                    !showSummary &&
                    isRemovingTimestamps && (
                      <div className='w-8 h-8 flex items-center justify-center'>
                        <svg
                          className='animate-spin h-7 w-7 text-updated-color-blue'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-75'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className=''
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </div>
                    )}
                  {file?.transcript_type === 'gpu' && !showSummary && (
                    <Switch
                      isOn={includeTimestamps}
                      handleToggle={handleTimestampToggle}
                      onColor='bg-dodgerblue-200'
                      text='Include Timestamps & Speaker'
                      loading={isRemovingTimestamps}
                    />
                  )}
                  {/* {file != null && file.transcript_type != 'file' && (
                    <div className='w-fit relative h-[23.9px] flex gap-2 items-center justify-self-end'>
                      <div
                        className={`h-full ${
                          showSummary
                            ? '[background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]'
                            : 'bg-white shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)]'
                        } rounded-8xl flex flex-row items-start justify-start py-[1.3px] px-[13.3px] box-border cursor-pointer`}
                        onClick={handleSummarize}
                      >
                        <img
                          className='w-[17.5px] relative h-[21.2px]'
                          alt=''
                          src={
                            showSummary ? '/ai-summary1.svg' : '/ai-summary.svg'
                          }
                        />
                      </div>
                      <div className=' font-medium text-transparent !bg-clip-text [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] font-montserrat'>
                        AI Summary
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              <div className='max-h-[40rem] min-h-[40rem] overflow-y-auto'>
                {['gpu', 'gemini'].includes(file?.transcript_type) &&
                !showSummary ? (
                  <Editor
                    ref={editorRef}
                    content={fileContent}
                    handleChange={(e) => setNewFileContent(e)}
                    setCanUndo={setCanUndo}
                    setCanRedo={setCanRedo}
                    includeTimestamps={includeTimestamps}
                    isRemovingTimestamps={isRemovingTimestamps}
                  />
                ) : (
                  <ReactMarkdown className='w-11/12 overflow-y-auto max-h-[90%] mx-auto'>
                    {showSummary ? summary : fileContent}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col min-w-80 max-w-80 self-start gap-4'>
          <div className='flex flex-col justify-center relative w-full gap-1'>
            <img
              src='/link-icon.png'
              alt=''
              className='w-6 h-6 absolute top-2 left-4'
            />
            <LinkInput></LinkInput>
          </div>
          <RecentsSidebar />
        </div>
      </div>
      {isChatbotOpen && (
        <ChatbotWidget
          isChatbotOpenRef={isChatbotOpenRef}
          onClose={() => setIsChatbotOpen(false)}
          contextRef={chatbotContextRef}
        />
      )}
      {!isChatbotOpen && (
        <img
          className='cursor-pointer fixed bottom-5 right-5'
          src='chai2.svg'
          alt='Chatbot'
          onClick={() => setIsChatbotOpen(true)}
        />
      )}
    </div>
  );
};

export default FileTranscript;
