import { translateTexts } from '../httpContext';
import { updateMessagesMerged } from './updateMessages';

export const handleInputLanguageClick = (
  language,
  inputLanguage,
  setInputLanguageDisplay,
  setLanguageDropdownVisible,
  setIsLoadingTranslation,
  messages,
  messagesRef,
  setMessages,
  setMessagesMerged,
) => {
  if (language !== inputLanguage.current && language !== 'Detect Language') {
    if (messagesRef.current.length) setIsLoadingTranslation(true);

    const textsToTranslate = [];
    const messagesToTranslateIndices = [];

    // Collect texts to translate and their indices
    messagesRef.current.forEach((message, index) => {
      if (message['corrected_transcripts'][language] === undefined) {
        textsToTranslate.push(message.transcript);
        messagesToTranslateIndices.push(index);
      }
    });

    // Proceed only if there are texts to translate
    if (textsToTranslate.length > 0) {
      translateTexts(textsToTranslate, language)
        .then((translations) => {
          const updatedMessages = [...messages]; // Create a shallow copy of messages

          // Assign translations to the correct messages
          messagesToTranslateIndices.forEach(
            (messageIndex, translationIndex) => {
              const translatedText = translations[translationIndex];
              // Ensure 'corrected_transcripts' exists
              if (!updatedMessages[messageIndex]['corrected_transcripts']) {
                updatedMessages[messageIndex]['corrected_transcripts'] = {};
              }
              updatedMessages[messageIndex]['corrected_transcripts'][language] =
                translatedText.trim();
            },
          );
          setMessages(updatedMessages);
          updateMessagesMerged(
            updatedMessages,
            setMessages,
            messagesRef,
            setMessagesMerged,
          );
        })
        .catch((error) => {
          console.error('Translation failed:', error);
          // Handle translation errors if necessary
        });
    } else {
      // If all messages are already translated, you might want to update the display immediately
      updateMessagesMerged(
        messages,
        setMessages,
        messagesRef,
        setMessagesMerged,
      );
    }
  }

  // Update the language state regardless of whether translation was needed
  inputLanguage.current = language;
  setInputLanguageDisplay(language);
  setLanguageDropdownVisible(false);
  setTimeout(() => {
    setIsLoadingTranslation(false);
  }, 1000);
};

export const handleOutputLanguageClick = (
  language,
  outputLanguage,
  setOutputLanguageDisplay,
  setLanguageTwoDropdownVisible,
  setIsLoadingOutputTranslation,
  messages,
  messagesRef,
  setMessages,
  setMessagesMerged,
) => {
  setLanguageTwoDropdownVisible(false);
  if (language != outputLanguage.current) {
    if (messagesRef.current.length) setIsLoadingOutputTranslation(true);

    let textsToTranslate = [];
    let messagesAlreadyTranslated = [];
    for (let i = 0; i < messagesRef.current.length; i++) {
      let message = messagesRef.current[i];
      console.log('message: ' + message);
      if (message['corrected_transcripts'][language] != undefined) {
        messagesAlreadyTranslated.push(i);
        continue;
      }
      textsToTranslate.push(message.transcript);
    }
    console.log('texts to translate: ' + textsToTranslate);
    translateTexts(textsToTranslate, language).then((translations) => {
      console.log('translations: ' + translations);
      let updatedMessages = [];
      for (let i = 0; i < messages.length; i++) {
        let message = messages[i];
        if (messagesAlreadyTranslated.includes(i)) {
          updatedMessages.push(message);
          continue;
        } else {
          if (message['corrected_transcripts'][language] == undefined) {
            message['corrected_transcripts'][language] = translations[i] + ' ';
          }

          updatedMessages.push(message);
        }
      }
      setMessages(updatedMessages);
      updateMessagesMerged(updatedMessages, setMessages, messagesRef, setMessagesMerged);
      setIsLoadingOutputTranslation(false);
      // localStorage.setItem('messages', JSON.stringify(messagesMerged));
    });
  }
  outputLanguage.current = language;
  setOutputLanguageDisplay(language);
};
