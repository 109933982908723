// src/components/files/FilesSortControls.jsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';

export const FilesSortControls = ({ onSortByNewest, onSortByOldest }) => {
  const [activeSort, setActiveSort] = useState('latest');
  
  const handleSortByNewest = () => {
    setActiveSort('latest');
    onSortByNewest();
  };
  
  const handleSortByOldest = () => {
    setActiveSort('earliest');
    onSortByOldest();
  };

  return (
    <div className="flex gap-3 text-sm">
      <motion.div
        className={`rounded-full relative overflow-hidden flex items-center justify-center py-1.5 px-4 cursor-pointer transition-all duration-300
          ${activeSort === 'latest' ? 'bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-medium shadow-md' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
        onClick={handleSortByNewest}
        whileHover={activeSort !== 'latest' ? { scale: 1.05 } : {}}
        whileTap={{ scale: 0.95 }}
      >
        <motion.div 
          className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-0"
          initial={false}
          animate={{ opacity: activeSort === 'latest' ? 1 : 0 }}
          transition={{ duration: 0.3 }}
        />
        
        <div className="relative flex items-center gap-1.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
          </svg>
          <span>Latest</span>
        </div>
      </motion.div>
      
      <motion.div
        className={`rounded-full relative overflow-hidden flex items-center justify-center py-1.5 px-4 cursor-pointer transition-all duration-300
          ${activeSort === 'earliest' ? 'bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-medium shadow-md' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
        onClick={handleSortByOldest}
        whileHover={activeSort !== 'earliest' ? { scale: 1.05 } : {}}
        whileTap={{ scale: 0.95 }}
      >
        <motion.div 
          className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-0"
          initial={false}
          animate={{ opacity: activeSort === 'earliest' ? 1 : 0 }}
          transition={{ duration: 0.3 }}
        />
        
        <div className="relative flex items-center gap-1.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
          <span>Earliest</span>
        </div>
      </motion.div>
    </div>
  );
};
