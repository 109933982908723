import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL } from '../../httpContext';

const LinkInput = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const addBotToMeeting = async (meetingUrl) => {
    if (!jwtTokenRef.current) return;

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${serverURL}/add_bot_to_zoom`,
        { meeting_url: meetingUrl },
        { headers: { Authorization: `Bearer ${jwtTokenRef.current}` } },
      );
      if (response.status === 200) {
        const { id, password } = response.data;
        navigate(`/broadcast/${id}?password=${password}`);
      }
    } catch (error) {
      console.error('Error adding bot to meeting:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key !== 'Enter') return;
    const url = e.target.value.trim();
    if (!url) return;

    setIsLoading(true);
    try {
      // check if zoom, teams, google meet, or slack huddle
      if (
        url.includes('zoom') ||
        url.includes('teams.microsoft.com') ||
        url.includes('meet.google.com') ||
        url.includes('slack.com')
      ) {
        await addBotToMeeting(url);
      } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
        const response = await axios.post(
          `${serverURL}/transcribe_youtube?youtube_url=${encodeURIComponent(url)}`,
          {},
          { headers: { Authorization: `Bearer ${jwtTokenRef.current}` } },
        );
        const { transcript_id } = response.data;
        navigate(`/file_transcript?id=${transcript_id}`);
      } else {
        console.error('Unsupported URL:', url);
      }
    } catch (error) {
      console.error('Error processing URL:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col justify-center relative w-full gap-1 mb-4'>
      <img
        src='/link-icon.png'
        alt=''
        className='w-6 h-6 absolute top-2 left-4'
      />
      <input
        placeholder='Paste Meeting or Youtube Link'
        className='text-base pl-12 py-2.5 border-solid rounded-full border border-updated-color-grey1 focus:outline-none bg-transparent shadow'
        onKeyDown={handleKeyDown}
        disabled={isLoading}
      />
      <div className='flex items-center text-[#c5c5c5] self-end'>
        <div className='pr-1 font-medium text-base'>Supports</div>
        <img src='/youtube.png' alt='' className='w-6 h-6' />
        <img
          src='/platforms.png'
          alt='Meeting platform logos'
          className='w-20'
        />
      </div>
    </div>
  );
};

export default LinkInput;
