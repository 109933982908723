import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const Markdown = ({ outlineContent, hiddenSections }) => {
  const [newOutlineContent, setNewOutlineContent] =
    React.useState(outlineContent);

  useEffect(() => {
    setNewOutlineContent(outlineContent);
  }, [outlineContent]);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ node, ...props }) => (
          <div className='flex items-center gap-1 my-4'>
            <img src='/summary.svg' alt='Heading Icon' className='w-5 h-6' />
            <h1
              className='text-[18px] font-semibold  text-slate-900'
              {...props}
            />
          </div>
        ),
        h2: ({ node, ...props }) => {
          return (
            <div className='flex justify-between items-center'>
              <h2
                className='text-[16px] font-medium my-1  text-slate-900'
                {...props}
              />
            </div>
          );
        },
        h3: ({ node, ...props }) => (
          <h3
            className='text-[14px] font-medium my-2  text-slate-900'
            {...props}
          />
        ),
        strong: ({ node, ...props }) => (
          <strong className='font-bold  text-black' {...props} />
        ),
        a: ({ node, ...props }) => (
          <a
            className='text-blue-500 underline hover:text-blue-700'
            target='_blank'
            rel='noopener noreferrer'
            {...props}
          />
        ),

        em: ({ node, ...props }) => <em className='italic ' {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          const hasMermaid = match && match[1] === 'mermaid';

          if (hasMermaid) {
            return (
              <div className='mermaid my-4'>
                {String(children).replace(/\n$/, '')}
              </div>
            );
          }
          return (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        table: ({ node, ...props }) => (
          <div className='overflow-x-auto my-4'>
            <table
              className='min-w-full border-collapse border-4 border-slate-400'
              {...props}
            />
          </div>
        ),
        th: ({ node, ...props }) => (
          <th
            className='border-4 border-slate-400 p-4  bg-slate-100 font-bold text-left'
            {...props}
          />
        ),
        td: ({ node, ...props }) => (
          <td className='border-2 border-slate-300  p-4' {...props} />
        ),

        hr: ({ node, ...props }) => (
          <hr className='my-4 border-t-2' {...props} />
        ),
        p: ({ node, children, ...props }) => {
          // Find the nearest h2 parent
          let currentNode = node;
          while (currentNode && currentNode.type !== 'heading') {
            currentNode = currentNode.parent;
          }
          const sectionTitle = currentNode?.children?.[0]?.value;

          if (hiddenSections.has(sectionTitle)) {
            return null;
          }

          // Existing highlighting logic
          const content = React.Children.map(children, (child) => {
            if (typeof child === 'string') {
              return child.split(/==(.+?)==/).map((part, i) =>
                i % 2 === 0 ? (
                  part
                ) : (
                  <mark className='bg-yellow-200 px-1 rounded' key={i}>
                    {part}
                  </mark>
                ),
              );
            }
            return child;
          });
          return <p {...props}>{content}</p>;
        },
      }}
    >
      {newOutlineContent}
    </ReactMarkdown>
  );
};
