import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import PortalPopup from './PortalPopup';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { isToday, isYesterday, subDays, parseISO } from 'date-fns';
import { FileActionButtons } from '../files/FileActionButtons';

const PopupTemplate = ({ className = '', imageSrc, title, description }) => {
  return (
    <div
      className={`w-full relative bg-updated-color-mobile-overlay-new-background h-full overflow-hidden max-w-full max-h-full text-left text-lg text-updated-color-new-black font-montserrat ${className}`}
    >
      <div className='absolute top-[calc(50%_-_68.5px)] left-[calc(50%_-_99.5px)] rounded-3xl bg-whitesmoke flex flex-col items-center justify-center pt-[35px] px-[60px] pb-5 gap-[10px]'>
        <img className='w-[45px] relative h-[50px]' alt='' src={imageSrc} />
        <div className='flex flex-col items-start justify-start'>
          <div className='flex flex-col items-center justify-start'>
            <div className='relative font-semibold'>{title}</div>
            {description && (
              <div className='relative text-sm text-center'>{description}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteFromHistory = ({ className = '', onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const timeoutRef = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      timeoutRef.current = setTimeout(onClose, 0);
    }
  }, [isVisible, onClose]);

  return (
    <PopupTemplate
      className={`${className} ${isVisible ? 'fade-out' : 'fade-out hidden'}`}
      imageSrc='/delete.svg'
      title='Deleted!'
    />
  );
};

const SaveFromHistory = ({ className = '' }) => (
  <PopupTemplate
    className={className}
    imageSrc='/interface--check-circle-1.svg'
    title='Saved!'
    description={
      <>
        <span>You can view it from </span>
        <i>Doc - Recently Saved</i>
      </>
    }
  />
);

const RenamePopup = ({ className = '', onClose, fileName, onRename }) => {
  const [inputValue, setInputValue] = useState(fileName);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleRename = () => {
    onRename(inputValue);
  };

  return (
    <div
      className={`w-full relative bg-updated-color-mobile-overlay-new-background h-full overflow-hidden max-w-full max-h-full text-left text-lg text-updated-color-new-black font-montserrat ${className}`}
    >
      <div className='absolute top-[calc(50%_-_109.5px)] left-[calc(50%_-_170.5px)] rounded-xl bg-whitesmoke w-[340px] flex flex-col items-start justify-start pt-[45px] px-[35px] pb-5 box-border gap-[35px]'>
        <div className='flex flex-col items-start justify-start gap-[20px]'>
          <div className='flex flex-col items-start justify-start'>
            <div className='relative font-semibold'>Rename</div>
          </div>
          <div className='w-[270px] flex flex-row items-center justify-start gap-[10px] text-center text-sm'>
            <input
              className='w-[270px] flex flex-row items-center justify-start py-2.5 px-0 relative gap-[10px] border-b-[0.5px] border-x-0 border-t-0 border-solid border-updated-color-grey2 bg-transparent outline-none'
              type='text'
              value={inputValue}
              onChange={handleInputChange}
              ref={inputRef}
            />
          </div>
        </div>
        <div
          className={`w-[270px] rounded-3xs ${
            inputValue
              ? 'bg-[linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)]'
              : 'bg-updated-color-grey2'
          } flex flex-row items-center justify-center py-2.5 px-[104px] box-border text-base text-white cursor-pointer`}
          onClick={handleRename}
        >
          <div className='relative font-medium'>OK</div>
        </div>
        <img
          className='w-6 absolute top-[10px] left-[305.5px] h-6 overflow-hidden cursor-pointer'
          alt=''
          src='/interface--close-circle-11.svg'
          onClick={onClose}
        />
      </div>
    </div>
  );
};

const groupTranscriptsByDate = (transcripts) => {
  const grouped = {
    today: [],
    yesterday: [],
    lastWeek: [],
    previous30Days: [],
  };

  transcripts.forEach((transcript) => {
    const transcriptDate = parseISO(transcript.created_at);
    if (isToday(transcriptDate)) {
      grouped.today.push(transcript);
    } else if (isYesterday(transcriptDate)) {
      grouped.yesterday.push(transcript);
    } else if (transcriptDate > subDays(new Date(), 7)) {
      grouped.lastWeek.push(transcript);
    } else {
      grouped.previous30Days.push(transcript);
    }
  });

  return grouped;
};

const RecentsSidebar = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [recentFiles, setRecentFiles] = useState([]);
  const [isRenameOpen, setRenameOpen] = useState(false);
  const [isSaveOpen, setSaveOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const windowRef = useRef(null);
  const scrollEndRef = useRef(null);
  const observer = useRef(null);
  const pageRef = useRef(2);
  const isFetchingRef = useRef(false);
  const searchTimeoutRef = useRef(null);

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        windowRef.current &&
        !windowRef.current.contains(event.target) &&
        !isRenameOpen
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose, isRenameOpen]);

  const fetchFiles = async (query = '', page = 1) => {
    try {
      await jwtTokenRef.current;
      const response = await axios.get(`${serverURL}/list_transcripts`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
        params: {
          folder_id: null,
          page_size: 10,
          page_number: page,
          get_raw_transcript: false,
          text_search: query,
        },
      });

      const data = response.data.transcripts;
      const retrievedFiles = data.filter((file) => file.transcript !== null);
      if (page === 1) {
        setRecentFiles(retrievedFiles);
      } else {
        setRecentFiles((prevFiles) => [...prevFiles, ...retrievedFiles]);
      }
      console.log("retrievedFiles", retrievedFiles)
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    const options = {
      root: windowRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isFetchingRef.current) {
        loadMoreFiles();
      }
    }, options);

    const target = scrollEndRef.current;
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current && target) {
        observer.current.unobserve(target);
      }
    };
  }, []);

  const loadMoreFiles = async () => {
    isFetchingRef.current = true;
    try {
      await jwtTokenRef.current;
      const response = await axios.get(`${serverURL}/list_transcripts`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
        params: {
          folder_id: null,
          page_size: 10,
          page_number: pageRef.current,
          get_raw_transcript: false,
          text_search: searchQuery,
        },
      });

      const data = response.data.transcripts;
      const retrievedFiles = data.filter(
        (file) => file.transcript_type !== 'gpu' && file.transcript_type !== 'gemini' || file.transcript !== null,
      );
      if (retrievedFiles.length > 0) {
        setRecentFiles((prevFiles) => [...prevFiles, ...retrievedFiles]);
        pageRef.current += 1;
      }
    } catch (error) {
      console.error('Error loading more files:', error);
    }
    isFetchingRef.current = false;
  };

  const groupedFiles = groupTranscriptsByDate(recentFiles);

  const togglePopup = (setter) => () => setter((prev) => !prev);

  const handleRename = (file) => {
    setCurrentFile(file);
    setRenameOpen(true);
  };

  const handleDelete = async (fileToRemove) => {
    try {
      await fetch(
        `${serverURL}/delete_transcript?transcript_id=${fileToRemove.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      setRecentFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileToRemove.id),
      );
      setDeleteOpen(true);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleMove = (fileId, folderId) => {
    // Placeholder for move functionality
    console.log(`Moving file ${fileId} to folder ${folderId}`);
  };

  const handleFileRename = async (newName) => {
    try {
      await fetch(
        `${serverURL}/rename_transcript?transcript_id=${currentFile.id}&new_name=${newName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      setRenameOpen(false);
      setRecentFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === currentFile.id ? { ...file, name: newName } : file,
        ),
      );
    } catch (error) {
      console.error('Error renaming file:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      fetchFiles(e.target.value);
    }, 1000);
  };

  return (
    <div className='top-0 right-0 z-10 w-[320px] absolute bg-white shadow-lg h-full overflow-hidden text-left text-sm text-gray-800 font-montserrat'>
      <div
        ref={windowRef}
        className='absolute top-0 left-0 bg-white w-80 h-full overflow-hidden flex flex-col'
      >
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center justify-between mb-2">
            <h2 className="font-bold text-xl">Recent Files</h2>
            <button 
              className="text-gray-500 hover:text-gray-700" 
              onClick={onClose}
              aria-label="Close sidebar"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <p className="text-xs text-gray-500 mb-4">
            * Your auto-saved documents will be stored for 30 days
          </p>
          <div className="relative">
            <input
              type="text"
              placeholder="Search files..."
              className="w-full px-4 py-2 pl-10 rounded-xl bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-300"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <img
                className="w-5 h-5"
                alt="Search"
                src="/general--search.svg"
              />
            </div>
          </div>
        </div>

        <div className="overflow-y-auto flex-1">
          <div className="p-4 flex flex-col gap-5">
            {Object.entries(groupedFiles).map(([timePeriod, files]) => (
              files.length > 0 && (
                <div
                  key={timePeriod}
                  className="flex flex-col gap-2.5"
                >
                  <h3 className="font-medium text-gray-500 text-sm">
                    {(timePeriod === 'lastWeek' && 'Last Week') ||
                      (timePeriod === 'previous30Days' && 'Previous 30 Days') ||
                      timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1)}
                  </h3>
                  <div className="flex flex-col gap-2.5">
                    {files.map((file) => (
                      <div
                        key={file.id}
                        className="p-3 rounded-lg shadow-sm border border-gray-100 hover:bg-gray-50 transition-colors"
                      >
                        <div className="flex justify-between items-center">
                          <div 
                            className="flex items-center cursor-pointer flex-grow truncate pr-2"
                            onClick={() => {
                              navigate(`/file_transcript?id=${file.id}`, {
                                state: { from: location.pathname },
                              });
                            }}
                          >
                            <span className="w-5 h-5 mr-2.5 flex-shrink-0">
                              <img src="/audio-video-file.svg" alt="Transcript" className="w-full h-full" />
                            </span>
                            <span className="font-medium truncate">{file.name}</span>
                          </div>
                          <FileActionButtons
                            file={file}
                            folders={[]} // Pass available folders here
                            onRename={handleRename}
                            onDelete={handleDelete}
                            onMove={handleMove}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            ))}
            <div ref={scrollEndRef}></div>
          </div>
          
          {recentFiles.length === 0 && (
            <div className="flex flex-col items-center justify-center h-48 text-gray-400">
              <svg className="w-12 h-12 mb-2 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <span>No recent files found</span>
            </div>
          )}
        </div>
      </div>
      {isRenameOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={togglePopup(setRenameOpen)}
        >
          <RenamePopup
            onClose={togglePopup(setRenameOpen)}
            fileName={currentFile?.name || ''}
            onRename={handleFileRename}
          />
        </PortalPopup>
      )}
      {isSaveOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={togglePopup(setSaveOpen)}
        >
          <SaveFromHistory />
        </PortalPopup>
      )}
      {isDeleteOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={togglePopup(setDeleteOpen)}
        >
          <DeleteFromHistory
            onClick={(e) => {
              e.stopPropagation();
            }}
            onClose={togglePopup(setDeleteOpen)}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default RecentsSidebar;
