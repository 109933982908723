import React from 'react';

export const CopyOptionsPopup = ({ onSelect }) => {
  return (
    <div className="absolute bottom-12 right-0 bg-white border border-gray-300 rounded-md shadow-lg z-20 w-48">
      <div className="flex flex-col">
        <button
          className="text-left px-4 py-2 hover:bg-gray-100 text-sm"
          onClick={() => onSelect('original')}
        >
          Copy Original
        </button>
        <button
          className="text-left px-4 py-2 hover:bg-gray-100 text-sm"
          onClick={() => onSelect('translated')}
        >
          Copy Translated
        </button>
        <button
          className="text-left px-4 py-2 hover:bg-gray-100 text-sm"
          onClick={() => onSelect('both')}
        >
          Copy Both
        </button>
      </div>
    </div>
  );
};

export default CopyOptionsPopup;