import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { motion } from 'framer-motion';

export const EditFolder = ({
  folder,
  setIsRenamingFolder,
  setOpenPopup,
  handleFolderDelete,
}) => {
  const handleRenameClick = (e) => {
    e.stopPropagation(); // Add this to stop event propagation
    setIsRenamingFolder(true);
    setOpenPopup(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    handleFolderDelete(folder.id);
    setOpenPopup(false);
  };

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.9, y: -5 },
    visible: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.9, y: -5 }
  };

  const buttonVariants = {
    hover: { backgroundColor: '#f3f4f6' },
    tap: { scale: 0.98 }
  };

  return (
    <motion.div 
      className='flex flex-col bg-white rounded-xl shadow-lg overflow-hidden'
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.2 }}
    >
      <motion.div
        className='p-4 border-b border-gray-100 flex items-center gap-2 text-gray-700 cursor-pointer'
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        onClick={handleRenameClick}
      >
        <EditOutlined className='text-lg' />
        <span>Rename folder</span>
      </motion.div>
      
      <motion.div
        className='p-4 flex items-center gap-2 text-red-500 cursor-pointer'
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        onClick={handleDelete}
      >
        <DeleteOutlined className='text-lg' />
        <span>Delete folder</span>
      </motion.div>
    </motion.div>
  );
};
