import { useState, useRef, useEffect, useCallback } from 'react';
import { playNextAudio, computeBlob } from '../../../../utils';

/**
 * Custom hook for managing audio processing and live audio playback
 * 
 * @param {Array} messages - Current transcription messages
 * @param {Object} outputLanguageRef - Reference to current output language
 * @returns {Object} Audio processing state and functions
 */
const useAudioProcessing = (messages, outputLanguageRef) => {
  // State for live audio
  const [liveAudioEnabled, setLiveAudioEnabled] = useState(false);
  const liveAudioEnabledRef = useRef(false);
  
  // Create audio context with blank audio source
  const blankSrc = 'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  const audioContextRef = useRef(new Audio(blankSrc));
  audioContextRef.current.autoplay = true;
  
  // Audio queue management
  const audioQueueRef = useRef([]);
  const playingAudioRef = useRef(false);
  const startProcessingIndexRef = useRef(0);
  
  // Update ref when state changes
  useEffect(() => {
    liveAudioEnabledRef.current = liveAudioEnabled;
  }, [liveAudioEnabled]);

  // Toggle live audio playback
  const toggleLiveAudio = useCallback(() => {
    if (!liveAudioEnabled) {
      // Enable live audio
      audioContextRef.current.play().catch(err => {
        console.error("Error playing audio:", err);
      });
      setLiveAudioEnabled(true);

      // Set the starting index to the current messages length
      let lastFinalMessage = 0;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i]['is_final']) {
          lastFinalMessage = i + 1;
          break;
        }
      }
      startProcessingIndexRef.current = lastFinalMessage;
    } else {
      // Disable live audio
      setLiveAudioEnabled(false);
      // Clear the audio queue and stop playback
      audioQueueRef.current = [];
      playingAudioRef.current = false;
      audioContextRef.current.pause();
    }
  }, [liveAudioEnabled, messages]);

  // Stop all audio playback
  const stopAllAudio = useCallback(() => {
    if (audioContextRef.current) {
      audioContextRef.current.pause();
      audioContextRef.current.currentTime = 0;
    }
    
    audioQueueRef.current = [];
    playingAudioRef.current = false;
  }, []);

  // Process messages for audio when they change
  useEffect(() => {
    if (!liveAudioEnabledRef.current) {
      return;
    }

    let tasks = [];
    let textToAdd = '';
    
    // Gather text from finalized messages
    for (let i = startProcessingIndexRef.current; i < messages.length; i++) {
      const message = messages[i];
      if (message['is_final']) {
        let text = message['transcript'] || message['text']; // Handle different message formats
        
        // Use translated text if available
        if (
          message['corrected_transcripts'] && 
          outputLanguageRef && 
          outputLanguageRef.current && 
          message['corrected_transcripts'][outputLanguageRef.current] !== undefined
        ) {
          text = message['corrected_transcripts'][outputLanguageRef.current];
        }
        textToAdd = textToAdd + ' ' + text;
      }
    }
    
    // Convert text to audio blob
    if (textToAdd !== '') {
      let blob = computeBlob(textToAdd);
      tasks.push(blob);
    }

    // Update the processing index
    let lastFinalMessage = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i]['is_final']) {
        lastFinalMessage = i + 1;
        break;
      }
    }
    startProcessingIndexRef.current = lastFinalMessage;

    // Process all blobs and add to queue
    Promise.all(tasks).then((blobs) => {
      for (let i = 0; i < blobs.length; i++) {
        audioQueueRef.current.push(blobs[i]);
      }
      // Start playing if not already playing
      playNextAudio(
        playingAudioRef,
        audioQueueRef,
        audioContextRef,
        liveAudioEnabledRef,
      );
    }).catch(error => {
      console.error("Error processing audio:", error);
    });
  }, [messages, outputLanguageRef]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Stop any playing audio
      stopAllAudio();
    };
  }, [stopAllAudio]);

  return {
    liveAudioEnabled,
    setLiveAudioEnabled,
    toggleLiveAudio,
    stopAllAudio,
    audioContextRef,
    audioQueueRef,
    playingAudioRef,
    startProcessingIndexRef
  };
};

export default useAudioProcessing;