import { useState, useRef, useEffect } from 'react';
import { MoreOutlined, FolderOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useHapticFeedback } from './AccessibleFeedback';
import { EditFolder } from './EditFolder'; // Add this import
import axios from 'axios'; // Add missing import
import { serverURL, jwtTokenRef } from '../../httpContext'; // Add missing imports

export const MobileFolder = ({
  folder,
  handleFolderClick,
  handleFolderRename,
  handleFolderDelete,
  refreshFolders,
}) => {
  const [newName, setNewName] = useState(folder?.name);
  const [isRenaming, setIsRenaming] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const popupRef = useRef(null);
  const inputRef = useRef(null);
  const folderRef = useRef(null);
  
  const { triggerHapticFeedback } = useHapticFeedback();

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const handleRename = async (folderId, newName) => {
    try {
      triggerHapticFeedback('click');
      
      const response = await axios.post(
        `${serverURL}/rename_folder?folder_id=${folderId}&new_name=${newName}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      // Update local state
      setNewName(newName);
      triggerHapticFeedback('success');
      
      // Call the parent's handleFolderRename instead of refreshFolders
      const success = handleFolderRename ? await handleFolderRename(folderId, newName) : true;
      
      return success;
    } catch (error) {
      console.error('Error renaming folder:', error);
      triggerHapticFeedback('error');
      return false;
    }
  };

  const handleKeyDownRename = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleRename(folder.id, newName).then(success => {
        if (success) setIsRenaming(false);
      });
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsRenaming(false);
      setNewName(folder?.name); // Reset to original name
    }
  };

  const handleItemClick = (e) => {
    // Only trigger folder click if we're not in renaming mode
    if (!isRenaming) {
      triggerHapticFeedback('click');
      handleFolderClick(folder);
    }
  };

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenPopup(false);
      }
      
      // Add this block to handle clicks outside rename input
      if (isRenaming && inputRef.current && !inputRef.current.contains(event.target)) {
        // If clicked outside while renaming, save changes
        if (newName.trim() !== '' && newName !== folder?.name) {
          handleRename(folder.id, newName);
        } else {
          setNewName(folder?.name); // Reset to original name if empty
        }
        setIsRenaming(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isRenaming, folder?.name]);

  const handleSaveButtonClick = (e) => {
    e.stopPropagation();
    if (newName.trim() === '') {
      setNewName(folder?.name); // Reset to original name if empty
      setIsRenaming(false);
      return;
    }
    
    triggerHapticFeedback('click');
    
    handleRename(folder.id, newName)
      .then((success) => {
        if (success) {
          setIsRenaming(false);
        }
      })
      .catch(err => {
        console.error("Error in folder rename:", err);
        setIsRenaming(false);
        setNewName(folder?.name); // Reset on error
      });
  };

  return (
    <motion.div
      className='relative w-full h-full text-left text-[1rem] font-montserrat'
      onClick={handleItemClick} // Only call handleItemClick when not renaming
      ref={folderRef}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      whileTap={{ scale: isRenaming ? 1 : 0.98 }}
      role="button"
      aria-label={`Folder: ${folder?.name || 'Unnamed folder'}`}
      tabIndex={0}
      onKeyDown={(e) => {
        if (!isRenaming && (e.key === 'Enter' || e.key === ' ')) {
          handleItemClick(e);
        }
      }}
    >
      {openPopup && (
        <div ref={popupRef} className='absolute w-fit h-fit z-50 right-[0px]'>
          <EditFolder
            folder={folder}
            setIsRenamingFolder={setIsRenaming}
            setOpenPopup={setOpenPopup}
            handleFolderDelete={handleFolderDelete}
          />
        </div>
      )}
      
      <motion.div
        className={`flex py-4 h-fit relative z-10 rounded-xl border-l-4 border-blue-500 
                    ${isPressed ? 'bg-gray-50' : 'bg-white'} 
                    shadow-sm hover:shadow-md transition-all duration-300`}
        whileHover={isRenaming ? {} : { 
          backgroundColor: 'rgba(249, 249, 249, 0.9)',
          boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
          y: -1
        }}
        onHoverStart={() => !isRenaming && setIsPressed(true)}
        onHoverEnd={() => !isRenaming && setIsPressed(false)}
        onTapStart={() => !isRenaming && setIsPressed(true)}
        onTap={() => !isRenaming && setIsPressed(false)}
        onTapCancel={() => !isRenaming && setIsPressed(false)}
        onClick={isRenaming ? (e) => e.stopPropagation() : undefined} // Stop propagation when renaming
      >
        {/* Icon */}
        <div className="flex items-center justify-center h-12 w-12 ml-2">
          <FolderOutlined className='text-2xl text-blue-500' aria-hidden="true" />
        </div>

        {/* Options button - only show when not renaming */}
        {!isRenaming && (
          <motion.button
            className='absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100'
            whileTap={{ scale: 0.9 }}
            onClick={(e) => {
              e.stopPropagation();
              triggerHapticFeedback('click');
              setOpenPopup(true);
            }}
            aria-label={`Options for folder ${folder?.name || ''}`}
          >
            <MoreOutlined />
          </motion.button>
        )}
        
        {/* Content area */}
        <div className='flex-grow flex flex-col gap-2 pr-12'>
          {!isRenaming ? (
            <div className='font-medium truncate flex items-baseline gap-2'>
              <span className='truncate'>{newName}</span>
            </div>
          ) : (
            <div 
              className='w-full flex items-center gap-2 py-1' 
              onClick={e => e.stopPropagation()} // Add explicit stop propagation
            >
              <input
                className='font-medium border border-gray-300 rounded px-2 py-1 w-3/4 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500'
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                enterKeyHint='done'
                ref={inputRef}
                type='text'
                onFocus={handleFocus}
                onKeyDown={handleKeyDownRename}
                autoFocus
                aria-label={`Rename folder`}
                onClick={e => e.stopPropagation()} // Reinforce stop propagation
              />
              <motion.button
                className='font-medium p-1 px-3 bg-blue-500 text-white rounded'
                onClick={(e) => {
                  e.stopPropagation(); // Add explicit stop propagation
                  handleSaveButtonClick(e);
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Save
              </motion.button>
            </div>
          )}
          {!isRenaming && (
            <div className='flex items-center text-xs text-blue-500'>
              <span>Tap to view contents</span>
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};
