import { useState, useEffect, useRef, useCallback } from 'react';
import { SearchOutlined, ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';
import { Files } from '../../components/mobile/Files';
import { FolderPopup } from '../../components/popups/FolderPopup';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom'; // Add this import

export const Docs = () => {
  const [searching, setSearching] = useState(false);
  const [recentFiles, setRecentFiles] = useState([]);
  const [query, setQuery] = useState('');
  const queryRef = useRef(query);
  const typingTimeoutRef = useRef(null);
  
  // Add folder navigation state
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [currentFolderName, setCurrentFolderName] = useState('');
  
  // Add status messages
  const [statusMessage, setStatusMessage] = useState(null);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const processFile = (file) => {
    return {
      name: file.name,
      content: file.transcript,
      created_at: file.created_at,
      path: file.path,
      id: parseInt(file.id),
      raw_transcript: file.raw_transcript,
      transcript_type: file.transcript_type,
      folder_id: file.folder_id,
    };
  };

  // Handle search query changes with pagination reset
  useEffect(() => {
    queryRef.current = query;

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setCurrentPage(1); // Reset to page 1 when search query changes
      refreshFiles(1);
    }, 500);

    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, [query]);

  // Fix: Define the onSearchBarContainerClick function that was missing
  const onSearchBarContainerClick = () => {
    setSearching(true);
  };

  // Add a separate function to refresh folders
  const refreshFolders = async () => {
    try {
      const folderResponse = await axios.get(`${serverURL}/list_folders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      // The Files component will handle this updated folders list on its next render
      return folderResponse.data.folders || [];
    } catch (folderError) {
      console.error('Error refreshing folders:', folderError);
      return [];
    }
  };

  // Update refreshFiles to ensure it refreshes both files and folders
  const refreshFiles = async (page = 1) => {
    setIsLoading(true);
    setCurrentPage(page);
    
    try {
      console.log(`Refreshing files: page=${page}, folder=${currentFolderId || 'root'}`);
      
      let endpoint = `${serverURL}/list_transcripts?text_search=${queryRef.current}&page_size=10&page_number=${page}`;
      
      // Add folder filter if we're in a folder
      if (currentFolderId) {
        endpoint += `&folder_id=${currentFolderId}`;
      }
      
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      
      const data = response.data;
      console.log(`Retrieved ${data.transcripts?.length || 0} files, total: ${data.total || 0}`);
      
      const retrievedFiles = data.transcripts ? data.transcripts.map(processFile) : [];
      setRecentFiles(retrievedFiles);
      setTotalCount(data.total || 0);
      
      // Always refresh the folder list too when not in a folder
      if (!currentFolderId) {
        await refreshFolders();
      }
      
      return retrievedFiles;
    } catch (error) {
      console.error('Error fetching files:', error);
      showStatusMessage('Error loading files', 'error');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Back button handler
  const handleBack = () => {
    if (currentFolderId) {
      setCurrentFolderId(null);
      setCurrentFolderName('');
      setCurrentPage(1); // Reset to page 1 when going back
      refreshFiles(1); 
    }
  };

  // Status message helper
  const showStatusMessage = (message, type = 'success') => {
    setStatusMessage({ text: message, type });
    setTimeout(() => setStatusMessage(null), 3000);
  };

  // Create folder - update to ensure UI updates
  const handleCreateFolder = async (newFolderName) => {
    if (!newFolderName.trim()) {
      showStatusMessage('Folder name cannot be empty', 'error');
      return;
    }
    
    try {
      setIsLoading(true);
      
      // Use axios instead of fetch for consistency
      const response = await axios.post(
        `${serverURL}/create_folder`,
        {},  // Empty body
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          params: {
            folder_name: newFolderName
          }
        }
      );
      
      setIsCreateFolderModalOpen(false);
      showStatusMessage('Folder created successfully');
      
      // Force folder refresh with key-based remounting
      setFolderRefreshKey(prev => prev + 1);
      
      // After folder creation, reset to root view to see the new folder
      setCurrentFolderId(null);
      setCurrentFolderName('');
      
      // Refresh files to update the UI - this will also trigger folder refresh
      refreshFiles(1);
    } catch (error) {
      console.error('Error creating folder:', error);
      showStatusMessage('Failed to create folder', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Initial data load and folder changes
  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when folder changes
    refreshFiles(1);
  }, [currentFolderId]);

  // Add a folder refresh key to force re-render when needed
  const [folderRefreshKey, setFolderRefreshKey] = useState(0);

  const location = useLocation(); // Add this to detect navigation changes
  const hasMounted = useRef(false);
  
  // Use this function to load files when component mounts or when navigating back
  const loadInitialData = useCallback(async () => {
    setIsLoading(true);
    try {
      await refreshFiles(currentPage);
    } catch (error) {
      console.error('Error loading initial data:', error);
      showStatusMessage('Error loading data', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [currentPage]); // Only depend on currentPage
  
  // Initial data load and handle navigation returns
  useEffect(() => {
    // Load data when first mounting or when location changes (navigation)
    if (!hasMounted.current) {
      console.log('Initial mount - loading files');
      hasMounted.current = true;
      loadInitialData();
    } else {
      // This will run when navigating back to this component
      console.log('Navigation detected - refreshing files');
      loadInitialData();
    }
  }, [location.key, loadInitialData]); // Add location.key as dependency
  
  // Separate useEffect for folder ID changes
  useEffect(() => {
    if (hasMounted.current) {
      setCurrentPage(1); // Reset to page 1 when folder changes
      refreshFiles(1);
    }
  }, [currentFolderId]);

  return (
    <motion.div 
      className='w-full h-full flex flex-col pt-16 px-4 gap-5 leading-[normal] tracking-[normal] relative overflow-y-auto overflow-x-hidden box-border'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      {/* Header with search and back button */}
      <div className='flex items-center justify-between py-1 gap-2 w-full'>
        <div className="relative flex items-center flex-grow">
          <AnimatePresence mode="wait">
            {currentFolderId ? (
              <motion.div
                key="back-button"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2 }}
                className="z-10"
              >
                <ArrowLeftOutlined 
                  className='text-2xl text-updated-color-grey2 pt-2' 
                  onClick={handleBack}
                />
              </motion.div>
            ) : (
              <SearchOutlined 
                className='absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-updated-color-grey2 z-10' 
              />
            )}
          </AnimatePresence>
          
          <motion.input
            className={`w-full transition-all duration-300 ease-in-out 
            ${currentFolderId ? 'pl-8' : 'pl-10'} 
            py-2 [border:none] [outline:none] rounded-xl bg-updated-color-grey1 font-montserrat 
            text-base leading-[1.375rem] text-updated-color-grey2 text-left`}
            placeholder={currentFolderId ? currentFolderName : 'Search...'}
            type='text'
            onClick={onSearchBarContainerClick}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.1 }}
          />
        </div>
        
        {/* Create folder button or Cancel search button */}
        <AnimatePresence mode="wait">
          {currentFolderId ? (
            <motion.div
              key="folder-plus-button"
              className='flex items-center justify-center ml-2'
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2 }}
            >
              <PlusOutlined
                className='text-xl text-updated-color-blue p-2 rounded-full bg-gray-100 hover:bg-gray-200'
                onClick={() => setIsCreateFolderModalOpen(true)}
              />
            </motion.div>
          ) : searching ? (
            <motion.button
              key="cancel-button"
              className="ml-2 text-base text-updated-color-blue whitespace-nowrap"
              onClick={() => {
                setSearching(false);
                setQuery('');
                setCurrentPage(1); // Reset page when canceling search
                refreshFiles(1);
              }}
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "auto" }}
              exit={{ opacity: 0, width: 0 }}
              transition={{ duration: 0.2 }}
            >
              Cancel
            </motion.button>
          ) : (
            <motion.div
              key="plus-button"
              className='flex items-center justify-center ml-2'
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2 }}
            >
              <PlusOutlined
                className='text-xl text-updated-color-blue p-2 rounded-full bg-gray-100 hover:bg-gray-200'
                onClick={() => setIsCreateFolderModalOpen(true)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      
      {/* Status message */}
      <AnimatePresence>
        {statusMessage && (
          <motion.div 
            className={`fixed top-20 left-0 right-0 mx-auto w-4/5 p-3 rounded-md text-white text-center z-50 shadow-lg ${
              statusMessage.type === 'error' ? 'bg-red-500' : 'bg-green-500'
            }`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {statusMessage.text}
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Loading indicator */}
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-white/60 flex items-center justify-center z-40"
            transition={{ duration: 0.2 }}
          >
            <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Content sections */}
      {query && (
        <motion.div 
          className='text-lg font-light'
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          Search results
        </motion.div>
      )}
      
      {/* Main content - Use Files component to handle both folders and files */}
      <Files
        key={`files-${folderRefreshKey}`} // Add this line to force remount when folders change
        title={currentFolderId ? currentFolderName : query ? 'Search Results' : 'Folders'}
        recentFiles={recentFiles}
        setRecentFiles={setRecentFiles}
        refreshFiles={refreshFiles}
        queryRef={queryRef}
        currentFolderId={currentFolderId}
        setCurrentFolderId={setCurrentFolderId}
        setCurrentFolderName={setCurrentFolderName}
        showFolders={!query && !currentFolderId}
        totalCount={totalCount}
        forceRefresh={folderRefreshKey} // Add this prop
      />
      
      {/* Create folder modal - using the improved FolderPopup component */}
      <AnimatePresence>
        {isCreateFolderModalOpen && (
          <FolderPopup
            onSubmit={handleCreateFolder}
            onClose={() => setIsCreateFolderModalOpen(false)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};
