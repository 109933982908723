import { useRef, useState, useEffect, useCallback } from 'react';

/**
 * Custom hook to manage chatbot reference with improved initialization status and method execution
 * @returns {Object} Chatbot reference and status utilities
 */
export default function useChatbotRef() {
  const chatbotRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initializationAttempts, setInitializationAttempts] = useState(0);

  // Track pending method calls
  const pendingMethodsRef = useRef([]);

  // Track last request time for throttling
  const lastRequestTimeRef = useRef({});

  // Cache for method results
  const methodResultCacheRef = useRef({});

  // Track debounce timers
  const debounceTimersRef = useRef({});

  // Track active method calls to prevent overlapping calls to the same method
  const activeMethodCallsRef = useRef({});

  // Set chatbot ref with callback
  const setChatbotRef = useCallback((ref) => {
    if (ref && !chatbotRef.current) {
      console.log('Chatbot reference set successfully');
      chatbotRef.current = ref;
      setIsInitialized(true);

      // Execute any pending methods
      if (pendingMethodsRef.current.length > 0) {
        console.log(
          `Executing ${pendingMethodsRef.current.length} pending chatbot methods`,
        );

        // Process pending methods sequentially
        const processPending = async () => {
          for (const {
            methodName,
            args,
            resolve,
            reject,
          } of pendingMethodsRef.current) {
            try {
              const result = await chatbotRef.current[methodName](...args);
              resolve(result);
              // Add small delay between operations
              await new Promise((r) => setTimeout(r, 50));
            } catch (error) {
              reject(error);
            }
          }
          pendingMethodsRef.current = [];
        };

        processPending();
      }
    }
  }, []);

  // Improved method execution with proper error handling, throttling and caching
  const executeChatbotMethod = useCallback(
    async (methodName, ...args) => {
      // Handle uninitialized chatbot
      if (!chatbotRef.current) {
        console.warn(`Chatbot ${methodName} called but not initialized`);
        return methodName.startsWith('get') ? '' : null;
      }
  
      // Check if method exists
      if (typeof chatbotRef.current[methodName] !== 'function') {
        console.error(`Method ${methodName} not available on chatbot`);
        return null;
      }
  
      try {
        // Simple direct execution with timeout
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error(`Timeout exceeded for ${methodName}`));
          }, 30000);
        });
  
        // Execute method with timeout protection
        const result = await Promise.race([
          chatbotRef.current[methodName](...args),
          timeoutPromise,
        ]);
  
        return result;
      } catch (error) {
        console.error(`Error executing ${methodName}:`, error);
        return null;
      }
    },
    [initializationAttempts],
  );
  // Function to clear caches
  const clearMethodCache = useCallback(() => {
    methodResultCacheRef.current = {};

    // Clear debounce timers
    Object.keys(debounceTimersRef.current).forEach((key) => {
      if (debounceTimersRef.current[key]) {
        clearTimeout(debounceTimersRef.current[key]);
        debounceTimersRef.current[key] = null;
      }
    });
  }, []);

  // Log initialization status
  useEffect(() => {
    console.log(
      `Chatbot initialization status: ${isInitialized ? 'Initialized' : 'Not initialized'}`,
    );
  }, [isInitialized]);

  // Log warning for multiple attempts
  useEffect(() => {
    if (initializationAttempts > 5) {
      console.warn(
        `Multiple chatbot initialization attempts (${initializationAttempts}) without success`,
      );
    }
  }, [initializationAttempts]);

  return {
    chatbotRef,
    isInitialized,
    setChatbotRef,
    executeChatbotMethod,
    clearMethodCache,
  };
}
