// src/pages/Docs.jsx
import { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL, userInfoRef } from '../../httpContext';
import axios from 'axios';
import '../../assets/styles/animations.css'; // Import animations CSS

// Components
import { SearchBar } from '../../components/common/SearchBar';
import { FoldersHeader } from '../../components/folders/FoldersHeader';
import { FilesHeader } from '../../components/files/FilesHeader';
import { FolderList } from '../../components/folders/FolderList';
import { FileList } from '../../components/files/FileList';
import { FilesSortControls } from '../../components/files/FilesSortControls';
import { StatusMessages } from '../../components/common/StatusMessages';
import LinkInput from '../../components/web/LinkInput';

const Docs = () => {
  const navigate = useNavigate();

  // State for files
  const [recentFiles, setRecentFiles] = useState([]);
  const recentFilesRef = useRef([]);
  const [fileError, setFileError] = useState(null);
  const [fileSuccess, setFileSuccess] = useState(null);

  // State for folders
  const [folders, setFolders] = useState([]);
  const [numFolders, setNumFolders] = useState(4);
  const [viewAllFolders, setViewAllFolders] = useState(false);

  // State for folder navigation
  const [folderId, setFolderId] = useState(null);
  const [folderName, setFolderName] = useState('');
  const [openedFolder, setOpenedFolder] = useState(false);
  const [viewAllFiles, setViewAllFiles] = useState(false);
  const [title, setTitle] = useState('Recently Saved Files');

  // Refs for querying and pagination
  const currentPageRef = useRef(1);
  const transcriptTypeRef = useRef(undefined);
  const queryRef = useRef('');
  const ascRef = useRef(false);

  // Credits
  const [credits, setCredits] = useState(
    Math.floor(userInfoRef.current?.credits || 0),
  );

  // Add totalFiles state to track total file count
  const [totalFiles, setTotalFiles] = useState(0);

  // Fetch folders
  const refreshFolders = useCallback(async () => {
    try {
      const response = await axios.get(`${serverURL}/list_folders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });

      let retrievedFolders = response.data.folders.map((folder) => ({
        name: folder.name,
        id: folder.id,
        path: folder.path,
      }));

      if (numFolders) {
        setFolders(retrievedFolders.slice(0, numFolders));
      } else {
        setFolders(retrievedFolders);
      }
    } catch (error) {
      console.error('Error fetching folders:', error);
      setFileError('Failed to load folders');
    }
  }, [numFolders]);

  // Fetch files
  const fetchFiles = useCallback(
    async (page, transcript_type, asc, query) => {
      try {
        const response = await axios.get(`${serverURL}/list_transcripts`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          params: {
            folder_id: folderId,
            page_size: 10,
            page_number: page,
            get_raw_transcript: false,
            transcript_type: transcript_type,
            asc: asc,
            text_search: query,
          },
        });

        const { transcripts, total } = response.data;

        // Filter out invalid transcripts
        const retrievedFiles = transcripts.filter(
          (item) => item.transcript_type === 'file' || item.transcript !== null,
        );

        // Update total files count
        setTotalFiles(total);

        return {
          files: retrievedFiles,
          total,
        };
      } catch (error) {
        console.error('Error fetching files:', error);
        setFileError('Failed to load files');
        return { files: [], total: 0 };
      }
    },
    [folderId],
  );

  // Update loadMoreFiles to handle pagination and replacement
  const loadMoreFiles = useCallback(
    (page = 1, replace = false) => {
      const fetchData = async () => {
        currentPageRef.current = page;

        const result = await fetchFiles(
          page,
          transcriptTypeRef.current,
          ascRef.current,
          queryRef.current,
        );

        if (result.files) {
          if (replace) {
            // Replace current files with new page
            setRecentFiles(result.files);
          } else {
            // Append to current files
            setRecentFiles((prevFiles) => [...prevFiles, ...result.files]);
          }
        }
      };

      fetchData();
    },
    [fetchFiles],
  );

  // Search handler
  const handleSearch = useCallback(
    async (query) => {
      currentPageRef.current = 1;
      queryRef.current = query;

      const result = await fetchFiles(
        1,
        transcriptTypeRef.current,
        ascRef.current,
        query,
      );

      if (result.files) {
        setRecentFiles(result.files);
      }
    },
    [fetchFiles],
  );

  // Sort handlers
  const handleSortByNewest = useCallback(() => {
    const fetchData = async () => {
      currentPageRef.current = 1;
      ascRef.current = false;

      const result = await fetchFiles(
        1,
        transcriptTypeRef.current,
        false,
        queryRef.current,
      );

      if (result.files) {
        setRecentFiles(result.files);
      }
    };

    fetchData();
  }, [fetchFiles]);

  const handleSortByOldest = useCallback(() => {
    const fetchData = async () => {
      currentPageRef.current = 1;
      ascRef.current = true;

      const result = await fetchFiles(
        1,
        transcriptTypeRef.current,
        true,
        queryRef.current,
      );

      if (result.files) {
        setRecentFiles(result.files);
      }
    };

    fetchData();
  }, [fetchFiles]);

  // Folder operations
  const handleCreateFolder = async (folderName) => {
    try {
      await fetch(`${serverURL}/create_folder?folder_name=${folderName}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });

      setFileSuccess('Folder created successfully');
      refreshFolders();
      return true;
    } catch (error) {
      console.error('Error creating folder:', error);
      setFileError('Failed to create folder');
      return false;
    }
  };

  const handleRenameFolder = async (folderId, newName) => {
    try {
      await fetch(
        `${serverURL}/rename_folder?folder_id=${folderId}&new_name=${newName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      setFileSuccess('Folder renamed successfully');

      // If this is the currently opened folder, update the title
      if (openedFolder && folderId === folderId) {
        setFolderName(newName);
        setTitle(newName);
      }

      refreshFolders();
      return true;
    } catch (error) {
      console.error('Error renaming folder:', error);
      setFileError('Failed to rename folder');
      return false;
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      await fetch(`${serverURL}/delete_folder?folder_id=${folderId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });

      setFileSuccess('Folder deleted successfully');

      // If this is the currently opened folder, navigate back
      if (openedFolder && folderId === folderId) {
        handleBackToAllFiles();
      }

      refreshFolders();
      return true;
    } catch (error) {
      console.error('Error deleting folder:', error);
      setFileError('Failed to delete folder');
      return false;
    }
  };

  // File operations
  const handleRenameFile = async (fileId, newName) => {
    try {
      const response = await fetch(
        `${serverURL}/rename_transcript?transcript_id=${fileId}&new_name=${newName}`,
        {
          method: 'POST',
          body: JSON.stringify({ fileName: newName }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.ok) {
        setFileSuccess('File renamed successfully');

        // Update file in state
        setRecentFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === fileId ? { ...file, name: newName } : file,
          ),
        );

        return true;
      } else {
        throw new Error('Failed to rename file');
      }
    } catch (error) {
      console.error('Error renaming file:', error);
      setFileError(error.message || 'Failed to rename file');
      return false;
    }
  };

  const handleDeleteFile = async (fileId, fileName) => {
    try {
      const response = await fetch(
        `${serverURL}/delete_transcript?transcript_id=${fileId}`,
        {
          method: 'POST',
          body: JSON.stringify({ fileName }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.ok) {
        setFileSuccess('File deleted successfully');

        // Remove file from state
        setRecentFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== fileId),
        );

        return true;
      } else {
        throw new Error('Failed to delete file');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      setFileError(error.message || 'Failed to delete file');
      return false;
    }
  };

  const handleMoveFile = async (fileId, targetFolderId) => {
    try {
      const response = await axios.post(
        `${serverURL}/add_file_to_folder?folder_id=${targetFolderId}&file_id=${fileId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200) {
        setFileSuccess('File moved successfully');

        // If in folder view, remove the file from state
        if (openedFolder && folderId !== targetFolderId) {
          setRecentFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== fileId),
          );
        } else {
          // If in all files view, just update the folder_id
          setRecentFiles((prevFiles) =>
            prevFiles.map((file) =>
              file.id === fileId
                ? { ...file, folder_id: targetFolderId }
                : file,
            ),
          );
        }

        return true;
      } else {
        throw new Error('Failed to move file');
      }
    } catch (error) {
      console.error('Error moving file:', error);
      setFileError(error.message || 'Failed to move file');
      return false;
    }
  };

  // Navigation
  const handleFolderClick = (folder) => {
    setOpenedFolder(true);
    setFolderName(folder.name);
    setFolderId(folder.id);
    setTitle(folder.name);

    // Reset pagination and fetch files for this folder
    currentPageRef.current = 1;
    fetchFiles(
      1,
      transcriptTypeRef.current,
      ascRef.current,
      queryRef.current,
    ).then((result) => {
      if (result.files) {
        setRecentFiles(result.files);
      }
    });
  };

  const handleBackToAllFiles = () => {
    navigate('/docs');
    setOpenedFolder(false);
    setFolderName('');
    setFolderId(null);
    setViewAllFiles(false);
    setTitle('Recently Saved Files');

    // Reset pagination and fetch all files
    currentPageRef.current = 1;
    fetchFiles(
      1,
      transcriptTypeRef.current,
      ascRef.current,
      queryRef.current,
    ).then((result) => {
      if (result.files) {
        setRecentFiles(result.files);
      }
    });
  };

  const handleToggleViewAllFolders = () => {
    const newLimit = viewAllFolders ? 4 : null;
    setNumFolders(newLimit);
    setViewAllFolders(!viewAllFolders);
  };

  const handleToggleViewAllFiles = () => {
    const newTitle = viewAllFiles ? 'Recently Saved Files' : 'All Files';
    setTitle(newTitle);
    setViewAllFiles(!viewAllFiles);
    setFolderId(null);

    // Reset pagination and fetch all files
    currentPageRef.current = 1;
    fetchFiles(
      1,
      transcriptTypeRef.current,
      ascRef.current,
      queryRef.current,
    ).then((result) => {
      if (result.files) {
        setRecentFiles(result.files);
      }
    });
  };

  // File navigation
  const handleFileNavigation = (path, state) => {
    navigate(path, state);
  };

  // Initialize credits
  useEffect(() => {
    const loadUser = () => {
      setCredits(Math.floor(userInfoRef.current?.credits || 0));
    };

    loadUser(); // Initial load
    const interval = setInterval(loadUser, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch initial files
        const result = await fetchFiles(
          1,
          transcriptTypeRef.current,
          ascRef.current,
          queryRef.current,
        );
        if (result.files) {
          setRecentFiles(result.files);
          recentFilesRef.current = result.files;
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setFileError('Failed to load files');
      }
    };

    fetchData();
    refreshFolders();
  }, [fetchFiles, refreshFolders]);

  // Update recentFilesRef when recentFiles changes
  useEffect(() => {
    recentFilesRef.current = recentFiles;
  }, [recentFiles]);

  // Refresh folders when viewAllFolders changes
  useEffect(() => {
    refreshFolders();
  }, [viewAllFolders, refreshFolders]);

  // Auto-dismiss status messages
  useEffect(() => {
    if (fileError || fileSuccess) {
      const timer = setTimeout(() => {
        setFileError(null);
        setFileSuccess(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [fileError, fileSuccess]);

  return (
    <div className='w-full relative text-left text-xs text-updated-color-new-black font-montserrat'>
      <div className='flex flex-col p-10 px-16 pr-24 pt-16 gap-20 min-h-screen'>
        {/* Header with search and credits */}
        <div className='flex items-center justify-between w-full'>
          <div className='flex self-start items-center gap-4'>
            {/* Back button when in folder or "View All" mode */}
            {(openedFolder || viewAllFiles) && (
              <img
                className='w-fit cursor-pointer'
                alt='Back'
                src='/group-1707478204.svg'
                onClick={handleBackToAllFiles}
              />
            )}

            {/* Search bar */}
            <SearchBar onSearch={handleSearch} />
          </div>

          <div className='w-80'>
            <LinkInput />
          </div>
        </div>

        {/* Status messages */}
        <StatusMessages
          error={fileError}
          success={fileSuccess}
          clearError={() => setFileError(null)}
          clearSuccess={() => setFileSuccess(null)}
        />

        {/* Folders section - only show when not in folder or "View All" mode */}
        {!viewAllFiles && !openedFolder && (
          <div className='flex flex-col gap-4'>
            {/* Folders header */}
            <FoldersHeader
              viewAllEnabled={true}
              isViewingAll={viewAllFolders}
              onToggleViewAll={handleToggleViewAllFolders}
            />

            {/* Folder list */}
            <FolderList
              folders={folders}
              showCreateButton={!viewAllFolders && !viewAllFiles}
              onFolderClick={handleFolderClick}
              onCreateFolder={handleCreateFolder}
              onRenameFolder={handleRenameFolder}
              onDeleteFolder={handleDeleteFolder}
            />
          </div>
        )}

        {/* Files section */}
        <div className='flex flex-col gap-4'>
          {/* Files header */}
          <FilesHeader
            title={title}
            viewAllEnabled={!openedFolder}
            isViewingAll={viewAllFiles}
            onToggleViewAll={handleToggleViewAllFiles}
          />

          {/* Sort controls */}
          <FilesSortControls
            onSortByNewest={handleSortByNewest}
            onSortByOldest={handleSortByOldest}
          />

          {/* File list header */}
          <div className='grid grid-cols-4 w-full text-sm text-updated-color-grey3'>
            <div className='leading-[22px] font-medium'>Name</div>
            <div className='leading-[22px] font-medium'>Type</div>
            <div className='leading-[22px] font-medium'>Created</div>
          </div>

          {/* File list */}
          <FileList
            files={recentFiles}
            folders={folders}
            loadMoreFiles={loadMoreFiles}
            onNavigate={handleFileNavigation}
            onDeleteFile={handleDeleteFile}
            onRenameFile={handleRenameFile}
            onMoveFile={handleMoveFile}
            totalFiles={totalFiles}
            pageSize={10}
          />
        </div>
      </div>
    </div>
  );
};

export default Docs;
