import { useState, useEffect } from 'react';

const useTimer = () => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  // Start the timer
  const startTimer = () => {
    // Only set a new start time if we're starting fresh
    if (elapsedTime === 0) {
      setStartTime(new Date().getTime());
    } else {
      // If we have existing elapsed time, adjust start time to maintain it
      setStartTime(new Date().getTime() - elapsedTime);
    }
    setTimerRunning(true);
  };

  // Pause the timer
  const pauseTimer = () => {
    setTimerRunning(false);
  };

  // Reset the timer
  const resetTimer = () => {
    pauseTimer();
    setElapsedTime(0);
    setStartTime(0);
  };

  // Update timer when running
  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerRunning, startTime]);

  return {
    elapsedTime,
    timerRunning,
    startTimer,
    pauseTimer,
    resetTimer,
    setStartTime
  };
};

export default useTimer;