import { Routes, Route, useLocation } from 'react-router-dom';
import NavBarMobile from './NavbarMobile';
import { useEffect, useState } from 'react';
import * as HttpContext from './httpContext';
import EmailVerification from './pages/mobile/EmailVerification';
import Nav from './components/Nav';
import LearnMoreContainer from './components/LearnMoreContainer';
import Plan from './pages/web2/Plan';
import LoginMobile from './pages/mobile/LoginMobile';
import RegisterMobile from './pages/mobile/RegisterMobile';
import ForgotPassword from './pages/mobile/ForgotPassword';
import ChangeForgotPassword from './pages/mobile/ChangeForgotPassword';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, userInfoRef, serverURL } from './httpContext';
import { Welcome } from './pages/mobile/Welcome';
import { Onboarding0 } from './pages/mobile/Onboarding0';
import { Onboarding1 } from './pages/mobile/Onboarding1';
import { Onboarding2 } from './pages/mobile/Onboarding2';
import { Onboarding3 } from './pages/mobile/Onboarding3';
import { Home } from './pages/mobile_2/Home';
import GuestView from './pages/mobile_2/GuestView';
import { AnimatePresence } from 'framer-motion';
import { Docs } from './pages/mobile/Docs';
import HomepageMobile from './pages/web2/HomepageMobile';
import TextTranslate from './pages/mobile_2/TextTranslate';
import { MobileFileRender } from './components/mobile/MobileFileRender';
import { Privacy } from './pages/web2/Privacy';
import { TOS } from './pages/web2/TOS';
import { Contact } from './pages/web2/Contact';
import { ZoomInstructions } from './pages/web2/ZoomInstructions';
import UnsubscribeEmail from './pages/web2/UnsubscribeEmail';
import { AdminPage } from './pages/web2/Admin';
import { Transcribe } from './pages/mobile_2/Transcribe/Transcribe';
import BuyPlanRedirect from './components/mobile/BuyPlanRedirect';

function AppMobile() {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showMobile, setShowMobile] = useState(false);

  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  const refreshTokenAndAccountInfo = async () => {
    if (jwtTokenRef.current != undefined) {
      console.log('JWT token exists');
      if (HttpContext.isJwtExpired(jwtTokenRef.current)) {
        console.log('JWT token expired');
        jwtTokenRef.current = undefined;
        localStorage.removeItem('jwtToken');
        navigate('/');
        setIsLoggedIn(false);
        return;
      }

      await HttpContext.refreshJWTToken(jwtTokenRef);

      await HttpContext.updateAccountInfo(jwtTokenRef, userInfoRef);

      if (
        userInfoRef.current != undefined &&
        userInfoRef.current.verified_email
      ) {
        console.log('Verified email');
        setIsLoggedIn(true);
        setShowMobile(true);
      } else {
        setShowMobile(true);
        navigate('/email-verification');
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  const handleVerifyEmail = async (email, verificationCode) => {
    const response = await fetch(
      `${serverURL}/verify-email?email=${email}&verification_code=${verificationCode}`,
      {
        method: 'GET',
      },
    );

    const response_json = await response.json();

    if (response.status === 200) {
      jwtTokenRef.current = response_json.access_token;
      try {
        localStorage.setItem('jwtToken', jwtTokenRef.current);
        await HttpContext.updateAccountInfo(jwtTokenRef, userInfoRef);
        console.log('Saved jwtToken');
      } catch (e) {
        console.log(e);
        return;
      }

      console.log(response_json);
      setIsLoggedIn(true);
      navigate('/');
    } else {
      console.log(response_json);
    }
  };

  useEffect(() => {
    if (!userInfoRef.current) {
      if (pathname === '/transcribe') navigate('/login');
    }
  }, [userInfoRef, navigate, pathname]);

  useEffect(() => {
    refreshTokenAndAccountInfo();
    const interval = setInterval(
      () => {
        refreshTokenAndAccountInfo();
      },
      1000 * 60 * 5,
    );

    if (pathname === '/verify-email') {
      const email = new URLSearchParams(location.search).get('email');
      const verificationCode = new URLSearchParams(location.search).get(
        'verification_code',
      );
      handleVerifyEmail(email, verificationCode);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='relative overflow-y-auto bg-whitesmoke'>
      {!(userInfoRef.current === undefined) ||
      showMobile ||
      pathname.includes('login') ||
      pathname.includes('signup') ||
      pathname.includes('change-password-forgot') ? (
        <div className='flex flex-col h-dvh relative'>
          {(pathname.includes('tos') ||
            pathname.includes('privacy') ||
            pathname.includes('zoom_instructions') ||
            pathname.includes('contact')) && <Nav showMobile={showMobile} />}
          {isLoggedIn ? (
            <AnimatePresence mode='sync'>
              <Routes location={location} key={location.pathname}>
                <Route path='/transcribe/*' element={<Transcribe />} />
                <Route
                  path='/signup'
                  element={
                    <RegisterMobile
                      setIsLoggedIn={setIsLoggedIn}
                      setShowMobile={setShowMobile}
                    />
                  }
                />
                <Route path='/buy_plan' element={<BuyPlanRedirect />} />
                <Route
                  path='/forgot-password'
                  element={<ForgotPassword setShowMobile={setShowMobile} />}
                />
                <Route
                  path='/unsubscribe_email'
                  element={<UnsubscribeEmail />}
                />
                <Route path='/text_translate' element={<TextTranslate />} />
                <Route path='/docs/' element={<Docs />} />
                <Route path='/admin' element={<AdminPage />} />
                <Route
                  path='/file_transcript/*'
                  element={<MobileFileRender />}
                />
                <Route path='/broadcast/*' element={<GuestView />} />
                <Route
                  path={'/'}
                  element={<Home setIsLoggedIn={setIsLoggedIn} />}
                />
                <Route
                  path={'/translate'}
                  element={<Home setIsLoggedIn={setIsLoggedIn} />}
                />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/tos' element={<TOS />} />
                <Route path='/plan' element={<Plan />} />
                <Route
                  path='/zoom_instructions'
                  element={<ZoomInstructions />}
                />
                <Route path='/contact' element={<Contact />} />
              </Routes>
            </AnimatePresence>
          ) : (
            <Routes>
              <Route path='/*' element={<Welcome />} />
              <Route path='/onboarding-0' element={<Onboarding0 />} />
              <Route path='/onboarding-1' element={<Onboarding1 />} />
              <Route path='/onboarding-2' element={<Onboarding2 />} />
              <Route path='/onboarding-3' element={<Onboarding3 />} />
              <Route
                path='/email-verification'
                element={<EmailVerification setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route
                path='/login'
                element={
                  <LoginMobile
                    setIsLoggedIn={setIsLoggedIn}
                    setShowMobile={setShowMobile}
                  />
                }
              />
              <Route path='/unsubscribe_email' element={<UnsubscribeEmail />} />
              <Route path='/buy_plan' element={<BuyPlanRedirect />} />
              <Route
                path='/signup'
                element={
                  <RegisterMobile
                    setIsLoggedIn={setIsLoggedIn}
                    setShowMobile={setShowMobile}
                  />
                }
              />
              <Route
                path='/forgot-password'
                element={<ForgotPassword setShowMobile={setShowMobile} />}
              />
              <Route
                path='/change-password-forgot'
                element={<ChangeForgotPassword />}
              />
              <Route path='/broadcast/*' element={<GuestView />} />
            </Routes>
          )}
          {((isLoggedIn && pathname === '/') || pathname === '/docs') && (
            <NavBarMobile />
          )}
        </div>
      ) : !pathname.includes('broadcast') ? (
        <div className='bg-white overflow-hidden flex flex-col'>
          {userInfoRef.current === undefined && <Nav />}
          <Routes>
            <Route path='/' element={<HomepageMobile />} />
            <Route path='/buy_plan' element={<BuyPlanRedirect />} />
            <Route
              path='/login'
              element={<LoginMobile setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route path='/unsubscribe_email' element={<UnsubscribeEmail />} />
            <Route path='/welcome' element={<Welcome />} />
            <Route path='/onboarding-0' element={<Onboarding0 />} />
            <Route path='/onboarding-1' element={<Onboarding1 />} />
            <Route path='/onboarding-2' element={<Onboarding2 />} />
            <Route path='/onboarding-3' element={<Onboarding3 />} />
            <Route path='/plan' element={<Plan />} />
            <Route
              path='/forgot-password'
              element={<ForgotPassword setShowMobile={setShowMobile} />}
            />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/tos' element={<TOS />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/zoom_instructions' element={<ZoomInstructions />} />
          </Routes>
          {userInfoRef.current === undefined &&
            !pathname.includes('broadcast') && <LearnMoreContainer />}
        </div>
      ) : (
        <div className='flex flex-col w-full h-dvh overflow-y-auto'>
          <Routes>
            <Route path='/broadcast/*' element={<GuestView />} />
          </Routes>
        </div>
      )}
    </div>
  );
}
export default AppMobile;
