export const updateMessagesMerged = (
  messagesUpdated,
  setMessages,
  messagesRef,
  setMessagesMerged,
) => {
  // copy the messagesUpdated array to avoid reference issues
  let messagesUpdatedCopy = JSON.parse(JSON.stringify(messagesUpdated));
  let messagesMergedUpdated = [];
  let startOfMessagesToKeep = -1;
  for (let i = 0; i < messagesUpdated.length; i++) {
    if (messagesUpdated[i]['transcript'] != '') {
      const currentTimestamp = messagesUpdated[i]['timestamp'];
      const lastMergedMessage =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];

      if (
        i > 0 &&
        !messagesUpdated[i]['did_speaker_change'] &&
        !messagesUpdated[i]['is_ai_answer'] &&
        !messagesUpdated[i - 1]['is_ai_answer'] &&
        messagesMergedUpdated.length > 0 &&
        lastMergedMessage['messages_merged'] < 4 &&
        currentTimestamp - lastMergedMessage['timestamp'] <= 60 // Check if the time gap is less than or equal to 60 seconds
      ) {
        lastMergedMessage['transcript'] =
          lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

        lastMergedMessage['messages_merged']++;

        for (let key in messagesUpdated[i]['corrected_transcripts']) {
          if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
            lastMergedMessage['corrected_transcripts'][key] = '';
          }
          
          const currentTranscript = messagesUpdated[i]['corrected_transcripts'][key];
          if (currentTranscript !== undefined) {
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              currentTranscript;
          }
        }
      } else {
        if (messagesUpdatedCopy.length > 10 && startOfMessagesToKeep == -1) {
          startOfMessagesToKeep = i;
        }
        messagesUpdated[i]['messages_merged'] = 1;
        const timestamp = new Date(currentTimestamp * 1000);
        messagesUpdated[i]['timestamp_display'] = timestamp.toLocaleTimeString(
          [],
          {
            hour: '2-digit',
            minute: '2-digit',
          },
        );
        messagesMergedUpdated.push(messagesUpdated[i]);
      }
    }
  }

  if (messagesMergedUpdated.length > 0) {
    let lastMessageMerged =
      messagesMergedUpdated[messagesMergedUpdated.length - 1];
    lastMessageMerged['unconfirmed_transcript'] = '';
    lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
  }

  if (
    messagesUpdatedCopy.length > 0 &&
    !messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['is_final']
  ) {
    // Make sure we have messages in messagesMergedUpdated before accessing the last one
    if (messagesMergedUpdated.length > 0) {
      let lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      let lengthOfLastMessage =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript'].length;
      
      // Check if the last message has a transcript before slicing
      if (lastMessageMerged['transcript'] && lastMessageMerged['transcript'].length >= lengthOfLastMessage) {
        lastMessageMerged['transcript'] = lastMessageMerged['transcript'].slice(0, -lengthOfLastMessage);
      }
      
      const lastUnconfirmedTranscript = messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript'];
      lastMessageMerged['unconfirmed_transcript'] = lastUnconfirmedTranscript || '';
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
      
      for (let key in lastMessageMerged['corrected_transcripts']) {
        // Make sure the key exists in the last message's corrected_transcripts
        if (messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['corrected_transcripts'] && 
            messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['corrected_transcripts'][key]) {
          
          lengthOfLastMessage =
            messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['corrected_transcripts'][key].length;
          
          // Check if the corrected transcript has enough length before slicing
          if (lastMessageMerged['corrected_transcripts'][key] && 
              lastMessageMerged['corrected_transcripts'][key].length >= lengthOfLastMessage) {
            lastMessageMerged['corrected_transcripts'][key] = lastMessageMerged['corrected_transcripts'][key].slice(0, -lengthOfLastMessage);
          }
          
          const lastUnconfirmedCorrectedTranscript = messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['corrected_transcripts'][key];
          if (lastUnconfirmedCorrectedTranscript !== undefined) {
            lastMessageMerged['unconfirmed_corrected_transcripts'][key] = lastUnconfirmedCorrectedTranscript;
          }
        }
      }
    }
  }

  // ensure only last 30 messages are stored
  if (messagesMergedUpdated.length > 15) {
    messagesMergedUpdated = messagesMergedUpdated.slice(
      messagesMergedUpdated.length - 15,
    );
    console.log('startOfMessagesToKeep: ' + startOfMessagesToKeep);

    if (startOfMessagesToKeep !== -1) {
      setMessages(messagesUpdatedCopy.slice(startOfMessagesToKeep));
      messagesRef.current = messagesUpdatedCopy.slice(startOfMessagesToKeep);
    } else {
      setMessages(messagesUpdatedCopy);
      messagesRef.current = messagesUpdatedCopy;
    }
  } else {
    setMessages(messagesUpdatedCopy);
    messagesRef.current = messagesUpdatedCopy;
  }

  console.log(messagesMergedUpdated);
  console.log(
    'messagesMergedUpdated: ' + JSON.stringify(messagesMergedUpdated),
  );
  setMessagesMerged(messagesMergedUpdated);
};
