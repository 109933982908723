import React from 'react';
import { motion } from 'framer-motion';

export const MobileAISummary = ({
  onSummaryContainerClick,
  summarySvg,
  isShowingSummary,
  backgroundColor,
  handleSpeakerSelect,
  isLoadingTTS,
  isPlaying,
  pauseAudio,
}) => {
  return (
    <motion.div
      className={`rounded-2xl ${backgroundColor} flex justify-between py-3 px-4 items-center cursor-pointer shadow-md transition-all duration-300`}
      onClick={onSummaryContainerClick}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <div className='flex items-center gap-3'>
        <motion.img
          className='w-6 h-6 relative'
          loading='lazy'
          alt=''
          src={summarySvg}
          whileHover={{ rotate: 15 }}
        />
        <div
          className={`text-sm ${
            isShowingSummary ? 'text-white' : 'text-updated-color-blue'
          } font-medium`}
        >
          {isShowingSummary ? 'Viewing AI Summary' : 'Generate AI Summary'}
        </div>
      </div>
      <motion.div
        className={`rounded-full flex items-center justify-center p-2 ${
          isShowingSummary
            ? 'bg-white/30 text-white'
            : 'bg-blue-100 text-blue-500'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          handleSpeakerSelect(e);
        }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {isLoadingTTS ? (
          <div className='w-5 h-5 animate-spin rounded-full border-2 border-white border-t-transparent' />
        ) : isPlaying ? (
          <motion.img
            className='h-5 w-5 relative overflow-hidden shrink-0'
            loading='lazy'
            alt='Pause'
            src='/pause.svg'
            onClick={(e) => {
              e.stopPropagation();
              pauseAudio();
            }}
          />
        ) : (
          <motion.img
            className='h-5 w-5 relative overflow-hidden shrink-0'
            loading='lazy'
            alt='Play'
            src='/play-speaker.svg'
            onClick={(e) => {
              e.stopPropagation();
              handleSpeakerSelect(e);
            }}
          />
        )}
      </motion.div>
    </motion.div>
  );
};
