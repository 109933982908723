import { Link } from 'react-router-dom';
import SEOScript from './SEOScript';
import { useState } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { FeatureCarousel } from '../../components/FeatureCarousel';
import { motion } from 'framer-motion';

const Homepage = () => {
  // check the url query parameter utm_source
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');
  if (utmSource) {
    localStorage.setItem('signup_source', utmSource);
  }

  const features = [
    {
      imgSrc: '/camera-reels-fill.svg',
      title: 'Zoom Integration',
    },
    {
      imgSrc: '/mic.png',
      title: 'Real-Time Transcription',
    },
    {
      imgSrc: '/people-fill.svg',
      title: 'Broadcast Room',
    },
    {
      imgSrc: '/live.png',
      title: 'AI live outline',
    },
    {
      imgSrc: '/check.png',
      title: 'File Transcription &\n Translation',
    },
    {
      imgSrc: '/ima.png',
      title: 'Image Translation',
    },
    {
      imgSrc: '/doc.png',
      title: 'Document Management',
    },
  ];

  const text = 'OUR MISSION';
  const wot = 'created by BlueJay AI';

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Array of words to cycle through
  const words = [
    'interpret',
    'Interpretar',
    'Interpréter',
    'Interpretieren',
    'Interpretare',
    'Interpreteren',
    'Интерпретировать',
    '解释',
    '解釈する',
  ];

  const handleWordClick = () => {
    setCurrentWordIndex((prevIndex) =>
      prevIndex === words.length - 1 ? 0 : prevIndex + 1,
    );
  };

  return (
    <div className='bg-white min-h-screen text-5xl text-black font-montserrat flex flex-col justify-center items-center gap-12 text-not-black border-x-0'>
      <SEOScript />
      <div className='pt-32 text-center flex flex-col gap-4 items-center bg-[#F8F9FF] justify-center relative w-full pb-20 border-solid border-[#DADADA] border-b border-t-0'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className='text-4xl font-black font-bold font-poppins leading-tight'
        >
          Every conversation,
          <br />
          <span className='text-gray-500'>Perfectly captured</span>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.9 }}
          className='leading-relaxed pt-5 font-medium text-lg px-10'
        >
          Elevate your communication with real-time transcription,
          <br /> Experience unmatched accuracy and clarity.
        </motion.div>
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: false }}
        >
          <Link
            to='/signup'
            className='flex w-52 mx-auto font-semibold text-base text-[#ffffff] bg-[#007EF3] rounded-xl p-2 py-5 justify-center hover:bg-[#2d65c0] transition-all duration-500 gap-2 items-center no-underline'
          >
            <img
              className='h-6 sm:h-6 w-6 sm:w-6'
              alt='Rocket'
              src='/rocket.png'
            />
            Get Started
          </Link>
        </motion.div>
        <div className='relative w-full mt-4'>
          <motion.img
            src='/circlestar.png'
            alt='Top decoration'
            className='absolute top-8 h-80 left-0 mx-auto right-0 z-0'
            initial={{ rotate: -20 }}
            animate={{ rotate: 0 }}
            transition={{
              duration: 1.5,
              ease: 'easeOut',
            }}
          />
          <img
            src='/phone.png'
            alt='Right decoration'
            className='h-96 relative z-20 ml-6'
          />
        </div>

        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: false }}
        >
          <div className='flex justify-center items-center gap-4'>
            <img
              src='/googleI.png'
              alt='Left image'
              className='h-[3rem] object-cover'
            />
            <img
              src='/download-apple.svg'
              alt='Right image'
              className='h-[3rem] object-cover'
              onClick={() => {
                window.open(
                  'https://apps.apple.com/us/app/interpret-ai/id6738939746',
                );
              }}
            />
          </div>
        </motion.div>
      </div>
      <FeatureCarousel features={features} />
      <div className='text-left flex flex-col gap-6 px-8'>
        <div className='text-lg text-updated-color-grey2 font-medium'>
          {text.split('').map((char, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: false }}
              transition={{
                duration: 0.1,
                delay: index * 0.1,
              }}
            >
              {char}
            </motion.span>
          ))}
        </div>
        <div className='text-3xl font-semibold'>
          Interpret AI helps individual and organizations <br /> break down
          communication barriers.
          <br /> <br />
          <span className='text-dodgerblue-200'>
            Our platform{' '}
            <motion.span
              initial={{ backgroundSize: '0% 100%' }}
              whileInView={{ backgroundSize: '100% 100%' }}
              transition={{ duration: 1.2 }}
              style={{
                background:
                  'linear-gradient(to right, rgba(0, 204, 255, 0.27) 0%, rgba(0, 204, 255, 0.27) 100%)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '0 100%',
              }}
            >
              empowers
            </motion.span>{' '}
            teams to capture, translate,
          </span>
          <br />
          <span className='text-dodgerblue-200'>
            and optimize their conversations in real time.
          </span>
        </div>
      </div>

      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div
          style={{
            borderBottom: '0.2px solid rgba(0, 0, 0, 0.2)',
            borderTop: '0.2px solid rgba(0, 0, 0, 0.2)',
          }}
          className='flex flex-col items-center justify-center gap-10 py-10 w-full'
        >
          <div className='flex gap-4 items-center justify-center'>
            <img
              src='/zoom.png'
              alt='Zoom'
              className='w-42 hover:border-solid hover:border-[#007EF3] hover:rounded-lg hover:p-5 transition-all duration-300'
            />
            <img
              src='/slack.png'
              alt='Slack'
              className='w-42 hover:border-solid hover:border-[#2db67d] hover:rounded-lg hover:p-5 transition-all duration-300'
            />
          </div>
          <div className='flex gap-4 items-center justify-center'>
            <img
              src='/gmeet.png'
              alt='Google Meet'
              className='w-44 hover:border-solid hover:border-[#ea4336] hover:rounded-lg hover:p-5 transition-all duration-300'
            />
            <img
              src='/teams.png'
              alt='Teams'
              className='w-44 hover:border-solid hover:border-[#7a83eb] hover:rounded-lg hover:p-5 transition-all duration-300'
            />
          </div>
        </div>
      </motion.div>
      <a
        href='https://theresanaiforthat.com/ai/interpret-ai/?ref=featured&v=3528940'
        target='_blank'
        rel='nofollow'
      >
        <img
          width='300'
          src='https://media.theresanaiforthat.com/featured-on-taaft.png?width=600'
        />
      </a>
      <div className='flex flex-col items-center justify-center'>
        <div className='text-4xl font-bold w-fit flex gap-2 items-center flex-col'>
          Introducing,
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1.5 }}
            viewport={{ once: false }}
          >
            <div className='font-bold text-dodgerblue-200 flex gap-4 items-center w-full text-[3rem]'>
              <span
                onClick={handleWordClick}
                className='cursor-pointer transition-all duration-300 hover:opacity-80'
              >
                {words[currentWordIndex]}
              </span>

              <img
                src='/logo.svg'
                alt='Logo'
                className='w-14 mb-4 hover:border-solid hover:border-[#007EF3] hover:rounded-lg hover:p-3 transition-all duration-300'
              />
            </div>
          </motion.div>
        </div>

        <div className='text-3xl font-semibold items-center text-center text-updated-color-grey2'>
          {wot.split('').map((char, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.1,
                delay: index * 0.1,
              }}
            >
              {char}
            </motion.span>
          ))}
        </div>
        <div className='w-full text-lg font-medium leading-tight text-center mt-4'>
          Enabling individuals facing language barriers to enjoy seamless
          conversations with an AI-powered transcription tool.
        </div>
      </div>
      <div
        style={{ backgroundImage: 'url(./stripes.png)' }}
        className='rounded-lg shadow bg-white w-11/12 text-center flex flex-col justify-center items-center gap-16 py-10 pb-10 bg-no-repeat bg-cover'
      >
        <motion.div
          initial={{ scale: 0.6, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.9 }}
          viewport={{ once: true }}
        >
          <div className='flex flex-col text-left gap-4 bg-[#DBDAF8] rounded-lg p-6'>
            <div className='text-lg'>REAL-TIME SPEECH TRANSCRIBING</div>
            <div className='font-semibold text-xl'>
              Capture and understand every conversation with precise, real-time
              transcription
            </div>
            <img src='/bottompic.png' alt='Example' className='w-2/3' />
          </div>
        </motion.div>
        <motion.div
          initial={{ scale: 0.6, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.9 }}
          viewport={{ once: true }}
        >
          <div className='flex flex-col text-left border border-solid border-[#DADADA] gap-4 bg-[#D6EBFF] rounded-lg p-6'>
            <div className='text-lg'>MEETING ROOM ADD-ON</div>
            <div className='font-semibold text-xl'>
              Receive live subtitles for all your meetings, making it easier to
              follow along and stay engaged
            </div>
            <img src='/link.png' alt='link' className='w-full' />
          </div>
        </motion.div>

        <motion.div
          initial={{ scale: 0.6, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.9 }}
          viewport={{ once: true }}
        >
          <div className='rounded-lg p-6 flex border border-solid border-[#DADADA] bg-white flex-col text-left gap-4'>
            <div className='text-lg'>LIVE BROADCAST ROOM SHARING</div>
            <div className='font-semibold text-xl'>
              Join live events remotely and read the speech in real-time,
              translated into your preferred language
            </div>
            <img src='/webglobe.png' alt='globe' className='w-full' />
            <img src='/mobilepins.svg' alt='Pins' className='w-full' />
          </div>
        </motion.div>

        <motion.div
          initial={{ scale: 0.6, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.9 }}
          viewport={{ once: true }}
        >
          <div className='flex flex-col gap-4 h-full bg-[#EFEFEF] text-left rounded-lg p-6'>
            <div className='text-lg'>AUDIO TRANSCRIPTION & TRANSLATION</div>
            <div className='font-semibold text-xl'>
              Capture speech instantly and translate it seamlessly into your
              language of choice — quick, precise, and effortless communication
              at your fingertips
            </div>
            <img src='/docs.svg' alt='Docs' className='w-full mt-2' />
          </div>
        </motion.div>
        <motion.div
          initial={{ scale: 0.6, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.9 }}
          viewport={{ once: true }}
        >
          <div className='flex flex-col gap-4 text-left bg-[#bad9b0] rounded-lg p-6'>
            <div className='text-lg'>EASY DOCUMENT MANAGEMENT</div>
            <div className='font-semibold text-xl'>
              Store, organize, and access all your transcribed and translated
              documents in one convenient location
            </div>
            <img
              src='/mobilepics.svg'
              alt='mobile pics'
              className='w-full mt-4'
            />
          </div>
        </motion.div>
      </div>

      <div className='flex items-center bg-[#F8F9FF] justify-center w-full py-20 border-solid border-[#DADADA] border-t border-b-0 border-x-0'>
        <div className='bg-white border border-solid border-[#DADADA] w-full p-6 mx-6 rounded-lg flex flex-col gap-6 justify-center'>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            <div className='text-4xl font-poppins font-bold'>Contact Us!</div>
          </motion.div>
          <div className='text-base font-medium leading-relaxed mt-2'>
            Got questions for us? Our team is here to help. Contact us for quick
            and friendly support.
          </div>
          <div className='flex items-center gap-3 mt-2'>
            <motion.img
              src='/contactcall.svg'
              className='w-7'
              animate={{
                rotate: [0, -10, 10, -10, 10, 0],
              }}
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatDelay: 2,
              }}
            />
            <a
              href='tel:+17144024029'
              className='text-lg font-medium no-underline visited:text-not-black'
            >
              +1 714 402 4029
            </a>
          </div>
          <div className='flex items-center gap-3'>
            <motion.div
              animate={{ y: [-3, 0] }}
              transition={{
                duration: 0.3,
                repeat: Infinity,
                repeatDelay: 1.5,
              }}
            >
              <MailOutlined className='w-fit text-updated-color-blue' />
            </motion.div>
            <a
              href='mailto:support@interpretapp.ai'
              className='text-lg font-medium no-underline'
            >
              support@interpretapp.ai
            </a>
          </div>
          <div className='text-2xl font-poppins font-semibold mt-2'>
            Connect with us
          </div>

          <div className='flex items-center gap-2'>
            <motion.div
              initial={{ scale: 0.4, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: false }}
            >
              <div className='flex justify-center items-center bg-[#D6EBFF] rounded-full'>
                <a href='https://www.linkedin.com/company/interpretapp/'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    className='p-2 pb-0'
                  >
                    <path
                      fill='black'
                      d='M20.47 2H3.53a1.45 1.45 0 0 0-1.47 1.43v17.14A1.45 1.45 0 0 0 3.53 22h16.94a1.45 1.45 0 0 0 1.47-1.43V3.43A1.45 1.45 0 0 0 20.47 2M8.09 18.74h-3v-9h3ZM6.59 8.48a1.56 1.56 0 1 1 0-3.12a1.57 1.57 0 1 1 0 3.12m12.32 10.26h-3v-4.83c0-1.21-.43-2-1.52-2A1.65 1.65 0 0 0 12.85 13a2 2 0 0 0-.1.73v5h-3v-9h3V11a3 3 0 0 1 2.71-1.5c2 0 3.45 1.29 3.45 4.06Z'
                    />
                  </svg>
                </a>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0.4, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1.3 }}
              viewport={{ once: false }}
            >
              <div className='flex justify-center items-center bg-[#D6EBFF] rounded-full'>
                <a href='https://www.instagram.com/interpretapp.ai/'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    className='p-2 pb-0'
                  >
                    <path
                      fill='black'
                      d='M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3'
                    />
                  </svg>
                </a>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0.4, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1.6 }}
              viewport={{ once: false }}
            >
              <div className='flex justify-center items-center bg-[#D6EBFF] rounded-full'>
                <a href='https://x.com/InterpretAppX'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='-2 -1 20 20'
                    className='p-2 pb-0'
                  >
                    <path
                      fill='black'
                      d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07l-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z'
                    />
                  </svg>
                </a>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0.4, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1.9 }}
              viewport={{ once: false }}
            >
              <div className='flex justify-center items-center bg-[#D6EBFF] rounded-full'>
                <a href='https://discord.gg/wqCfM6mjPS'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    className='p-2 pb-0'
                  >
                    <path
                      fill='black'
                      d='M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.1.1 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.1 16.1 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02M8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12m6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12'
                    />
                  </svg>
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
