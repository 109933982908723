import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useHapticFeedback } from '../mobile/AccessibleFeedback';

export const Pagination = ({ 
  currentPage, 
  totalPages, 
  onPageChange,
  className = '',
  isMobile = false
}) => {
  if (totalPages <= 1) return null;
  
  const { triggerHapticFeedback } = useHapticFeedback();
  
  // Generate page numbers to display
  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = isMobile ? 5 : 7; // Show fewer pages on mobile
    
    // Always include first page
    pages.push(1);
    
    // Calculate range around current page
    const rangeSize = isMobile ? 1 : 2; // Smaller range on mobile
    let rangeStart = Math.max(2, currentPage - rangeSize);
    let rangeEnd = Math.min(totalPages - 1, currentPage + rangeSize);
    
    // Adjust range to show consistent number of pages
    if (rangeEnd - rangeStart < 2 * rangeSize && totalPages > maxVisiblePages - 1) {
      if (rangeStart === 2) {
        rangeEnd = Math.min(maxVisiblePages - 2, totalPages - 1);
      } else if (rangeEnd === totalPages - 1) {
        rangeStart = Math.max(2, totalPages - (maxVisiblePages - 2));
      }
    }
    
    // Add ellipsis before range if needed
    if (rangeStart > 2) {
      pages.push('...');
    }
    
    // Add range pages
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(i);
    }
    
    // Add ellipsis after range if needed
    if (rangeEnd < totalPages - 1) {
      pages.push('...');
    }
    
    // Always include last page if > 1
    if (totalPages > 1) {
      pages.push(totalPages);
    }
    
    return pages;
  };

  const pageNumbers = getPageNumbers();
  
  // Animation variants
  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.05 },
    tap: { scale: 0.95 }
  };
  
  const containerStyles = `flex items-center justify-center ${className}`;
  const buttonBaseStyles = isMobile 
    ? "w-8 h-8 mx-1 flex items-center justify-center rounded-md text-sm"
    : "w-9 h-9 mx-1 flex items-center justify-center rounded-md"; 
    
  const activeButtonStyles = "bg-blue-500 text-white font-medium";
  const inactiveButtonStyles = "text-gray-700 hover:bg-gray-100";
  const disabledButtonStyles = "text-gray-400 cursor-not-allowed";

  const handleClick = (page) => {
    if (page < 1 || page > totalPages || page === currentPage) return;
    triggerHapticFeedback('click');
    onPageChange(page);
  };

  return (
    <nav 
      className={containerStyles}
      aria-label="Pagination"
      role="navigation"
    >
      {/* Previous page button */}
      <motion.button
        className={`${buttonBaseStyles} ${
          currentPage === 1 ? disabledButtonStyles : inactiveButtonStyles
        }`}
        onClick={() => currentPage > 1 && handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        variants={buttonVariants}
        initial="rest"
        whileHover={currentPage > 1 ? "hover" : "rest"}
        whileTap={currentPage > 1 ? "tap" : "rest"}
        aria-label="Previous page"
      >
        <LeftOutlined aria-hidden="true" />
      </motion.button>
      
      {/* Page numbers */}
      <div className="flex items-center" role="list">
        {pageNumbers.map((page, index) => (
          page === '...' ? (
            <span 
              key={`ellipsis-${index}`} 
              className="mx-1 text-gray-500" 
              aria-hidden="true"
            >
              ...
            </span>
          ) : (
            <motion.button
              key={`page-${page}`}
              className={`${buttonBaseStyles} ${
                currentPage === page 
                  ? activeButtonStyles
                  : inactiveButtonStyles
              }`}
              onClick={() => handleClick(page)}
              variants={buttonVariants}
              initial="rest"
              whileHover="hover"
              whileTap="tap"
              transition={{ duration: 0.2 }}
              aria-label={`Page ${page}`}
              aria-current={currentPage === page ? 'page' : undefined}
              role="listitem"
            >
              {page}
            </motion.button>
          )
        ))}
      </div>
      
      {/* Next page button */}
      <motion.button
        className={`${buttonBaseStyles} ${
          currentPage === totalPages ? disabledButtonStyles : inactiveButtonStyles
        }`}
        onClick={() => currentPage < totalPages && handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        variants={buttonVariants}
        initial="rest"
        whileHover={currentPage < totalPages ? "hover" : "rest"}
        whileTap={currentPage < totalPages ? "tap" : "rest"}
        aria-label="Next page"
      >
        <RightOutlined aria-hidden="true" />
      </motion.button>
    </nav>
  );
};
