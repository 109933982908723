// src/components/popups/MoveFilePopup.jsx
import React from 'react';
import { BasePopup } from './BasePopup';
import { FolderOutlined } from '@ant-design/icons';
import { Button } from '../common/Button';

export const MoveFilePopup = ({ folders, currentFolderId, onMove, onClose }) => {
  const filteredFolders = folders.filter(folder => folder.id !== currentFolderId);
  
  const handleClose = (e) => {
    if (e) e.stopPropagation();
    onClose();
  };

  if (filteredFolders.length === 0) {
    return (
      <BasePopup title="Move File" onClose={handleClose}>
        <p className="text-gray-600">No other folders available to move to.</p>
        <div className="flex justify-end mt-4">
          <Button variant="ghost" onClick={handleClose}>
            Close
          </Button>
        </div>
      </BasePopup>
    );
  }
  
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <BasePopup title="Move File To" onClose={handleClose}>
        <div className="max-h-60 overflow-y-auto py-2">
          {filteredFolders.map((folder) => (
            <div 
              key={folder.id}
              className="flex items-center gap-2 p-2 hover:bg-sky-100 cursor-pointer rounded-md transition-colors"
              onClick={() => onMove(folder.id)}
            >
              <FolderOutlined className="text-blue-500" />
              <div className="truncate">{folder.name}</div>
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </BasePopup>
    </div>
  );
};