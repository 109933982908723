// src/components/popups/RenameFolderPopup.jsx
import React from 'react';
import { PopupForm } from './PopupForm';

export const RenameFolderPopup = ({ name, onSubmit, onClose }) => {
  return (
    <PopupForm
      title="Rename Folder"
      initialValue={name}
      onSubmit={onSubmit}
      onClose={onClose}
      label="Folder Name"
      submitText="Rename"
      placeholder="Enter new folder name..."
    />
  );
};