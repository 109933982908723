import { useState, useRef, useEffect, useCallback } from 'react';
import { handleInputLanguageClick, handleOutputLanguageClick } from '../../../../helpers/handleLanguageSelect';

/**
 * Custom hook to manage language selection and translation
 * 
 * @param {Array} messages - Current messages
 * @param {Object} messagesRef - Reference to messages for async operations
 * @param {Function} setMessages - Function to update messages
 * @param {Function} setMessagesMerged - Function to update merged messages
 * @returns {Object} Language selection state and functions
 */
const useLanguageSelection = (messages, messagesRef, setMessages, setMessagesMerged) => {
  // Language state
  const [inputLanguageDisplay, setInputLanguageDisplay] = useState('Detect Language');
  const [outputLanguageDisplay, setOutputLanguageDisplay] = useState('Original');
  const inputLanguage = useRef('Original');
  const outputLanguage = useRef('Original');
  
  // Loading state
  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false);
  const [isLoadingOutputTranslation, setIsLoadingOutputTranslation] = useState(false);
  
  // Dropdown visibility state
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [languageTwoDropdownVisible, setLanguageTwoDropdownVisible] = useState(false);
  
  // Search input state
  const [searchBarInput, setSearchBarInput] = useState(null);
  const [searchBarTwoInput, setSearchBarTwoInput] = useState(null);
  
  // Element refs
  const inputLanguageRef = useRef(null);
  const outputLanguageRef = useRef(null);
  const inputLanguageDropdownRef = useRef(null);
  const outputLanguageDropdownRef = useRef(null);
  
  // Track popup state
  const usingInputLanguagePopupRef = useRef(false);
  const usingOutputLanguagePopupRef = useRef(false);
  
  // Handle input language selection
  const handleInputLanguageSelect = useCallback((language) => {
    handleInputLanguageClick(
      language,
      inputLanguage,
      setInputLanguageDisplay,
      setLanguageDropdownVisible,
      setIsLoadingTranslation,
      messages,
      messagesRef,
      setMessages,
      setMessagesMerged
    );
  }, [messages, messagesRef, setMessages, setMessagesMerged]);
  
  // Handle output language selection
  const handleOutputLanguageSelect = useCallback((language) => {
    handleOutputLanguageClick(
      language,
      outputLanguage,
      setOutputLanguageDisplay,
      setLanguageTwoDropdownVisible,
      setIsLoadingOutputTranslation,
      messages,
      messagesRef,
      setMessages,
      setMessagesMerged
    );
  }, [messages, messagesRef, setMessages, setMessagesMerged]);
  
  // Toggle input language dropdown
  const toggleInputLanguageDropdown = useCallback(() => {
    setLanguageDropdownVisible(prev => !prev);
    usingInputLanguagePopupRef.current = !usingInputLanguagePopupRef.current;
    
    // Close the other dropdown if open
    if (languageTwoDropdownVisible) {
      setLanguageTwoDropdownVisible(false);
      usingOutputLanguagePopupRef.current = false;
    }
  }, [languageTwoDropdownVisible]);
  
  // Toggle output language dropdown
  const toggleOutputLanguageDropdown = useCallback(() => {
    setLanguageTwoDropdownVisible(prev => !prev);
    usingOutputLanguagePopupRef.current = !usingOutputLanguagePopupRef.current;
    
    // Close the other dropdown if open
    if (languageDropdownVisible) {
      setLanguageDropdownVisible(false);
      usingInputLanguagePopupRef.current = false;
    }
  }, [languageDropdownVisible]);
  
  // Switch input and output languages
  const switchLanguages = useCallback(() => {
    const tempLanguage = inputLanguage.current;
    const tempDisplay = inputLanguageDisplay;
    
    // Only switch if output is not "Original"
    if (outputLanguageDisplay === "Original") {
      return;
    }
    
    inputLanguage.current = outputLanguage.current;
    outputLanguage.current = tempLanguage;
    
    setInputLanguageDisplay(outputLanguageDisplay);
    setOutputLanguageDisplay(tempDisplay);
    
    // Trigger retranslation if needed
    if (messages.length > 0) {
      // The helper functions need to be called here to properly translate 
      // after the language switch
      handleInputLanguageClick(
        outputLanguageDisplay,
        inputLanguage,
        setInputLanguageDisplay,
        setLanguageDropdownVisible,
        setIsLoadingTranslation,
        messages,
        messagesRef,
        setMessages,
        setMessagesMerged
      );
    }
  }, [inputLanguageDisplay, outputLanguageDisplay, messages, messagesRef, setMessages, setMessagesMerged]);
  
  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
      
      if (usingOutputLanguagePopupRef.current) {
        if (
          outputLanguageRef.current &&
          !outputLanguageRef.current.contains(event.target)
        ) {
          if (
            outputLanguageDropdownRef.current &&
            !outputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingOutputLanguagePopupRef.current = false;
            setLanguageTwoDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return {
    inputLanguage,
    outputLanguage,
    inputLanguageDisplay,
    setInputLanguageDisplay,
    outputLanguageDisplay, 
    setOutputLanguageDisplay,
    isLoadingTranslation,
    setIsLoadingTranslation,
    isLoadingOutputTranslation,
    setIsLoadingOutputTranslation,
    languageDropdownVisible,
    setLanguageDropdownVisible,
    languageTwoDropdownVisible,
    setLanguageTwoDropdownVisible,
    searchBarInput,
    setSearchBarInput,
    searchBarTwoInput,
    setSearchBarTwoInput,
    inputLanguageRef,
    outputLanguageRef,
    inputLanguageDropdownRef,
    outputLanguageDropdownRef,
    usingInputLanguagePopupRef,
    usingOutputLanguagePopupRef,
    handleInputLanguageSelect,
    handleOutputLanguageSelect,
    toggleInputLanguageDropdown,
    toggleOutputLanguageDropdown,
    switchLanguages
  };
};

export default useLanguageSelection;