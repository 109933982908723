export const handleAddSection = async (
  title,
  instructions,
  position,
  currentSectionTitle,
  chatbotRef,
  outlineContentRef,
  deletedSectionsRef,
  setOutlineContent,
  setIsUpdatingOutline,
) => {
  if (chatbotRef.current) {
    setIsUpdatingOutline(true);
    const updatedContent = await chatbotRef.current.addSection(
      title,
      instructions,
      position,
      currentSectionTitle,
    );

    // Update content states and refs
    setOutlineContent(updatedContent);
    outlineContentRef.current = updatedContent;
    chatbotRef.current.setOutlineContent(updatedContent);

    // Clean deleted sections
    const cleanedSections = updatedContent
      ?.split('##')
      .filter((section, index) => {
        if (index === 0) return true; // Keep title section
        const sectionTitle = section.split('\n')[0].trim();
        return !deletedSectionsRef.current.has(sectionTitle);
      });

    const finalContent =
      cleanedSections &&
      cleanedSections[0] +
        cleanedSections
          .slice(1)
          .map((s) => '##' + s)
          .join('');

    setOutlineContent(finalContent);
    outlineContentRef.current = finalContent;
    chatbotRef.current.setOutlineContent(finalContent);
  }
};
