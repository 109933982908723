import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { isToday, isYesterday, subDays, parseISO } from 'date-fns';
import { FileActionButtons } from '../../components/files/FileActionButtons';
import PortalPopup from '../../components/mobile/PortalPopup';
import { RenameFilePopup } from '../../components/popups/RenameFilePopup';
import { MoveFilePopup } from '../../components/popups/MoveFilePopup';

const groupTranscriptsByDate = (transcripts) => {
  const grouped = {
    today: [],
    yesterday: [],
    lastWeek: [],
    previous30Days: [],
  };

  transcripts.forEach((transcript) => {
    const transcriptDate = parseISO(transcript.created_at);
    if (isToday(transcriptDate)) {
      grouped.today.push(transcript);
    } else if (isYesterday(transcriptDate)) {
      grouped.yesterday.push(transcript);
    } else if (transcriptDate > subDays(new Date(), 7)) {
      grouped.lastWeek.push(transcript);
    } else {
      grouped.previous30Days.push(transcript);
    }
  });

  return grouped;
};

const RecentsSidebar = () => {
  const navigate = useNavigate();
  const [recentFiles, setRecentFiles] = useState([]);
  const [isRenameOpen, setRenameOpen] = useState(false);
  const [isMoveOpen, setMoveOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const searchTimeoutRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const isFetchingRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const PAGE_SIZE = 10;
  const [folders, setFolders] = useState([]);
  const [numFolders, setNumFolders] = useState(4);
  const [fileError, setFileError] = useState(null);

  const fetchFiles = async (query = '', page = 1) => {
    try {
      setIsLoading(true);
      await jwtTokenRef.current;
      const response = await axios.get(`${serverURL}/list_transcripts`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
        params: {
          folder_id: null,
          page_size: PAGE_SIZE,
          page_number: page,
          get_raw_transcript: false,
          text_search: query,
        },
      });

      const data = response.data.transcripts;
      const retrievedFiles = data.filter((file) => file.transcript !== null);
      const totalCount = response.data.total_count || retrievedFiles.length;
      setTotalPages(Math.ceil(totalCount / PAGE_SIZE));
      setRecentFiles(retrievedFiles);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch folders
  const refreshFolders = useCallback(async () => {
    try {
      const response = await axios.get(`${serverURL}/list_folders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });

      let retrievedFolders = response.data.folders.map((folder) => ({
        name: folder.name,
        id: folder.id,
        path: folder.path,
      }));

      if (numFolders) {
        setFolders(retrievedFolders.slice(0, numFolders));
      } else {
        setFolders(retrievedFolders);
      }
    } catch (error) {
      console.error('Error fetching folders:', error);
      setFileError('Failed to load folders');
    }
  }, [numFolders]);

  useEffect(() => {
    fetchFiles();
    refreshFolders();
  }, [refreshFolders]);

  const handlePageChange = (page) => {
    if (isFetchingRef.current || page === currentPage) return;

    isFetchingRef.current = true;
    fetchFiles(searchQuery, page).finally(() => {
      isFetchingRef.current = false;
    });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      fetchFiles(value, 1);
    }, 500);
  };

  const togglePopup = (setter) => () => setter((prev) => !prev);

  const handleRename = (file) => {
    setCurrentFile(file);
    setRenameOpen(true);
  };

  const handleMoveFileClick = (file) => {
    setCurrentFile(file);
    setMoveOpen(true);
  };

  const handleDelete = async (fileToRemove) => {
    try {
      await fetch(
        `${serverURL}/delete_transcript?transcript_id=${fileToRemove.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      setRecentFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileToRemove.id),
      );
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleMove = async (fileId, folderId) => {
    try {
      await fetch(
        `${serverURL}/move_transcript?transcript_id=${fileId}&folder_id=${folderId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      setMoveOpen(false);

      // Update the file in the current view with its new folder_id
      // instead of removing it from the recents view
      setRecentFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId ? { ...file, folder_id: folderId } : file,
        ),
      );

      // Optionally show a success message
      console.log('File moved successfully');
    } catch (error) {
      console.error('Error moving file:', error);
      // Optionally show an error message
    }
  };

  const handleFileRename = async (newName) => {
    try {
      await fetch(
        `${serverURL}/rename_transcript?transcript_id=${currentFile.id}&new_name=${newName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      setRenameOpen(false);
      setRecentFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === currentFile.id ? { ...file, name: newName } : file,
        ),
      );
    } catch (error) {
      console.error('Error renaming file:', error);
    }
  };

  const groupedFiles = groupTranscriptsByDate(recentFiles);

  // Generate pagination numbers
  const renderPaginationNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;

    // Always show first page
    pages.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={`w-8 h-8 rounded-md flex items-center justify-center transition-colors duration-200 ${
          currentPage === 1
            ? 'bg-blue-500 text-white'
            : 'bg-white text-gray-700 hover:bg-blue-100'
        }`}
      >
        1
      </button>,
    );

    // Calculate range of pages to show
    let startPage = Math.max(2, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 3);

    // Adjust if we're near the beginning
    if (startPage > 2) {
      pages.push(
        <span key='ellipsis1' className='px-1'>
          ...
        </span>,
      );
    }

    // Add middle pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`w-8 h-8 rounded-md flex items-center justify-center transition-colors duration-200 ${
            currentPage === i
              ? 'bg-blue-500 text-white'
              : 'bg-white text-gray-700 hover:bg-blue-100'
          }`}
        >
          {i}
        </button>,
      );
    }

    // Add ellipsis if needed
    if (endPage < totalPages - 1) {
      pages.push(
        <span key='ellipsis2' className='px-1'>
          ...
        </span>,
      );
    }

    // Always show last page if there is more than one page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`w-8 h-8 rounded-md flex items-center justify-center transition-colors duration-200 ${
            currentPage === totalPages
              ? 'bg-blue-500 text-white'
              : 'bg-white text-gray-700 hover:bg-blue-100'
          }`}
        >
          {totalPages}
        </button>,
      );
    }

    return pages;
  };

  return (
    <div className='flex flex-col rounded-xl bg-white border border-gray-200 shadow-lg h-full overflow-hidden transition-all duration-300 hover:shadow-xl'>
      <div className='flex flex-col px-5 py-3 border-b border-gray-200 bg-gradient-to-r from-blue-50 to-indigo-100'>
        <h2 className='text-xl font-semibold text-gray-800 mb-3 animate-fadeIn'>
          Recent Files
        </h2>

        {/* Search bar with improved styling and animation */}
        <div className='relative w-full animate-slideInFromTop'>
          <input
            type='text'
            placeholder='Search files...'
            className='rounded-lg bg-white border border-gray-300 py-2 px-4 pl-10 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 hover:shadow-sm'
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <img
            className='absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 transition-all duration-200'
            alt='Search'
            src='/general--search.svg'
          />
        </div>
      </div>

      <div className='overflow-y-auto flex-1 p-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent bg-gradient-to-b from-gray-50 to-white'>
        {isLoading ? (
          <div className='flex flex-col items-center justify-center h-full py-16'>
            <div className='w-12 h-12 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin mb-4'></div>
            <p className='text-gray-600 font-medium'>Loading files...</p>
          </div>
        ) : (
          <>
            {Object.entries(groupedFiles).map(
              ([timePeriod, files], index) =>
                files.length > 0 && (
                  <div
                    key={timePeriod}
                    className='mb-6 animate-fadeIn'
                    style={{ animationDelay: `${index * 100}ms` }}
                  >
                    <h3 className='text-xs uppercase font-semibold text-indigo-600 px-2 mb-3 tracking-wider'>
                      {(timePeriod === 'lastWeek' && 'Last Week') ||
                        (timePeriod === 'previous30Days' &&
                          'Previous 30 Days') ||
                        timePeriod.charAt(0).toUpperCase() +
                          timePeriod.slice(1)}
                    </h3>
                    <div className='space-y-3'>
                      {files.map((file, fileIndex) => (
                        <div
                          key={file.id}
                          className='rounded-lg bg-white hover:bg-blue-50 transition-all duration-300 p-3 shadow hover:shadow-md border border-gray-200 hover:border-blue-300 transform hover:-translate-y-1 animate-slideInFromRight'
                          style={{
                            animationDelay: `${index * 100 + fileIndex * 50}ms`,
                          }}
                        >
                          <div className='flex items-center justify-between'>
                            <div
                              className='flex-grow cursor-pointer truncate pr-2 flex items-center group'
                              onClick={() =>
                                navigate(`/file_transcript?id=${file.id}`)
                              }
                            >
                              <span className='w-6 h-6 mr-3 flex-shrink-0 bg-gradient-to-br from-blue-100 to-indigo-100 rounded-full p-1 flex items-center justify-center transition-all duration-300 group-hover:from-blue-200 group-hover:to-indigo-200'>
                                <img
                                  src='/icons/audio-video-file.svg'
                                  alt=''
                                  className='w-4 h-4 transition-transform duration-300 group-hover:scale-110'
                                />
                              </span>
                              <span className='font-medium text-gray-800 group-hover:text-blue-600 truncate transition-colors duration-200'>
                                {file.name}
                              </span>
                            </div>
                            <FileActionButtons
                              file={file}
                              folders={folders} // Pass available folders here
                              onRename={handleRename}
                              onDelete={handleDelete}
                              onMove={handleMoveFileClick}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ),
            )}

            {totalPages > 1 && (
              <div className='flex justify-center items-center mt-6 mb-2 space-x-2'>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center w-8 h-8 rounded-md ${
                    currentPage === 1
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-gray-700 hover:bg-blue-100'
                  }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>

                {renderPaginationNumbers()}

                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`flex items-center justify-center w-8 h-8 rounded-md ${
                    currentPage === totalPages
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-gray-700 hover:bg-blue-100'
                  }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
            )}

            {recentFiles.length === 0 && !isLoading && (
              <div className='text-center py-16 flex flex-col items-center bg-white rounded-lg border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md animate-fadeIn'>
                <div className='w-16 h-16 mb-4 rounded-full bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center animate-pulse'>
                  <svg
                    className='w-8 h-8 text-indigo-400'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={1.5}
                      d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'
                    />
                  </svg>
                </div>
                <span
                  className='text-indigo-600 font-medium animate-fadeIn'
                  style={{ animationDelay: '200ms' }}
                >
                  No recent files found
                </span>
                <p
                  className='text-gray-400 text-sm mt-1 animate-fadeIn'
                  style={{ animationDelay: '400ms' }}
                >
                  Your recent transcripts will appear here
                </p>
              </div>
            )}
          </>
        )}
      </div>

      {isRenameOpen && (
        <PortalPopup
          overlayColor='rgba(0, 0, 0, 0.5)'
          placement='Centered'
          onOutsideClick={togglePopup(setRenameOpen)}
        >
          <RenameFilePopup
            onClose={togglePopup(setRenameOpen)}
            fileName={currentFile?.name || ''}
            onRename={handleFileRename}
          />
        </PortalPopup>
      )}

      {isMoveOpen && currentFile && (
        <PortalPopup
          overlayColor='rgba(0, 0, 0, 0.5)'
          placement='Centered'
          onOutsideClick={togglePopup(setMoveOpen)}
        >
          <MoveFilePopup
            folders={folders}
            currentFolderId={currentFile.folder_id}
            onMove={(folderId) => handleMove(currentFile.id, folderId)}
            onClose={togglePopup(setMoveOpen)}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default RecentsSidebar;
