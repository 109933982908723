// src/components/chatbot/ChatbotServices.js
import axios from 'axios';
import { jwtTokenRef, serverURL, translateTexts } from '../../httpContext';

// Function to translate messages and content
export const translateMessages = async (messages, setMessages, outlineContent, setOutlineContent, suggestedPrompts, setSuggestedPrompts, language) => {
  try {
    // Extract messages that need to be translated
    const textsToTranslate = messages
      .filter((message) => message.role !== 'user')
      .map((message) => message.content);

    // Translate messages
    const translatedTexts = await translateTexts(textsToTranslate, language);

    // Update the messages array with translated content
    const updatedMessages = messages.map((message) => {
      if (message.role !== 'user') {
        return { ...message, content: translatedTexts.shift() };
      }
      return message;
    });

    setMessages(updatedMessages);

    // Translate outline content if it exists
    if (outlineContent) {
      const [translatedOutline] = await translateTexts([outlineContent], language);
      setOutlineContent(translatedOutline);
    }

    // Translate suggested prompts
    if (suggestedPrompts.length) {
      const translatedPrompts = await translateTexts(suggestedPrompts, language);
      setSuggestedPrompts(translatedPrompts);
    }
  } catch (error) {
    console.error('Error translating messages:', error);
  }
};

// Get prompt template based on detail mode
export const getPromptTemplate = (isDetailed) => {
  if (isDetailed) {
    return `Format detailed notes using Markdown:
      
      You are an advanced note-taking assistant. When provided with a transcript of a conversation, lecture, or meeting, generate a comprehensive and detailed note summary. Begin with a clear title, then create sections using Markdown headers for each major topic. Use bullet points to list key ideas, include links to relevant resources or references using [text](url) syntax, and include extended explanations, contextual details, illustrative examples, and any relevant follow-up questions. Emphasize important terms in bold and incorporate tables or code blocks if applicable. Your response should be thorough and articulate, providing in-depth insights while maintaining clarity and structure.    
      
  If you think that it is necessary for the user to understand it visualy(like if the transcript is full of numerical data), then you can represent the data using these(You can use any of them, and you don't have to use all of them):

  - Pie charts for showing proportions:
  \`\`\`mermaid
  pie title "Distribution"
  "Category A" : 40
  "Category B" : 30
  "Category C" : 30
  \`\`\`

  - Bar graphs for comparing values:
  \`\`\`mermaid
  graph TD
  A[Sales] --> B[Q1: 200k]
  A --> C[Q2: 150k]
  A --> D[Q3: 300k]
  \`\`\`

  - Line charts for tracking trends:
  \`\`\`mermaid
  xychart-beta
  title "Sales Revenue"
  x-axis [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
  y-axis "Revenue (in $)" 4000 --> 11000
  bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
  line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
  \`\`\`

  - Vertical Bar Charts for comparing values:
  \`\`\`mermaid
  xychart-beta
  title "Sample Vertical Bar Chart"
  x-axis ["Category A", "Category B", "Category C", "Category D"]
  y-axis "Values" 0 --> 100
  bar [30, 80, 45, 60]
  \`\`\`
      
  Use rich Markdown formatting including:
      - Headers (# for title, ## for sections, ### for subsections)
      - Bold text (**text**) for key terms
      - Italic text (*text*) for emphasis
      - Links ([text](url)) for references
      - Bullet points for details
      - Tables for structured data
      - Code blocks for technical content`;
  }

  return `Format notes using Markdown:
    You are a digital note-taking assistant. When given a transcript from a conversation, lecture, or meeting, produce a succinct summary. Start with a clear title, then use bullet points to list only the most essential ideas and key terms (emphasizing them in bold). Your output should be as brief as possible, capturing the core messages without extra elaboration.

    Use rich Markdown formatting including:
    - Headers (# for title, ## for sections, ### for content)
    - Bold text (**text**)
    - Links ([text](url)) for references
    - Italic text (*text*)
    - Tables where appropriate based on the context
    - Code blocks if the content is about code or something related to it`;
};

// Function to send messages
export const sendMessage = async (content, messages, setMessages, input, setInput, contextRef, selectedLanguage, roomId, roomPassword) => {
  if (!content) return;

  const messagesCopy = JSON.parse(JSON.stringify(messages));

  // Add user message
  setMessages((prevMessages) => [
    ...prevMessages,
    { role: 'user', content },
  ]);

  messagesCopy.push({ role: 'user', content });

  if (contextRef.current) {
    messagesCopy.unshift({
      role: 'assistant',
      content: 'Transcript context: ' + contextRef.current,
    });
  }

  // System message with language preference
  messagesCopy.unshift({
    role: 'system',
    content: selectedLanguage === 'Select Language' 
      ? `You are Eva AI, an AI assistant for Interpret AI. Unless necessary, try to give more concise answers.`
      : `You are Eva AI, an AI assistant for Interpret AI. Unless necessary, try to give more concise answers. Respond in ${selectedLanguage}.`
  });

  setInput('');

  try {
    const response = await axios.post(
      `${serverURL}/call_chatbot`,
      {
        messages: messagesCopy,
        chatbot_request_type: 'chat',
        room_id: roomId,
        room_password: roomPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      },
    );

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'assistant', content: response.data },
    ]);
  } catch (error) {
    console.error('Error sending message:', error);
    // Add error message to chat
    setMessages((prevMessages) => [
      ...prevMessages,
      { 
        role: 'assistant', 
        content: 'Sorry, I encountered an error. Please try again later.' 
      },
    ]);
  }
};

// Function to update the outline
export const updateOutline = async (isDetailed, contextRef, selectedLanguage) => {
  if (!contextRef.current) return null;
  
  const currentContext = contextRef.current || '';
  
  // Create messages array with system prompt and content
  const messagesToSend = [
    {
      role: 'system',
      content: getPromptTemplate(isDetailed)
    },
    { role: 'user', content: currentContext }
  ];

  // Add language preference if selected
  if (selectedLanguage !== 'Select Language') {
    messagesToSend[0].content += `\n\nRespond in ${selectedLanguage}.`;
  }

  try {
    const response = await axios.post(
      `${serverURL}/call_chatbot`,
      {
        messages: messagesToSend,
        chatbot_request_type: 'outline',
      },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data.trim();
  } catch (error) {
    console.error('Error updating outline:', error);
    return null;
  }
};

// Function to update suggested prompts
export const updateSuggestedPrompts = async (
  isChatbotOpenRef,
  contextRef,
  lastContextRefSnapshotSuggestedPromptsRef,
  selectedLanguage,
  roomId,
  roomPassword
) => {
  if (!isChatbotOpenRef?.current) return null;
  
  const currentContext = contextRef.current || '';
  if (!currentContext.trim()) return null;

  const lastSnapshot = lastContextRefSnapshotSuggestedPromptsRef.current || '';

  if (currentContext === lastSnapshot) return null;

  // Get new messages since the last snapshot
  const newMessages =
    lastSnapshot.length < 10
      ? currentContext.slice(0)
      : currentContext.slice(lastSnapshot.length - 10).trim();

  const messagesToSend = [{
    role: 'system',
    content: `You are an assistant suggesting questions a user might ask about the meeting transcript provided. For example, what does DFS mean? Or, what are the action items? Just output the suggestions split by newline. Don't interact with the user.${
      selectedLanguage !== 'Select Language' ? ` Output in ${selectedLanguage}` : ''
    }`
  }];
  
  messagesToSend.push({
    role: 'user',
    content: `Based on this transcript, suggest two questions:\n${newMessages}`,
  });

  lastContextRefSnapshotSuggestedPromptsRef.current = currentContext;

  try {
    const response = await axios.post(
      `${serverURL}/call_chatbot`,
      {
        messages: messagesToSend,
        chatbot_request_type: 'generate_suggestions',
        room_id: roomId,
        room_password: roomPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      },
    );

    const suggestions = response.data
      .trim()
      .split('\n')
      .map((line) => line.replace(/^\d+\.\s*/, '').trim())
      .filter((s) => s);
      
    return suggestions;
  } catch (error) {
    console.error('Error updating suggested prompts:', error);
    return null;
  }
};

// Function to modify notes with AI
export const modifyNotes = async (prompt, isDetailedMode, selectedLanguage) => {
  const messagesToSend = [
    {
      role: 'system',
      content: getPromptTemplate(isDetailedMode)
    },
    { role: 'user', content: prompt },
  ];

  // Add language preference if selected
  if (selectedLanguage !== 'Select Language') {
    messagesToSend[0].content += `\n\nRespond in ${selectedLanguage}.`;
  }

  try {
    const response = await axios.post(
      `${serverURL}/call_chatbot`,
      {
        messages: messagesToSend,
        chatbot_request_type: 'modify_notes',
      },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error modifying notes:', error);
    return null;
  }
};