import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

export const Message = ({ 
  showOriginal, 
  message, 
  languageOne, 
  languageTwo, 
  isLoadingTTS, 
  playAudio 
}) => {
  const [isOriginalHovered, setIsOriginalHovered] = useState(false);
  const [isTranslatedHovered, setIsTranslatedHovered] = useState(false);
  const messageIdRef = useRef(message.id || Date.now().toString());
  
  // Use a ref to track if this is the first render to prevent animations on update
  const isInitialRender = useRef(true);
  useEffect(() => {
    // After first render, mark as not initial anymore
    return () => {
      isInitialRender.current = false;
    };
  }, []);
  
  const getTranscriptText = (lang) => {
    return (message.corrected_transcripts[lang] || message.transcript || '') +
      ((message.unconfirmed_corrected_transcripts && 
        message.unconfirmed_corrected_transcripts[lang]) || 
        message.unconfirmed_transcript || '');
  };

  // Loading spinner component with improved animation
  const LoadingSpinner = () => (
    <div className='absolute bottom-2 right-2 w-8 h-8 flex items-center justify-center'>
      <svg
        className='animate-spin h-7 w-7 text-updated-color-blue'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        <circle
          className='opacity-25'
          cx='12'
          cy='12'
          r='10'
          stroke='currentColor'
          strokeWidth='3'
        ></circle>
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        ></path>
      </svg>
    </div>
  );

  // Speaker button component with hover effects
  const SpeakerButton = ({ language, isHovered }) => (
    <div
      className={`absolute bottom-2 right-2 w-8 h-8 flex items-center justify-center cursor-pointer bg-white/40 backdrop-blur-sm rounded-full shadow-sm transition-opacity duration-200 ${isHovered ? 'opacity-100' : 'opacity-70'}`}
      onClick={() => playAudio(getTranscriptText(language))}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-blue-600">
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
        <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
      </svg>
    </div>
  );

  // Transcript text component with static styling for unconfirmed text
  const TranscriptText = ({ correctedText, unconfirmedText }) => (
    <div className="w-full pr-8">
      <span className="text-base leading-relaxed text-gray-900 font-medium">
        {correctedText}
      </span>
      {unconfirmedText && (
        <span className="text-base leading-relaxed text-gray-500">
          {unconfirmedText}
        </span>
      )}
    </div>
  );

  const hasOriginalContent = showOriginal &&
    (message.corrected_transcripts[languageOne] || message.transcript || message.unconfirmed_transcript);

  const hasTranslatedContent = 
    message.corrected_transcripts[languageTwo] || message.transcript || message.unconfirmed_transcript;

  // No content to display
  if (!hasOriginalContent && !hasTranslatedContent) return null;

  // Only apply enter animations to new messages
  const enterAnimation = isInitialRender.current ? {
    initial: { opacity: 0.7, y: 5 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.2 }
  } : { animate: {} };

  return (
    <motion.div 
      key={messageIdRef.current}
      {...enterAnimation}
      className='flex flex-col text-sm mb-4'
      layout="position"
    >
      {/* Timestamp */}
      <div className='text-xs text-updated-color-new-black font-medium mb-1'>
        {message.timestamp_display}
      </div>
      
      {/* Original language */}
      {hasOriginalContent && (
        <div className='relative overflow-hidden'>
          <div 
            onMouseEnter={() => setIsOriginalHovered(true)}
            onMouseLeave={() => setIsOriginalHovered(false)}
            className='relative rounded-lg bg-gradient-to-r from-blue-100/80 to-blue-200/60
                       border border-blue-200/50 flex py-3 px-4 mt-1 box-border w-full transition-transform duration-200 hover:translate-y-[-2px] hover:shadow-md'
          >
            <TranscriptText 
              correctedText={message.corrected_transcripts[languageOne] || message.transcript}
              unconfirmedText={(message.unconfirmed_corrected_transcripts &&
                message.unconfirmed_corrected_transcripts[languageOne]) ||
                message.unconfirmed_transcript}
            />
            
            {isLoadingTTS ? <LoadingSpinner /> : <SpeakerButton language={languageOne} isHovered={isOriginalHovered} />}
          </div>
        </div>
      )}
      
      {/* Translated language */}
      {hasTranslatedContent && (
        <div className='relative overflow-hidden'>
          <div 
            onMouseEnter={() => setIsTranslatedHovered(true)}
            onMouseLeave={() => setIsTranslatedHovered(false)}
            className='relative rounded-lg bg-white border border-gray-100 flex py-3 px-4 mt-2 box-border w-full transition-transform duration-200 hover:translate-y-[-2px] hover:shadow-sm'
          >
            <TranscriptText 
              correctedText={message.corrected_transcripts[languageTwo] || message.transcript}
              unconfirmedText={(message.unconfirmed_corrected_transcripts &&
                message.unconfirmed_corrected_transcripts[languageTwo]) ||
                message.unconfirmed_transcript}
            />
            
            {isLoadingTTS ? <LoadingSpinner /> : <SpeakerButton language={languageTwo} isHovered={isTranslatedHovered} />}
          </div>
        </div>
      )}
    </motion.div>
  );
};