import { EditOutlined, DeleteOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

export const EditFile = ({
  file,
  setIsRenamingFile,
  setOpenPopup,
  setOpenMoveFilePopup,
  handleFileRemove,
}) => {
  // Improved event handling with better propagation prevention
  const handleRenameClick = (e) => {
    e.stopPropagation();
    setIsRenamingFile(true);
    setOpenPopup(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    handleFileRemove(file);
    setOpenPopup(false);
  };

  const handleMove = (e) => {
    e.stopPropagation();
    setOpenMoveFilePopup(true);
    setOpenPopup(false);
  };

  const copyHandler = async (e) => {
    e.stopPropagation();

    try {
      const response = await axios.get(
        `${serverURL}/get_transcript?transcript_id=${file.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.data.transcript) {
        await navigator.clipboard.writeText(response.data.transcript);
        console.log('Text copied to clipboard');
      }
    } catch (error) {
      console.error('Error copying file content:', error);
    }
    
    setOpenPopup(false);
  };

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.9, y: -5 },
    visible: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.9, y: -5 }
  };

  const buttonVariants = {
    hover: { backgroundColor: '#f3f4f6' },
    tap: { scale: 0.98 }
  };
  
  // Add a handler to prevent click propagation on the entire component
  const preventPropagation = (e) => {
    e.stopPropagation();
  };

  // Add passive event listener handling
  useEffect(() => {
    const handleTouchStart = (e) => {
      e.stopPropagation();
    };

    // Get the current element
    const element = document.querySelector('.edit-file-popup');
    if (element) {
      element.addEventListener('touchstart', handleTouchStart, { passive: false });
      
      return () => {
        element.removeEventListener('touchstart', handleTouchStart);
      };
    }
  }, []);

  return (
    <motion.div 
      className='flex flex-col bg-white rounded-xl shadow-lg overflow-hidden edit-file-popup'
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.2 }}
      onClick={preventPropagation}
      onPointerDown={preventPropagation}
    >
      <motion.div
        className='p-4 border-b border-gray-100 flex items-center gap-2 text-gray-700 cursor-pointer'
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        onClick={handleRenameClick}
      >
        <EditOutlined className='text-lg' />
        <span>Rename file</span>
      </motion.div>
      
      <motion.div
        className='p-4 border-b border-gray-100 flex items-center gap-2 text-gray-700 cursor-pointer'
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        onClick={handleMove}
      >
        <FolderOpenOutlined className='text-lg' />
        <span>Move to folder</span>
      </motion.div>
      
      <motion.div
        className='p-4 border-b border-gray-100 flex items-center gap-2 text-gray-700 cursor-pointer'
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        onClick={copyHandler}
      >
        <i className="fas fa-copy text-lg" />
        <span>Copy content</span>
      </motion.div>
      
      <motion.div
        className='p-4 flex items-center gap-2 text-red-500 cursor-pointer'
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        onClick={handleDelete}
      >
        <DeleteOutlined className='text-lg' />
        <span>Delete file</span>
      </motion.div>
    </motion.div>
  );
};
