// src/components/popups/RenameFilePopup.jsx
import React from 'react';
import { PopupForm } from './PopupForm';

export const RenameFilePopup = ({ name, onSubmit, onClose }) => {
  const handleClose = (e) => {
    if (e) e.stopPropagation();
    onClose();
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <PopupForm
        title="Rename File"
        initialValue={name}
        onSubmit={onSubmit}
        onClose={handleClose}
        label="File Name"
        submitText="Rename"
        placeholder="Enter new file name..."
      />
    </div>
  );
};