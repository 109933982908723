import { useState } from 'react';

export const AddSectionModal = ({
  onClose,
  onAdd,
  newSectionPosition,
  currentSectionTitle,
  outlineContent,
}) => {
  const [section, setSection] = useState({ title: '', instructions: '' });
  const [titleError, setTitleError] = useState('');

  // Helper function to get existing section titles
  const getExistingSectionTitles = () => {
    const sections = outlineContent.split('##');
    return new Set(
      sections.map((section) => section.split('\n')[0].trim()).filter(Boolean),
    );
  };

  // Validate title as user types
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    const existingTitles = getExistingSectionTitles();

    if (existingTitles.has(newTitle)) {
      setTitleError('A section with this title already exists');
    } else {
      setTitleError('');
    }

    setSection((prev) => ({ ...prev, title: newTitle }));
  };

  return (
    <div className='fixed inset-0 bg-black/50 flex items-center justify-center z-50'>
      <div className='bg-white rounded-lg p-6 w-[500px]'>
        <h2 className='text-xl font-semibold mb-4'>
          Add New Section {newSectionPosition === 'top' ? 'Above' : 'Below'} "
          {currentSectionTitle}"
        </h2>

        <div className='mb-4'>
          <input
            type='text'
            placeholder='Section Title'
            className={`w-full p-2 border rounded ${
              titleError ? 'border-red-500' : 'border-gray-300'
            }`}
            value={section.title}
            onChange={handleTitleChange}
          />
          {titleError && (
            <p className='text-red-500 text-sm mt-1'>{titleError}</p>
          )}
        </div>

        <textarea
          placeholder='Section Instructions'
          className='w-full p-2 border rounded mb-4 h-32'
          value={section.instructions}
          onChange={(e) =>
            setSection((prev) => ({ ...prev, instructions: e.target.value }))
          }
        />

        <div className='flex justify-end gap-2'>
          <button onClick={onClose} className='px-4 py-2 text-gray-600'>
            Cancel
          </button>
          <button
            onClick={() => {
              if (!section.title || !section.instructions) return;
              if (titleError) return;

              const sanitizedTitle = section.title
                .replace(/[#]/g, '')
                .substring(0, 40);
              const sanitizedInstructions = section.instructions
                .replace(/```/g, '')
                .substring(0, 500);

              onAdd(
                sanitizedTitle,
                sanitizedInstructions,
                newSectionPosition,
                currentSectionTitle,
              );
              onClose();
            }}
            className={`px-4 py-2 text-white rounded ${
              !section.title || !section.instructions || titleError
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
            disabled={!section.title || !section.instructions || titleError}
          >
            Add Section
          </button>
        </div>
      </div>
    </div>
  );
};
