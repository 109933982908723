import React, { useState } from 'react';

export const LanguageInputRow = ({ language, onSelect, flagEmoji, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  // Get random flag emoji if not provided
  const getDefaultFlag = () => {
    // Map of common languages to their flag emojis
    const languageFlags = {
      'english': '🇺🇸',
      'spanish': '🇪🇸',
      'french': '🇫🇷',
      'german': '🇩🇪',
      'italian': '🇮🇹',
      'japanese': '🇯🇵',
      'korean': '🇰🇷',
      'chinese': '🇨🇳',
      'russian': '🇷🇺',
      'portuguese': '🇵🇹',
      'arabic': '🇸🇦',
      'hindi': '🇮🇳',
      'amharic': '🇪🇹',
      'armenian': '🇦🇲',
      'azerbaijani': '🇦🇿',
      'bengali': '🇧🇩',
      'bulgarian': '🇧🇬',
      'burmese': '🇲🇲',
      'cantonese': '🇭🇰',
      'chavacano': '🇵🇭',
      'chinese (simplified)': '🇨🇳',
      'croatian': '🇭🇷',
      'czech': '🇨🇿',
      'danish': '🇩🇰',
      'dutch': '🇳🇱',
      'estonian': '🇪🇪',
      'filipino (tagalog)': '🇵🇭',
      'finnish': '🇫🇮',
      'georgian': '🇬🇪',
      'greek': '🇬🇷',
      'haitian creole': '🇭🇹',
      'hebrew': '🇮🇱',
      'hungarian': '🇭🇺',
      'icelandic': '🇮🇸',
      'irish': '🇮🇪',
      'kazakh': '🇰🇿',
      'khmer': '🇰🇭',
      'lao': '🇱🇦',
      'latin': '🏛️',
      'latvian': '🇱🇻',
      'lithuanian': '🇱🇹',
      'macedonian': '🇲🇰',
      'malay': '🇲🇾',
      'malayalam': '🇮🇳',
      'maltese': '🇲🇹',
      'marathi': '🇮🇳',
      'mongolian (cyrillic)': '🇲🇳',
      'norwegian': '🇳🇴',
      'persian': '🇮🇷',
      'polish': '🇵🇱',
      'punjabi': '🇮🇳',
      'romanian': '🇷🇴',
      'serbian (cyrillic letters)': '🇷🇸',
      'serbian (latin letters)': '🇷🇸',
      'sinhala': '🇱🇰',
      'slovak': '🇸🇰',
      'slovenian': '🇸🇮',
      'swahili': '🇹🇿',
      'swedish': '🇸🇪',
      'tagalog': '🇵🇭',
      'tamil': '🇮🇳',
      'telugu': '🇮🇳',
      'thai': '🇹🇭',
      'tigrinya': '🇪🇷',
      'turkish': '🇹🇷',
      'uighur': '🌐',
      'ukrainian': '🇺🇦',
      'uzbek': '🇺🇿',
      'vietnamese': '🇻🇳',
      'yiddish': '✡️'
    };
    
    // Check if we have a flag for this language (case insensitive)
    const lowercaseLanguage = language.toLowerCase();
    return languageFlags[lowercaseLanguage] || '';
  };

  return (
    <div
      className={`relative flex flex-row items-center w-full cursor-pointer mb-1 transition-opacity duration-200 ${index > 15 ? 'opacity-0' : 'opacity-100'}`}
      style={{
        transitionDelay: `${Math.min(index * 20, 300)}ms`
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onSelect(language)}
    >
      <div 
        className={`flex-1 flex items-center justify-start py-3 border-b transition-all duration-200 ${isHovered ? 'border-blue-500 translate-x-1' : 'border-gray-200'}`}
      >
        <div className="mr-3 text-xl">
          {flagEmoji || getDefaultFlag()}
        </div>
        <div 
          className={`font-medium text-base transition-colors duration-200 ${isHovered ? 'text-blue-600' : 'text-gray-800'}`}
        >
          {language}
        </div>
        
        <div
          className={`ml-auto transition-all duration-200 ${isHovered ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-2'}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-blue-500">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </div>
    </div>
  );
};