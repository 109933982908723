import { jwtTokenRef, serverURL } from './httpContext';
import axios from 'axios';

export function extractMeetingId(url) {
  let regex, match;

  if (url.includes('zoom.us')) {
    // Regular expression to match Zoom meeting ID
    regex = /\/j\/(\d+)/;
    match = url.match(regex);
  } else if (url.includes('teams.live.com')) {
    // Regular expression to match Microsoft Teams meeting ID
    regex = /\/meet\/(\d+)/;
    match = url.match(regex);
  }

  return match ? match[1] : null;
}

export function playNextAudio(
  playingAudioRef,
  audioQueueRef,
  audioContextRef,
  liveAudioEnabledRef,
) {
  if (playingAudioRef.current || audioQueueRef.current.length === 0) {
    return;
  }

  if (audioContextRef.current && !audioContextRef.current.ended) {
    return;
  }

  if (!liveAudioEnabledRef.current) {
    // Clear the queue if live audio is disabled
    audioQueueRef.current = [];
    return;
  }

  playingAudioRef.current = true;

  if (!audioContextRef.current) {
    console.error('AudioContext is not initialized.');
    playingAudioRef.current = false;
    return;
  }

  const audioBlob = audioQueueRef.current.shift();

  const audioUrl = URL.createObjectURL(audioBlob);
  var audioRefToUse = audioContextRef.current;
  audioRefToUse.src = audioUrl;
  audioRefToUse.currentTime = 0;
  audioRefToUse.play();
  audioRefToUse.onended = () => {
    audioRefToUse.pause();
    playingAudioRef.current = false;
    URL.revokeObjectURL(audioUrl);
    playNextAudio(
      playingAudioRef,
      audioQueueRef,
      audioContextRef,
      liveAudioEnabledRef,
    ); // Play the next audio in the queue
  };
}

export async function computeBlob(text, roomIdRef) {
  try {
    console.log('Computing blob for text:', text);
    let url = `${serverURL}/text_to_speech`;
    
    // Handle the case where roomIdRef might be optional
    if (roomIdRef && roomIdRef.current) {
      url += `?room_id=${roomIdRef.current}`;
    } else {
      url += `?jwt_token=${jwtTokenRef.current}`;
    }
    
    const response = await axios.post(
      url,
      {
        text: text,
      },
      {
        responseType: 'blob',
      },
    );

    if (response.status === 200) {
      console.log('Computed blob successfully');
      const audioBlob = response.data;
      return audioBlob;
    } else {
      throw new Error(`Received status code ${response.status}`);
    }
  } catch (error) {
    console.error('Error in computeBlob:', error);
  }
}
