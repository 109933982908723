import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Markdown } from '../../../../components/Markdown';
import { AddSectionModal } from '../../../../components/AddSectionModal';
import { handleAddSection } from '../../../../helpers/addSection';
import { OutlineContent } from '../../../../components/OutlineContent';

// Memoized Header component for better performance
const NoteTakerHeader = memo(
  ({ isToggled, handleToggleChange, isUpdatingOutline }) => (
    <div className='flex items-center gap-4 px-5 pb-2 pt-5 border-b border-updated-color-grey1'>
      <span className='text-2xl font-montserrat font-semibold'>Note Taker</span>

      <label className='relative inline-flex items-center cursor-pointer'>
        <input
          type='checkbox'
          className='sr-only peer'
          checked={isToggled}
          onChange={handleToggleChange}
          aria-label='Toggle note taker'
        />
        <div className="w-11 h-6 bg-[#454545] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['OFF'] after:flex after:items-center after:justify-center after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:content-['ON'] after:font-montserrat after:font-semibold after:text-[8px] after:text-black/50 peer-checked:bg-gradient-to-r peer-checked:from-[#75b1f4] peer-checked:to-[#a2ace7]"></div>
      </label>

      {isToggled && isUpdatingOutline && (
        <div className='flex items-center'>
          <svg
            className='animate-spin h-6 w-6 mr-2 text-updated-color-blue'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-75'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className=''
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
          <div className='text-updated-color-blue font-medium'>
            Updating notes...
          </div>
        </div>
      )}
    </div>
  ),
);

NoteTakerHeader.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  handleToggleChange: PropTypes.func.isRequired,
  isUpdatingOutline: PropTypes.bool.isRequired,
};

// Memoized ModeToggle component
const ModeToggle = memo(
  ({
    isDetailedMode,
    setIsDetailedMode, // Receives setter directly now
  }) => {
    const [isChangingMode, setIsChangingMode] = useState(false);
    const modeChangeTimeoutRef = useRef(null);

    const handleModeChange = useCallback(
      async (newDetailedMode) => {
        if (isChangingMode || isDetailedMode === newDetailedMode) return;

        setIsChangingMode(true);
        // Directly update the state via the passed setter
        // The parent's useEffect watching isDetailedMode will trigger the update via the hook
        setIsDetailedMode(newDetailedMode);

        // Simulate change duration for UI feedback - Actual update handled by the hook
        modeChangeTimeoutRef.current = setTimeout(() => {
          setIsChangingMode(false);
          modeChangeTimeoutRef.current = null;
        }, 1500); // Adjust timing as needed, maybe shorter

        // Removed direct executeChatbotMethod calls here
      },
      [isChangingMode, isDetailedMode, setIsDetailedMode]
    );

    useEffect(() => {
      // Cleanup timeout
      return () => {
        if (modeChangeTimeoutRef.current) {
          clearTimeout(modeChangeTimeoutRef.current);
        }
      };
    }, []);

    return (
      <div className='flex items-center gap-1 px-5 border-b border-updated-color-grey1'>
        <div className='flex rounded-lg gap-[0.1rem] overflow-hidden shadow-lg'>
          <button
            className={`px-3 py-[0.2rem] text-sm transition-colors ${
              !isDetailedMode
                ? 'bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] text-white'
                : 'bg-[#F0F0F0] text-[#454545]'
            }`}
            onClick={() => handleModeChange(false)}
            disabled={isChangingMode || !isDetailedMode}
            aria-pressed={!isDetailedMode}
          >
            Concise
          </button>

          <button
            className={`px-3 py-[0.2rem] text-sm transition-colors ${
              isDetailedMode
                ? 'bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] text-white'
                : 'bg-[#F0F0F0] text-[#454545]'
            }`}
            onClick={() => handleModeChange(true)}
            disabled={isChangingMode || isDetailedMode}
            aria-pressed={isDetailedMode}
          >
            Detailed
          </button>
        </div>
        {isChangingMode && (
          <div className='ml-2 text-sm text-updated-color-blue flex items-center'>
            <svg
              className='animate-spin h-4 w-4 mr-1'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
            Changing...
          </div>
        )}
      </div>
    );
  },
);

ModeToggle.propTypes = {
  isDetailedMode: PropTypes.bool.isRequired,
  setIsDetailedMode: PropTypes.func.isRequired,
};

// Broadcast Button component
const BroadcastButton = memo(({ setShowStartJoinARoomOverlay }) => {
  const [hoveringEnterBroadcast, setHoveringEnterBroadcast] = useState(false);

  return (
    <div
      className='fixed bottom-20 z-10 opacity-85 left-0 rounded-tl-none rounded-tr-11xl rounded-br-11xl rounded-bl-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] h-11 flex flex-row items-center justify-center py-2.5 pr-5 pl-[49px] box-border gap-[5px] cursor-pointer w-fit'
      onClick={() => setShowStartJoinARoomOverlay(true)}
      onMouseOver={() => setHoveringEnterBroadcast(true)}
      onMouseOut={() => setHoveringEnterBroadcast(false)}
      role='button'
      aria-label='Enter broadcast mode'
    >
      <div
        className={`font-semibold transition duration-500 ${hoveringEnterBroadcast && 'scale-125 -translate-x-5'}`}
      >
        Enter Broadcast
      </div>
      <img
        className={`w-6 h-6 transition duration-500 ${hoveringEnterBroadcast && '-rotate-45'}`}
        src='/arrow--arrow-right-1.svg'
        alt='Enter broadcast'
      />
    </div>
  );
});

BroadcastButton.propTypes = {
  setShowStartJoinARoomOverlay: PropTypes.func.isRequired,
};

// Main NoteTakerPanel component
const NoteTakerPanel = ({
  splitPosition,
  isToggled,
  setIsToggled,
  isUpdatingOutline,
  outlineContent,
  isDetailedMode,
  setIsDetailedMode,
  chatbotRef,
  setOutlineContent,
  activeFormats,
  setActiveFormats,
  setIsEditingNotes,
  deletedSectionsRef,
  setShowStartJoinARoomOverlay,
  formats,
  setFormats,
  executeChatbotMethod,
  isChatbotInitialized,
  isTranscribing,
  applyFormat,
  discontinueFormat,
}) => {
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [newSectionPosition, setNewSectionPosition] = useState(null);
  const [currentSectionTitle, setCurrentSectionTitle] = useState(null);
  const [hiddenSections, setHiddenSections] = useState(new Set());
  const [isFormatPopupOpen, setIsFormatPopupOpen] = useState(false);
  const [showFormatDropdown, setShowFormatDropdown] = useState(false);
  const [formatToEdit, setFormatToEdit] = useState(null);
  const [noteEnhancementPrompt, setNoteEnhancementPrompt] = useState('');
  const [autoApplyNoteEnhancement, setAutoApplyNoteEnhancement] =
    useState(false);

  // Handler for toggle change with verification
  const handleToggleChange = setIsToggled;

  // Handler for creating/editing formats
  const handleCreate = useCallback(
    async (format, shouldApply) => {
      if (!isChatbotInitialized) {
        console.warn('Chatbot not initialized');
        return;
      }

      let updatedFormat;

      if (format.id) {
        updatedFormat = { ...format };
        setFormats((prevFormats) =>
          prevFormats.map((f) => (f.id === format.id ? updatedFormat : f)),
        );
      } else {
        updatedFormat = { ...format, id: Date.now() };
        setFormats((prevFormats) => [...prevFormats, updatedFormat]);
      }

      if (shouldApply) {
        await applyFormat(updatedFormat);
      }

      setFormatToEdit(null);
    },
    [
      isChatbotInitialized,
      setFormats,
      applyFormat,
    ],
  );

  // Handler for applying a format with retry
  const handleApplyFormat = useCallback(
    async (format) => {
      await applyFormat(format);
    },
    [applyFormat],
  );

  // Handler for discontinuing a format
  const handleDiscontinueFormat = useCallback(async () => {
    await discontinueFormat();
  }, [discontinueFormat]);

  // Only render the panel if it's visible based on splitPosition
  if (splitPosition > 70) {
    return null;
  }

  return (
    <div
      className='flex flex-col gap-8 relative'
      style={{
        width:
          splitPosition < 32
            ? '100%'
            : splitPosition > 70
              ? '0%'
              : `${100 - splitPosition}%`,
        position: splitPosition < 32 ? 'absolute' : 'relative',
        left: 0,
        top: 0,
        height: '100%',
        display: splitPosition > 70 ? 'none' : 'flex',
      }}
    >
      {/* Broadcast button */}
      <BroadcastButton
        setShowStartJoinARoomOverlay={setShowStartJoinARoomOverlay}
      />

      <div className='flex flex-col h-full'>
        {/* Header */}
        <NoteTakerHeader
          isToggled={isToggled}
          handleToggleChange={handleToggleChange}
          isUpdatingOutline={isUpdatingOutline}
        />

        {/* Mode toggle for notes */}
        {isToggled && (
          <ModeToggle
            isDetailedMode={isDetailedMode}
            setIsDetailedMode={setIsDetailedMode}
          />
        )}

        {/* Note content area */}
        <div
          className='p-5 pt-2 pl-5 leading-[20px] px-2 overflow-y-auto text-sm note-taker-content'
          aria-live='polite'
        >
          {isToggled && (
            <Markdown
              outlineContent={outlineContent}
              hiddenSections={hiddenSections}
            />
          )}
        </div>

        {/* Add section modal */}
        {showAddSectionModal && (
          <AddSectionModal
            onClose={() => setShowAddSectionModal(false)}
            onAdd={(title, instructions, position, currentSectionTitle) => {
              handleAddSection(
                title,
                instructions,
                position,
                currentSectionTitle,
                chatbotRef,
                { current: outlineContent },
                deletedSectionsRef,
                setOutlineContent,
                () => {},
                executeChatbotMethod
              );
            }}
            newSectionPosition={newSectionPosition}
            currentSectionTitle={currentSectionTitle}
            outlineContent={outlineContent}
          />
        )}

        {/* Outline content controls */}
        {isToggled && (
          <OutlineContent
            outlineContent={outlineContent}
            chatbotRef={chatbotRef}
            formatToEdit={formatToEdit}
            setFormatToEdit={setFormatToEdit}
            activeFormats={activeFormats}
            splitPosition={splitPosition}
            setIsEditingNotes={setIsEditingNotes}
            setIsFormatPopupOpen={setIsFormatPopupOpen}
            setOutlineContent={setOutlineContent}
            noteEnhancementPrompt={noteEnhancementPrompt}
            setNoteEnhancementPrompt={setNoteEnhancementPrompt}
            setShowFormatDropdown={setShowFormatDropdown}
            formats={formats}
            isFormatPopupOpen={isFormatPopupOpen}
            showFormatDropdown={showFormatDropdown}
            autoApplyNoteEnhancement={autoApplyNoteEnhancement}
            setAutoApplyNoteEnhancement={setAutoApplyNoteEnhancement}
            handleCreate={handleCreate}
            handleApplyFormat={handleApplyFormat}
            handleDiscontinueFormat={handleDiscontinueFormat}
            executeChatbotMethod={executeChatbotMethod}
            isChatbotInitialized={isChatbotInitialized}
          />
        )}
      </div>
    </div>
  );
};

NoteTakerPanel.propTypes = {
  splitPosition: PropTypes.number.isRequired,
  isToggled: PropTypes.bool.isRequired,
  setIsToggled: PropTypes.func.isRequired,
  isUpdatingOutline: PropTypes.bool.isRequired,
  outlineContent: PropTypes.string.isRequired,
  isDetailedMode: PropTypes.bool.isRequired,
  setIsDetailedMode: PropTypes.func.isRequired,
  chatbotRef: PropTypes.object.isRequired,
  setOutlineContent: PropTypes.func.isRequired,
  activeFormats: PropTypes.array.isRequired,
  setActiveFormats: PropTypes.func.isRequired,
  setIsEditingNotes: PropTypes.func.isRequired,
  deletedSectionsRef: PropTypes.object.isRequired,
  setShowStartJoinARoomOverlay: PropTypes.func.isRequired,
  formats: PropTypes.array.isRequired,
  setFormats: PropTypes.func.isRequired,
  executeChatbotMethod: PropTypes.func.isRequired,
  isChatbotInitialized: PropTypes.bool.isRequired,
  isTranscribing: PropTypes.bool.isRequired,
  applyFormat: PropTypes.func.isRequired,
  discontinueFormat: PropTypes.func.isRequired,
};

export default memo(NoteTakerPanel);
