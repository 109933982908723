import React, { useEffect } from 'react';

/**
 * Component that provides haptic feedback when supported
 */
export const useHapticFeedback = () => {
  // Vibration pattern for different actions
  const vibrationPatterns = {
    success: [10],
    error: [100, 50, 100],
    warning: [50, 20, 50],
    click: [5],
  };
  
  const triggerHapticFeedback = (type = 'click') => {
    if ('vibrate' in navigator) {
      navigator.vibrate(vibrationPatterns[type] || vibrationPatterns.click);
    }
  };
  
  return { triggerHapticFeedback };
};

/**
 * Hook to add focus-visible class for keyboard navigation
 */
export const useFocusVisible = (ref) => {
  useEffect(() => {
    const element = ref?.current;
    if (!element) return;
    
    const handleKeyDown = (e) => {
      if (e.key === 'Tab') {
        document.body.classList.add('keyboard-navigation');
      }
    };
    
    const handleMouseDown = () => {
      document.body.classList.remove('keyboard-navigation');
    };
    
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleMouseDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ref]);
};

// Add any global CSS needed
export const addAccessibilityCss = () => {
  const styleEl = document.createElement('style');
  styleEl.textContent = `
    .keyboard-navigation :focus:not(:focus-visible) {
      outline: none;
    }
    .keyboard-navigation :focus-visible {
      outline: 3px solid #4d90fe;
      outline-offset: 2px;
    }
  `;
  document.head.appendChild(styleEl);
};
