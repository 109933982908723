// src/components/chatbot/ChatbotContext.js
import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

const ChatbotContext = createContext();

export const ChatbotProvider = ({
  children,
  contextRef,
  isChatbotOpenRef,
  roomId,
  roomPassword,
}) => {
  // Chat state
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  // Outline state
  const [selectedTab, setSelectedTab] = useState('AI Chat');
  const [outlineContent, setOutlineContent] = useState('');
  const outlineContentRef = useRef('');
  const lastContextRefSnapshotRef = useRef('');
  const lastContextRefSnapshotSuggestedPromptsRef = useRef('');

  // Detail mode state
  const [isDetailedMode, setIsDetailedMode] = useState(false);
  const currentModeRef = useRef(false);

  // Language state
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('Select Language');
  const selectedLanguageRef = useRef(selectedLanguage);
  const [languageSearch, setLanguageSearch] = useState('');

  // Suggested prompts state
  const [suggestedPrompts, setSuggestedPrompts] = useState([
    'Generate a summary',
    'Generate an outline',
  ]);

  // Section state
  const [sections, setSections] = useState([
    [
      'Summary',
      'Please generate a concise summary of the provided text that captures its key insights in a natural, engaging tone.',
    ],
    [
      'Bullet Points',
      'Please generate a set of bullet points that succinctly capture the key insights from the provided text.',
    ],
    [
      'Action Plan',
      'Please generate an action plan based on any actionable recommendations or steps present in the provided text.',
    ],
  ]);

  // Update refs when state changes
  useEffect(() => {
    outlineContentRef.current = outlineContent;
  }, [outlineContent]);

  useEffect(() => {
    selectedLanguageRef.current = selectedLanguage;
  }, [selectedLanguage]);

  useEffect(() => {
    currentModeRef.current = isDetailedMode;
  }, [isDetailedMode]);

  // Generate initial assistant message
  useEffect(() => {
    setMessages([
      {
        role: 'assistant',
        content:
          'Hello, I am Eva AI, your personal AI chat assistant for Interpret AI. You can ask me questions about the meeting. You can also go to Live Outline to see a live outline of the meeting.',
      },
    ]);
  }, []);

  // Scroll to bottom of messages function
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Auto-scroll when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const value = {
    // State
    messages,
    setMessages,
    input,
    setInput,
    selectedTab,
    setSelectedTab,
    outlineContent,
    setOutlineContent,
    isDetailedMode,
    setIsDetailedMode,
    selectedLanguage,
    setSelectedLanguage,
    languageDropdownOpen,
    setLanguageDropdownOpen,
    languageSearch,
    setLanguageSearch,
    suggestedPrompts,
    setSuggestedPrompts,
    sections,
    setSections,

    // Refs
    messagesEndRef,
    outlineContentRef,
    selectedLanguageRef,
    currentModeRef,
    lastContextRefSnapshotRef,
    lastContextRefSnapshotSuggestedPromptsRef,

    // Context
    contextRef,
    isChatbotOpenRef,
    roomId,
    roomPassword,

    // Helper function
    scrollToBottom,
  };

  return (
    <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>
  );
};

export const useChatbot = () => useContext(ChatbotContext);

ChatbotProvider.propTypes = {
  children: PropTypes.node.isRequired,
  contextRef: PropTypes.object,
  isChatbotOpenRef: PropTypes.object,
  roomId: PropTypes.string,
  roomPassword: PropTypes.string,
};

export default ChatbotContext;
