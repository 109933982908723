import { useState, useEffect, useRef } from 'react';
import { socket } from '../../../../socketContext';
import { updateMessagesMerged } from '../../../../helpers/updateMessages';

const useTranscription = (
  messagesContainerRef,
  shouldScroll,
  chatbotContextRef,
  roomIdRef,
) => {
  const initMessages = [];
  const [messages, setMessages] = useState(initMessages);
  const [messagesMerged, setMessagesMerged] = useState(initMessages);
  const [isTranscribing, setTranscribing] = useState(false);
  const messagesRef = useRef(messages);
  const isTranscribingRef = useRef(false);
  const stoppedTranscribing = useRef(false);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const correctedMessagesShouldScroll = useRef(false);

  // Update ref when state changes
  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    isTranscribingRef.current = isTranscribing;
  }, [isTranscribing]);

  // Set up socket listeners for transcription
  useEffect(() => {
    socket.removeAllListeners();
    console.log('adding listener for receive_transcriptions');

    socket.on('receive_transcriptions', async (data) => {
      console.log('received transcriptions ' + JSON.stringify(data));
      let messagesToAdd = [];
      let oldMessages = [...messagesRef.current];
      let message = data;

      let messageToAdd = {
        transcript_id: message['transcript_id'],
        transcript: message['transcript'],
        corrected_transcripts: message['corrected_transcripts'],
        is_final: message['is_final'],
        timestamp: message['timestamp'],
        diarization: message['diarization'],
        did_speaker_change: message['did_speaker_change'],
        is_ai_answer: message['is_ai_answer'],
      };

      let replacedMessage = false;
      for (let i = 0; i < oldMessages.length; i++) {
        if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
          oldMessages[i] = messageToAdd;
          replacedMessage = true;
          messagesToAdd = [];
        }
      }

      if (!replacedMessage) {
        messagesToAdd = [message];
      }

      // Check if we should auto-scroll
      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        console.log(scrollTop, clientHeight, scrollHeight);
        console.log('dist: ', scrollHeight - clientHeight - scrollTop);
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        if (atBottom) {
          shouldScroll.current = true;
        } else {
          setShouldShowScrollButton(true);
        }
      }

      // Update messages
      setMessages([...oldMessages, ...messagesToAdd]);

      // Create deep copy for merged messages
      let messagesUpdated = [];
      for (let i = 0; i < oldMessages.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(oldMessages[i])),
        ];
      }
      for (let i = 0; i < messagesToAdd.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(messagesToAdd[i])),
        ];
      }

      // Update transcript context for chatbot
      let completeTranscript = '';
      for (let i = 0; i < messagesUpdated.length; i++) {
        completeTranscript += messagesUpdated[i]['transcript'];
      }

      if (chatbotContextRef) {
        chatbotContextRef.current = completeTranscript;
      }

      // Merge messages that should be grouped
      updateMessagesMerged(
        messagesUpdated,
        setMessages,
        messagesRef,
        setMessagesMerged,
      );

      messagesRef.current = [...oldMessages, ...messagesToAdd];
    });

    return () => {
      console.log('Removing socket listeners');
      socket.removeAllListeners();
    };
  }, []);

  // Scroll to bottom function
  const scrollToBottom = () => {
    setShouldShowScrollButton(false);
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  // Auto-scroll when messages change
  useEffect(() => {
    if (correctedMessagesShouldScroll.current) {
      scrollToBottom();
      correctedMessagesShouldScroll.current = false;
    }

    if (shouldScroll.current) {
      scrollToBottom();
    }
  }, [messagesMerged]);

  // Scroll event listener
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      let lastScrollTop = messagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = messagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          shouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      messagesContainer.addEventListener('scroll', handleScroll);

      return () => {
        messagesContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // Clear transcript function
  const clearTranscript = () => {
    setMessages([]);
    setMessagesMerged([]);
    messagesRef.current = [];
    if (chatbotContextRef) {
      chatbotContextRef.current = '';
    }
  };

  // Function to handle transcription data
  const handleTranscribeData = (
    blob,
    inputLanguage,
    isTranscribingRef,
    jwtTokenRef,
    outputLanguage,
    setTranscribing,
    stoppedTranscribing,
    userInfoRef,
    setIsOutOfCreditsOpen,
    pauseTimer,
    stopRecording,
  ) => {
    if (!isTranscribingRef.current) {
      return;
    }

    // Check if transcription was manually stopped
    if (stoppedTranscribing.current) {
      return;
    }

    // Check if user has enough credits
    if (userInfoRef.current?.credits < 1) {
      console.log('User out of credits');
      setIsOutOfCreditsOpen(true);
      setTranscribing(false);
      pauseTimer();
      stopRecording();
      try {
        socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
      } catch (error) {
        console.error('Error stopping transcription:', error);
      }
      return;
    }

    // Process the audio data and send to server
    const processAndSendData = async () => {
      try {
        // Convert blob to base64
        const base64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });

        // Create the transcription request object
        let transcriptionData = {
          audio: base64,
          jwt_token: jwtTokenRef.current,
        };

        // Add languages for translation
        let translateToLanguages = [];

        // Add input language if specified
        if (
          inputLanguage.current !== 'Original' &&
          inputLanguage.current !== 'Detect Language'
        ) {
          translateToLanguages.push(inputLanguage.current);
        }

        // Add output language if specified
        if (
          outputLanguage.current !== 'Original' &&
          outputLanguage.current !== 'Detect Language'
        ) {
          translateToLanguages.push(outputLanguage.current);
        }

        console.log('translateToLanguages:', translateToLanguages);

        // Add translate_to_languages to the request if any languages are specified
        if (translateToLanguages.length > 0) {
          transcriptionData.translate_to_languages = translateToLanguages;
        }

        if (roomIdRef && roomIdRef.current) {
          transcriptionData.room_id = roomIdRef.current;
        }

        // Send the audio data to the server via socket with error handling
        if (socket && socket.connected) {
          console.log('trascriptionData:', transcriptionData);
          socket.emit('transcribe', transcriptionData);
        } else {
          console.error('Socket is not connected');
        }
      } catch (error) {
        console.error('Error processing or sending transcription data:', error);
      }
    };

    // Execute the async function without awaiting to prevent call stack issues
    processAndSendData().catch((err) => {
      console.error('Failed to process transcription data:', err);
    });
  };

  // Function to toggle transcription
  const toggleTranscription = (
    userInfoRef,
    setIsOutOfCreditsOpen,
    stoppedTranscribing,
    isTranscribingRef,
    pauseTimer,
    stopRecording,
    socket,
    jwtTokenRef,
    startRecording,
    startTimer,
    setTranscribing,
  ) => {
    // If already transcribing, stop transcription
    if (isTranscribingRef.current) {
      console.log('Stopping transcription');
      setTranscribing(false);
      stoppedTranscribing.current = true;
      pauseTimer();
      stopRecording();
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
      return;
    }

    // If not transcribing, check credits and start if available
    if (userInfoRef.current?.credits < 1) {
      console.log('User out of credits');
      setIsOutOfCreditsOpen(true);
      return;
    }

    // Start transcription
    console.log('Starting transcription');
    stoppedTranscribing.current = false;
    startRecording();
    startTimer();
    setTranscribing(true);

    // Emit start transcribing event to server
    socket.emit('start_transcribing', {
      jwt_token: jwtTokenRef.current,
    });
  };

  return {
    messages,
    setMessages,
    messagesMerged,
    setMessagesMerged,
    isTranscribing,
    setTranscribing,
    messagesRef,
    isTranscribingRef,
    stoppedTranscribing,
    clearTranscript,
    shouldShowScrollButton,
    setShouldShowScrollButton,
    scrollToBottom,
    handleTranscribeData,
    toggleTranscription,
  };
};

export default useTranscription;
