import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWhisper } from '@chengsokdara/use-whisper';
import { socket } from '../../../socketContext';
import React from 'react';
import mermaid from 'mermaid';

import { jwtTokenRef, userInfoRef } from '../../../httpContext';
import TranscriptionPanel from './components/TranscriptionPanel';
import NoteTakerPanel from './components/NoteTakerPanel';
import ResizablePanel from './components/ResizablePanel';
import CreditDisplay from '../../../components/web/CreditDisplay';
import RecentsSidebar from '../RecentsSidebar';
import { OutOfCreditsModal } from './modals/OutOfCreditsModal';
import ConfirmationModal from './modals/ConfirmationModal';
import { noteFormats } from '../../../helpers/formats';

// Import hooks
import useTranscription from './hooks/useTranscription';
import useAudioProcessing from './hooks/useAudioProcessing';
import useTimer from './hooks/useTimer';
import useLanguageSelection from './hooks/useLanguageSelection';
import ChatbotWidget from '../../../components/chatbot/ChatbotWidget';
import StartjoinARoomOverlay from '../../../components/StartJoinRoom';
import LinkInput from '../../../components/web/LinkInput';
import useChatbotRef from './hooks/useChatbotRef';
import useNoteTaking from './hooks/useNoteTaking'; // Import the new hook

const TranscribeContainer = () => {
  const navigate = useNavigate();

  // State for panels
  const [splitPosition, setSplitPosition] = useState(66);

  // Chatbot Management
  const { chatbotRef, isInitialized, setChatbotRef, executeChatbotMethod } =
    useChatbotRef();

  // State for modals
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [isOutOfCreditsOpen, setIsOutOfCreditsOpen] = useState(false);
  const [showStartJoinARoomOverlay, setShowStartJoinARoomOverlay] =
    useState(false);
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);

  // Refs
  const messagesContainerRef = useRef(null);
  const isChatbotOpenRef = useRef(true);
  const roomIdRef = useRef(null);
  const shouldScroll = useRef(false);

  // State for chatbot
  const [isChatbotOpen, setIsChatbotOpen] = useState(true);
  const chatbotContextRef = useRef(null);

  // State for credits
  const [credits, setCredits] = useState(
    Math.floor(userInfoRef.current?.credits || 0),
  );

  // Timer state and functions from hook
  const { elapsedTime, startTimer, pauseTimer, resetTimer } = useTimer();

  // Transcription state and functions from hook
  const {
    // State
    messages,
    messagesMerged,
    isTranscribing,
    shouldShowScrollButton,

    // Refs
    messagesRef,
    isTranscribingRef,
    stoppedTranscribing,

    // Functions
    setMessages,
    setMessagesMerged,
    setTranscribing,
    clearTranscript,
    scrollToBottom,
    handleTranscribeData,
    toggleTranscription,
  } = useTranscription(messagesContainerRef, shouldScroll, chatbotContextRef);

  // Enhanced language switching with animation state
  const [isLanguageSwitching, setIsLanguageSwitching] = useState(false);

  // Language state and functions from hook
  const {
    inputLanguage,
    outputLanguage,
    inputLanguageDisplay,
    outputLanguageDisplay,
    isLoadingTranslation,
    isLoadingOutputTranslation,
    languageDropdownVisible,
    languageTwoDropdownVisible,
    searchBarInput,
    setSearchBarInput,
    searchBarTwoInput,
    setSearchBarTwoInput,
    inputLanguageRef,
    outputLanguageRef,
    inputLanguageDropdownRef,
    outputLanguageDropdownRef,
    handleInputLanguageSelect,
    handleOutputLanguageSelect,
    toggleInputLanguageDropdown,
    toggleOutputLanguageDropdown,
    switchLanguages: originalSwitchLanguages,
  } = useLanguageSelection(
    messages,
    messagesRef,
    setMessages,
    setMessagesMerged,
  );

  // Enhanced switch languages function with animation
  const switchLanguages = useCallback(() => {
    setIsLanguageSwitching(true);
    setTimeout(() => {
      originalSwitchLanguages();
      setIsLanguageSwitching(false);
    }, 300);
  }, [originalSwitchLanguages]);

  // Audio state and functions from hook
  const { liveAudioEnabled, toggleLiveAudio } = useAudioProcessing(
    messages,
    outputLanguage,
  );

  // --- Note Taker State and Logic ---
  const [isToggled, setIsToggled] = useState(false); // Note Taker ON/OFF toggle
  const [isEditingNotes, setIsEditingNotes] = useState(false); // Track if user is manually editing
  const [formats, setFormats] = useState(noteFormats); // Predefined/saved formats
  const deletedSectionsRef = useRef(new Set()); // Keep track of deleted sections

  // Use the NoteTaking Hook
  const {
    outlineContent,
    isUpdatingOutline,
    isDetailedMode,
    setIsDetailedMode,
    activeFormats,
    setActiveFormats,
    setOutlineContentManual,
    applyFormat,
    discontinueFormat,
  } = useNoteTaking({
    isToggled,
    isChatbotInitialized: isInitialized,
    executeChatbotMethod,
    chatbotContextRef, // Keep passing the ref for baseline setting
    deletedSectionsRef,
    isEditingNotes,
    initialDetailedMode: false,
    transcriptContent: messagesMerged, // Pass the state that changes
    magnitudeUpdateThreshold: 150, // Or your desired threshold
    // Pass initial state if loaded from elsewhere
    // initialOutlineContent: loadedOutline,
    // initialActiveFormats: loadedActiveFormats,
  });

  // Function to handle transcription data
  const handleTranscriptionData = (blob) => {
    handleTranscribeData(
      blob,
      inputLanguage,
      isTranscribingRef,
      jwtTokenRef,
      outputLanguage,
      setTranscribing,
      stoppedTranscribing,
      userInfoRef,
      setIsOutOfCreditsOpen,
      pauseTimer,
      stopRecording,
      startRecording,
      startTimer,
      elapsedTime,
    );
  };

  // Set up whisper for voice recording
  const { stopRecording, startRecording } = useWhisper({
    onDataAvailable: handleTranscriptionData,
    onTranscribe: handleTranscriptionData,
    streaming: true,
    timeSlice: 250,
    removeSilence: false,
    whisperConfig: {
      language: 'en',
    },
  });

  // Toggle Note Taker
  const handleToggleChange = useCallback(() => {
    setIsToggled((prev) => !prev);
    // The useNoteTaking hook's useEffect will handle the initial fetch on toggle ON
  }, []);

  // Function to handle transcribe button
  const handleTranscribeButton = useCallback(() => {
    toggleTranscription(
      userInfoRef,
      setIsOutOfCreditsOpen,
      stoppedTranscribing,
      isTranscribingRef,
      pauseTimer,
      stopRecording,
      socket,
      jwtTokenRef,
      startRecording,
      startTimer,
      setTranscribing,
    );
  }, [toggleTranscription, elapsedTime]); // Add proper dependencies

  // Handle Clear Confirmation (modified to clear notes if toggled)
  const handleConfirmClear = useCallback(() => {
    clearTranscript(); // Clears transcription messages and context ref
    if (isToggled) {
      setOutlineContentManual(''); // Clear notes manually
      deletedSectionsRef.current.clear(); // Clear deleted sections history
      // Optionally trigger a backend reset if needed via executeChatbotMethod
    }
    setShowClearConfirmation(false);
  }, [clearTranscript, isToggled, setOutlineContentManual]);

  useEffect(() => {
    if (!userInfoRef.current) {
      navigate('/login');
    }
  }, [userInfoRef, navigate]);

  // Effect to load user credits periodically
  useEffect(() => {
    const loadUser = () => {
      setCredits(Math.floor(userInfoRef.current?.credits || 0));
    };

    loadUser(); // Initial load
    const interval = setInterval(loadUser, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Effect to initialize mermaid
  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
      flowchart: { curve: 'basis' },
      pie: { textPosition: 0.5 },
      er: { fontSize: 16 },
      errorCallback: (error) => {
        // Handle mermaid errors
        console.error('Mermaid error:', error);
      },
      fontFamily: 'monospace',
    });

    mermaid.contentLoaded();
  }, []);

  // Set up room ID from query parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const roomId = queryParams.get('room_id');
    if (roomId) {
      roomIdRef.current = roomId;
    }
  }, []);

  // Effect to load chatbot closed state
  useEffect(() => {
    if (localStorage.getItem('chatbotClosed') === 'true') {
      setIsChatbotOpen(false);
    }
  }, []);

  return (
    <div className='w-full max-w-full mx-auto relative text-left text-base text-updated-color-new-black font-montserrat flex flex-col'>
      {/* Modals */}
      {isOutOfCreditsOpen && (
        <OutOfCreditsModal
          onClose={() => setIsOutOfCreditsOpen(false)}
          onBuyCredits={() => {
            setIsOutOfCreditsOpen(false);
            navigate('/buy_plan');
          }}
        />
      )}

      {showStartJoinARoomOverlay && (
        <StartjoinARoomOverlay
          onOutsideClickFunc={() => setShowStartJoinARoomOverlay(false)}
        />
      )}

      {showClearConfirmation && (
        <ConfirmationModal
          title='Clear Transcript?'
          message='This will delete the entire transcript. This action cannot be undone.'
          confirmText='Clear'
          cancelText='Cancel'
          onConfirm={handleConfirmClear}
          onCancel={() => setShowClearConfirmation(false)}
          confirmButtonClass='bg-red-500 text-white rounded hover:bg-red-600'
        />
      )}

      <div className='flex relative justify-center p-16 pt-12 pb-28 h-screen'>
        <div className='shadow-inner border border-solid border-updated-color-grey1 rounded-lg w-full flex justify-between max-h-[650px] relative'>
          {/* Transcription Panel */}
          <TranscriptionPanel
            splitPosition={splitPosition}
            messagesMerged={messagesMerged}
            messagesContainerRef={messagesContainerRef}
            inputLanguage={inputLanguage}
            outputLanguage={outputLanguage}
            inputLanguageDisplay={inputLanguageDisplay}
            outputLanguageDisplay={outputLanguageDisplay}
            isTranscribing={isTranscribing}
            handleTranscribeButton={handleTranscribeButton}
            elapsedTime={elapsedTime}
            liveAudioEnabled={liveAudioEnabled}
            toggleLiveAudio={toggleLiveAudio}
            showSavedMessage={showSavedMessage}
            setShowSavedMessage={setShowSavedMessage}
            setShowClearConfirmation={setShowClearConfirmation}
            shouldShowScrollButton={shouldShowScrollButton}
            scrollToBottom={scrollToBottom}
            isLoadingTranslation={isLoadingTranslation}
            isLoadingOutputTranslation={isLoadingOutputTranslation}
            languageDropdownVisible={languageDropdownVisible}
            languageTwoDropdownVisible={languageTwoDropdownVisible}
            searchBarInput={searchBarInput}
            setSearchBarInput={setSearchBarInput}
            searchBarTwoInput={searchBarTwoInput}
            setSearchBarTwoInput={setSearchBarTwoInput}
            handleInputLanguageSelect={handleInputLanguageSelect}
            handleOutputLanguageSelect={handleOutputLanguageSelect}
            toggleInputLanguageDropdown={toggleInputLanguageDropdown}
            toggleOutputLanguageDropdown={toggleOutputLanguageDropdown}
            switchLanguages={switchLanguages}
            isLanguageSwitching={isLanguageSwitching}
            inputLanguageRef={inputLanguageRef}
            outputLanguageRef={outputLanguageRef}
            inputLanguageDropdownRef={inputLanguageDropdownRef}
            outputLanguageDropdownRef={outputLanguageDropdownRef}
            stoppedTranscribing={stoppedTranscribing}
            isTranscribingRef={isTranscribingRef}
            pauseTimer={pauseTimer}
            stopRecording={stopRecording}
            setTranscribing={setTranscribing}
          />

          {/* Resizable Panel Divider */}
          <ResizablePanel
            splitPosition={splitPosition}
            setSplitPosition={setSplitPosition}
          />

          {/* Note Taker Panel */}
          <NoteTakerPanel
            splitPosition={splitPosition}
            isToggled={isToggled}
            setIsToggled={handleToggleChange}
            isUpdatingOutline={isUpdatingOutline}
            outlineContent={outlineContent}
            isDetailedMode={isDetailedMode}
            setIsDetailedMode={setIsDetailedMode}
            chatbotRef={chatbotRef}
            setOutlineContent={setOutlineContentManual}
            activeFormats={activeFormats}
            setActiveFormats={setActiveFormats}
            setIsEditingNotes={setIsEditingNotes}
            deletedSectionsRef={deletedSectionsRef}
            setShowStartJoinARoomOverlay={setShowStartJoinARoomOverlay}
            formats={formats}
            setFormats={setFormats}
            executeChatbotMethod={executeChatbotMethod}
            isChatbotInitialized={isInitialized}
            isTranscribing={isTranscribing}
            applyFormat={applyFormat}
            discontinueFormat={discontinueFormat}
          />
        </div>

        {/* Sidebar with credits, meeting links, and recents */}
        <div className='flex flex-col gap-4 ml-12 min-w-80 max-w-80'>
          <CreditDisplay
            credits={credits}
            navigate={navigate}
            userInfoRef={userInfoRef}
          />

          <LinkInput></LinkInput>

          <RecentsSidebar />
        </div>
      </div>

      {/* Chatbot Widget */}
      {isChatbotOpen && (
        <ChatbotWidget
          isChatbotOpenRef={isChatbotOpenRef}
          onClose={() => setIsChatbotOpen(false)}
          contextRef={chatbotContextRef}
          ref={setChatbotRef}
        />
      )}
      {!isChatbotOpen && (
        <img
          className='cursor-pointer fixed bottom-5 right-5'
          src='chai2.svg'
          alt='Chatbot'
          onClick={() => setIsChatbotOpen(true)}
        />
      )}
    </div>
  );
};

export default TranscribeContainer;
