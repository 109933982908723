import React, { forwardRef, useEffect, useState } from 'react';

const LanguageDropdown = forwardRef(({
  languages,
  searchValue,
  onSearchChange,
  onLanguageSelect,
  position
}, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    // Trigger fade-in animation after component mount
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      ref={ref}
      className={`grid grid-cols-4 gap-6 gap-y-4 pb-4 px-6 bg-white border absolute top-20 border-solid border-gray-300 rounded-md shadow-lg z-[100] h-96 overflow-y-auto min-w-[600px] max-w-[600px] transition-all duration-300 ease-in-out ${isVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform -translate-y-2'}`}
      style={{
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)'
      }}
    >
      <div className={`flex h-fit items-center col-span-4 p-3 mb-2 sticky top-0 bg-white border-solid border-x-0 border-t-0 border-b border-gray-300 z-10`}>
        <input
          type='text'
          placeholder='Search language...'
          className='w-full p-2 border-none outline-none text-lg font-montserrat focus:ring-2 focus:ring-blue-300 rounded transition-all duration-200'
          value={searchValue || ''}
          onChange={(e) => onSearchChange(e.target.value)}
          autoFocus
        />
        <img
          className='w-5 h-5 ml-2 opacity-60'
          alt=''
          src='/general--search.svg'
        />
      </div>
      <div
        className='col-span-4 p-3 hover:bg-gray-100 cursor-pointer w-full h-fit rounded transition-colors duration-200 font-semibold'
        onClick={() =>
          onLanguageSelect('Detect Language')
        }
      >
        Detect Language
      </div>
      {languages
        .filter((language) =>
          language
            .toLowerCase()
            .includes(searchValue?.toLowerCase() || ''),
        )
        .map((language) => (
          <div
            key={language}
            className={`p-3 hover:bg-gray-100 cursor-pointer ${position === 'input' ? 'w-full' : 'w-fit'} h-fit rounded transition-colors duration-200`}
            onClick={() => onLanguageSelect(language)}
          >
            {language}
          </div>
        ))}
      {languages
        .filter((language) =>
          language
            .toLowerCase()
            .includes(searchValue?.toLowerCase() || ''),
        ).length === 0 && (
          <div className="col-span-4 text-center text-gray-500 py-4">
            No languages found matching your search
          </div>
        )}
    </div>
  );
});

LanguageDropdown.displayName = 'LanguageDropdown';

export default LanguageDropdown;