import { Link } from 'react-router-dom';
import SEOScript from './SEOScript';
import { useState } from 'react';

const Homepage = () => {
  // check the url query parameter utm_source
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');
  if (utmSource) {
    localStorage.setItem('signup_source', utmSource);
  }
  const certificates = [
    {
      imgSrc: '/soc.webp',
      title: 'SOC 2 Certification',
    },
    {
      imgSrc: '/DUNS.jpeg',
      title: 'DUNS Certification',
    },
    {
      imgSrc: '/sam.png',
      title: 'SAM Certification',
    },
    {
      imgSrc: '/SBE.png',
      title: 'SBE Certification',
    }
  ];
  const features = [
    {
      imgSrc: '/camera-reels-fill.svg',
      title: 'Zoom Integration',
    },
    {
      imgSrc: '/mic.png',
      title: 'Real-Time Transcription',
    },
    {
      imgSrc: '/people-fill.svg',
      title: 'Broadcast Room',
    },
    {
      imgSrc: '/live.png',
      title: 'AI live outline',
    },
    {
      imgSrc: '/check.png',
      title: 'File Transcription &\n Translation',
    },
    {
      imgSrc: '/ima.png',
      title: 'Image Translation',
    },
    {
      imgSrc: '/doc.png',
      title: 'Document Management',
    },
  ];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Array of words to cycle through
  const words = [
    'interpret',
    'Interpretar',
    'Interpréter',
    'Interpretieren',
    'Interpretare',
    'Interpreteren',
    'Интерпретировать',
    '解释',
    '解釈する',
  ];

  const handleWordClick = () => {
    setCurrentWordIndex((prevIndex) =>
      prevIndex === words.length - 1 ? 0 : prevIndex + 1,
    );
  };

  return (
    <div className='bg-white min-h-screen mx-auto  text-5xl text-black font-montserrat flex flex-col justify-center items-center  pt-7 gap-20 text-not-black'>
      <SEOScript />
      <div
        className='text-left  flex-col gap-4 items-center  bg-cover justify-center relative  w-full py-52'
        style={{ backgroundColor: '#F8F9FF' }}
      >
        <img
          src='/circlestar.png'
          alt='Top decoration'
          className='absolute right-0 w-[30.5rem] h-[28rem] pt-2 pr-20 z-0'
        />

        {/* Right SVG */}
        <img
          src='/phone.png'
          alt='Right decoration'
          className='absolute right-0 top-1/2 -translate-y-1/2 h-[32rem] z-10  pr-40 pt-5'
        />

        <div className='flex justify-center gap-4 absolute right-0 bottom-0 pr-36 pb-20'>
          <img
            src='/googleI.png'
            alt='Left image'
            className='h-[3rem] object-cover'
          />
          <img
            src='/download-apple.svg'
            alt='Right image'
            className='h-[3rem] object-cover cursor-pointer'
            onClick={() => {
              window.open(
                'https://apps.apple.com/us/app/interpret-ai/id6738939746',
              );
            }}
          />
        </div>

        <img src='/logo.svg' alt='Logo' className='w-16 pl-20 ' />
        <div className='text-[4.5rem] font-bold pl-20 pt-5'>
          Every conversation,
        </div>
        <div className='text-[4.5rem] text-gray-500 font-bold pl-20 '>
          Perfectly captured
        </div>
        <div className='text-[22px] text-left leading-relaxed pl-20 pt-5'>
          Elevate your communication with real-time transcription,
          <br /> Experience unmatched accuracy and clarity.
        </div>
        <div className='flex pl-20 mx-auto pt-10 gap-4'>
          <div className='flex justify-center gap-4'>
            <Link
              to='/signup'
              className='z-10 flex font-semibold text-base text-[#ffffff] bg-[#007EF3] rounded-xl p-2 px-16 py-5 justify-center cursor-pointer hover:bg-[#2d65c0] transition-all duration-500 gap-2 items-center no-underline'
            >
              <img
                className='h-6 sm:h-6 w-6 sm:w-6'
                alt='Rocket'
                src='/rocket.png'
              />
              <span>Get Started</span>
            </Link>
            <a
              href='https://www.blog.interpretapp.ai/blog/how-to-live-translate-audio-with-interpret-ai'
              target='_blank'
              rel='noopener noreferrer'
              className='z-10 flex font-semibold text-base text-[#007EF3] bg-[#ffffff] rounded-xl p-2 px-16 py-5 justify-center cursor-pointer hover:bg-[#007EF3] hover:text-[#ffffff] transition-all duration-500 gap-2 items-center no-underline border-[#007EF3] border-solid'
            >
              <span>How it works</span>
            </a>
          </div>
        </div>
      </div>
      <div
        style={{ borderBottom: '0.2px solid rgba(0, 0, 0, 0.2)' }}
        className='flex justify-center items-center gap-14 flex-wrap py-4 w-11/12'
      >
        {features.map((feature, index) => (
          <div
            key={index}
            className='flex flex-col p-2 items-center  hover:shadow-lg hover: hover:rounded-lg  transition-all duration-300'
          >
            <img
              src={feature.imgSrc}
              alt={feature.title}
              className='w-12 h-12'
            />
            <p className='text-center text-medium text-sm mt-2 whitespace-pre-line'>
              {feature.title}
            </p>
          </div>
        ))}
      </div>

      <div className='w-11/12 text-left flex justify-between items-center px-40'>
        <div className='flex flex-col gap-6 pl-20'>
          <div className='text-base'>OUR MISSION</div>
          <div className='text-3xl font-bold '>
            Interpret AI helps individual and organizations <br /> break down
            communication barriers.
            <br /> <br /> Our platform empowers teams to capture, translate,{' '}
            <br /> and optimize their conversations in real time.
          </div>
        </div>
        <img
          src='/mission.png'
          alt='Mission illustration'
          className='w-[17rem] h-[15rem] pr-20'
        />
      </div>

      <div className="w-11/12 flex justify-center items-center gap-20  rounded-xl animate-gradient ">

<div className="text-2xl font-normal  ">Certifications:</div>
  {certificates.map((cert, index) => (
    <div 
      key={index} 
      className="flex flex-col items-center gap-3 hover:transform  hover:scale-105 transition-all  bg-white hover:shadow-lg hover:rounded-lg  duration-300  opacity-85 p-4  "
    >
      <div className="w-20 h-20 flex items-center justify-center ">
        <img 
          src={cert.imgSrc} 
          alt={cert.title} 
          className="object-contain h-24 w-24" 
        />
      </div>
      <div className="text-sm font-medium text-slate-600 text-center">
        {cert.title}
      </div>
    </div>
  ))}
</div>
      
    
      <div
        style={{
          borderBottom: '0.2px solid rgba(0, 0, 0, 0.2)',
          borderTop: '0.2px solid rgba(0, 0, 0, 0.2)',
        }}
        className='flex items-center justify-center gap-20 w-11/12 mx-auto py-10'
      >
        <div className='text-2xl'>Integrated with: </div>
        <img
          src='/zoom.png'
          alt='Zoom'
          className='w-28 hover:border-solid hover:border-[#007EF3] hover:rounded-lg hover:p-5 transition-all duration-300'
        />
        <img
          src='/teams.png'
          alt='Teams'
          className='w-46 hover:border-solid hover:border-[#7a83eb] hover:rounded-lg hover:p-5 transition-all duration-300'
        />
        <img
          src='/slack.png'
          alt='Slack'
          className='w-32 hover:border-solid hover:border-[#2db67d] hover:rounded-lg hover:p-5 transition-all duration-300'
        />
        <img
          src='/gmeet.png'
          alt='Google Meet'
          className='w-22 hover:border-solid hover:border-[#ea4336] hover:rounded-lg hover:p-5 transition-all duration-300 '
        />
      </div>

      <a
        href='https://theresanaiforthat.com/ai/interpret-ai/?ref=featured&v=3528940'
        target='_blank'
        rel='nofollow'
      >
        <img
          width='300'
          src='https://media.theresanaiforthat.com/featured-on-taaft.png?width=600'
        />
      </a>

      <div className='flex flex-col items-center'>
        <div className='text-21xl font-bold w-fit flex gap-4 items-center'>
          Introducing,{' '}
          <div className='font-bold text-dodgerblue-200 flex gap-4 items-center w-full'>
            <span
              onClick={handleWordClick}
              className='cursor-pointer transition-all duration-300 hover:opacity-80'
            >
              {words[currentWordIndex]}
            </span>
            <img
              src='/logo.svg'
              alt='Logo'
              className='w-14 self-start mb-6 hover:border-solid hover:border-[#007EF3] hover:rounded-lg hover:p-3 transition-all duration-300'
            />
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-3xl font-semibold items-center text-center'>
            created by BlueJay AI
          </div>
          <div className='text-lg text-updated-color-grey2 leading-7 text-center mt-4'>
            Enabling individuals facing language barriers to enjoy
            <br /> seamless conversations with an AI-powered transcription tool.
          </div>
        </div>
      </div>
      <div
        style={{ backgroundImage: 'url(./stripes.png)' }}
        className='rounded-t-lg shadow-2xl h-fit bg-white w-10/12 xl:w-4/5 px-20 mx-auto text-center flex flex-col justify-center items-center gap-16 py-10 pb-36 bg-no-repeat bg-cover'
      >
        <div className='flex w-12/12 items-center h-fit p-12 bg-[#DBDAF8] rounded-lg pr-16 pl-20'>
          <div className='flex flex-col gap-4 text-left self-start pt-10'>
            <div className='text-2xl'>REAL-TIME SPEECH TRANSCRIBING</div>
            <div className='font-semibold w-10/12 rounded-lg text-[34px]'>
              Capture and understand every conversation with precise, real-time
              transcription
            </div>
          </div>
          <img
            src='/example.svg'
            alt='Example'
            className='w-1/3 border border-solid rounded-lg'
          />
        </div>
        <div className='flex w-12/12 items-center h-fit p-16 bg-[#D6EBFF] rounded-lg pr-16 pl-20'>
          <div className='flex flex-col gap-4 text-left self-start pt-10'>
            <div className='text-2xl'>MEETING ROOM ADD-ON</div>
            <div className='font-semibold w-10/12 rounded-lg text-[34px]'>
              Receive live subtitles for all your meetings, making it easier to
              follow along and stay engaged
            </div>
          </div>
          <img src='/link.png' alt='link' className='w-2/5' />
        </div>
        <div className='w-12/12 rounded-lg p-14 flex border-solid border-[#DADADA] border-[2px] bg-white px-8 pr-16'>
          <div className='flex flex-col text-left gap-4'>
            <div className='text-2xl'>LIVE BROADCAST ROOM SHARING</div>
            <div className='font-semibold w-10/12 text-[26px]'>
              Join live events remotely and read the speech in real-time,
              translated into your preferred language
            </div>
            <img src='/webglobe.png' alt='globe' className='w-fit' />
          </div>
          <img src='/pins.svg' alt='Pins' className='w-auto self-center' />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
