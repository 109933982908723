// src/components/folders/FolderList.jsx
import React, { useState } from 'react';
import { FolderItem } from './FolderItem';
import { CreateFolderButton } from './CreateFolderButton';
import { FolderPopup } from '../popups/FolderPopup';
import { RenameFolderPopup } from '../popups/RenameFolderPopup';
import { StatusMessages } from '../common/StatusMessages';

export const FolderList = ({
  folders = [],
  showCreateButton = true,
  onFolderClick,
  onCreateFolder,
  onRenameFolder,
  onDeleteFolder
}) => {
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [renameFolderOpen, setRenameFolderOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Handle folder creation
  const handleFolderCreate = async (folderName) => {
    try {
      await onCreateFolder(folderName);
      setSuccess('Folder created successfully');
    } catch (err) {
      setError(err.message || 'Failed to create folder');
    } finally {
      setCreateFolderOpen(false);
    }
  };

  // Handle folder rename
  const handleFolderRename = async (newName) => {
    try {
      await onRenameFolder(selectedFolder.id, newName);
      setSuccess('Folder renamed successfully');
    } catch (err) {
      setError(err.message || 'Failed to rename folder');
    } finally {
      setRenameFolderOpen(false);
    }
  };

  // Handle folder delete
  const handleFolderDelete = async (folder) => {
    try {
      await onDeleteFolder(folder.id);
      setSuccess('Folder deleted successfully');
    } catch (err) {
      setError(err.message || 'Failed to delete folder');
    }
  };

  // Handle folder edit (rename)
  const handleFolderEdit = (folder) => {
    setSelectedFolder(folder);
    setRenameFolderOpen(true);
  };

  return (
    <>
      <StatusMessages 
        error={error} 
        success={success} 
        clearError={() => setError(null)} 
        clearSuccess={() => setSuccess(null)} 
      />
      
      {createFolderOpen && (
        <FolderPopup
          onClose={() => setCreateFolderOpen(false)}
          onSubmit={handleFolderCreate}
        />
      )}
      
      {renameFolderOpen && selectedFolder && (
        <RenameFolderPopup
          name={selectedFolder.name}
          onClose={() => setRenameFolderOpen(false)}
          onSubmit={handleFolderRename}
        />
      )}
      
      <div className="grid grid-cols-5 gap-4">
        {/* Create folder button */}
        {showCreateButton && (
          <CreateFolderButton onClick={() => setCreateFolderOpen(true)} />
        )}
        
        {/* Folder items */}
        {folders.map((folder) => (
          <FolderItem
            key={folder.id}
            folder={folder}
            onClick={onFolderClick}
            onEdit={handleFolderEdit}
            onDelete={handleFolderDelete}
          />
        ))}
        
        {/* Show empty message if no folders */}
        {folders.length === 0 && !showCreateButton && (
          <div className="col-span-5 text-center py-6 text-gray-500">
            No folders found
          </div>
        )}
      </div>
    </>
  );
};