// src/components/common/SearchBar.jsx
import React, { useState, useEffect } from 'react';

export const SearchBar = ({ onSearch, placeholder = 'Search in My Documents' }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        onSearch(newQuery);
      }, 1000) // Reduced from 2000ms to 1000ms for better responsiveness
    );
  };

  // Clean up the timeout on unmount
  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <div className="w-96 rounded-full [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-row items-center justify-start py-2.5 pl-5 text-lg text-updated-color-grey2">
      <div className="flex flex-row items-center justify-start gap-[15px] w-full">
        <img
          className="w-[22.9px] relative h-[22.9px] overflow-hidden shrink-0"
          alt="Search icon"
          src="/general--search1.svg"
        />
        <input
          className="w-full relative font-medium bg-transparent border-none outline-none font-montserrat text-lg"
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleSearchChange}
          aria-label="Search"
        />
      </div>
    </div>
  );
};