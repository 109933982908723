import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { userInfoRef } from '../httpContext';
import { isMobile } from 'react-device-detect';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

const Nav = ({ showMobile }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        setIsUserLoggedIn(true);
        setIsCheckingAuth(false);
        clearInterval(interval);
      } else {
        setIsUserLoggedIn(false);
        setIsCheckingAuth(false);
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate],
  );

  const handleAuthNavigation = useCallback(() => {
    if (userInfoRef.current?.verified_email) {
      navigate('/transcribe');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const MobileMenuDropDown = () => {
    return (
      <div className='fixed top-0 left-0 w-full bg-white z-50 text-updated-color-blue font-montserrat'>
        <div className='flex relative px-4 md:px-16 mx-auto 2xl:px-0 items-center sm:gap-0 gap-5 py-8 pb-6 justify-between max-w-[1512px] min-h-8'>
          <div
            className='md:pl-2 cursor-pointer flex items-center gap-[5px]'
            onClick={() => handleNavigation('/')}
          >
            <img
              className='h-6 sm:h-12 w-6 sm:w-12'
              alt=''
              src='/1024px-x-1024px-round-w-text1.svg'
            />
            <div className='tracking-[-0.02em] font-medium text-md sm:text-2xl'>
              interpret
            </div>
          </div>
          {!showMobileMenu && (
            <MenuOutlined
              className='text-2xl text-updated-color-grey2 stroke-[40px] stroke-updated-color-grey2'
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
          )}
          {showMobileMenu && (
            <div className='absolute top-10 right-5 flex flex-col gap-4 text-xs sm:text-lg text-not-black-black bg-white shadow rounded-lg p-4 items-start'>
              <CloseOutlined
                className='text-lg absolute top-2 right-2'
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              />
              {!showMobile && (
                <div
                  className='flex items-center rounded-full p-2 px-3 justify-center cursor-pointer hover:bg-gray-100 w-full transition-all duration-300'
                  onClick={() => {
                    handleNavigation('/plan');
                    setShowMobileMenu(false);
                  }}
                >
                  <div className='font-medium'>Pricing</div>
                </div>
              )}
              <div
                className='flex items-center rounded-full p-2 px-3 justify-center cursor-pointer hover:bg-gray-100 w-full transition-all duration-300'
                onClick={() => {
                  handleAuthNavigation();
                  setShowMobileMenu(false);
                }}
              >
                <div className='font-medium flex items-center gap-2'>
                  {isCheckingAuth ? (
                    <div className='w-4 h-4 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 animate-pulse'></div>
                  ) : (
                    <img className='h-6 w-6' alt='Rocket' src='/rocket.png' />
                  )}
                  {isCheckingAuth
                    ? 'Checking...'
                    : isUserLoggedIn
                      ? 'Dashboard'
                      : 'Login'}
                </div>
              </div>
              <a
                className='block no-underline text-inherit flex font-medium items-center rounded-full p-2 px-3 justify-center cursor-pointer hover:bg-gray-100 w-full transition-all duration-300'
                href='https://www.blog.interpretapp.ai/'
              >
                Blog
              </a>
              <div
                className='flex font-medium items-center rounded-full p-2 px-3 justify-center cursor-pointer hover:bg-gray-100 w-full transition-all duration-300'
                onClick={() => {
                  handleNavigation('/contact');
                  setShowMobileMenu(false);
                }}
              >
                Contact Us
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return !isMobile ? (
    <div className='fixed top-0 left-0 w-full bg-white/90 z-20 text-updated-color-blue font-montserrat shadow-sm'>
      <div className='flex mx-auto px-16 2xl:px-0 items-center sm:gap-0 gap-5 py-8 pb-6 justify-between max-w-[1600px]'>
        <div
          className='pl-2 cursor-pointer flex items-center gap-[5px]'
          onClick={() => handleNavigation('/')}
        >
          <img
            className='h-6 sm:h-12 w-6 sm:w-12'
            alt=''
            src='/1024px-x-1024px-round-w-text1.svg'
          />
          <div className='tracking-[-0.02em] font-medium text-md sm:text-2xl'>
            interpret
          </div>
        </div>
        <div className='pr-2 flex gap-8 text-xs sm:text-xl text-not-black-black'>
          <div
            className='flex font-semibold text-base items-center text-[#007EF3] rounded-full p-2 px-3 justify-center cursor-pointer hover:bg-updated-color-blue/10 transition-all duration-500'
            onClick={() => handleNavigation('/contact')}
          >
            Contact
          </div>
          {!showMobile && (
            <div
              className='flex font-semibold text-base text-[#007EF3] items-center rounded-full p-2 px-3 justify-center cursor-pointer hover:bg-updated-color-blue/10 transition-all duration-500'
              onClick={() => handleNavigation('/plan')}
            >
              Pricing
            </div>
          )}
          <a
            href='https://www.blog.interpretapp.ai/'
            target='_blank'
            rel='noopener noreferrer'
            className='no-underline'
          >
            <div className='flex font-semibold text-base text-[#007EF3] bg-sky-300/20 items-center rounded-xl p-2 px-8 py-5 justify-center cursor-pointer hover:bg-sky-200 transition-all duration-500'>
              How it works
            </div>
          </a>

          <div
            className={`flex font-semibold text-base text-[#ffffff] ${isCheckingAuth ? 'bg-gradient-to-r from-[#007EF3] to-[#4DA3FF] animate-gradient-x' : 'bg-[#007EF3]'} items-center rounded-xl p-2 px-8 justify-center cursor-pointer hover:bg-[#2d65c0] transition-all duration-500 gap-2`}
            onClick={handleAuthNavigation}
          >
            {isCheckingAuth ? (
              <div className='w-5 h-5 rounded-full bg-white/80 animate-pulse'></div>
            ) : (
              <img className='h-6 w-6' alt='Rocket' src='/rocket.png' />
            )}
            {isCheckingAuth
              ? 'Checking...'
              : isUserLoggedIn
                ? 'Dashboard'
                : 'Login'}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MobileMenuDropDown />
  );
};

export default Nav;
