// src/components/folders/FolderItem.jsx
import React, { useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';

export const FolderItem = ({ 
  folder, 
  onClick, 
  onEdit, 
  onDelete 
}) => {
  const [showOptions, setShowOptions] = useState(false);
  
  const handleClick = () => {
    onClick(folder);
  };
  
  const handleOptionsClick = (e) => {
    e.stopPropagation();
    setShowOptions(!showOptions);
  };
  
  const handleEditClick = (e) => {
    e.stopPropagation();
    onEdit(folder);
    setShowOptions(false);
  };
  
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(folder);
    setShowOptions(false);
  };

  return (
    <div
      onClick={handleClick}
      className="cursor-pointer px-4 shadow-[0px_1px_3.3px_rgba(140,_140,_140,_0.09)] rounded-3xs bg-app-close-to-white-grey-bkg flex py-5 relative gap-4 border-[0.5px] border-solid border-updated-color-grey1 w-10/12 hover:bg-updated-color-grey1 transition-all duration-300 overflow-visible"
    >
      {/* More options icon */}
      <MoreOutlined
        onClick={handleOptionsClick}
        className="absolute top-3 right-1 text-2xl p-1 cursor-pointer"
      />
      
      {/* Options dropdown */}
      {showOptions && (
        <div
          className="absolute top-3 -right-40 flex flex-col gap-2 bg-white py-2 shadow rounded-md w-48 z-10"
          onMouseLeave={() => setShowOptions(false)}
        >
          <div
            className="text-base hover:bg-sky-200 p-1 cursor-pointer"
            onClick={handleEditClick}
          >
            Rename
          </div>
          <div
            className="text-base hover:bg-sky-200 p-1 cursor-pointer"
            onClick={handleDeleteClick}
          >
            Delete
          </div>
        </div>
      )}
      
      {/* Folder icon */}
      <div className="rounded-3xs bg-updated-color-purple flex flex-col items-start justify-start py-[13px] px-[11px] gap-[10px] z-[1]">
        <img
          className="w-[25px] relative h-[19.4px]"
          alt="Folder"
          src="/vector-70.svg"
        />
      </div>
      
      {/* Folder name */}
      <div className="flex flex-col gap-2">
        <div className="font-medium text-base truncate">{folder.name}</div>
      </div>
    </div>
  );
};