// src/components/popups/FolderPopup.jsx
import React, { useState, useEffect, useRef } from 'react';
import { FolderAddOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';

export const FolderPopup = ({ onSubmit, onClose, initialValue = '' }) => {
  const [folderName, setFolderName] = useState(initialValue);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef(null);
  
  // Focus the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!folderName.trim()) return;
    
    try {
      setIsSubmitting(true);
      // Wait for the onSubmit promise to resolve
      await onSubmit(folderName);
      setFolderName('');
      // After submission completes, explicitly close the modal
      onClose();
    } catch (error) {
      console.error("Error creating folder:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle escape key
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    
    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onClose]);

  return (
    <motion.div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-40 px-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div 
        className="bg-white w-full max-w-sm rounded-xl shadow-2xl overflow-hidden"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-6">
          <div className="flex items-center mb-4">
            <div className="bg-blue-50 p-3 rounded-full mr-3">
              <FolderAddOutlined className="text-xl text-blue-500" />
            </div>
            <h3 className="text-xl font-bold text-gray-800">Create New Folder</h3>
          </div>
          
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label htmlFor="folderName" className="block text-sm font-medium text-gray-700 mb-1">
                Folder Name
              </label>
              <input
                type="text"
                id="folderName"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder="Enter folder name..."
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                autoFocus
                ref={inputRef}
                disabled={isSubmitting}
              />
            </div>
            
            <div className="flex space-x-3 justify-end">
              <motion.button
                type="button"
                className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-medium"
                onClick={onClose}
                whileHover={{ backgroundColor: '#f3f4f6' }}
                whileTap={{ scale: 0.97 }}
                disabled={isSubmitting}
              >
                Cancel
              </motion.button>
              <motion.button
                type="submit"
                className={`px-4 py-2 ${!folderName.trim() || isSubmitting ? 'bg-blue-300' : 'bg-blue-500'} text-white rounded-lg font-medium flex items-center justify-center min-w-[80px]`}
                whileHover={!isSubmitting && folderName.trim() ? { backgroundColor: '#3b82f6' } : {}}
                whileTap={!isSubmitting && folderName.trim() ? { scale: 0.97 } : {}}
                disabled={!folderName.trim() || isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                    <span>Creating...</span>
                  </>
                ) : (
                  'Create'
                )}
              </motion.button>
            </div>
          </form>
        </div>
      </motion.div>
    </motion.div>
  );
};