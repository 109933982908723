import React from 'react';

export const OutOfCreditsModal = ({ onClose, onBuyCredits }) => {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[30]">
      <div className="bg-white rounded-lg p-8 max-w-md w-full">
        <div className="flex flex-col items-center">
          <img 
            src="/warning-icon.svg" 
            alt="Warning" 
            className="w-16 h-16 mb-4"
          />
          <h3 className="text-xl font-semibold mb-2">Out of Credits</h3>
          <p className="text-center mb-6">
            You have used all your available credits. Purchase more credits to continue using the transcription service.
          </p>
          <div className="flex gap-4">
            <button
              onClick={onClose}
              className="px-6 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Later
            </button>
            <button
              onClick={onBuyCredits}
              className="px-6 py-2 bg-gradient-to-r from-[#007EF3] to-[#7977D1] text-white rounded-md hover:opacity-90"
            >
              Buy Credits
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutOfCreditsModal;