import React from 'react';
import { Pagination } from '../common/Pagination';

export const MobilePagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <Pagination 
      currentPage={currentPage} 
      totalPages={totalPages} 
      onPageChange={onPageChange}
      isMobile={true}
    />
  );
};
