import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, userInfoRef } from '../../httpContext';

// This component redirects to home with subscription flag if logged in, otherwise to login
const BuyPlanRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in
    if (jwtTokenRef.current && userInfoRef.current?.verified_email) {
      // User is logged in, navigate to home and open subscriptions
      navigate('/', { state: { openSubscriptions: true } });
    } else {
      // User is not logged in, navigate to login page
      // We can also pass state to redirect back to buy_plan after login
      navigate('/login', { state: { returnTo: '/buy_plan' } });
    }
  }, [navigate]);

  return null; // This component doesn't render anything
};

export default BuyPlanRedirect;
