// src/components/popups/BasePopup.jsx
import React, { useEffect, useRef } from 'react';

export const BasePopup = ({ children, onClose, title, className = "" }) => {
  const popupRef = useRef(null);

  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Handle escape key to close
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-blue-300 bg-opacity-30">
      <div 
        ref={popupRef}
        className={`bg-white text-not-black w-2/6 h-fit p-8 gap-8 flex flex-col rounded-lg shadow-sm font-montserrat ${className}`}
      >
        {title && (
          <p className="text-xl font-medium">{title}</p>
        )}
        {children}
      </div>
    </div>

    
  );
};