import { useState, useRef, useEffect } from 'react';
import { MoreOutlined, FileOutlined } from '@ant-design/icons';
import { EditFile } from './EditFile'; // Update this import
import axios from 'axios';
import { jwtTokenRef, serverURL } from '../../httpContext';
import PortalPopup from './PortalPopup';
import MoveFile from './MoveFile';
import { motion } from 'framer-motion';
import { useHapticFeedback } from './AccessibleFeedback';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

export const MobileFile = ({
  file,
  handleFileOpen,
  handleFileRemove,
  handleFileRename,
  refreshFiles,
}) => {
  const deleteWidth = 0;
  const [newName, setNewName] = useState(file?.name);
  const [isRenaming, setIsRenaming] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openMoveFilePopup, setOpenMoveFilePopup] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const popupRef = useRef(null);
  const inputRef = useRef(null);
  const fileRef = useRef(null);
  
  const { triggerHapticFeedback } = useHapticFeedback();

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const handleRename = async (fileId, newName) => {
    try {
      triggerHapticFeedback('click');
      
      const response = await axios.post(
        `${serverURL}/rename_transcript?transcript_id=${fileId}&new_name=${newName}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      // Update local state without triggering a full refresh
      setNewName(newName);
      // Update the file name in parent component
      if (handleFileRename) {
        handleFileRename(fileId, newName);
      }
      
      triggerHapticFeedback('success');
      // Don't call refreshFiles() here to avoid resetting the view
      return true;
    } catch (error) {
      console.error('Error renaming file:', error);
      triggerHapticFeedback('error');
      return false;
    }
  };

  const handleFileMove = async (fileId, folderId) => {
    try {
      triggerHapticFeedback('click');
      await axios.post(
        `${serverURL}/add_file_to_folder?folder_id=${folderId}&file_id=${fileId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
            'Content-Type': 'application/json',
          },
          responseType: 'json',
        },
      );

      setOpenMoveFilePopup(false);
      refreshFiles();
      triggerHapticFeedback('success');
    } catch (error) {
      console.error('Error moving file:', error);
      triggerHapticFeedback('error');
    }
  };

  const handleKeyDownRename = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleRename(file.id, newName).then(success => {
        if (success) setIsRenaming(false);
      });
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsRenaming(false);
      setNewName(file?.name); // Reset to original name
    }
  };

  const handleItemClick = (e) => {
    if (isRenaming || openPopup) return; // Don't navigate if renaming or popup is open
    triggerHapticFeedback('click');
    handleFileOpen(file, file?.raw_transcript || file?.content);
  };

  // Update the click handler for the options button with better propagation prevention
  const handleOptionsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    triggerHapticFeedback('click');
    setOpenPopup(true);
  };

  // Detect clicks outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  // Handle save button click
  const handleSaveButtonClick = (e) => {
    e.stopPropagation();
    if (newName.trim() === '') {
      setNewName(file?.name);
      setIsRenaming(false);
      return;
    }
    
    triggerHapticFeedback('click');
    
    handleRename(file.id, newName)
      .then((success) => {
        if (success) {
          setIsRenaming(false);
        }
      })
      .catch(err => {
        console.error("Error in file rename:", err);
        setIsRenaming(false);
        setNewName(file?.name);
      });
  };

  return (
    <motion.div
      className='relative w-full h-full text-left text-[1rem] font-montserrat'
      onClick={handleItemClick}
      ref={fileRef}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      whileTap={{ scale: isRenaming ? 1 : 0.98 }}
      role="link"
      aria-label={`File: ${file?.name || 'Unnamed file'}, Created on: ${formatDate(file?.created_at)}`}
      tabIndex={0}
      onKeyDown={(e) => {
        if (!isRenaming && (e.key === 'Enter' || e.key === ' ')) {
          handleItemClick(e);
        }
      }}
    >
      {openMoveFilePopup && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={(e) => {
            e.stopPropagation(); // Stop propagation when clicking outside popup
            setOpenMoveFilePopup(false);
          }}
        >
          <MoveFile
            close={(e) => {
              if (e) e.stopPropagation(); // Stop propagation when closing
              setOpenMoveFilePopup(false);
            }}
            handleFileMove={(folderId) => {
              handleFileMove(file.id, folderId);
              // Stop event propagation after handling file move
              setTimeout(() => setOpenMoveFilePopup(false), 0);
            }}
          />
        </PortalPopup>
      )}
      
      {openPopup && (
        <div 
          ref={popupRef} 
          className='absolute w-fit h-fit z-50 right-[0px]'
          onClick={(e) => e.stopPropagation()} // Add explicit stop propagation
          onTouchStart={(e) => e.stopPropagation()} // Add touch event prevention
          onPointerDown={(e) => e.stopPropagation()} // Add pointer event prevention
        >
          <EditFile
            file={file}
            setIsRenamingFile={setIsRenaming}
            setOpenPopup={setOpenPopup}
            setOpenMoveFilePopup={setOpenMoveFilePopup}
            handleFileRemove={handleFileRemove}
          />
        </div>
      )}
      
      {/* Delete swipe area */}
      <div
        className='absolute top-0 bottom-0 right-0 bg-red-500 overflow-hidden rounded-xl'
        style={{ width: deleteWidth > 0 ? deleteWidth + 50 : deleteWidth }}
        onClick={(e) => {
          e.stopPropagation();
          triggerHapticFeedback('warning');
          handleFileRemove(file);
        }}
      >
        <img
          src='delete-icon.svg'
          alt='Delete'
          className='w-[1.688rem] h-[1.875rem] absolute top-6 right-[1.5rem]'
        />
      </div>
      
      {/* Main file content */}
      <motion.div
        className={`flex py-4 h-fit transition-transform transform relative z-10 rounded-xl 
                   ${isPressed ? 'bg-gray-50' : 'bg-white'} 
                   shadow-sm hover:shadow-md transition-all duration-300`}
        style={{ transform: `translateX(${-deleteWidth}px)` }}
        whileHover={isRenaming ? {} : { 
          backgroundColor: 'rgba(249, 249, 249, 0.9)',
          boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
          y: -1
        }}
        onHoverStart={() => !isRenaming && setIsPressed(true)}
        onHoverEnd={() => !isRenaming && setIsPressed(false)}
        onTapStart={() => !isRenaming && setIsPressed(true)}
        onTap={() => !isRenaming && setIsPressed(false)}
        onTapCancel={() => !isRenaming && setIsPressed(false)}
      >
        {/* File icon */}
        <div className="flex items-center justify-center h-12 w-12 ml-2">
          <FileOutlined className='text-2xl text-gray-500' aria-hidden="true" />
        </div>

        {/* Options button - only show when not renaming */}
        {!isRenaming && (
          <motion.button
            className='absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100'
            whileTap={{ scale: 0.9 }}
            onClick={handleOptionsClick} // Use updated handler
            aria-label={`Options for file ${file?.name || ''}`}
          >
            <MoreOutlined />
          </motion.button>
        )}
        
        {/* Content area */}
        <div className='flex-grow flex flex-col gap-2 pr-12'>
          {!isRenaming ? (
            <div className='font-medium flex flex-col w-full'>
              <div className='flex items-baseline gap-2 w-full'>
                <span className='max-w-[80%] break-words'>{newName}</span>
                <span className='text-gray-500 text-xs whitespace-nowrap ml-auto'>
                  {formatDate(file?.created_at)}
                </span>
              </div>
            </div>
          ) : (
            <div className='w-full flex items-center gap-2 py-1' onClick={e => e.stopPropagation()}>
              <input
                className='font-medium border border-gray-300 rounded px-2 py-1 w-3/4 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500'
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                enterKeyHint='done'
                ref={inputRef}
                type='text'
                onFocus={handleFocus}
                onKeyDown={handleKeyDownRename}
                autoFocus
                aria-label={`Rename file`}
                onClick={e => e.stopPropagation()}
              />
              <motion.button
                className='font-medium p-1 px-3 bg-blue-500 text-white rounded'
                onClick={handleSaveButtonClick}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Save
              </motion.button>
            </div>
          )}
          {file?.content && (
            <div className='text-[0.75rem] text-darkslategray-100 line-clamp-2 opacity-75'>
              {file.content}
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};