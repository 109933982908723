import React, { useEffect, useState } from 'react';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';
import { motion } from 'framer-motion';

const MoveFile = ({ close, handleFileMove }) => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await axios.get(`${serverURL}/list_folders`, {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        });
        setFolders(response.data.folders || []);
      } catch (error) {
        console.error('Error fetching folders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFolders();
  }, []);

  // Add a handler to prevent click propagation on the entire component
  const preventPropagation = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleFolderClick = (e, folderId) => {
    preventPropagation(e);
    handleFileMove(folderId);
  };

  return (
    <motion.div 
      className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full max-h-[70vh] overflow-y-auto"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
      onClick={preventPropagation}
      onPointerDown={preventPropagation}
      onTouchStart={preventPropagation}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Move to Folder</h3>
        <button 
          onClick={(e) => {
            preventPropagation(e);
            close(e);
          }}
          className="text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center py-8">
          <div className="w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      ) : folders.length === 0 ? (
        <p className="text-center py-8 text-gray-500">No folders available</p>
      ) : (
        <div className="space-y-3">
          {folders.map((folder) => (
            <motion.div
              key={folder.id}
              className="p-3 border rounded-lg cursor-pointer hover:bg-gray-50 transition-colors flex items-center"
              whileHover={{ backgroundColor: '#f9fafb' }}
              whileTap={{ scale: 0.98 }}
              onClick={(e) => handleFolderClick(e, folder.id)}
            >
              <div className="w-8 h-8 bg-blue-100 rounded-lg flex items-center justify-center mr-3">
                <i className="fas fa-folder text-blue-500"></i>
              </div>
              <span>{folder.name}</span>
            </motion.div>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default MoveFile;
