import TranscribeContainer from './TranscribeContainer';

// Export the main component
export default TranscribeContainer;

// Export other components for direct use
export { default as TranscriptionPanel } from './components/TranscriptionPanel';
export { default as NoteTakerPanel } from './components/NoteTakerPanel.jsx';
export { default as ResizablePanel } from './components/ResizablePanel.jsx';
export { default as TranscriptionMessage } from './components/TranscriptionMessage.jsx';
export { default as TimerDisplay } from './components/TimerDisplay.jsx';
export { default as LanguageDropdown } from './components/LanguageDropdown.jsx';
export { default as CreditDisplay } from '../../../components/web/CreditDisplay.jsx';

// Export hooks
export { default as useTranscription } from './hooks/useTranscription';
export { default as useAudioProcessing } from './hooks/useAudioProcessing';
export { default as useTimer } from './hooks/useTimer';
export { default as useLanguageSelection } from './hooks/useLanguageSelection';

// Export modals
export { default as ConfirmationModal } from './modals/ConfirmationModal';
export { default as CopyOptionsPopup } from './modals/CopyOptionsPopup';
export { default as OutOfCreditsModal } from './modals/OutOfCreditsModal.jsx';
