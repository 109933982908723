/**
 * Transcribe Component
 *
 * This component handles real-time speech transcription and translation.
 * It allows users to record speech, view transcriptions, and translate between languages.
 *
 * @module Transcribe
 */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWhisper } from '@chengsokdara/use-whisper';
import { motion } from 'framer-motion';
import { CheckOutlined } from '@ant-design/icons';

// Custom components
import { SavePopup } from '../../../components/mobile/SavePopup';
import RecentsSidebar from '../../../components/mobile/RecentsSidebar';
import RoomLinkAndQRCode from '../../../components/mobile/RoomLinkAndQRCode';
import { Switch } from '../../../components/Switch';
import { OutOfCreditsWindow } from '../../../components/OutOfCreditsWindow';
import Subscription from '../../../components/mobile/Subscription';
import PortalPopup from '../../../components/mobile/PortalPopup';
import { MobileChatbotWidget } from '../../../components/chatbot/MobileChatbotWidget';
import { Message } from './Message'; // Extracted message component

// Custom hooks
import { useTranscription } from '../../web2/Transcribe';
import { useLanguageSelection } from '../../web2/Transcribe';
import { useAudioProcessing } from '../../web2/Transcribe';
import { useTimer } from '../../web2/Transcribe';

// Utilities and context
import { socket } from '../../../socketContext';
import { jwtTokenRef, serverURL, userInfoRef } from '../../../httpContext';
import { formatDate, formatTime } from './utils/formatters';
import {
  fetchRoomDetails,
  textToSpeech,
  clearMessagesFromRoom,
} from './utils/api';
import { copyMessagesToClipboard } from './utils/messageHelpers';
import RoomSelectLanguageOverlay from '../../../components/mobile/RoomSelectLanguageOverlay';
import { updateMessagesMerged } from '../../../helpers/updateMessages';

// Main component
export const Transcribe = () => {
  // State for UI elements
  const [startTime, setStartTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [languageToSet, setLanguageToSet] = useState('');
  const [showOriginal, setShowOriginal] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isLoadingTTS, setIsLoadingTTS] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [isOutOfCreditsOpen, setIsOutOfCreditsOpen] = useState(false);
  const [isSubscriptionsOpen, setIsSubscriptionsOpen] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  // Refs
  const messagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const audioRef = useRef(null);
  const linkAndQRCodeRef = useRef(null);
  const isChatbotOpenRef = useRef(isChatbotOpen);
  const chatbotContextRef = useRef('');
  const roomIdRef = useRef(null);
  const passwordRef = useRef(null);

  // Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Date formatting
  const date = new Date();
  const formattedDate = formatDate(date);

  // Custom hooks
  const {
    messages,
    messagesMerged,
    isTranscribing,
    shouldShowScrollButton,
    messagesRef,
    isTranscribingRef,
    stoppedTranscribing,
    setMessages,
    setMessagesMerged,
    setTranscribing,
    scrollToBottom,
    handleTranscribeData,
    toggleTranscription,
  } = useTranscription(
    messagesContainerRef,
    shouldScroll,
    chatbotContextRef,
    roomIdRef,
  );

  const { elapsedTime, startTimer, pauseTimer, resetTimer } = useTimer();

  const {
    inputLanguage,
    outputLanguage,
    inputLanguageDisplay,
    setInputLanguageDisplay,
    outputLanguageDisplay,
    setOutputLanguageDisplay,
    isLoadingTranslation,
    setIsLoadingTranslation,
    isLoadingOutputTranslation,
    setIsLoadingOutputTranslation,
    languageDropdownVisible,
    setLanguageDropdownVisible,
    languageTwoDropdownVisible,
    setLanguageTwoDropdownVisible,
    searchBarInput,
    setSearchBarInput,
    searchBarTwoInput,
    setSearchBarTwoInput,
    inputLanguageRef,
    outputLanguageRef,
    inputLanguageDropdownRef,
    outputLanguageDropdownRef,
    usingInputLanguagePopupRef,
    usingOutputLanguagePopupRef,
    handleInputLanguageSelect,
    handleOutputLanguageSelect,
    toggleInputLanguageDropdown,
    toggleOutputLanguageDropdown,
    switchLanguages,
  } = useLanguageSelection(
    messages,
    messagesRef,
    setMessages,
    setMessagesMerged,
  );

  const { liveAudioEnabled, toggleLiveAudio } = useAudioProcessing(
    messages,
    outputLanguage,
  );

  // Handle transcription data
  const handleTranscriptionData = (blob) => {
    handleTranscribeData(
      blob,
      inputLanguage,
      isTranscribingRef,
      jwtTokenRef,
      outputLanguage,
      setTranscribing,
      stoppedTranscribing,
      userInfoRef,
      setIsOutOfCreditsOpen,
      pauseTimer,
      stopRecording,
      startRecording,
      startTimer,
      elapsedTime,
    );
  };

  // Whisper setup
  const { stopRecording, startRecording } = useWhisper({
    onDataAvailable: handleTranscriptionData,
    onTranscribe: handleTranscriptionData,
    streaming: true,
    timeSlice: 250,
    removeSilence: false,
    whisperConfig: {
      language: 'en',
    },
  });

  // useEffect(() => {
  //   if (!userInfoRef.current) {
  //     navigate('/login');
  //   }
  // }, [userInfoRef, navigate]);

  // Effects
  useEffect(() => {
    isChatbotOpenRef.current = isChatbotOpen;
  }, [isChatbotOpen]);

  useEffect(() => {
    // Handle timer
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        setElapsedTime(elapsed);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerRunning, startTime]);

  useEffect(() => {
    // Handle QR code outside click
    const handleClickOutside = (event) => {
      if (
        linkAndQRCodeRef.current &&
        !linkAndQRCodeRef.current.contains(event.target)
      ) {
        setShowQRCode(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    // Load room data
    const searchParams = new URLSearchParams(location.search);
    const roomId = searchParams.get('room_id');

    const loadRoomData = async () => {
      if (roomId !== null) {
        roomIdRef.current = roomId;
        try {
          const { password } = await fetchRoomDetails(
            roomId,
            jwtTokenRef.current,
            setMessages,
            messagesRef,
            setMessagesMerged,
          );
          passwordRef.current = password;

          // Check if we should scroll
          checkIfShouldScroll();
        } catch (error) {
          console.error('Error loading room data:', error);
        }
      } else if (localStorage.getItem('messages')) {
        // Load messages from local storage
        let messages = JSON.parse(localStorage.getItem('messages'));

        // Add timestamps if missing
        messages = messages.map((msg) => ({
          ...msg,
          timestamp: msg.timestamp || new Date().getTime(),
        }));

        setMessages(messages);
        updateMessagesMerged(
          messages,
          setMessages,
          messagesRef,
          setMessagesMerged,
        );
      }
    };

    loadRoomData();
  }, [location.search, setMessages, setMessagesMerged]);

  useEffect(() => {
    if (shouldScroll.current) scrollToBottom();
  }, [messagesMerged, scrollToBottom]);

  useEffect(() => {
    isTranscribingRef.current = isTranscribing;
  }, [isTranscribing]);

  // Functions
  const checkIfShouldScroll = () => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
      const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
      shouldScroll.current = atBottom;
    }
  };

  const handleCopyMessages = () => {
    copyMessagesToClipboard(messagesMerged, outputLanguage.current)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      })
      .catch((error) => console.error('Failed to copy:', error));
  };

  const playAudio = async (text) => {
    try {
      setIsLoadingTTS(true);

      // Stop current audio if playing
      if (playingAudio && audioRef.current) {
        audioRef.current.pause();
        setPlayingAudio(false);
      }

      const audioUrl = await textToSpeech(text, jwtTokenRef.current);

      setIsLoadingTTS(false);
      setPlayingAudio(true);

      const audio = new Audio(audioUrl);
      audioRef.current = audio;

      audio.play();
      audio.onended = () => setPlayingAudio(false);
    } catch (error) {
      setIsLoadingTTS(false);
      console.error('Error playing audio:', error);
    }
  };

  const handleTranscribeButton = useCallback(() => {
    toggleTranscription(
      userInfoRef,
      setIsOutOfCreditsOpen,
      stoppedTranscribing,
      isTranscribingRef,
      pauseTimer,
      stopRecording,
      socket,
      jwtTokenRef,
      startRecording,
      startTimer,
      setTranscribing,
    );
  }, [
    toggleTranscription,
    elapsedTime,
    pauseTimer,
    stopRecording,
    startRecording,
    startTimer,
    setTranscribing,
  ]);

  const selectLanguage = (language) => {
    setIsOverlayOpen(false);

    if (languageToSet === 'one') {
      handleInputLanguageSelect(
        language,
        inputLanguage,
        setInputLanguageDisplay,
        setLanguageDropdownVisible,
        setIsLoadingTranslation,
        messages,
        messagesRef,
        setMessages,
        setMessagesMerged,
      );
    } else {
      handleOutputLanguageSelect(
        language,
        outputLanguage,
        setOutputLanguageDisplay,
        setLanguageTwoDropdownVisible,
        setIsLoadingOutputTranslation,
        messages,
        messagesRef,
        setMessages,
        setMessagesMerged,
      );
    }
  };

  const navigateBack = () => {
    stoppedTranscribing.current = true;
    pauseTimer();

    // Save messages to localStorage before leaving
    if (messagesRef.current.length > 0) {
      setMessages([...messagesRef.current]);
      updateMessagesMerged(
        messagesRef.current,
        setMessages,
        messagesRef,
        setMessagesMerged,
      );
      localStorage.setItem('messages', JSON.stringify(messagesRef.current));
    }

    navigate('/');
  };

  const clearMessages = async () => {
    if (roomIdRef.current !== null) {
      try {
        await clearMessagesFromRoom(roomIdRef.current, jwtTokenRef.current);
      } catch (error) {
        console.error('Error clearing room messages:', error);
      }
    }

    localStorage.removeItem('messages');
    messagesRef.current = [];
    setMessagesMerged([]);
    setMessages([]);
  };

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ type: 'tween', duration: 0.25 }}
      className='absolute z-20 w-full h-full flex items-center justify-center overflow-hidden'
    >
      {/* Modals */}
      {isOutOfCreditsOpen && (
        <OutOfCreditsWindow
          onClose={() => setIsOutOfCreditsOpen(false)}
          onBuyCredits={() => {
            setIsOutOfCreditsOpen(false);
            setIsSubscriptionsOpen(true);
          }}
        />
      )}

      {isSubscriptionsOpen && (
        <PortalPopup>
          <Subscription
            closeFunc={() => setIsSubscriptionsOpen(false)}
            email={userInfoRef.current.email}
          />
        </PortalPopup>
      )}

      {showQRCode && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/20'>
          <RoomLinkAndQRCode
            link={`https://interpretapp.ai/broadcast/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`}
            onOutsideClickFunc={() => setShowQRCode(false)}
            divRef={linkAndQRCodeRef}
            isWeb={false}
            shareOnly={true}
          />
        </div>
      )}

      {/* Sidebar */}
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: isSidebarVisible ? '0%' : '100%' }}
        transition={{ type: 'tween', duration: 0.5 }}
        className={`fixed top-0 right-0 w-80 h-full transition-colors shadow-md ${isSidebarVisible ? 'bg-black/30 duration-1000' : 'duration-0'} w-full`}
        style={{ zIndex: 100 }}
      >
        <RecentsSidebar onClose={() => setIsSidebarVisible(false)} />
      </motion.div>

      {/* Main content */}
      <div className='bg-whitesmoke w-full h-full flex flex-col pt-14 sm:pt-[70px] px-2.5 sm:px-5 pb-0 sm:pb-[100px] box-border text-center text-xs text-updated-color-new-black font-montserrat relative'>
        {/* Header */}
        <div className='flex items-center w-full'>
          <img
            className='h-10 w-10 cursor-pointer'
            loading='lazy'
            alt='Back'
            src='/arrow--arrow-left-6-circle.svg'
            onClick={navigateBack}
          />
          <div className='font-medium font-montserrat w-full mr-6'>
            Transcriber {formattedDate}
          </div>
          <img
            src='/recents.svg'
            alt='Recents'
            className='h-9 w-9'
            onClick={() => setIsSidebarVisible(!isSidebarVisible)}
          />
        </div>

        {/* Language selection */}
        <div className='flex flex-row items-start justify-center w-full mt-4'>
          <div className='flex rounded-xl bg-white shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] flex-row items-center justify-center py-1 px-2.5 box-border gap-[10px]'>
            <div className='flex-1 flex flex-row items-center justify-start gap-[5px]'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  setIsOverlayOpen(true);
                  setLanguageToSet('one');
                }}
              >
                {inputLanguageDisplay}
              </div>
            </div>
            <img
              className='h-6 w-6 relative overflow-hidden shrink-0 cursor-pointer'
              loading='lazy'
              alt='Switch languages'
              src='/media--playlist---repeat-list.svg'
              onClick={switchLanguages}
            />
            <div className='flex items-center justify-start gap-[5px]'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  setIsOverlayOpen(true);
                  setLanguageToSet('two');
                }}
              >
                {outputLanguageDisplay}
              </div>
            </div>
          </div>
        </div>

        {/* Messages container */}
        <div
          className='flex-1 flex flex-col w-full overflow-y-auto items-start justify-start pt-0 px-0 pb-10 text-xs text-updated-color-new-black font-montserrat mt-4'
          ref={messagesContainerRef}
          onScroll={() => {
            const messagesContainer = messagesContainerRef.current;
            if (messagesContainer) {
              const { scrollTop, clientHeight, scrollHeight } =
                messagesContainer;
              const atBottom =
                Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
              shouldScroll.current = atBottom;
            }
          }}
        >
          <div className='flex flex-col w-full gap-2.5 text-left'>
            {messagesMerged.map((message, index) => (
              <Message
                key={index}
                showOriginal={showOriginal}
                message={message}
                languageOne={inputLanguage.current}
                languageTwo={outputLanguage.current}
                isLoadingTTS={isLoadingTTS}
                playAudio={playAudio}
              />
            ))}
          </div>
        </div>

        {/* Floating controls */}
        <div className='self-stretch relative flex flex-col items-start justify-start text-left text-xs text-updated-color-new-black font-montserrat'>
          <div className='w-full flex items-center justify-end absolute -top-10 py-2 pr-1 backdrop-blur-sm bg-white/30'>
            <div className='mr-auto'>
              <Switch
                isOn={liveAudioEnabled}
                handleToggle={toggleLiveAudio}
                onColor='bg-dodgerblue-200'
                text='Live Audio'
              />
            </div>

            {shouldShowScrollButton && (
              <img
                className='w-[34px] rounded-full mx-auto absolute top-2 left-0 right-0 h-[34px] cursor-pointer'
                loading='lazy'
                alt='Scroll down'
                src='/arrow--arrow-down-6-circle.svg'
                onClick={scrollToBottom}
              />
            )}

            <div className='flex items-center gap-1.5'>
              {roomIdRef.current && (
                <img
                  src='/share.svg'
                  alt='Share'
                  className='w-6 h-6 cursor-pointer'
                  onClick={() => setShowQRCode(true)}
                />
              )}

              <div className='text-xs font-medium text-center'>AI Chat</div>

              <img
                src='/chai2.svg'
                alt='Chatbot'
                className='w-8 h-8 cursor-pointer'
                onClick={() => setIsChatbotOpen(true)}
              />

              {!copied ? (
                <img
                  className='w-6 h-6 overflow-hidden cursor-pointer'
                  alt='Copy button'
                  src='/files--copy.svg'
                  onClick={handleCopyMessages}
                />
              ) : (
                <CheckOutlined className='text-2xl' />
              )}

              <img
                className='w-6 h-6 overflow-hidden cursor-pointer'
                alt='Delete button'
                src='/trash.svg'
                onClick={clearMessages}
              />
            </div>
          </div>

          {/* Bottom controls */}
          <div className='self-stretch flex flex-col items-start justify-start pt-2 px-0 pb-10 gap-[10px]'>
            <div className='flex justify-center items-center w-full gap-2'>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
              <div className='text-xs font-medium font-montserrat text-updated-color-new-black text-center inline-block'>
                {formatTime(elapsedTime)}
              </div>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
            </div>

            <div className='w-full flex justify-center gap-10 pl-4 mt-2'>
              <div
                className={`shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-white flex items-center justify-center h-fit p-3 border-[0.5px] border-solid border-updated-color-grey1 ${messagesMerged.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => {
                  if (messagesMerged.length > 0) {
                    setShowPopup(true);
                  }
                }}
              >
                Save
              </div>

              <div className='w-fit h-fit flex items-center justify-center'>
                {!isTranscribing ? (
                  <div
                    className='self-stretch shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-white flex flex-row items-center justify-center p-1 px-5 max-h-[40px] my-auto border-[0.5px] border-solid border-updated-color-grey1 cursor-pointer'
                    onClick={() => {
                      if (userInfoRef.current.credits <= 10) {
                        setIsOutOfCreditsOpen(true);
                        return;
                      }
                      handleTranscribeButton();
                    }}
                  >
                    <img
                      className='h-7 w-7 relative overflow-hidden shrink-0'
                      loading='lazy'
                      alt='Start transcribing'
                      src='/media--player---play.svg'
                    />
                  </div>
                ) : (
                  <div
                    className='self-stretch shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-updated-color-red flex flex-row items-center justify-center py-[10px] max-h-[40px] px-[32px] my-auto border-[0.5px] border-solid border-updated-color-grey1 cursor-pointer'
                    onClick={handleTranscribeButton}
                  >
                    <img
                      className='h-7 w-7 relative overflow-hidden shrink-0 mx-auto'
                      loading='lazy'
                      alt='Stop transcribing'
                      src='/media--player---pause.svg'
                    />
                  </div>
                )}
              </div>

              <div className='w-fit flex flex-col items-center justify-center text-center text-[10px]'>
                <div
                  className='h-[33px] w-[50px] relative cursor-pointer'
                  onClick={() => setShowOriginal(!showOriginal)}
                >
                  <div
                    className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[31px] ${
                      showOriginal
                        ? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))]'
                        : 'bg-gray-300'
                    }`}
                  />
                  <div
                    className={`absolute h-[75.76%] w-6/12 top-[12.12%] bottom-[12.12%] rounded-[50%] bg-white ${
                      showOriginal
                        ? 'right-[12%] left-[38%]'
                        : 'right-[38%] left-[12%]'
                    }`}
                  />
                </div>
                <div className='mt-2 text-xs font-medium text-center w-fit'>
                  Show Original
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Overlays */}
      {isOverlayOpen && (
        <div className='absolute top-0 left-0 w-full h-full'>
          <RoomSelectLanguageOverlay
            onClose={() => setIsOverlayOpen(false)}
            onSelect={selectLanguage}
          />
        </div>
      )}

      {showPopup && (
        <SavePopup
          stoppedTranscribing={stoppedTranscribing}
          socket={socket}
          handleTranscribeButton={handleTranscribeButton}
          jwtTokenRef={jwtTokenRef}
          setShowPopup={setShowPopup}
          isTranscribing={isTranscribing}
        />
      )}

      {/* Chatbot */}
      {isChatbotOpen && (
        <MobileChatbotWidget
          isChatbotOpenRef={isChatbotOpenRef}
          onClose={() => setIsChatbotOpen(false)}
          contextRef={chatbotContextRef}
          roomId={roomIdRef.current}
          roomPassword={passwordRef.current}
        />
      )}
    </motion.div>
  );
};
