import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import mermaid from 'mermaid';

export const downloadNotesAsPDF = async (outlineContent) => {
  const noteTakerElement = document.querySelector('.note-taker-content');
  if (!noteTakerElement || !outlineContent) {
    alert('No notes available to download.');
    return;
  }

  // Store original scroll position and styles
  const originalScroll = noteTakerElement.scrollTop;
  const originalHeight = noteTakerElement.style.height;
  const originalOverflow = noteTakerElement.style.overflow;

  // Temporarily modify the element to show all content
  noteTakerElement.style.height = 'auto';
  noteTakerElement.style.overflow = 'visible';

  const pdf = new jsPDF('p', 'mm', 'a4');
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const margin = 10;

  // Ensure Mermaid diagrams are rendered
  const mermaidElements = noteTakerElement.querySelectorAll('.mermaid');
  for (let mermaidEl of mermaidElements) {
    try {
      const mermaidCode = mermaidEl.textContent.trim();
      if (!mermaidCode) continue;

      mermaidEl.style.display = 'block';
      const { svg } = await mermaid.render(
        `mermaid-${Math.random().toString(36).substr(2, 9)}`,
        mermaidCode,
      );
      mermaidEl.innerHTML = svg;
    } catch (error) {
      console.error('Failed to render Mermaid diagram:', error);
    }
  }

  // Capture the entire content
  const canvas = await html2canvas(noteTakerElement, {
    height: noteTakerElement.scrollHeight,
    windowHeight: noteTakerElement.scrollHeight,
    scale: 2,
    useCORS: true,
    logging: false,
    backgroundColor: '#ffffff',
  });

  // Calculate dimensions
  const imgData = canvas.toDataURL('image/png');
  const imgWidth = canvas.width;
  const imgHeight = canvas.height;
  const ratio = Math.min(
    (pageWidth - 2 * margin) / imgWidth,
    (pageHeight - 2 * margin) / imgHeight,
  );
  const scaledWidth = imgWidth * ratio;
  const scaledHeight = imgHeight * ratio;

  // Add content to PDF with pagination
  let heightLeft = scaledHeight;
  let position = 0;
  let pageCount = 0;

  while (heightLeft > 0) {
    if (pageCount > 0) {
      pdf.addPage();
    }
    pdf.addImage(
      imgData,
      'PNG',
      margin,
      pageCount === 0 ? margin : -position + margin,
      scaledWidth,
      scaledHeight,
      undefined,
      'FAST',
    );
    heightLeft -= pageHeight - 2 * margin;
    position += pageHeight - 2 * margin;
    pageCount++;
  }

  // Restore original element state
  noteTakerElement.style.height = originalHeight;
  noteTakerElement.style.overflow = originalOverflow;
  noteTakerElement.scrollTop = originalScroll;

  // Download the PDF
  pdf.save('notes.pdf');
};
