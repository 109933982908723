import React, { useEffect, useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { 
  ChatbotProvider, 
  useChatbot 
} from './ChatbotContext';
import {
  ChatHeader,
  ChatInput,
  SuggestedPrompts,
  OutlineContent,
  ChatMessages
} from './ChatbotComponents';
import {
  translateMessages,
  sendMessage,
  updateOutline,
  updateSuggestedPrompts
} from './ChatbotServices';
import { top100WorldLanguages } from '../../languages';

// Inner component with access to context
const MobileChatbotWidgetInner = ({ onClose }) => {
  const {
    // State
    messages,
    setMessages,
    input,
    setInput,
    selectedTab,
    setSelectedTab,
    outlineContent,
    setOutlineContent,
    selectedLanguage,
    setSelectedLanguage,
    languageDropdownOpen,
    setLanguageDropdownOpen,
    languageSearch, 
    setLanguageSearch,
    suggestedPrompts,
    setSuggestedPrompts,
    
    // Refs
    messagesEndRef,
    outlineContentRef,
    selectedLanguageRef,
    lastContextRefSnapshotRef,
    lastContextRefSnapshotSuggestedPromptsRef,
    
    // Context
    contextRef,
    isChatbotOpenRef,
    roomId,
    roomPassword
  } = useChatbot();
  
  const languageDropdownRef = useRef(null);
  const [slideIn, setSlideIn] = useState(false);
  
  // Close language dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setLanguageDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setLanguageDropdownOpen]);
  
  // Trigger entrance animation
  useEffect(() => {
    setSlideIn(true);
  }, []);
  
  // useEffect to handle the Live Outline updates every 30 seconds
  useEffect(() => {
    let interval;
    const updateOutlineInternal = async () => {
      if (!contextRef.current) return;
      
      const currentContext = contextRef.current || '';
      const lastSnapshot = lastContextRefSnapshotRef.current || '';

      if (currentContext === lastSnapshot) return;
      
      // Get new messages since the last snapshot
      const newContent = lastSnapshot.length < 10
        ? currentContext.slice(0)
        : currentContext.slice(lastSnapshot.length - 10).trim();

      if (!newContent || !isChatbotOpenRef?.current) return;

      lastContextRefSnapshotRef.current = currentContext;
      
      const newOutline = await updateOutline(false, contextRef, selectedLanguage);
      if (newOutline) {
        setOutlineContent(newOutline);
        outlineContentRef.current = newOutline;
      }
    };
    
    if (selectedTab === 'Live Outline') {
      updateOutlineInternal();
      interval = setInterval(updateOutlineInternal, 30000);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [selectedTab, contextRef, isChatbotOpenRef, lastContextRefSnapshotRef, selectedLanguage, setOutlineContent]);
  
  // useEffect to update suggested prompts every 30 seconds
  useEffect(() => {
    let interval;
    const checkAndUpdatePrompts = async () => {
      while (!contextRef.current || contextRef.current.trim() === '') {
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
      
      const suggestions = await updateSuggestedPrompts(
        isChatbotOpenRef,
        contextRef,
        lastContextRefSnapshotSuggestedPromptsRef,
        selectedLanguage,
        roomId,
        roomPassword
      );
      
      if (suggestions) {
        setSuggestedPrompts(prev => [...prev.slice(0, 2), ...suggestions]);
      }
    };

    if (selectedTab === 'AI Chat') {
      checkAndUpdatePrompts();
      interval = setInterval(checkAndUpdatePrompts, 30000);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [selectedTab, contextRef, isChatbotOpenRef, lastContextRefSnapshotSuggestedPromptsRef, selectedLanguage, roomId, roomPassword, setSuggestedPrompts]);
  
  const handleSend = (messageContent) => {
    const content = messageContent || input;
    sendMessage(content, messages, setMessages, input, setInput, contextRef, selectedLanguage, roomId, roomPassword);
  };
  
  const handleLanguageSelect = (lang) => {
    setSelectedLanguage(lang);
    setLanguageDropdownOpen(false);
    setLanguageSearch('');
    translateMessages(
      messages, 
      setMessages, 
      outlineContent, 
      setOutlineContent, 
      suggestedPrompts, 
      setSuggestedPrompts, 
      lang
    );
  };
  
  // Filtered languages for dropdown
  const filteredLanguages = [
    'Select Language',
    ...top100WorldLanguages.filter((lang) =>
      lang.toLowerCase().includes(languageSearch.toLowerCase()),
    ),
  ];

  // Fix close button for mobile
  const handleClose = () => {
    // Start the slide out animation first
    setSlideIn(false);
    
    // Call the parent's onClose callback AFTER animation completes
    setTimeout(() => {
      if (isChatbotOpenRef) {
        isChatbotOpenRef.current = false;
      }
      onClose();
    }, 300);
  };

  // Update the ref when the component is visible
  useEffect(() => {
    if (isChatbotOpenRef) {
      isChatbotOpenRef.current = true;
    }
    
    // Clean up is handled by the close handler now
  }, [isChatbotOpenRef]);

  return (
    <div className={`fixed inset-0 z-50 bg-white flex flex-col overflow-hidden 
                    transition-transform duration-300 ease-in-out 
                    ${slideIn ? 'translate-y-0' : 'translate-y-full'}`}>
      {/* Header with fixed close button position */}
      <ChatHeader 
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        selectedLanguage={selectedLanguage}
        languageDropdownOpen={languageDropdownOpen}
        setLanguageDropdownOpen={setLanguageDropdownOpen}
        languageDropdownRef={languageDropdownRef}
        languageSearch={languageSearch}
        setLanguageSearch={setLanguageSearch}
        filteredLanguages={filteredLanguages}
        onSelectLanguage={handleLanguageSelect}
        onClose={handleClose}
      />

      {/* Content */}
      {selectedTab === 'Live Outline' ? (
        <OutlineContent content={outlineContent} />
      ) : (
        <ChatMessages messages={messages} messagesEndRef={messagesEndRef} />
      )}

      {/* Chat input and suggested prompts - only shown on AI Chat tab */}
      {selectedTab === 'AI Chat' && (
        <>
          <ChatInput 
            input={input} 
            setInput={setInput} 
            handleSend={handleSend} 
          />
          <SuggestedPrompts 
            prompts={suggestedPrompts} 
            onPromptClick={handleSend} 
          />
        </>
      )}
    </div>
  );
};

MobileChatbotWidgetInner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

// Main component wrapper
const MobileChatbotWidget = ({ onClose, contextRef, isChatbotOpenRef, roomId, roomPassword }) => {
  return (
    <div className="mobile-chatbot-wrapper">
      <ChatbotProvider
        contextRef={contextRef}
        isChatbotOpenRef={isChatbotOpenRef}
        roomId={roomId}
        roomPassword={roomPassword}
      >
        <MobileChatbotWidgetInner onClose={onClose} />
      </ChatbotProvider>
    </div>
  );
};

MobileChatbotWidget.propTypes = {
  onClose: PropTypes.func.isRequired,
  contextRef: PropTypes.object.isRequired,
  isChatbotOpenRef: PropTypes.object.isRequired,
  roomId: PropTypes.string,
  roomPassword: PropTypes.string,
};

export { MobileChatbotWidget };