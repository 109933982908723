// src/components/folders/CreateFolderButton.jsx
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

export const CreateFolderButton = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer rounded-3xs py-2 pt-3 border-solid border-[.5px] border-updated-color-grey1 flex flex-col gap-1 items-center justify-center w-11/12 hover:bg-updated-color-grey1 transition-all duration-300"
    >
      <div className="bg-whitesmoke w-fit p-2 rounded-[10px] flex items-center justify-center">
        <PlusOutlined className="text-lg" />
      </div>
      <div className="text-sm font-medium">Create a Folder</div>
    </div>
  );
};