import React from 'react';

const CreditDisplay = ({ credits, navigate, userInfoRef }) => {
  const getPlanMaxCredits = (plan) => {
    const planLimits = {
      free: 100,
      lite: 1000,
      standard: 2000,
      pro: 4000,
    };
    return planLimits[plan?.toLowerCase()] || 100;
  };

  return (
    <div className='flex flex-col gap-2 p-4 bg-white border-solid rounded-lg border border-updated-color-grey1 focus:outline-none'>
      <div className='font-montserrat font-normal text-[20px] pb-1 bg-gradient-to-r from-[#007EF3] to-[#7977D1] text-transparent bg-clip-text'>
        Your Current Balance:
      </div>

      {/* Progress Bar */}
      <div className='w-full bg-[#BFBFBF] rounded-full h-2.5'>
        <div
          className='bg-gradient-to-r from-[#007EF3] to-[#7977D1] h-2.5 rounded-full transition-all duration-300'
          style={{
            width: `${credits > 4000 ? 100 : (credits / getPlanMaxCredits(userInfoRef.current?.plan)) * 100}%`,
          }}
        ></div>
      </div>

      <div className='flex items-center flex-wrap justify-between'>
        <div className='font-medium text-[20px] pt-2 bg-gradient-to-r from-[#007EF3] to-[#7977D1] text-transparent bg-clip-text'>
          Credits: {credits}
        </div>
        <div
          onClick={() => navigate('/buy_plan')}
          className='text-sm decoration-[#7977D1] underline underline-offset-2 cursor-pointer pt-2 bg-gradient-to-r from-[#007EF3] to-[#7977D1] text-transparent bg-clip-text'
        >
          Want More Credits?
        </div>
      </div>
    </div>
  );
};

export default CreditDisplay;