// hooks/useNoteTaking.js
import { useState, useEffect, useRef, useCallback } from 'react';
// Removed unused import: import { debounce } from 'lodash';

// --- Configuration ---
const MAGNITUDE_UPDATE_DEBOUNCE_MS = 3500; // Used for final update delay
const DEFAULT_MAGNITUDE_UPDATE_THRESHOLD = 150;
const MIN_TIME_BETWEEN_UPDATES_MS = 10000; // Throttle for non-forced updates

export default function useNoteTaking({
  isToggled,
  isChatbotInitialized,
  executeChatbotMethod,
  chatbotContextRef, // Ref containing the full transcript/context
  deletedSectionsRef,
  isEditingNotes,
  initialDetailedMode = false,
  initialOutlineContent = '',
  initialActiveFormats = [],
  transcriptContent, // The state representing the full transcript (used as a trigger)
  magnitudeUpdateThreshold = DEFAULT_MAGNITUDE_UPDATE_THRESHOLD,
}) {
  // --- State ---
  const [outlineContent, setOutlineContent] = useState(initialOutlineContent);
  const [isUpdatingOutline, setIsUpdatingOutline] = useState(false);
  const [isDetailedMode, setIsDetailedMode] = useState(initialDetailedMode);
  const [activeFormats, setActiveFormats] = useState(initialActiveFormats);

  // --- Refs for internal control ---
  const updateInProgressRef = useRef(false);
  const lastUpdateTimeRef = useRef(0);
  const hasFetchedInitialContentRef = useRef(false);
  // Stores the context from chatbotContextRef.current *at the start* of the last successful/attempted update
  const contextAtLastUpdateRef = useRef('');

  // --- Core Update Logic ---
  const performOutlineUpdate = useCallback(
    async (options = {}) => {
      const { force = false, isModeChange = false, isFinalUpdate = false } = options;
      const now = Date.now();
      const timeSinceLastUpdate = now - lastUpdateTimeRef.current;

      // --- Skip Conditions ---
      // 1. Basic requirements, editing lock, or already updating (unless final)
      if (!isToggled || !isChatbotInitialized || (isEditingNotes && !force) || (updateInProgressRef.current && !isFinalUpdate)) {
        // console.log('🚫 Update skipped (Conditions unmet, editing, or already in progress)');
        return;
      }
      // 2. Throttling (only applies if not forced, not mode change, not final update)
      if (!force && !isModeChange && !isFinalUpdate && timeSinceLastUpdate < MIN_TIME_BETWEEN_UPDATES_MS) {
        // console.log(`🚫 Update skipped (Throttled - ${timeSinceLastUpdate}ms < ${MIN_TIME_BETWEEN_UPDATES_MS}ms)`);
        return;
      }

      // --- Start Update ---
      // console.log(`🚀 Starting outline update (Force: ${force}, ModeChange: ${isModeChange}, Final: ${isFinalUpdate})`);
      updateInProgressRef.current = true;
      setIsUpdatingOutline(true);
      const currentContextForBaseline = chatbotContextRef?.current || '';
      // Set baseline *before* async operation for magnitude check of subsequent transcript changes
      contextAtLastUpdateRef.current = currentContextForBaseline;

      try {
        // Determine if the backend needs a full regeneration
        const shouldUpdateBackendOutline = force || isModeChange || isFinalUpdate || !outlineContent;

        if (shouldUpdateBackendOutline) {
          // console.log(`🔄 Calling chatbot.updateOutline(${isDetailedMode})...`);
          await executeChatbotMethod('updateOutline', isDetailedMode);
        } else {
          // console.log('ℹ️ Calling chatbot.getOutlineContent (no backend regeneration)...');
        }

        // Fetch the potentially updated content
        // console.log('📄 Calling chatbot.getOutlineContent...');
        const newOutline = await executeChatbotMethod('getOutlineContent');

        if (typeof newOutline !== 'string') {
           console.warn('⚠️ Received non-string outline content:', newOutline);
           throw new Error('Invalid outline content received');
        }

        // Process Deleted Sections
        let processedOutline = newOutline;
        if (deletedSectionsRef?.current?.size > 0 && newOutline) {
          // console.log('🗑️ Filtering deleted sections...');
          const sections = newOutline.split(/(?=## )/g);
          processedOutline = sections.filter(section => {
            if (!section.startsWith('## ')) return true;
            const titleMatch = section.match(/## (.*?)\n/);
            const title = titleMatch ? titleMatch[1].trim() : null;
            return !title || !deletedSectionsRef.current.has(title);
          }).join('');
        }

        // Update State only if content actually changed
        if (processedOutline !== outlineContent) {
          // console.log('💾 Change detected. Updating local outline state.');
          setOutlineContent(processedOutline);
          lastUpdateTimeRef.current = Date.now(); // Update time only on actual change
        } else {
          // console.log('ℹ️ No change detected in outline content.');
        }

        if (!hasFetchedInitialContentRef.current) {
          hasFetchedInitialContentRef.current = true;
        }

      } catch (error) {
        console.error(`❌ Error during outline update:`, error);
      } finally {
        // console.log(`🏁 Finished outline update attempt.`);
        setIsUpdatingOutline(false);
        updateInProgressRef.current = false;
      }
    },
    [ // Dependencies for performOutlineUpdate
      isToggled,
      isChatbotInitialized,
      isEditingNotes,
      executeChatbotMethod,
      chatbotContextRef,
      deletedSectionsRef,
      isDetailedMode,
      outlineContent, // Added: Use state directly, ensures callback updates if outlineContent changes
      // setOutlineContent is stable and doesn't need to be listed
    ]
  );

  // --- Effects to Trigger Updates ---

  // Effect for Initial Toggle ON & Reset
  useEffect(() => {
    if (isToggled && isChatbotInitialized && !hasFetchedInitialContentRef.current) {
      // console.log('💡 Initial Fetch Triggered');
      contextAtLastUpdateRef.current = ''; // Reset baseline for initial fetch
      performOutlineUpdate({ force: true });
    }
    // Reset flags when toggled off
    if (!isToggled) {
      hasFetchedInitialContentRef.current = false;
      contextAtLastUpdateRef.current = ''; // Clear baseline when off
    }
  }, [isToggled, isChatbotInitialized, performOutlineUpdate]);

  // Effect for Mode Change (Detailed/Concise)
  const previousDetailedMode = useRef(isDetailedMode);
  useEffect(() => {
    if (isToggled && isChatbotInitialized && isDetailedMode !== previousDetailedMode.current) {
      // console.log(`🔄 Mode changed - Forcing update.`);
      performOutlineUpdate({ force: true, isModeChange: true });
    }
    previousDetailedMode.current = isDetailedMode;
  }, [isDetailedMode, isToggled, isChatbotInitialized, performOutlineUpdate]);

  // Effect for Transcript Changes (Magnitude Check)
  useEffect(() => {
    if (isToggled && isChatbotInitialized && !updateInProgressRef.current) {
      const currentContext = chatbotContextRef.current || '';
      const baselineContext = contextAtLastUpdateRef.current;
      const changeMagnitude = Math.abs(currentContext.length - baselineContext.length);

      // console.log(`[Magnitude Check] Diff: ${changeMagnitude}, Threshold: ${magnitudeUpdateThreshold}`);
      if (changeMagnitude >= magnitudeUpdateThreshold) {
        // console.log(`📈 Magnitude threshold reached. Forcing update.`);
        // Force update bypasses throttling but respects updateInProgressRef
        performOutlineUpdate({ force: true });
      }
    }
    // No cleanup needed here
  }, [transcriptContent, isToggled, isChatbotInitialized, magnitudeUpdateThreshold, performOutlineUpdate, chatbotContextRef]); // chatbotContextRef needed here to read .current

  // Effect for Transcription Stop (Final Update)
  const hadContentPreviously = useRef(false);
  useEffect(() => {
    const currentContext = chatbotContextRef.current || '';
    const hasContentNow = currentContext.length > 0;

    let timeoutId = null;

    if (isToggled && isChatbotInitialized) {
      // Check if content *was* present and now *is not*
      if (hadContentPreviously.current && !hasContentNow) {
        // console.log('🛑 Transcription stopped? Scheduling final update.');
        timeoutId = setTimeout(() => {
          // Re-check conditions when timeout fires
          if (isToggled && isChatbotInitialized) {
             // console.log('🚀 Performing final outline update...');
             performOutlineUpdate({ force: true, isFinalUpdate: true });
          }
        }, MAGNITUDE_UPDATE_DEBOUNCE_MS + 500); // Delay slightly longer than debounce interval

        hadContentPreviously.current = false; // Reset flag
      } else if (hasContentNow) {
        hadContentPreviously.current = true; // Set flag if content becomes present
      }
    } else {
       hadContentPreviously.current = false; // Reset if toggled off or not initialized
    }

    // Cleanup function to clear timeout if dependencies change or component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  // transcriptContent triggers this check, other deps ensure conditions are right
  }, [transcriptContent, isToggled, isChatbotInitialized, performOutlineUpdate, chatbotContextRef]); // chatbotContextRef needed here to read .current

  // --- External Control Functions ---

  // Allows manual setting of outline (e.g., after direct editing)
  const setOutlineContentManual = useCallback((content) => {
    if (content !== outlineContent) { // Use state directly for comparison
      // console.log('🔧 Setting outline content manually.');
      setOutlineContent(content);
      // IMPORTANT: Update baseline to reflect the transcript state *at the time* of the manual update.
      // This prevents immediate magnitude triggers if the manual content differs significantly from what an auto-update would produce based on the *current* transcript.
      contextAtLastUpdateRef.current = chatbotContextRef.current || '';
      lastUpdateTimeRef.current = Date.now(); // Treat manual update like a regular update for throttling purposes
      // Optional: Sync manual changes back to chatbot's internal state if needed
      // executeChatbotMethod('setOutlineContent', content);
    }
  }, [outlineContent, setOutlineContent, chatbotContextRef]); // Dependency on outlineContent for comparison, setOutlineContent for update, chatbotContextRef for baseline

  // Apply a specific formatting instruction set
  const applyFormat = useCallback(async (format) => {
    if (!isChatbotInitialized || !format?.instructions) return;
    // console.log(`🎨 Applying format: ${format.name}`);
    setIsUpdatingOutline(true);
    updateInProgressRef.current = true; // Lock updates during formatting
    setActiveFormats([format]); // Assume only one format active at a time

    const currentContent = outlineContent; // Use state directly
    const prompt = `Based on these notes:\n${currentContent}\n\nApply the following format instructions:\n${format.instructions}`;

    try {
      const methodName = 'handleNoteModification'; // Or similar
      const formattedContent = await executeChatbotMethod(methodName, prompt);

      if (formattedContent && typeof formattedContent === 'string') {
        // Use manual set to update content AND baseline correctly
        setOutlineContentManual(formattedContent);
      } else {
        console.warn(`⚠️ Format application (${format.name}) returned invalid response.`);
        setActiveFormats([]); // Revert active format state on failure
      }
    } catch (error) {
      console.error(`❌ Failed to apply format (${format.name}):`, error);
      setActiveFormats([]); // Revert active format state on error
    } finally {
      setIsUpdatingOutline(false);
      updateInProgressRef.current = false;
    }
  }, [isChatbotInitialized, executeChatbotMethod, outlineContent, setOutlineContentManual, setActiveFormats, setIsUpdatingOutline /* Added missing state setters */]); // Needs outlineContent, setOutlineContentManual

  // Revert from a format back to the standard outline
  const discontinueFormat = useCallback(async () => {
    if (!isChatbotInitialized || activeFormats.length === 0) return; // Don't run if no format active
    // console.log('↩️ Discontinuing format, reverting to standard outline.');
    setActiveFormats([]);
    // Force an update to regenerate the standard outline based on current context
    performOutlineUpdate({ force: true });
  }, [isChatbotInitialized, performOutlineUpdate, activeFormats, setActiveFormats]); // Needs activeFormats check/clear

  // --- Return Values ---
  return {
    outlineContent,
    isUpdatingOutline,
    isDetailedMode,
    setIsDetailedMode,
    activeFormats,
    setActiveFormats, // Expose setter if needed externally
    performOutlineUpdate, // Expose for potential manual trigger if needed
    setOutlineContentManual,
    applyFormat,
    discontinueFormat,
  };
}