export const noteFormats = [
  {
    id: 1,
    title: 'Educational Notes',
    instructions: `Transform the transcript into school notes in this format:

    #Title

    ##Introduction
      Write two engaging sentences that serve as a hook for any student. The introduction should be relatable and captivating.

    ---

    ##Objectives
      Provide three concise bullet points outlining the key objectives of the lesson.

    ---

    ##Vocab/Key Terms
      List 5-6 key terms along with their detailed definitions. Each term should include a clear explanation of its meaning and significance.

    ---

    ##Main Content
      Break the lesson into clear sections that are adaptable to any subject. DO AS MANY SECTIONS AS NEEDED FOR THE USER TO UNDERSTAND IT. Use the following example to see how a section should be formated:
      ### Section 1: Section Title
        Explanation: Provide a clear, step-by-step explanation of the main concept.
        Examples: Use bullet points, tables, or short examples to illustrate the idea.

      ###Section 2: Section Title
        Explanation: Provide a clear, step-by-step explanation of the main concept.
        Examples: Use bullet points, tables, or short examples to illustrate the idea.
    
    ---

    ##Links
      Include 1-3 relevant links to additional resources or information.

    ---

    ##Summary
      Provide a concise summary that recaps the essential points and key takeaways from the lesson.


      Use rich Markdown formatting including:
      - Headers (# for title, ## for sections, ### for subsections)
      - Bold text (**text**) for key terms
      - Italic text (*text*) for emphasis
      - Links ([text](url)) for references
      - Bullet points for details
      - Tables for structured data
      - Code blocks for technical content
      
      If the information would be clearer or more engaging through visual representation, use one or more of the following chart types as appropriate (choose only what best fits the data, not all):

      - Pie charts for showing proportions:
      \`\`\`mermaid
      pie title "Distribution"
      "Category A" : 40
      "Category B" : 30
      "Category C" : 30
      \`\`\`
  
      - Bar graphs for comparing values:
      \`\`\`mermaid
      graph TD
      A[Sales] --> B[Q1: 200k]
      A --> C[Q2: 150k]
      A --> D[Q3: 300k]
      \`\`\`
  
      - Line charts for tracking trends:
      \`\`\`mermaid
      xychart-beta
      title "Sales Revenue"
      x-axis [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
      y-axis "Revenue (in $)" 4000 --> 11000
      bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
      line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
      \`\`\`
  
  
      - Vertical Bar Charts for comparing values:
      \`\`\`mermaid
      xychart-beta
      title "Sample Vertical Bar Chart"
      x-axis ["Category A", "Category B", "Category C", "Category D"]
      y-axis "Values" 0 --> 100
      bar [30, 80, 45, 60]
      \`\`\`
      

      - Concept Maps for showing relationships:
      \`\`\`mermaid
      graph TD
    A[Main Concept] --> B[Related Idea 1]
    A --> C[Related Idea 2]
    B --> D[Sub-concept 1.1]
    B --> E[Sub-concept 1.2]
    C --> F[Sub-concept 2.1]
    C --> G[Sub-concept 2.2]
    style A fill:#f9f,stroke:#333
    style B fill:#bbf,stroke:#333
    style C fill:#bbf,stroke:#333
    \`\`\`


    For displaying steps and procedures:
    \`\`\`mermaid
    graph TD
    A([Begin]) --> B[Step 1: Planning]
    B --> C[Step 2: Implementation]
    C --> D{Review}
    D -->|Pass| E[Step 3: Deploy]
    D -->|Fail| B
    E --> F([Complete])
    
    style A,F fill:#90EE90
    style D fill:#FFB6C1
    \`\`\`

    For comparing and contrasting ideas:
    \`\`\`mermaid
    graph TD
    Title[Solution Analysis] --> Pros
    Title --> Cons
    
    Pros --> P1[Benefit 1]
    Pros --> P2[Benefit 2]
    Pros --> P3[Benefit 3]
    
    Cons --> C1[Drawback 1]
    Cons --> C2[Drawback 2]
    Cons --> C3[Drawback 3]
    
    style Title fill:#B39DDB,stroke:#4527A0
    style Pros fill:#81C784,stroke:#2E7D32
    style Cons fill:#EF9A9A,stroke:#C62828
    style P1,P2,P3 fill:#C8E6C9
    style C1,C2,C3 fill:#FFCDD2
    \`\`\`
      
    For historical events or sequences(REMEMBER TGO DO THIS IF IT HAS TO DO WITH HISTORY):
    \`\`\`mermaid
    graph LR
    A([1900]) --> B[Industrial Era]
    B --> C[1950s]
    C --> D[Digital Age]
    D --> E[2000s]
    E --> F[Modern Era]
    F --> G([Present])
    
    style A,G fill:#FFE0B2,stroke:#E65100
    style B,D,F fill:#90CAF9,stroke:#1565C0
    style C,E fill:#81C784,stroke:#2E7D32
    \`\`\``,
  },
  {
    id: 2,
    title: 'Meeting Notes',
    instructions: `Transform the transcript into meeting notes in this format:


     #Title

    ---

    ##Agenda:
    Extract and list the agenda items discussed during the meeting.
    If the agenda items are not explicitly stated or are fragmented in the transcript, summarize them based on the discussion flow and indicate uncertainty if needed

    ---

    ##Meeting Discussion
    Break the discussion into clear sections corresponding to the agenda items or topics discussed. For each section, include:
          Discussion Summary: Summarize the key points and topics covered.
          Notable Comments/Quotes: Include significant quotes or paraphrased statements.
          Uncertainties: Indicate any unclear or tentative information with a note (e.g., "unclear," ).

    ---

    ##Decisions
      Clearly list any decisions made during the meeting.
      For each decision, provide context if necessary and note any uncertainties (e.g., "Decision to postpone the launch (exact date not confirmed)").

    ---

    ##Action Items
      For each action item identified in the transcript, include:
          Task Description: What needs to be done.
          Responsible Person(s): The individual(s) assigned, noting if the assignment is unclear.
          Deadline: The expected deadline, if mentioned, or note if the timing is tentative.
    
    
    ---

    ##Additional Notes
      Include any extra observations, side discussions, or topics that may need follow-up.
      Mention any relevant comments that didn’t fit into the above sections.

  


      Use rich Markdown formatting including:
      - Headers (# for title, ## for sections, ### for subsections)
      - Bold text (**text**) for key terms
      - Italic text (*text*) for emphasis
      - Links ([text](url)) for references
      - Bullet points for details


      


          
          






      `,
  },
  {
    id: 3,
    title: 'To do list',
    instructions: `"Based on the following transcript, extract a list of to-dos. To display a to do list we will create a task graph diagram showing dependencies and deadlines:

    #TO-DO List
    
    \`\`\`mermaid
    flowchart TD
        A[To-Do List]
        B["⭕ Task 1: Research (In Progress)"]
        C["❌ Task 2: Write Report (Not Started)"]
        D["✅ Task 3: Submit (Completed)"]
    
        %% Style definitions
        style B fill:#FFF3CD,stroke:#FFA500  %% Yellow/Orange for In Progress
        style C fill:#FFE4E1,stroke:#DC3545  %% Red for Not Started  
        style D fill:#D4EDDA,stroke:#28A745  %% Green for Completed
    
        A --> B
        A --> C
        A --> D
    \`\`\`

    Note: Tasks will be connected based on dependencies, with deadlines shown as notes. Node colors indicate status/priority.


      Use rich Markdown formatting including:
      - Headers (# for title, ## for sections, ### for subsections)
      - Bold text (**text**) for key terms
      - Italic text (*text*) for emphasis
      - Links ([text](url)) for references
      - Bullet points for details
      `,
  },
  {
    id: 4,
    title: 'Q&A Log',
    instructions: `"Based on the following transcript create a Q&A Log: 
    
    Create a Q&A Log that captures the key questions and their corresponding answers. For each Q&A pair, include:

    Question: A brief summary of the question asked.
    Answer: The response provided.
    Note any follow-ups or clarifications if applicable, but keep it concise.
    Present the log in a clear, organized format.

      Use rich Markdown formatting including:
      - Headers (# for title, ## for sections, ### for subsections)
      - Bold text (**text**) for key terms
      - Italic text (*text*) for emphasis
      - Links ([text](url)) for references
      - Bullet points for details
      `,
  },
  {
    id: 5,
    title: 'Follow-Up Email Draft',
    instructions: `"Based on the transcript provided, generate a refined follow-up email draft. The email should include:

    A brief introduction summarizing the meeting/lecture context.
    A concise recap of key discussion points and decisions.
    A list of action items with responsible parties and deadlines (if mentioned).
    A closing statement with next steps or additional reminders.
    Keep the tone professional and clear, without excessive detail.


    Note:
      DON'T GENERATE ANY TABLES, ONLY DO A TITLE ONCE AT THE TOP, DON'T ADD ANY TITLES IN THE EMAIL ITSELF(WHICH MEANS DON'T USE ## or # IN THE EMAIL)

      Use rich Markdown formatting including:
      - Headers (# for title, ## for sections, ### for subsections)
      - Bold text (**text**) for key terms
      - Italic text (*text*) for emphasis
      - Links ([text](url)) for references
      - Bullet points for details
      `,
  },
  {
    id: 6,
    title: 'Brainstorming Log',
    instructions: `"Generate a Brainstorming/Idea Log from the transcript using a mind map. Include these categories: Ideas, Context, Potential Implications, and Follow-Ups. Make it clear, concise, and ready for review:


    
    \`\`\`mermaid
    mindmap
    root((Brainstorming/Idea Log))
      Ideas
        Idea 1: Concept Name
        Idea 2: Another Concept
        Idea 3: Emerging Thought
      Context
        Reason 1: Mentioned by Speaker A
        Reason 2: Responding to Question X
        Trigger: A challenge discussed
      Potential Implications
        Positive Impact: Increased engagement
        Opportunity: Collaboration with partners
        Risk: Requires additional resources
      Follow-Ups
        Action 1: Research feasibility
        Action 2: Contact potential collaborators
        Deadline: Set for next meeting
  
    \`\`\`

   

      Use rich Markdown formatting including:
      - Headers (# for title, ## for sections, ### for subsections)
      - Bold text (**text**) for key terms
      - Italic text (*text*) for emphasis
      - Links ([text](url)) for references
      - Bullet points for details
      `,
  },
  {
    id: 7,
    title: 'Data report',
    instructions: `Transform the transcript into educational notes with visual elements:
  
    #Title
  
    ##Key Statistics
    When numerical data is mentioned, represent it using:

    - Pie charts for showing proportions:
    \`\`\`mermaid
    pie title "Distribution"
    "Category A" : 40
    "Category B" : 30
    "Category C" : 30
    \`\`\`

    - Bar graphs for comparing values:
    \`\`\`mermaid
    graph TD
    A[Sales] --> B[Q1: 200k]
    A --> C[Q2: 150k]
    A --> D[Q3: 300k]
    \`\`\`

    - Line charts for tracking trends:
    \`\`\`mermaid
    xychart-beta
    title "Sales Revenue"
    x-axis [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
    y-axis "Revenue (in $)" 4000 --> 11000
    bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
    line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
    \`\`\`


    - Vertical Bar Charts for comparing values:
    \`\`\`mermaid
    xychart-beta
    title "Sample Vertical Bar Chart"
    x-axis ["Category A", "Category B", "Category C", "Category D"]
    y-axis "Values" 0 --> 100
    bar [30, 80, 45, 60]
    \`\`\`
   
    [Rest of your existing educational notes format...]`,
  },
];
