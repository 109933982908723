// src/components/common/StatusMessages.jsx
import React, { useEffect } from 'react';

export const ErrorMessage = ({ message, clearError }) => {
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        if (clearError) clearError();
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [message, clearError]);

  if (!message) return null;

  return (
    <div className="error-message text-red-500 p-2 rounded-md bg-red-100 border border-red-400 mb-3">
      {message}
    </div>
  );
};

export const SuccessMessage = ({ message, clearSuccess }) => {
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        if (clearSuccess) clearSuccess();
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [message, clearSuccess]);

  if (!message) return null;

  return (
    <div className="success-message text-green-500 p-2 rounded-md bg-green-100 border border-green-400 mb-3">
      {message}
    </div>
  );
};

export const StatusMessages = ({ error, success, clearError, clearSuccess }) => {
  return (
    <>
      <ErrorMessage message={error} clearError={clearError} />
      <SuccessMessage message={success} clearSuccess={clearSuccess} />
    </>
  );
};