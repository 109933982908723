import React from 'react';
import { format } from 'date-fns';

const TranscriptionMessage = ({ message, outputLanguage, inputLanguage }) => {
  const localTime = format(
    new Date(message.timestamp * 1000),
    'hh:mm:ss a',
  );

  return (
    <div className='flex flex-col m-1'>
      <span className='mb-2 text-gray-600 text-smi'>
        {localTime}
      </span>
      
      {/* Show original language message when in translation mode */}
      {outputLanguage !== 'Original' && outputLanguage !== 'Detect Language' && (
        <div className='flex gap-4 w-fit px-2 rounded-md bg-[#b3d8fb] text-sm'>
          <p className='inline'>
            {message.corrected_transcripts[inputLanguage] || message.transcript}
            <span className='inline text-gray-500'>
              {message.unconfirmed_corrected_transcripts?.hasOwnProperty(inputLanguage) 
                ? message.unconfirmed_corrected_transcripts[inputLanguage] 
                : message.unconfirmed_transcript}
            </span>
          </p>
        </div>
      )}
      
      {/* Show translated or original message */}
      <div className='flex items-start'>
        <p className='inline rounded-md bg-[#ffffff] border-solid text-sm border-[#D9D9D9] border-[.5px] p-3'>
          {outputLanguage === 'Original' || 
          outputLanguage === 'Detect Language' ||
          !message.corrected_transcripts[outputLanguage]
            ? message.transcript
            : message.corrected_transcripts[outputLanguage]
          }
          <span className='inline text-gray-500 text-sm'>
            {outputLanguage === 'Original' ||
            outputLanguage === 'Detect Language' ||
            !message.unconfirmed_corrected_transcripts?.hasOwnProperty(outputLanguage)
              ? message.unconfirmed_transcript
              : message.unconfirmed_corrected_transcripts[outputLanguage]
            }
          </span>
        </p>
      </div>
    </div>
  );
};

export default TranscriptionMessage;