import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { serverURL, userInfoRef, jwtTokenRef } from '../../httpContext';

const ProfileCurrentPlan = () => {
  const navigate = useNavigate();
  const [plan, setPlan] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [credits, setCredits] = useState(0);

  const handleCancelOrBuyPlan = async () => {
    if (disabled) return;

    try {
      if (plan === 'Free Trial') {
        navigate('/buy_plan');
      } else {
        const response = await axios.post(
          `${serverURL}/unsubscribe`,
          {
            email: userInfoRef.current.email,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtTokenRef.current}`,
            },
          },
        );

        if (response.status === 200) {
          setPlan('Free Trial');
          setDisabled(false);
        }

        console.log('Plan cancelled successfully:', response.data);
      }
    } catch (error) {
      console.error('Error cancelling plan:', error);
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      while (
        userInfoRef.current === undefined ||
        userInfoRef.current === null ||
        userInfoRef.current.email === null
      ) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      console.log('userInfoRef.current: ', userInfoRef.current);
      let plan = userInfoRef.current?.plan;
      let credits = userInfoRef.current?.credits;
      credits = parseInt(credits);
      if (plan === null || plan.length === 0) {
        plan = 'Free Trial';
        setDisabled(false);
      } else {
        setDisabled(false);
      }

      if (plan === 'pro') {
        plan = 'Pro';
      }

      if (plan === 'lite') {
        plan = 'Lite';
      }
      if (plan === 'standard') {
        plan = 'Standard';
      }

      setPlan(plan);
      setCredits(credits);
    };
    loadUser();
  }, []);

  // Plan details mapping
  const planDetails = {
    'Free Trial': {
      color: 'bg-gradient-to-br from-blue-50 to-blue-100',
      borderColor: 'border-blue-200',
      icon: './hourglass.svg',
      description: `${credits} credits remaining`,
      shadowColor: 'shadow-blue-100',
    },
    Lite: {
      color: 'bg-gradient-to-br from-green-50 to-green-100',
      borderColor: 'border-green-200',
      icon: './feather.svg',
      price: '$7.99',
      description: 'Use up to 10 hours of our services',
      shadowColor: 'shadow-green-100',
    },
    Standard: {
      color: 'bg-gradient-to-br from-orange-50 to-orange-100',
      borderColor: 'border-orange-200',
      icon: './blocks.svg',
      price: '$15.99',
      description: 'Use up to 20 hours of our services',
      shadowColor: 'shadow-orange-100',
    },
    Pro: {
      color: 'bg-gradient-to-br from-purple-50 to-purple-100',
      borderColor: 'border-purple-200',
      icon: './diamond.svg',
      price: '$24.99',
      description: 'Unlimited time use of our services',
      shadowColor: 'shadow-purple-100',
    },
  };
  const currentPlan = planDetails[plan] || planDetails['Free Trial'];

  return (
    <div className='w-full max-w-4xl mx-auto py-10 px-4 font-montserrat'>
      <h1 className='text-3xl font-bold mb-8 text-gray-800 tracking-tight'>
        Your Subscription
      </h1>

      <div className='bg-white rounded-2xl shadow-xl border border-gray-100 overflow-hidden backdrop-blur-sm'>
        {/* Header */}
        <div className='bg-gradient-to-r from-gray-50 to-gray-100 px-6 py-5 border-b border-gray-100'>
          <h2 className='text-xl font-semibold text-gray-800'>Current Plan</h2>
        </div>

        {/* Plan Card */}
        <div className='p-8'>
          <div
            className={`rounded-xl ${currentPlan.color} ${currentPlan.borderColor} border-2 p-8 
            flex flex-col md:flex-row justify-between items-start md:items-center gap-6 
            transition-all duration-300 hover:shadow-lg ${currentPlan.shadowColor}`}
          >
            {/* Plan Info */}
            <div className='flex-1'>
              <div className='flex items-center gap-3 mb-3'>
                <img
                  src={currentPlan.icon}
                  alt='Plan Icon'
                  className='w-8 h-8 transform hover:scale-110 transition-transform'
                />
                <h3 className='text-2xl font-bold bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text text-transparent'>
                  {plan}
                </h3>
              </div>

              {plan === 'Free Trial' ? (
                <div className='text-lg  text-gray-700 font-medium flex items-center gap-2'>
                  <span className='inline-block w-2 h-2 rounded-full bg-blue-500'></span>
                  {credits} credits remaining
                </div>
              ) : (
                <div className='space-y-2'>
                  <div className='text-xl text-gray-700 font-semibold'>
                    {currentPlan.price}{' '}
                    <span className='text-gray-500 text-base'>per month</span>
                  </div>
                  <div className='text-gray-600 flex items-center gap-2'>
                    <span className='inline-block w-2 h-2 rounded-full bg-green-500'></span>
                    {currentPlan.description}
                  </div>
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div className='flex flex-col sm:flex-row gap-4 w-full md:w-auto'>
              <button
                onClick={handleCancelOrBuyPlan}
                disabled={disabled}
                className={`px-6 py-3 rounded-lg font-medium transition-all duration-300 transform hover:scale-105 ${
                  plan === 'Free Trial'
                    ? 'bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white shadow-md hover:shadow-lg'
                    : disabled
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'border-2 border-red-500 text-red-500 hover:bg-red-50'
                }`}
              >
                {plan === 'Free Trial' ? 'Buy Plan' : 'Cancel Plan'}
              </button>

              {(plan === 'Lite' || plan === 'Pro' || plan === 'Standard') && (
                <button
                  onClick={() => navigate('/buy_plan')}
                  className='px-6 py-3 rounded-lg font-medium bg-gradient-to-r from-blue-600 to-blue-700 
                    hover:from-blue-700 hover:to-blue-800 text-white transition-all duration-300 
                    transform hover:scale-105 shadow-md hover:shadow-lg'
                >
                  Upgrade Plan
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Plan Comparison */}
        <div className='px-10 py-12 border-t border-gray-100 bg-gradient-to-br from-slate-50 to-white'>
          <div className='text-2xl font-bold mb-12 text-center text-not-black'>
            Choose Your Perfect Plan
          </div>

          <div className='grid grid-cols-1 md:grid-cols-4 gap-2'>
            {Object.entries(planDetails).map(([planName, details]) => (
              <div
                key={planName}
                onClick={() => navigate('/buy_plan')}
                className={`rounded-xl overflow-hidden transition-all duration-300 group ${
                  plan === planName
                    ? 'transform hover:-translate-y-1 shadow-xl ring-2 ring-blue-400'
                    : 'hover:shadow-lg border-solid border-slate-300 hover:border-blue-200'
                }`}
              >
                {/* Colorful header */}

                <div className='p-6 bg-white relative'>
                  {/* Current plan indicator */}
                  {plan === planName && (
                    <div className='absolute top-2 right-2'>
                      <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800'>
                        Current
                      </span>
                    </div>
                  )}

                  {/* Plan header */}
                  <div className='flex items-center gap-3 mb-4'>
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center ${
                        planName === 'Free Trial'
                          ? 'bg-blue-100'
                          : planName === 'Lite'
                            ? 'bg-green-100'
                            : planName === 'Pro'
                              ? 'bg-purple-100'
                              : 'bg-orange-100'
                      }`}
                    >
                      <img
                        src={details.icon}
                        alt={`${planName} Icon`}
                        className='w-6 h-6'
                      />
                    </div>
                    <h4 className='font-bold text-lg text-gray-800'>
                      {planName}
                    </h4>
                  </div>

                  {/* Price */}
                  {planName !== 'Free Trial' ? (
                    <div className='mb-4'>
                      <span
                        className={`text-2xl font-bold ${
                          planName === 'Lite'
                            ? 'text-green-600'
                            : planName === 'Pro'
                              ? 'text-purple-600'
                              : 'text-orange-600'
                        }`}
                      >
                        {details.price}
                      </span>
                      <span className='text-gray-500 text-sm'>/month</span>
                    </div>
                  ) : (
                    <div className='mb-4 h-8 flex items-end'>
                      <span className='text-blue-600 font-medium'>
                        Free to start
                      </span>
                    </div>
                  )}

                  {/* Divider */}
                  <div className='w-full h-px bg-gray-100 my-4'></div>

                  {/* Description */}
                  <p className='text-gray-600 text-sm mb-4'>
                    {details.description}
                  </p>

                  {/* Feature indicator */}
                  <div
                    className={`mt-2 text-xs font-medium ${
                      plan === planName ? 'text-blue-600' : 'text-gray-500'
                    }`}
                  >
                    {plan === planName ? (
                      <div className='flex items-center'>
                        <svg
                          className='w-4 h-4 mr-1'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                            clipRule='evenodd'
                          />
                        </svg>
                        Currently Active
                      </div>
                    ) : (
                      <div className='opacity-0 group-hover:opacity-100 transition-opacity'></div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCurrentPlan;
