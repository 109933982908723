// src/components/chatbot/ChatbotComponents.js
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion'
import { userInfoRef } from '../../httpContext';

// Message bubble component with animation
export const MessageBubble = ({ message, delay = 0, userProfilePic  }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    // Trigger animation after component mounts
    const timer = setTimeout(() => setIsVisible(true), 100 + delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div
      className={`flex items-start ${message.role === 'user' ? 'justify-end' : ''} 
                transition-opacity duration-500 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      
      <div
        className={`${
          message.role === 'assistant'
            ? 'ml-2 bg-gradient-to-r from-updated-color-blue to-updated-color-purple text-white'
            : 'mr-2 bg-white text-updated-color-new-black'
        } px-3 rounded-lg font-light shadow-xl text-[14px] max-w-[85%] transform transition-all duration-200 
          hover:shadow-lg ${message.role === 'user' ? 'hover:-translate-y-0.5' : 'hover:scale-[1.01]'}`}
      >
         <div className="flex items-start gap-2">
{message.role === 'assistant' && (
        <img
          src='/chat.svg'
          alt='Chatbot'
          className='w-5 h-5 pt-3 pr-2 '
        />
      )}
         
        <ReactMarkdown className="prose prose-sm max-w-none">{message.content}</ReactMarkdown>
       
  </div>
      </div>
    </div>
  );
};

MessageBubble.propTypes = {
  message: PropTypes.shape({
    role: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  delay: PropTypes.number,
};

// Enhanced speech bubble with hover effects
export const SpeechBubble = ({ text, onClick }) => {
  return (
    <div
      className='bg-gradient-to-r from-[#e6f2fd] to-[#f1f2fb] border-solid border-[0.5px] border-[#8C8C8C] text-[#454545] py-1.5 px-3 
                rounded-[10px] cursor-pointer text-center m-1.5 max-w-xs break-words text-sm
                shadow-sm hover:shadow-md transition-all duration-200 transform hover:-translate-y-0.5
                active:translate-y-0 active:shadow-inner active:bg-blue-600'
      onClick={onClick}
    >
      {text}
    </div>
  );
};

SpeechBubble.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

// Enhanced language dropdown with animations
export const LanguageDropdown = ({
  isOpen,
  languageRef,
  selectedLanguage,
  languageSearch,
  setLanguageSearch,
  filteredLanguages,
  onSelectLanguage,
}) => {
  const [animate, setAnimate] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      setAnimate(true);
    } else {
      const timer = setTimeout(() => setAnimate(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);
  
  if (!isOpen && !animate) return null;

  return (
    <div 
      className={`absolute bg-white border rounded-lg shadow-lg max-h-60 w-[300px] overflow-auto 
                mt-2 p-3 right-0 z-10 transition-all duration-300 ease-in-out
                ${isOpen ? 'opacity-100 transform scale-100' : 'opacity-0 transform scale-95 pointer-events-none'}`}
    >
      <input
        type='text'
        placeholder='Search language'
        value={languageSearch}
        onChange={(e) => setLanguageSearch(e.target.value)}
        className='p-2 mb-3 w-full border rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-300'
        aria-label="Search languages"
      />
      <div className='grid grid-cols-2 sm:grid-cols-3 gap-2'>
        {filteredLanguages.map((lang, index) => (
          <div
            key={index}
            className={`px-2 py-1.5 text-center cursor-pointer border rounded-lg
                      transition-colors duration-200 ease-in-out
                      ${lang === selectedLanguage 
                        ? 'bg-blue-100 border-blue-300' 
                        : 'hover:bg-gray-100'}`}
            onClick={() => onSelectLanguage(lang)}
            role="option"
            aria-selected={lang === selectedLanguage}
          >
            {lang}
          </div>
        ))}
      </div>
    </div>
  );
};

LanguageDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  languageRef: PropTypes.object,
  selectedLanguage: PropTypes.string.isRequired,
  languageSearch: PropTypes.string.isRequired,
  setLanguageSearch: PropTypes.func.isRequired,
  filteredLanguages: PropTypes.array.isRequired,
  onSelectLanguage: PropTypes.func.isRequired,
};

// Enhanced tab button with better animations
export const TabButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-6 py-1.5 rounded-[10px] transition-all duration-300 ease-in-out font-normal text-[14px] flex items-center gap-2
              ${!isActive
                ? 'border-solid border-[0.5px] border-[#8C8C8C] text-[#454545] hover:bg-gray-100 hover:shadow'
                : 'bg-gradient-to-r from-[#9fccfa] to-[#c4c9ee] text-[#454545] border-solid border-[0.5px] border-[#8C8C8C] transform active:translate-y-0.5 active:shadow-sm'}`}
    aria-pressed={isActive}
  >
    <img 
      src={isActive ? '/empty.svg' : '/full.svg'} 
      alt={isActive ? "Active tab" : "Inactive tab"}
      className="w-5 h-5"
    />
    {children}
  </button>
);

TabButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

// Chat header with improved mobile responsiveness and fixed close button position
export const ChatHeader = ({ 
  selectedTab, 
  onTabChange, 
  selectedLanguage, 
  languageDropdownOpen, 
  setLanguageDropdownOpen,
  languageDropdownRef,
  languageSearch,
  setLanguageSearch,
  filteredLanguages,
  onSelectLanguage,
  onClose 
}) => (
  <div className='bg-gradient-to-r from-[#e6f2fd] to-[#f1f2fb]  p-4'>

<div  className="pl-[28rem] ">
<button 
        onClick={onClose}
        className="p-1  "
        aria-label="Close chatbot"
      >
       
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path d="M1 1L15 15M1 15L15 1" stroke="#454545" stroke-width="1.84874" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </button>

      </div>
    {/* Eva AI Section */}
    <div className='flex items-center justify-between mb-2'>
      <div className='flex items-center justify-center pt-0 '>
        <div>
          <div className='text-[#454545] font-semibold items-center justify-center pl-44 text-[16px]'>  
          
          <motion.img
    src='/balls.svg'
    alt='balls'
    className='p-[0.145rem] pr-3 rounded-full'
    animate={{
      scale: [1, 1.2, 1],
    }}
    transition={{
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }}
    initial={{ scale: 1 }}
  />
        
        Eva AI</div>
          <div className='text-[#8C8C8C] text-[14px] items-center justify-center pl-[9.5rem] pt-1'>Personal AI Assistant</div>
        </div>
      </div>
      
      {/* Close button */}
     
    </div>

    {/* Tabs and Language Section */}
    <div className='flex items-center justify-center pt-5 space-x-2 border-[#8C8C8C] border-t-[0.5px] border-solid border-b-0 border-l-0 border-r-0'>
      <div className='flex space-x-2'>
        <TabButton 
          isActive={selectedTab === 'AI Chat'} 
          onClick={() => onTabChange('AI Chat')}
        >
          AI Chat
        </TabButton>
        
        <TabButton 
          isActive={selectedTab === 'Live Outline'} 
          onClick={() => onTabChange('Live Outline')}
        >
          Live Outline
        </TabButton>
      </div>
      
      <div className='relative' ref={languageDropdownRef}>
        <button
          onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
          className='px-3 py-2 text-[14px] rounded-[10px] text-[#454545] font-md border-solid border-[0.5px] border-[#8C8C8C]   hover:bg-gray-100 
                    transition-colors duration-200 flex items-center space-x-1'
          aria-haspopup="true"
          aria-expanded={languageDropdownOpen}
        >
          <span>{selectedLanguage}</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        
        <LanguageDropdown
          isOpen={languageDropdownOpen}
          languageRef={languageDropdownRef}
          selectedLanguage={selectedLanguage}
          languageSearch={languageSearch}
          setLanguageSearch={setLanguageSearch}
          filteredLanguages={filteredLanguages}
          onSelectLanguage={onSelectLanguage}
        />
      </div>
    </div>
  </div>
);

// Enhanced Chat input component with animations
export const ChatInput = ({ input, setInput, handleSend }) => {
  const [isFocused, setIsFocused] = useState(false);
  
  return (
    <div className='p-2 flex items-center border-t bg-gradient-to-r from-[#e6f2fd] to-[#f1f2fb] '>
      <div className={`flex-1 relative transition-all duration-200 ${isFocused ? 'scale-[1.01]' : ''}`}>
        <input
          type='text'
          placeholder='Write your message'
          className='w-[26rem] px-2 py-3 shadow-md pl-4 border rounded-full text-sm focus:outline-none 
                    transition-all duration-300 ease-in-out
                    focus:ring-2 focus:ring-blue-300 focus:border-blue-300'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSend()}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          aria-label="Message input"
        />
      </div>
      <button 
  className='ml-2 p-2 rounded-full bg-blue-500 hover:bg-blue-600 
             transition-colors duration-200 transform hover:scale-105 active:scale-95' 
  onClick={() => handleSend(input)}
  aria-label="Send message"
  disabled={!input.trim()}
>
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
  </svg>
</button>
    </div>
  );
};

ChatInput.propTypes = {
  input: PropTypes.string.isRequired,
  setInput: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
};

// Suggested prompts component
export const SuggestedPrompts = ({ prompts, onPromptClick }) => (
  <div className='flex flex-wrap justify-center bg-gradient-to-r from-[#e6f2fd] to-[#f1f2fb] items-center space-x-2 mb-4'>
    {prompts.filter(Boolean).map((prompt, index) => (
      <SpeechBubble
        key={index}
        text={prompt}
        onClick={() => onPromptClick(prompt)}
      />
    ))}
  </div>
);

SuggestedPrompts.propTypes = {
  prompts: PropTypes.array.isRequired,
  onPromptClick: PropTypes.func.isRequired,
};

// Enhanced Outline content component with better styling
export const OutlineContent = ({ content }) => {
  const [fadeIn, setFadeIn] = useState(false);
  
  useEffect(() => {
    setFadeIn(false);
    const timer = setTimeout(() => setFadeIn(true), 100);
    return () => clearTimeout(timer);
  }, [content]);
  
  return (
    <div className='flex-1 p-4 overflow-y-auto bg-gradient-to-r from-[#9fccfa] to-[#c4c9ee] chat-scrollbar'>
      <div className={`transition-opacity duration-300 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
        {content ? (
          <div className="bg-gradient-to-r from-[#9fccfa] to-[#c4c9ee] rounded-lg shadow-sm p-6 mb-4">
            <ReactMarkdown 
              className="prose prose-sm max-w-none 
                prose-headings:text-blue-700 prose-headings:font-semibold
                prose-h1:text-2xl prose-h1:border-b prose-h1:border-gray-200 prose-h1:pb-2 prose-h1:mb-4
                prose-h2:text-xl prose-h2:mt-6 prose-h2:mb-3
                prose-h3:text-lg prose-h3:mt-5 prose-h3:mb-2
                prose-p:my-2 prose-p:leading-relaxed
                prose-a:text-blue-600 prose-a:no-underline hover:prose-a:underline
                prose-strong:text-blue-800 prose-strong:font-semibold
                prose-ul:my-2 prose-li:my-0.5
                prose-table:border-collapse prose-table:w-full
                prose-th:bg-gray-100 prose-th:p-2 prose-th:border prose-th:border-gray-300
                prose-td:border prose-td:border-gray-300 prose-td:p-2"
            >
              {content}
            </ReactMarkdown>
          </div>
        ) : (
<div className="flex flex-col items-center justify-center h-full p-8 text-gray-500 bg-gradient-to-r from-[#e6f2fd] to-[#f1f2fb] rounded-lg shadow-sm">

<div className="animate-pulse mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <p className="text-center font-medium">Generating Live Outline...</p>
            <p className="text-sm text-center mt-2">Analyzing the conversation and creating structured notes</p>
          </div>
        )}
      </div>
    </div>
  );
};

OutlineContent.propTypes = {
  content: PropTypes.string.isRequired,
};

// Enhanced Chat messages component with scroll animations
export const ChatMessages = ({ messages, messagesEndRef }) => {
  const scrollContainerRef = useRef(null);
  console.log("User Profile Picture:", userInfoRef.current?.profilePicture);

  // Smooth scroll effect
  const smoothScrollToBottom = () => {
    if (scrollContainerRef.current) {
      const { scrollHeight, clientHeight } = scrollContainerRef.current;
      const maxScrollTop = scrollHeight - clientHeight;
      
      const startTime = performance.now();
      const startTop = scrollContainerRef.current.scrollTop;
      const duration = 300;
      
      const animateScroll = (timestamp) => {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easeProgress = 1 - Math.pow(1 - progress, 3); // Cubic ease out
        
        scrollContainerRef.current.scrollTop = startTop + (maxScrollTop - startTop) * easeProgress;
        
        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };
      
      requestAnimationFrame(animateScroll);
    }
  };
  
  useEffect(() => {
    if (messages.length > 0) {
      smoothScrollToBottom();
    }
  }, [messages]);
  
  return (
    <div 
      className='flex-1 p-4 overflow-y-auto bg-gradient-to-r from-[#e6f2fd] to-[#f1f2fb] scroll-smooth' 
      ref={scrollContainerRef}
    >
      <div className='space-y-4'>
        {messages.map((message, index) => (
          <MessageBubble key={index} message={message} delay={index * 50}     userProfilePic={userInfoRef?.profilePicture}



          />
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

ChatMessages.propTypes = {
  messages: PropTypes.array.isRequired,
  messagesEndRef: PropTypes.object.isRequired,
};