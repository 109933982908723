import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Icon from '@mdi/react';
import { mdiArrowDown } from '@mdi/js';

import { socket } from '../../../../socketContext';
import { jwtTokenRef } from '../../../../httpContext';
import { top100WorldLanguages } from '../../../../languages';
import TranscriptionMessage from './TranscriptionMessage';
import TimerDisplay from './TimerDisplay';
import LanguageDropdown from './LanguageDropdown';
import { Switch } from '../../../../components/Switch';
import { CopyOptionsPopup } from '../modals/CopyOptionsPopup';

const TranscriptionPanel = ({
  splitPosition,
  messagesMerged,
  messagesContainerRef,
  inputLanguage,
  outputLanguage,
  inputLanguageDisplay,
  outputLanguageDisplay,
  isTranscribing,
  handleTranscribeButton,
  elapsedTime,
  liveAudioEnabled,
  toggleLiveAudio,
  showSavedMessage,
  setShowSavedMessage,
  setShowClearConfirmation,
  shouldShowScrollButton,
  scrollToBottom,
  isLoadingTranslation,
  isLoadingOutputTranslation,
  languageDropdownVisible,
  languageTwoDropdownVisible,
  searchBarInput,
  setSearchBarInput,
  searchBarTwoInput,
  setSearchBarTwoInput,
  handleInputLanguageSelect,
  handleOutputLanguageSelect,
  toggleInputLanguageDropdown,
  toggleOutputLanguageDropdown,
  switchLanguages,
  inputLanguageRef,
  outputLanguageRef,
  inputLanguageDropdownRef,
  outputLanguageDropdownRef,
  stoppedTranscribing,
  isTranscribingRef,
  pauseTimer,
  stopRecording,
  setTranscribing,
}) => {
  // Only this state is local to this component now
  const [showCopyOptions, setShowCopyOptions] = useState(false);

  const handleCopySelection = (type) => {
    let copiedTranscript = '';

    messagesMerged.forEach((message) => {
      const localTime = format(
        new Date(message.timestamp * 1000),
        'hh:mm:ss a',
      );

      switch (type) {
        case 'original':
          if (
            inputLanguage.current !== 'Original' &&
            inputLanguage.current !== 'Detect Language' &&
            message.corrected_transcripts[inputLanguage.current]
          ) {
            copiedTranscript += `${localTime}: ${message.corrected_transcripts[inputLanguage.current]}\n\n`;
          } else {
            copiedTranscript += `${localTime}: ${message.transcript}\n\n`;
          }
          break;
        case 'translated':
          copiedTranscript += `${localTime}: ${message.corrected_transcripts[outputLanguage.current] || message.transcript}\n\n`;
          break;
        case 'both':
          const originalText =
            inputLanguage.current !== 'Original' &&
            inputLanguage.current !== 'Detect Language' &&
            message.corrected_transcripts[inputLanguage.current]
              ? message.corrected_transcripts[inputLanguage.current]
              : message.transcript;

          copiedTranscript += `${localTime}:\nOriginal: ${originalText}\nTranslated: ${message.corrected_transcripts[outputLanguage.current] || message.transcript}\n\n`;
          break;
      }
    });

    navigator.clipboard.writeText(copiedTranscript);
    setShowCopyOptions(false);
  };

  // Handle page navigation/unload to save audio and stop transcribing
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isTranscribingRef.current) {
        console.log(
          'Saving audio and stopping transcription before page unload',
        );
        setTranscribing(false);
        stoppedTranscribing.current = true;
        pauseTimer();
        stopRecording();

        try {
          // Save the audio before leaving
          const expiryDateEpochSeconds =
            Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60; // 30 days later

          socket.emit('save_audio', {
            jwt_token: jwtTokenRef.current,
            clear_audio: true,
            expiry_date_epoch_seconds: expiryDateEpochSeconds,
          });

          // Stop transcribing
          socket.emit('stop_transcribing', {
            jwt_token: jwtTokenRef.current,
          });
        } catch (error) {
          console.error('Error during page unload cleanup:', error);
        }
      }
    };

    // Add event listener for page navigation/unload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handleBeforeUnload(); // Also run the cleanup when component unmounts
    };
  }, []);

  // Only render the panel if it's visible based on splitPosition. NOTE: must always be called after all other hooks
  if (splitPosition < 32) {
    return null;
  }

  return (
    <div
      className='flex flex-col gap-8 w-full'
      style={{
        width: splitPosition > 70 ? '100%' : `${splitPosition}%`,
        position: splitPosition > 70 ? 'absolute' : 'relative',
        left: 0,
        top: 0,
        height: '100%',
        display: splitPosition < 32 ? 'none' : 'flex',
      }}
    >
      {/* Language Dropdowns */}
      {languageTwoDropdownVisible && (
        <LanguageDropdown
          ref={outputLanguageDropdownRef}
          languages={top100WorldLanguages}
          searchValue={searchBarTwoInput}
          onSearchChange={setSearchBarTwoInput}
          onLanguageSelect={handleOutputLanguageSelect}
          position='output'
        />
      )}

      {languageDropdownVisible && (
        <LanguageDropdown
          ref={inputLanguageDropdownRef}
          languages={top100WorldLanguages}
          searchValue={searchBarInput}
          onSearchChange={setSearchBarInput}
          onLanguageSelect={handleInputLanguageSelect}
          position='input'
        />
      )}

      <div className='relative overflow-hidden pb-24 h-full'>
        {/* Header */}
        <div className='font-montserrat font-semibold p-5 pb-2 pl-5 flex items-center gap-2'>
          <div className='text-2xl'>Transcription</div>
        </div>

        {/* Language Selector Bar */}
        <div className='flex items-center gap-1 border-b border-updated-color-grey1'>
          <div
            ref={inputLanguageRef}
            className='w-fit rounded-34xl flex pl-5 pr-1 cursor-pointer pb-1'
            onClick={toggleInputLanguageDropdown}
          >
            <div className='flex gap-[4px] cursor-pointer items-center'>
              {isLoadingTranslation && (
                <svg
                  className='animate-spin h-5 w-5 self-center text-updated-color-blue'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-75'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className=''
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              )}
              <div className='relative font-semibold'>
                {inputLanguageDisplay}
              </div>
              <img
                className='w-6 relative h-6'
                alt=''
                src='/select-more-language.svg'
              />
            </div>
          </div>

          <div className='w-fit'>
            <img
              className='w-9 h-9 cursor-pointer'
              alt=''
              src='/switch.svg'
              onClick={switchLanguages}
            />
          </div>

          {isLoadingOutputTranslation && (
            <div className='w-8 h-8 flex items-center justify-center'>
              <svg
                className='animate-spin h-7 w-7 text-updated-color-blue'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-75'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='10'
                ></circle>
                <path
                  className=''
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            </div>
          )}

          <div
            ref={outputLanguageRef}
            className='w-fit rounded-34xl flex items-center pr-5 cursor-pointer pb-1'
            onClick={toggleOutputLanguageDropdown}
          >
            <div className='flex items-center gap-[4px] px-2.5'>
              <div className='relative font-semibold'>
                {outputLanguageDisplay}
              </div>
              <img
                className='w-6 relative h-6'
                alt=''
                src='/select-more-language.svg'
              />
            </div>
          </div>
        </div>

        {/* Transcription Messages */}
        <div
          className='flex flex-col gap-4 overflow-y-auto h-[calc(100%-120px)] px-4'
          ref={messagesContainerRef}
        >
          <div
            className={`flex item-center justify-between absolute w-[96%] z-10 right-0 left-0 mx-auto bottom-2`}
          >
            {!isTranscribing && (
              <img
                className='w-12 h-12 absolute m-auto top-0 bottom-0 left-0 right-0 cursor-pointer hover:scale-110 transition-transform duration-300'
                alt=''
                src='/group-1707478176.svg'
                onClick={handleTranscribeButton}
              />
            )}
            {isTranscribing && (
              <img
                className='w-12 h-12  cursor-pointer absolute m-auto top-0 bottom-0 left-0 right-0  hover:scale-110 transition-transform duration-300'
                alt=''
                src='/group-1707478181.svg'
                onClick={handleTranscribeButton} // Simply call the function without parameters
              />
            )}
            <Switch
              isOn={liveAudioEnabled}
              handleToggle={toggleLiveAudio}
              onColor='bg-gradient-to-r from-[#75b1f4] to-[#a2ace7]'
              text={splitPosition > 32 ? 'Live Audio' : ''} // Only show text if panel is wide enough
              newline={true}
              showTooltip={splitPosition > 32 ? true : false}
            />
            <div className='flex w-fit justify-end gap-4'>
              <img
                className={`${
                  splitPosition > 43 ? 'mr-0' : ' absolute left-20  '
                } w-12 h-12 cursor-pointer hover:scale-110 transition-transform duration-300`}
                alt=''
                src='/group-1707478175.svg'
                onClick={() => setShowCopyOptions(!showCopyOptions)}
              />
              {showCopyOptions && (
                <CopyOptionsPopup onSelect={handleCopySelection} />
              )}
              <img
                className='w-[1.8rem] h-[1.8rem] rounded-full shadow-md p-1 cursor-pointer hover:scale-110 transition-transform duration-300'
                alt='Clear transcript'
                src='/trash1.svg'
                onClick={() => setShowClearConfirmation(true)}
              />
              <div
                onClick={() => {
                  socket.emit('save_audio', {
                    jwt_token: jwtTokenRef.current,
                  });
                  setShowSavedMessage(true);
                  setTimeout(() => setShowSavedMessage(false), 2000);
                }}
                className='cursor-pointer px-4 py-2.5 flex h-fit items-center bg-white shadow rounded-full text-sm transform hover:scale-120 transition-all duration-300'
              >
                {showSavedMessage ? 'Saved!' : 'Save'}
              </div>
            </div>
          </div>

          {/* Message list */}
          {messagesMerged.map((message, index) => (
            <TranscriptionMessage
              key={index}
              message={message}
              outputLanguage={outputLanguage.current}
              inputLanguage={inputLanguage.current}
            />
          ))}

          {/* Scroll button and timer */}
          <div className='absolute bottom-20 left-0 w-full flex flex-col gap-4 items-center'>
            {shouldShowScrollButton && (
              <div className='w-fit cursor-pointer' onClick={scrollToBottom}>
                <Icon path={mdiArrowDown} size={1} />
              </div>
            )}
            <TimerDisplay elapsedTime={elapsedTime} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranscriptionPanel;
