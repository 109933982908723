import { isMobile } from 'react-device-detect';
import { useState } from 'react';

export const Switch = ({
  isOn,
  handleToggle,
  onColor,
  text,
  loading,
  newline = false,
  showTooltip = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const mobileStyles = {
    switchCircle: `absolute text-[10px] flex items-center justify-center left-0.5 top-[1.5px] bg-white w-fit p-0.5 h-4 font-medium rounded-full transition-transform duration-300 ease-in-out ${
      isOn ? 'text-dodgerblue-200 transform translate-x-full' : 'text-gray-600'
    }`,
    switchBackground: `block w-11 pr-0.5 h-5 rounded-full border-solid border-1 border-white ${
      isOn ? onColor : 'bg-gray-600'
    } transition-colors duration-300 ease-in-out`,
  };

  const desktopStyles = {
    switchCircle: `absolute text-[10px] flex items-center justify-center left-[2px] top-0 bottom-0 my-auto bg-white w-7 h-7 font-medium rounded-full transition-transform duration-300 ease-in-out ${
      isOn
        ? 'text-dodgerblue-200 transform translate-x-[135%]'
        : 'text-gray-600'
    }`,
    switchBackground: `w-[70px] h-8 rounded-full ${
      isOn ? onColor : 'bg-gray-600'
    } transition-colors duration-300 ease-in-out`,
  };

  return (
    <label
      className={`flex ${newline && 'flex-col relative'} items-center gap-1 cursor-pointer`}
      onMouseOver={
        showTooltip
          ? () => {
              setIsHovered(true);
            }
          : null
      }
      onMouseLeave={
        showTooltip
          ? () => {
              setIsHovered(false);
            }
          : null
      }
    >
     
      <div className='relative'>
        <input
          type='checkbox'
          className='sr-only'
          checked={isOn}
          onChange={handleToggle}
          disabled={loading}
        />
        <div
          className={
            !isMobile
              ? desktopStyles.switchBackground
              : mobileStyles.switchBackground
          }
        ></div>
        <div
          className={
            !isMobile ? desktopStyles.switchCircle : mobileStyles.switchCircle
          }
        >
          {isOn ? 'ON' : 'OFF'}
        </div>
      </div>

      {showTooltip && isHovered && (

        <div className='absolute w-44 left-2 bottom-24 text-[10px] bg-updated-color-grey1 rounded-lg p-2 pl-2 pr-0 '>

          Turn on Live Audio to listen to the translated text.
         
        </div>
       
    
      )}
      <div
        className={`flex items-end text-gray-700 ${(!isMobile && 'font-medium text-xs') || 'text-[12px] ml-1'}`}
      >
        {text}
        {showTooltip && <img src='/info.svg' />}
      </div>
      
    </label>
  );
};
