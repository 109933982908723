import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { jwtTokenRef, userInfoRef, serverURL } from '../httpContext';
import { getAuth, signOut } from 'firebase/auth';
import { DownCircleTwoTone } from '@ant-design/icons';
import { PhoneOutlined } from '@ant-design/icons';

const ApplicationTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='shadow text-lg flex flex-col'>
      <div className='flex pl-16 pt-5 w-full font-normal text-[18px]'>
        <div className='flex gap-10'>
          <div
            className={`flex cursor-pointer pb-3 -mb-[.5px]  ${
              location.pathname === '/transcribe'
                ? 'text-updated-color-blue border-b-4 border-x-0 w-24 border-t-0 border-solid border-blue-500'
                : 'text-not-black transition-all duration-500 hover:text-updated-color-blue'
            }`}
            onClick={() => navigate('/transcribe')}
          >
            <div className='relative pl-3'>Record</div>
          </div>
          <div
            className={`flex cursor-pointer pb-3 -mb-[.5px]  ${
              location.pathname === '/audio-translate'
                ? 'text-updated-color-blue border-b-4 w-[17rem] border-x-0 border-t-0 border-solid border-blue-500'
                : 'text-not-black transition-all duration-500 hover:text-updated-color-blue'
            }`}
            onClick={() => navigate('/audio-translate')}
          >
            <div className='relative pl-2'>Video & Audio Transcriber</div>
          </div>
          <div
            className={`flex cursor-pointer pb-3 -mb-[.5px] ${
              location.pathname === '/file-translate'
                ? 'text-updated-color-blue border-b-4 w-[20rem] border-x-0 border-t-0 border-solid border-blue-500'
                : 'text-not-black transition-all duration-500 hover:text-updated-color-blue'
            }`}
            onClick={() => navigate('/file-translate')}
          >
            <div className='relative pl-2'>Document & Image Translator</div>
          </div>
          <div
            className={`flex cursor-pointer pb-3 -mb-[.5px] ${
              location.pathname === '/docs' ||
              location.pathname.includes('file_transcript')
                ? 'text-updated-color-blue border-b-4  w-[11rem] border-x-0 border-t-0 border-solid border-blue-500'
                : 'text-not-black transition-all duration-500 hover:text-updated-color-blue'
            }`}
            onClick={() => navigate('/docs')}
          >
            <div className='relative pl-3'>My Documents</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='shadow text-lg'>
      <div className='flex gap-10 pl-20 pt-5 max-w-[1512px] mx-auto'>
        <div
          className={`flex items-center p-2.5 -mb-[.5px] cursor-pointer ${
            location.pathname === '/transcribe'
              ? 'text-updated-color-blue border-solid border-x-0 border-t-0 border-b-4 border-blue-500'
              : 'text-updated-color-new-black transition-all duration-500 hover:text-updated-color-blue'
          }`}
          onClick={() => navigate('/transcribe')}
        >
          <div className='relative'>Dashboard</div>
        </div>
        <div
          className={`flex items-center p-2.5 -mb-[.5px] cursor-pointer ${
            location.pathname === '/profile'
              ? 'text-updated-color-blue border-solid border-x-0 border-t-0 border-b-4 border-blue-500'
              : 'text-updated-color-new-black transition-all duration-500 hover:text-updated-color-blue'
          }`}
          onClick={() => navigate('/profile')}
        >
          <div className='relative'>Account Info</div>
        </div>
        <div
          className={`flex items-center p-2.5 -mb-[.5px] cursor-pointer ${
            location.pathname === '/invoices'
              ? 'text-updated-color-blue border-solid border-x-0 border-t-0 border-b-4 border-blue-500'
              : 'text-updated-color-new-black transition-all duration-500 hover:text-updated-color-blue'
          }`}
          onClick={() => navigate('/invoices')}
        >
          <div className='relative'>Invoices</div>
        </div>
        <div
          className={`flex items-center p-2.5 -mb-[.5px] cursor-pointer ${
            location.pathname === '/profile_payment_info' ||
            location.pathname === '/buy_plan'
              ? 'text-updated-color-blue border-solid border-x-0 border-t-0 border-b-4 border-blue-500'
              : 'text-updated-color-new-black transition-all duration-500 hover:text-updated-color-blue'
          }`}
          onClick={() => navigate('/profile_payment_info')}
        >
          <div className='relative'>Payment Info</div>
        </div>
      </div>
    </div>
  );
};

const ProfileDropdown = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [hasZoomIntegration, setHasZoomIntegration] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Add event listener when dropdown is open
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  useEffect(() => {
    fetch(`${serverURL}/get_zoom_integration`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtTokenRef.current,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setHasZoomIntegration(true);
          return response.json();
        } else if (response.status === 204) {
          setHasZoomIntegration(false);
          return null;
        } else {
          setHasZoomIntegration(false);
          throw new Error('Network response was not ok');
        }
      })
      .then((data) => {
        if (data) {
          console.log('Success:', data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem('jwtToken');
    if (jwtTokenRef.current != undefined) {
      jwtTokenRef.current = undefined;
      userInfoRef.current = undefined;
    }

    await signOut(auth);

    navigate('/login');
  };

  useEffect(() => {
    const loadUser = async () => {
      while (
        userInfoRef.current === undefined ||
        userInfoRef.current === null ||
        userInfoRef.current.email === null
      ) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      console.log('userInfoRef.current: ', userInfoRef.current);
    };
    loadUser();
  }, []);

  return (
    <div className='flex flex-col font-montserrat'>
      <div className='h-[70px] flex justify-between items-center p-16 py-4 bg-gradient-to-r from-[#007ef3] to-[#7977d1]'>
        <img
          className='cursor-pointer h-fit w-fit'
          alt='Logo'
          src='/interper.svg'
          onClick={() => {
            navigate('/');
          }}
        />
        <div className='flex justify-center items-center gap-2'>
          <div className='font-bold text-white'>Hello,</div>
          <div
            ref={dropdownRef}
            className='w-[43px] h-[43px] rounded-full p-px bg-white relative cursor-pointer profile-dropdown-trigger'
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <DownCircleTwoTone className='text-xl absolute -bottom-1 -left-1' />
            <img
              className='w-full h-full object-cover rounded-full'
              alt='Profile'
              src={userInfoRef?.current?.profile_picture || '/no-picture.svg'}
            />
            {menuOpen && (
              <div className='absolute top-9 z-10 right-8 shadow rounded-lg bg-white flex flex-col overflow-hidden w-48 pr-4'>
                <div className='relative leading-[22px] font-semibold px-[15px] py-2 text-blue-500 cursor-default border-x-0 border-t-0 border-solid border-b-[0.5px] border-updated-color-grey3 w-full'>
                  {parseInt(userInfoRef.current?.credits) || 0}{' '}
                  <span className='text-black'>credits</span>
                </div>
                {/* <div className='relative leading-[22px] font-medium px-[15px] pr-0 py-4 text-ellipsis overflow-hidden'>
                    {email}
                  </div> */}
                {/* <div className='bg-gradient-to-r from-[#007ef3] to-[#7977d1] py-[5px] px-[15px] pr-28 w-full text-sm text-white'>
                    <div className='flex items-center gap-[5px]'>
                      <div className='relative leading-[22px] font-medium'>
                        {plan}
                      </div>
                      <div className='relative text-xs leading-[22px]'>
                        current plan
                      </div>
                    </div>
                  </div> */}
                <div
                  className='w-full flex items-center py-2 pt-4 px-[15px] pl-3 cursor-pointer'
                  onClick={() => {
                    if (!hasZoomIntegration) {
                      window.location.href =
                        'https://zoom.us/oauth/authorize?response_type=code&client_id=Ra9qH0RyS_2WdWqQnaFWMw&redirect_uri=https://interpretapp.ai/transcribe';
                    } else {
                      window.location.href =
                        'https://marketplace.zoom.us/user/dashboard'; // Link to disable integration
                    }
                  }}
                >
                  <div className='flex items-center gap-2'>
                    <img
                      className='w-6 h-6'
                      alt='Profile Icon'
                      src='/zoom.svg'
                    />
                    <div className='font-medium transition-all duration-500 hover:text-updated-color-blue'>
                      {hasZoomIntegration
                        ? 'Disable Integration'
                        : 'Zoom Integration'}
                    </div>
                  </div>
                </div>
                <div
                  className='w-full flex items-center py-3 pt-4 pr-0 px-[15px] pl-3 cursor-pointer'
                  onClick={() => {
                    navigate('/profile');
                    setMenuOpen(false);
                  }}
                >
                  <div className='flex items-center gap-2'>
                    <img
                      className='w-6 h-6'
                      alt='Profile Icon'
                      src='/profile.svg'
                    />
                    <div className='relative font-medium transition-all duration-500 hover:text-updated-color-blue'>
                      Profile
                    </div>
                  </div>
                </div>
                <div
                  className='w-full flex items-center py-3 pr-0 px-[15px] pl-3 cursor-pointer'
                  onClick={() => {
                    navigate('/profile_payment_info');
                    setMenuOpen(false);
                  }}
                >
                  <div className='flex items-center gap-2'>
                    <img className='w-6 h-6' alt='Cart Icon' src='/cart.svg' />
                    <div className='relative font-medium transition-all duration-500 hover:text-updated-color-blue'>
                      Subscription
                    </div>
                  </div>
                </div>
                <div
                  className='w-full flex items-center py-3 pr-0 px-[15px] pl-3 cursor-pointer'
                  onClick={() => {
                    navigate('/contact');
                    setMenuOpen(false);
                  }}
                >
                  <div className='flex items-center gap-2'>
                    <PhoneOutlined className='text-2xl' />
                    <div className='relative font-medium transition-all duration-500 hover:text-updated-color-blue'>
                      Contact Us
                    </div>
                  </div>
                </div>
                <div
                  className='w-full flex items-center py-3 pr-0 px-[15px] pl-3 cursor-pointer'
                  onClick={handleLogout}
                >
                  <div className='flex items-center gap-2'>
                    <img
                      className='w-6 h-6'
                      alt='Logout Icon'
                      src='/logout.svg'
                    />
                    <div className='relative font-medium transition-all duration-500 hover:text-updated-color-blue'>
                      Log Out
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {location.pathname.includes('profile') ||
      location.pathname.includes('invoices') ||
      location.pathname.includes('buy_plan') ? (
        <ProfileTabs />
      ) : (
        <ApplicationTabs />
      )}
    </div>
  );
};

export default ProfileDropdown;
