import { useState, useEffect } from 'react';

/**
 * Popup for creating/applying/editing/deleting formats
 */
export const NoteFormatPopup = ({
  isOpen,
  onClose,
  formats,
  onCreateFormat,
  onApplyFormat,
  formatToEdit,
  activeFormats,
}) => {
  const [view, setView] = useState('main');
  const [newFormat, setNewFormat] = useState({
    title: '',
    instructions: '',
  });

  // If a format is passed in for editing, switch to 'create' view
  useEffect(() => {
    if (formatToEdit) {
      setNewFormat({
        id: formatToEdit.id,
        title: formatToEdit.title,
        instructions: formatToEdit.instructions,
      });
      setView('create');
    }
  }, [formatToEdit]);

  const handleCreate = (shouldApply) => {
    if (newFormat.title && newFormat.instructions) {
      onCreateFormat(newFormat, shouldApply);
      // reset
      setNewFormat({ title: '', instructions: '' });
      setView('main');
    }
  };

  const handleCancel = () => {
    setNewFormat({ title: '', instructions: '' });
    setView('main');
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 bg-black/50 flex items-center justify-center z-50 ${
        isOpen ? '' : 'hidden'
      }`}
    >
      <div className='bg-white rounded-lg p-6 w-[1000px] relative border-3 '>
        <button
          onClick={onClose}
          className='absolute top-4 right-4 text-gray-600 hover:text-gray-800'
        >
          <svg
            className='w-6 h-6'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>

        {view === 'create' && (
          <button
            onClick={() => setView('main')}
            className='absolute top-3 left-2 text-gray-600 hover:text-gray-800'
          >
            <img src='/back.svg' className='w-4 h-4' />
          </button>
        )}

        {view === 'main' && (
          <>
            <div className='text-2xl font-normal  top-3 mt-1'>
              Choose Your Template
            </div>

            <div className='text-base font-normal text-zinc-500 top-0  mb-10'>
              Transform your notes instantly with ready-to-use templates
            </div>

            {/* Add this condition */}
            {(!formats || formats.length === 0) && (
              <div className='text-center text-gray-500 italic mb-6'>
                No formats have been created yet
              </div>
            )}
            <div className='grid grid-cols-4 gap-6 pb-5 mb-6 max-h-[500px] overflow-y-auto'>
              {formats.map((format) => (
                <div
                  key={format.id}
                  className='py-2 px-1 border  border-solid border-[#d9d9d9] bg-white shadow-sm group relative hover:shadow-lg transition-all duration-300 flex flex-col items-center text-center'
                >
                  <img
                    src={`/${format.title.toLowerCase().replace(/\s+/g, '-').replace('/', ':')}.png`}
                    alt={format.title}
                    className='w-[100px] h-[100px] object-cover   shadow-sm transition-transform group-hover:scale-105'
                  />
                  <div className='mt-3 font-montserrat font-semibold text-[#454545] text-[16px]'>
                    {format.title}
                  </div>

                  <div className='mt-0 text-gray-900 py-2 text-[14px] font-inter px-1  h-16 overflow-hidden flex flex-col justify-start'>
                    {format.title === 'Educational Notes' && (
                      <div className='flex flex-col gap-2 '>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Key concepts & definitions</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Visual learning aids</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Structured summaries</span>
                        </div>
                      </div>
                    )}

                    {format.title === 'Meeting Notes' && (
                      <div className='flex flex-col gap-2'>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Action items & owners</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Key decisions made</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Next steps & deadlines</span>
                        </div>
                      </div>
                    )}

                    {format.title === 'To do list' && (
                      <div className='flex flex-col gap-2'>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Priority tasks</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Assigned owners</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Timeline tracking</span>
                        </div>
                      </div>
                    )}

                    {format.title === 'Q&A Log' && (
                      <div className='flex flex-col gap-2'>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Quick questions</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Direct answers</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Follow-up notes</span>
                        </div>
                      </div>
                    )}

                    {format.title === 'Follow-Up Email Draft' && (
                      <div className='flex flex-col gap-2'>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Meeting recap</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Action items</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Next steps</span>
                        </div>
                      </div>
                    )}

                    {format.title === 'Brainstorming Log' && (
                      <div className='flex flex-col gap-2'>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Mind mapping</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Key concepts</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Idea flows</span>
                        </div>
                      </div>
                    )}

                    {format.title === 'Data report' && (
                      <div className='flex flex-col gap-2'>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Visual charts</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Key metrics</span>
                        </div>
                        <div className='flex items-start'>
                          <span className='w-4'>•</span>
                          <span>Data insights</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      if (activeFormats.some((f) => f.id === format.id)) {
                        handleDiscontinueFormat(format.id);
                      } else {
                        onApplyFormat(format);
                      }
                    }}
                    className={` px-10 py-1  rounded-full text-[12px] font-medium transition-all duration-300 ${
                      activeFormats.some((f) => f.id === format.id)
                        ? 'bg-red-500 text-white hover:opacity-75'
                        : 'bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] text-white hover:opacity-75'
                    }`}
                  >
                    {activeFormats.some((f) => f.id === format.id)
                      ? 'Discontinue'
                      : 'Apply'}
                  </button>

                  {/*
      <div className='left-40 top-7 flex gap-2'>
        <label className='relative inline-flex items-center cursor-pointer'>
          <input
            type='checkbox'
            className='sr-only peer'
            checked={activeFormats.find(
              (f) => f.id === format.id,
            )}
            onChange={() => {
              if (activeFormats.find((f) => f.id === format.id)) {
                handleDiscontinueFormat(format.id);
              } else {
                onApplyFormat(format);
              }
            }}
          />
          <div className="w-11 h-6 bg-[#454545] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['OFF'] after:flex after:items-center after:justify-center after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:content-['ON'] after:font-montserrat after:font-semibold after:text-[8px] after:text-black/50 peer-checked:bg-gradient-to-r peer-checked:from-[#75b1f4] peer-checked:to-[#a2ace7]"></div>
        </label>



                      {/*
                      <img
                        src='/edit.svg'
                        alt='Edit'
                        onClick={() => onEditFormat(format)}
                        className='w-8 h-8 cursor-pointer hover:opacity-70'
                      />
                      <img
                        src='/trash1.svg'
                        alt='Delete'
                        onClick={() => onDeleteFormat(format.id)}
                        className='w-8 h-8 cursor-pointer hover:opacity-70'
                      />
                        */}
                </div>
              ))}
            </div>
            {/*
              <button
                onClick={() => setView('create')}
                className='w-full py-2 bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] text-white rounded-lg transition-all duration-400 hover:bg-gradient-to-r hover:from-[#a2ace7] hover:to-[#75b1f4] '
              >
                Create New Format
              </button>

                      */}
          </>
        )}

        {view === 'create' && (
          <>
            <div className='text-xl font-semibold mb-4'>Create Note Format</div>
            <div className='text-sm text-[#9a9a9a] font-normal pb-6'>
              How It Works: Enter a title and instructions - AI will format your
              notes when you apply the template.
            </div>
            <div className='space-y-4'>
              <input
                type='text'
                placeholder="Enter Format Title (e.g., 'Bullet Points', 'Meeting Summary')"
                className='w-full p-2 border-solid rounded-md border-updated-color-blue'
                value={newFormat.title}
                onChange={(e) =>
                  setNewFormat({ ...newFormat, title: e.target.value })
                }
              />
              <textarea
                placeholder="Enter Format Instructions (e.g., 'Translate to Spanish', 'Summarize')"
                className='w-full p-2 border rounded-lg placeholder-[#d8e8ff] h-32 bg-gradient-to-r from-[#75b1f4] to-[#a2ace7] text-white  '
                value={newFormat.instructions}
                onChange={(e) =>
                  setNewFormat({ ...newFormat, instructions: e.target.value })
                }
              />
              <div className='flex gap-3 justify-end'>
                <button
                  onClick={handleCancel}
                  className='px-4 py-2 text-gray-600 hover:text-gray-800'
                >
                  Cancel
                </button>
                {/* If editing, you could show "Update" vs. "Create" dynamically */}
                <button
                  onClick={() => handleCreate(false)}
                  className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'
                >
                  {formatToEdit ? 'Update' : 'Create'}
                </button>
                <button
                  onClick={() => handleCreate(true)}
                  className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
                >
                  {formatToEdit ? 'Update + Apply' : 'Create + Apply'}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
