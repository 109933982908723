// src/components/files/FilesHeader.jsx
import React from 'react';

export const FilesHeader = ({ title, viewAllEnabled, isViewingAll, onToggleViewAll }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="font-semibold text-xl">{title}</div>
      {viewAllEnabled && (
        <div
          onClick={onToggleViewAll}
          className="text-base cursor-pointer hover:text-updated-color-blue"
        >
          {isViewingAll ? 'View Less' : 'View All'}
        </div>
      )}
    </div>
  );
};