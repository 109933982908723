import React from 'react';
import { motion } from 'framer-motion';

export const FileActionButtons = ({ 
  file, 
  folders, 
  onRename, 
  onDelete, 
  onMove 
}) => {
  const handleRenameClick = (e) => {
    e.stopPropagation();
    onRename(file);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(file);
  };

  const handleMoveClick = (e) => {
    e.stopPropagation();
    onMove(file);
  };
  
  const buttonVariants = {
    hover: { 
      scale: 1.1,
      boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
      backgroundColor: "rgba(186, 230, 253, 0.7)" // Light blue background
    },
    tap: { 
      scale: 0.9
    }
  };

  return (
    <div className="flex justify-end gap-2 relative" onClick={(e) => e.stopPropagation()}>
      {/* Rename button */}
      <motion.div
        className="rounded-full overflow-hidden flex p-1.5 bg-white border border-gray-200 cursor-pointer"
        onClick={handleRenameClick}
        title="Rename file"
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
      >
        <img src="/icons/rename-icon.svg" alt="Rename" width="16" height="16" />
      </motion.div>
      
      {/* Move button */}
      <motion.div
        className="rounded-full overflow-hidden flex p-1.5 bg-white border border-gray-200 cursor-pointer"
        onClick={handleMoveClick}
        title="Move file"
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
      >
        <img src="/icons/move-icon.svg" alt="Move" width="16" height="16" />
      </motion.div>
      
      {/* Delete button */}
      <motion.div
        className="rounded-full overflow-hidden flex p-1.5 bg-white border border-gray-200 cursor-pointer"
        onClick={handleDeleteClick}
        title="Delete file"
        variants={buttonVariants}
        whileHover="hover"
        whileTap="tap"
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
      >
        <img src="/icons/delete-icon.svg" alt="Delete" width="16" height="16" />
      </motion.div>
    </div>
  );
};
